import { ChangeEvent } from 'react';

interface PaginationSliderProps {
  min: number;
  max: number;
  onPageChange?: (page: number) => void;
  step?: number;
  value: number;
}

export function PaginationSlider({
  min,
  max,
  onPageChange,
  step = 1,
  value,
}: PaginationSliderProps) {
  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const newStep = Number.parseInt(e.target.value);
    onPageChange?.(newStep);
  }

  return (
    <div className="isolate flex flex-col justify-center nopan nodrag">
      <input
        id="default-range"
        type="range"
        value={value}
        onChange={handleChange}
        min={min}
        max={max}
        step={step}
        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-500"
      />
      <span className="whitespace-nowrap mt-1 text-center text-xs dark:text-gray-100">
        Page {value} of {max}
      </span>
    </div>
  );
}
