import validator from '@rjsf/validator-ajv8';
import {
  FieldTemplateProps,
  RJSFSchema,
  WidgetProps,
  RegistryWidgetsType,
  ObjectFieldTemplateProps,
} from '@rjsf/utils';
import Form from '@rjsf/core';
import { UiSchema } from '@rjsf/utils';
import { useEffect, useState } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { useUserEmailUnqiue } from '@/hooks/user/use-user-email-unique';
import { useQueryClient } from '@tanstack/react-query';

const profileSchema: RJSFSchema = {
  type: 'object',
  required: ['email', 'firstName', 'lastName', 'password'],
  properties: {
    email: {
      type: 'string',
      title: 'Email',
      format: 'email',
    },
    password: {
      type: 'string',
      title: 'Password',
    },
    firstName: {
      type: 'string',
      title: 'First Name',
      minLength: 1,
    },
    lastName: {
      type: 'string',
      title: 'Last Name',
    },
  },
};

const uiSchema: UiSchema = {
  'ui:submitButtonOptions': {
    props: {
      disabled: false,
      className:
        'mt-6 mr-auto inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-2.5 py-1.5 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
    },
    norender: false,
    submitText: 'Done',
  },
  email: {
    'ui:classNames': 'sm:col-span-3',
    'ui:widget': 'email',
    'ui:hideError': true,
  },
  firstName: {
    'ui:classNames': 'sm:col-span-3 sm:col-start-1',
    'ui:hideError': true,
  },
  lastName: {
    'ui:classNames': 'sm:col-span-3',
    'ui:hideError': true,
  },
  password: {
    'ui:classNames': 'sm:col-span-3 sm:col-start-1',
    'ui:widget': 'password',
    'ui:hideError': true,
  },
};

function transformErrors(errors) {
  return errors.map((error) => {
    if (error.name === 'required') {
      error.message = 'This field is required';
    }
    return error;
  });
}

export function RegisterProfile(props: any) {
  const queryClient = useQueryClient();
  const [currentSchema] = useState(profileSchema);

  const customValidate = function (formData: any, errors: any, uiSchema: any) {
    const data = queryClient.getQueryData(['userEmailUnique', formData.email]);
    if (data?.isUnique === false) {
      errors.email.addError('Email address is already registered');
    }

    if (formData.email && data === undefined) {
      if (
        queryClient.getQueryState(['userEmailUnique', formData.email])
          ?.status === 'loading'
      ) {
        errors.email.addError('Email address is validating, please try again');
      }
    }
    // if (data?.isUnique !== true) {
    //   console.log("block");
    //   errors.email.addError("Email address is already registered");
    // }
    // need to wait for data...
    return errors;
  };

  const onSubmit = ({ formData }: any) => {
    props.progressNextStep(formData);
  };

  const onBlur = (id, value) => {
    props.handleBlur(id, value);
  };

  function CustomFieldTemplate(props: FieldTemplateProps) {
    const {
      id,
      classNames,
      label,
      help,
      required,
      description,
      errors,
      children,
    } = props;
    return (
      <div className={classNames}>
        <label
          className={
            'block text-sm font-medium text-gray-700 dark:text-gray-200'
          }
          htmlFor={id}
        >
          {label}
          {required ? '*' : null}
        </label>
        {description}
        {children}
        {errors}
        {help}
      </div>
    );
  }

  const CustomTextWidget = function (props: WidgetProps) {
    return (
      <div>
        <div className="mt-1 relative">
          <input
            type="text"
            className={clsx(
              'block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:border-gray-600',
              props.rawErrors?.length > 0 ? 'border-red-300' : ''
            )}
            value={props.value ?? ''}
            required={props.required}
            onChange={(event) =>
              props.onChange(
                event.target.value !== '' ? event.target.value : undefined
              )
            }
          />
          {props.rawErrors?.length > 0 && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
        {props.rawErrors &&
          props.rawErrors.map((error) => (
            <p key={error} className="mt-2 text-sm text-red-600">
              {error}
            </p>
          ))}
      </div>
    );
  };

  const CustomEmailWidget = function (props: WidgetProps) {
    return (
      <div>
        <div className="mt-1 relative">
          <input
            type="email"
            className={clsx(
              'block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:border-gray-600',
              props.rawErrors?.length > 0 ? 'border-red-300' : ''
            )}
            value={props.value ?? ''}
            required={props.required}
            onChange={(event) =>
              props.onChange(
                event.target.value !== '' ? event.target.value : undefined
              )
            }
            onBlur={(event) => props.onBlur('', event.target.value)}
          />
          {props.rawErrors?.length > 0 && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
        {props.rawErrors &&
          props.rawErrors.map((error) => (
            <p key={error} className="mt-2 text-sm text-red-600">
              {error}
            </p>
          ))}
      </div>
    );
  };

  const CustomPasswordWidget = function (props: WidgetProps) {
    return (
      <div>
        <div className="mt-1 relative">
          <input
            type="password"
            className={clsx(
              'block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:border-gray-600',
              props.rawErrors?.length > 0 ? 'border-red-300' : ''
            )}
            value={props.value ?? ''}
            required={props.required}
            onChange={(event) =>
              props.onChange(
                event.target.value !== '' ? event.target.value : undefined
              )
            }
          />
          {props.rawErrors?.length > 0 && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
        {props.rawErrors &&
          props.rawErrors.map((error) => (
            <p key={error} className="mt-2 text-sm text-red-600">
              {error}
            </p>
          ))}
      </div>
    );
  };

  function ObjectFieldTemplate(props: ObjectFieldTemplateProps) {
    return (
      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        {props.properties.map((element) => element.content)}
      </div>
    );
  }

  const widgets: RegistryWidgetsType = {
    TextWidget: CustomTextWidget,
    EmailWidget: CustomEmailWidget,
    PasswordWidget: CustomPasswordWidget,
  };

  return (
    <Form
      schema={currentSchema}
      uiSchema={uiSchema}
      validator={validator}
      customValidate={customValidate}
      templates={{ FieldTemplate: CustomFieldTemplate, ObjectFieldTemplate }}
      widgets={widgets}
      onSubmit={onSubmit}
      onBlur={onBlur}
      transformErrors={transformErrors}
      showErrorList={false}
      noHtml5Validate
    />
  );
}
