import { GridPanel } from '@/components/grid-panel';
import { Page } from '@/components/page';
import { Toggle } from '@/components/toggle';
import { useState } from 'react';
import GridLayout, { WidthProvider } from 'react-grid-layout';
import { TreeStoreProvider } from '@/context/tree-context';
import { ExploreWidget } from '@/components/explore-widget';
import { CustomAppWidget } from '@/components/custom-app-widget';

const layout = [
  { i: 'main_1', x: 0, y: 0, w: 40, h: 12 },
  { i: 'main_2', x: 0, y: 0, w: 40, h: 12 },
];

const AutoWidthGridLayout = WidthProvider(GridLayout);

export function CustomWidget() {
  const [isDraggable, setIsDraggable] = useState<boolean>(false);

  return (
    <Page
      title="Libelle Onlineshop"
      toolbar={
        <Toggle
          enabled={isDraggable}
          label="Enable drag"
          onChange={setIsDraggable}
        />
      }
    >
      <AutoWidthGridLayout
        className="layout"
        layout={layout}
        cols={40}
        rowHeight={24}
        isDraggable={isDraggable}
      >
        <GridPanel key="main_1">
          <TreeStoreProvider name="custom-app">
            <ExploreWidget />
          </TreeStoreProvider>
        </GridPanel>
        <GridPanel key="main_2">
          <CustomAppWidget />
        </GridPanel>
      </AutoWidthGridLayout>
    </Page>
  );
}
