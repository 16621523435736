import { WidgetProps } from '@rjsf/utils';
import { Editor, EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

function MenuBar({ editor }: { editor: Editor | null }) {
  if (!editor) {
    return null;
  }

  return (
    <div className="isolate inline-flex rounded-md shadow-sm my-1">
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className={`relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold ring-1 ring-inset  hover:bg-gray-50 focus:z-10 ${
          editor.isActive('bold')
            ? 'bg-gray-900 text-white ring-gray-900'
            : 'text-gray-900 bg-white ring-gray-300'
        }`}
      >
        B
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        className={`relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset hover:bg-gray-50 focus:z-10 ${
          editor.isActive('italic')
            ? 'bg-gray-900 text-white ring-gray-900'
            : 'text-gray-900 bg-white ring-gray-300'
        }`}
      >
        I
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        className={`relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset hover:bg-gray-50 focus:z-10 ${
          editor.isActive('strike')
            ? 'bg-gray-900 text-white ring-gray-900'
            : 'text-gray-900 bg-white ring-gray-300'
        }`}
      >
        <s>S</s>
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleCode().run()}
        disabled={!editor.can().chain().focus().toggleCode().run()}
        className={`relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold ring-1 ring-inset hover:bg-gray-50 focus:z-10 ${
          editor.isActive('code')
            ? 'bg-gray-900 text-white ring-gray-900'
            : 'text-gray-900 bg-white ring-gray-300'
        }`}
      >
        <pre>C</pre>
      </button>
    </div>
  );
}

export function RteWidget({ onChange, value }: WidgetProps) {
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
    ],
    editorProps: {
      attributes: {
        class: 'prose prose-sm focus:outline-none',
      },
    },
    content: value,
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML());
    },
  });

  return (
    <div>
      <MenuBar editor={editor} />
      <EditorContent
        editor={editor}
        className="block border w-full rounded-md shadow-sm focus:outline-none sm:text-sm border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 px-3 py-2 max-h-[258px] overflow-auto"
      />
    </div>
  );
}
