import { Widget } from '@/components/widget';
import { useChainStats } from '@/hooks/stats/use-chain-stats';
import { useTheme } from '@/hooks/use-theme';
import ReactECharts from 'echarts-for-react';
import { useEffect, useState } from 'react';
import { useListener } from '@/hooks/use-listener';
import cloneDeep from 'lodash.clonedeep';

const WIDGET_ID = 'CHAIN_STATS_WIDGET';

type Data = number | { value: number };

interface BarSeries {
  type: string;
  showBackground: boolean;
  itemStyle: {
    backgroundColor: string;
  };
  data: Data[];
  coordinateSystem: string;
  roundCap: boolean;
  name: string;
}

export function ChainStatsWidget() {
  const { theme } = useTheme('light');
  const [status, setStatus] = useState<string|null>(null);
  const [bundleFunction, setBundleFunction] = useState<string|null>(null);
  const [organisation, setOrganisation] = useState<string|null>(null);
  const [chainUuid, setChainUuid] = useState<string|null>(null);
  const [forceRefetchAggregations, setForceRefetchAggregations] = useState<boolean>(false);
  const [options, setOptions] = useState<any>(
    {
      color: [
        'rgb(199, 210, 254)',
        'rgb(165, 180, 252)',
        'rgb(129, 140, 248)',
        'rgb(99, 102, 241)',
        'rgb(79, 70, 229)',
        'rgb(67, 56, 202)',
        'rgb(55, 48, 163)',
        'rgb(49, 46, 129)',
      ],
      angleAxis: {
        startAngle: 90,
        splitLine: {
          show: false,
        },
        show: false,
        max: 'dataMax',
      },
      radiusAxis: {
        type: 'category',
        z: 10,
        show: false,
        min: -1,
      },
      polar: {
        radius: '85%',
        center: ['65%', '50%'],
      },
      series: [],
      legend: {
        show: true,
        orient: 'vertical',
        left: 'left',
        top: '40%',
        selectMode: false,
        textStyle: { color: theme === 'light' ? '#000' : '#fff' },
        formatter: `{name}`
      },
      tooltip: {
        show: true,
        formatter: '{a}<br /><strong>{c0}</strong>',
      },
    }
  );

  const { data, refetch } = useChainStats(null, status, bundleFunction, organisation, chainUuid, forceRefetchAggregations);

  useListener({
    id: WIDGET_ID,
    listenerFn: (data) => {
      setStatus(data[0].status);
      setBundleFunction(data[0].bundleFunction);
      setOrganisation(data[0].organisation);
      setChainUuid(data[0].chainUuid);
      setForceRefetchAggregations(data[0].forceRefetchAggregations);
    },
    type: 'ChainLogFilters',
  });

  useEffect(()=> {
    refetch();
  }, [status, bundleFunction, organisation, chainUuid, forceRefetchAggregations]);

  useEffect(()=> {
    // console.log(data);
    if (!data || !data.series) {
      const newOptions = cloneDeep(options);
      newOptions.series = [];

      newOptions.legend.formatter = '{name}';

    setOptions(newOptions);
      return;
    }

    const newOptions = cloneDeep(options);
    newOptions.series = [];

    newOptions.series = data.series.map((item: any) => {
      return {
        type: 'bar',
        showBackground: true,
        itemStyle: {
          backgroundColor: 'gray',
        },
        data: [item.value],
        coordinateSystem: 'polar',
        roundCap: true,
        name: item.name,
      };
    });

    newOptions.legend.formatter = data
    ? function (name: string) {
        let itemValue = data?.series.filter((item) => item.name === name);
        return `${name}: ${itemValue ? itemValue[0].value : ''}`;
      }
    : '{name}';

    setOptions(newOptions);
  }, [data]);

  return (
    <Widget title={'Automation Metrics'} id={WIDGET_ID}>
      {data && options && (
        <ReactECharts
          option={options}
          style={{ width: '100%' }}
          notMerge={true}
        />
      )}
    </Widget>
  );
}
