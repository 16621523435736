import {
  ArrayField,
  ArrayFieldItem,
  BaseInput,
  AddButton,
  MoveDownButton,
  MoveUpButton,
  RemoveButton,
  ErrorList,
  Field,
  FieldError,
  TitleField,
  Wrapper,
} from '@/components/form';
import { usePuck } from '@measured/puck';
import Form, { IChangeEvent } from '@rjsf/core';
import validator from '@rjsf/validator-ajv8';
import { useState, useEffect, useMemo } from 'react';
import { fields, widgets } from '@/features/populate/lib/form';
import {
  getChanged,
  transformConfigToFormSchema,
} from '@/features/populate/lib/helpers';
import { cache } from '@/features/populate/lib/cache';
import { UiSchema } from '@rjsf/utils';

export function PuckFields({ uiSchema }: { uiSchema: UiSchema }) {
  const { appState, config, dispatch, selectedItem } = usePuck();
  const [formData, setFormData] = useState<any>(null);
  const { itemSelector } = appState.ui;

  const schema = useMemo(() => {
    return transformConfigToFormSchema(config);
  }, [config]);

  useEffect(() => {
    if (!selectedItem) {
      return;
    }

    setFormData(selectedItem.props);
  }, [selectedItem]);

  async function handleChange(data: IChangeEvent<any>) {
    // Handle root editor form updates
    if (!selectedItem || !itemSelector) {
      setFormData(data.formData);
      dispatch({
        type: 'set',
        state: {
          ui: { ...appState.ui },
          data: {
            ...appState.data,
            root: {
              props: {
                ...appState.data.root.props,
                ...data.formData,
              },
            },
          },
        },
        recordHistory: true,
      });
      return;
    }

    if (!cache[selectedItem.props.id]) {
      cache[selectedItem.props.id] = {
        item: selectedItem,
      };
    }

    setFormData(data.formData);

    let newProps;
    const configForComponent = config.components[selectedItem.type];

    if (configForComponent.resolveData) {
      const { item: oldItem = {} } = cache[selectedItem.props.id] || {};

      if (data.formData === oldItem?.props) {
        return;
      }

      const changed = getChanged({ props: data.formData }, oldItem);

      const { props: resolvedProps } = await configForComponent.resolveData(
        { props: data.formData },
        { changed, lastData: { ...oldItem }, fields: configForComponent.fields }
      );

      cache[selectedItem.props.id] = {
        item: {
          ...selectedItem,
          props: {
            ...selectedItem.props,
            ...resolvedProps,
          },
        },
      };

      newProps = {
        ...selectedItem.props,
        ...resolvedProps,
      };
    } else {
      newProps = {
        ...selectedItem.props,
        ...data.formData,
      };
    }

    dispatch({
      type: 'replace',
      destinationIndex: itemSelector.index,
      destinationZone: itemSelector.zone || 'default-zone',
      data: {
        ...selectedItem,
        props: newProps,
      },
    });
  }

  return (
    <Form
      schema={schema[selectedItem?.type || 'root']}
      uiSchema={uiSchema}
      validator={validator}
      onChange={handleChange}
      onSubmit={() => console.log('submit')}
      formData={formData}
      fields={fields}
      templates={{
        ArrayFieldTemplate: ArrayField,
        ArrayFieldItemTemplate: ArrayFieldItem,
        BaseInputTemplate: BaseInput,
        ButtonTemplates: {
          AddButton,
          MoveDownButton,
          MoveUpButton,
          RemoveButton,
          SubmitButton: () => null,
        },
        ErrorListTemplate: ErrorList,
        FieldTemplate: Field,
        FieldErrorTemplate: FieldError,
        TitleFieldTemplate: TitleField,
        WrapIfAdditionalTemplate: Wrapper,
      }}
      widgets={widgets}
    />
  );
}
