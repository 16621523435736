import { useQuery } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface UserAgentsData {
  label: string;
  value: string;
}

export function useGetUserAgents() {
  const { keycloak } = useKeycloak();

  return useQuery({
    enabled: !!keycloak.token,
    queryKey: ['userAgents'],
    queryFn: async (): Promise<UserAgentsData[]> => {
      const response = await fetch(
        `${
          import.meta.env.VITE_USER_ENGINE_ENDPOINT
        }/api/v1/bundles/user_bundle/functions/get_user_agents`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${keycloak.token}`,
          },
          method: 'GET',
        }
      );

      const data = await response.json();

      return data?.data?.agents || [];
    },
  });
}
