import debounce from 'lodash.debounce';
import { useEffect, useMemo } from 'react';
import { useTreeStore } from '@/context/tree-context';

export const GRAN_FINE = 10;
export const GRAN_ROUGH = 200;
export const COVERAGE = 200;

export interface PaginationData {
  selectedPages?: number[];
  from?: number;
  limit?: number;
  columns?: number;
}

export function calculatePaginationData(
  page: number,
  total: number = 0,
  limit: number = GRAN_FINE
) {
  const maxRoughPages = Math.ceil(total / GRAN_ROUGH);
  const lastRoughPageItems = total % GRAN_ROUGH;
  const maxFinePages = Math.ceil(
    (page === maxRoughPages && lastRoughPageItems > 0
      ? lastRoughPageItems
      : COVERAGE) / limit
  );

  return {
    maxRoughPages,
    maxFinePages,
    lastRoughPageItems,
  };
}

function calculateFrom(
  page: number,
  finePage: number,
  limit: number = GRAN_FINE
) {
  const fromRough = (page - 1) * GRAN_ROUGH;
  const fromFine = (finePage - 1) * limit;

  return fromRough + fromFine;
}

export function useNodePagination(
  id: string,
  paginationData?: PaginationData,
  total?: number
) {
  const [selectedPage, selectedFinePage] = paginationData?.selectedPages || [
    1, 1,
  ];
  const limit = paginationData?.limit || GRAN_FINE;

  const { maxRoughPages, maxFinePages, lastRoughPageItems } =
    calculatePaginationData(selectedPage, total, limit);

  const setPagination = useTreeStore((state) => state.setPagination);

  useEffect(() => {
    if (!paginationData?.from) {
      return;
    }

    if (!total) {
      return;
    }

    setPagination(id, {
      selectedPages: [
        Math.floor(paginationData.from / (total / maxRoughPages)) + 1,
        Math.floor((paginationData.from % (total / maxRoughPages)) / limit) + 1,
      ],
    });
  }, [paginationData?.from]);

  const handleSelectedPageChange = useMemo(() => {
    return debounce((newPage) => {
      let finePage = 1;

      if (newPage === 1) {
        finePage = 1;
      } else if (newPage === maxRoughPages) {
        finePage =
          lastRoughPageItems > 0
            ? Math.ceil(lastRoughPageItems / limit)
            : Math.ceil(COVERAGE / limit);
      } else {
        finePage = Math.ceil(COVERAGE / limit / 2);
      }

      setPagination(id, {
        from: calculateFrom(newPage, finePage, limit),
        selectedPages: [newPage, finePage],
      });
    }, 200);
  }, [maxFinePages, maxRoughPages, limit]);

  function handleRoughPageChange(page: number) {
    handleSelectedPageChange(page);
  }

  const handleSelectedFinePageChange = useMemo(() => {
    return debounce((page) => {
      setPagination(id, {
        from: calculateFrom(selectedPage, page, limit),
        selectedPages: [selectedPage, page],
      });
    }, 200);
  }, [selectedPage, limit]);

  function handleFinePageChange(page: number) {
    if (page > maxFinePages) {
      return;
    }

    if (page < 1) {
      return;
    }

    handleSelectedFinePageChange(page);
  }

  function resetPagination() {
    setPagination(id, {
      from: 0,
      selectedPages: [1, 1],
    });
  }

  return {
    limit,
    handleFinePageChange,
    handleRoughPageChange,
    maxFinePages,
    maxRoughPages,
    resetPagination,
    selectedPage,
    selectedFinePage,
  };
}
