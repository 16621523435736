import { useEffect, useState } from 'react';
import { useWatchLocalStorageValue } from '@/hooks/use-watch-localstorage-value';

const DEFAULT_THEME: Theme = 'light';
const LOCALSTORAGE_THEME_KEY = 'dyta-theme';
const EVENT_TYPE = 'dyta-theme-sync';

export type Theme = 'light' | 'dark' | 'auto';

export function useTheme(defaultTheme: Theme) {
  const [theme, setTheme] = useState<Theme>(getInitialTheme(defaultTheme));

  useEffect(() => {
    setModeInLocalstorage(theme);
    setThemeInDOM(theme);
  }, []);

  useWatchLocalStorageValue({
    key: LOCALSTORAGE_THEME_KEY,
    onChange(newValue) {
      if (newValue) {
        return handleSetTheme(newValue as Theme);
      }
    },
  });

  useSyncMode((mode) => setTheme(mode));

  function handleSetTheme(theme: Theme) {
    setTheme(theme);
    setModeInLocalstorage(theme);
    setThemeInDOM(theme);
    document.dispatchEvent(new CustomEvent(EVENT_TYPE, { detail: theme }));
  }

  function toggleTheme() {
    let newTheme = theme;

    if (newTheme === 'auto') {
      newTheme = computedThemeValue(newTheme);
    }

    newTheme = newTheme === 'dark' ? 'light' : 'dark';

    handleSetTheme(newTheme);
  }

  return {
    theme,
    computedTheme: computedThemeValue(theme),
    setTheme: handleSetTheme,
    toggleTheme,
  };
}

function useSyncMode(onChange: (mode: Theme) => void) {
  useEffect(() => {
    function handleSync(e: Event) {
      const mode = (e as CustomEvent<Theme>).detail;

      onChange(mode);
    }

    document.addEventListener(EVENT_TYPE, handleSync);
    return () => document.removeEventListener(EVENT_TYPE, handleSync);
  }, []);
}

function setModeInLocalstorage(theme: Theme) {
  localStorage.setItem(LOCALSTORAGE_THEME_KEY, theme);
}

function setThemeInDOM(theme: Theme) {
  const computedMode = computedThemeValue(theme);

  if (computedMode === 'dark') {
    document.documentElement.classList.add('dark');
  } else {
    document.documentElement.classList.remove('dark');
  }
}

function getInitialTheme(defaultMode?: Theme): Theme {
  const LSMode = localStorage.getItem(LOCALSTORAGE_THEME_KEY) as
    | Theme
    | undefined;

  return LSMode ?? defaultMode ?? DEFAULT_THEME;
}

function computedThemeValue(theme: Theme): Theme {
  return theme === 'auto' ? prefersColorScheme() : theme;
}

function prefersColorScheme(): Theme {
  return window.matchMedia?.('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light';
}
