import { Data } from '@measured/puck';
import { Widget } from '@/components/widget';
import { PuckEditor } from '@/features/populate/components/puck-editor';
import { useObjectFormUpsert } from '@/hooks/use-object-form-upsert';
import { RootNode } from '@/features/populate/hooks/use-load-puck-data';

export const WIDGET_ID = 'PAGE_EDITOR_WIDGET';

export function PageEditorWidget() {
  const objectFormUpsert = useObjectFormUpsert();

  function handlePuckSave(data: Data, rootNode?: RootNode) {
    objectFormUpsert.mutate({
      container: 'populate_contentbuilds',
      dataClassUuid: 'populate_contentbuilds',
      data: {
        content: data,
        rootNode: rootNode
      },
    });
  }

  return (
    <Widget id={WIDGET_ID} title="Compose Content">
      <PuckEditor
        onSave={handlePuckSave}
        isLoading={objectFormUpsert.isLoading}
      />
    </Widget>
  );
}
