import { useQuery } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface AgentData {
  title: string;
  runtimeStatus: {
    status: string;
    log: string;
    error: string;
    attributes: Record<string, unknown>;
  };
  revision: number;
}

export function useGetAgent(uuid: string) {
  const { keycloak } = useKeycloak();

  return useQuery({
    queryKey: ['agent', uuid],
    queryFn: async (): Promise<AgentData> => {
      const response = await fetch(
        `${
          import.meta.env.VITE_IMPORT_ENGINE_ENDPOINT
        }/api/v1/bundles/generate_bundle/functions/get_agent`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${keycloak.token}`,
          },
          method: 'POST',
          body: JSON.stringify({ uuid }),
        }
      );

      const data = await response.json();

      return data?.data;
    },
  });
}
