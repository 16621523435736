import {
  DEFAULT_GRID_COLUMNS,
  DEFAULT_GRID_GUTTER,
  DEFAULT_GRID_PADDING,
} from '@/components/group-node';
import { DEFAULT_IMAGE_NODE_HEIGHT } from '@/components/image-node';
import {
  DEFAULT_OBJECT_NODE_HEIGHT,
  DEFAULT_OBJECT_NODE_WIDTH,
} from '@/components/object-node';
import { DEFAULT_BORDER_WIDTH, DEFAULT_PADDING } from '@/components/table-node';
import { Node, Position } from 'reactflow';

export function transformNodes(nodes: Node[], existingNodes: Node[] = []) {
  let j = 0;
  let k = 0;
  let previousRowHeight = 0;

  return nodes.map((node) => {
    const newNode = {
      ...node,
      className: '',
    };

    const parentNode = nodes.find((n) => n.id === node?.parentNode);

    if (newNode.type === 'object' || newNode.type === 'image') {
      newNode.position = { x: 0, y: 0 };
      newNode.style = {
        height: calculateNodeHeight(newNode.data?.root, newNode.type),
        width: DEFAULT_OBJECT_NODE_WIDTH,
      };

      if (!newNode.data?.root) {
        newNode.data.open = false;
      }
    }

    if (newNode.type === 'table') {
      const rowCount = newNode.data?.collectionData?.length || 0;

      const tableWidth = Object.keys(
        newNode.data?.collectionSchema?.properties
      )?.reduce((width, key) => {
        return (
          width +
          (newNode.data?.collectionSchema?.properties[key]?.tableWidth || 150)
        );
      }, 0);

      // Calculate the height and width of the table node based on the number of rows and columns
      // 48 -> height of table header
      // 55 -> height of table row
      // @todo find a better way to remove magic numbers
      newNode.style = {
        height:
          48 + rowCount * 55 + 2 * DEFAULT_PADDING + 2 * DEFAULT_BORDER_WIDTH,
        width: tableWidth + 2 * DEFAULT_PADDING + 2 * DEFAULT_BORDER_WIDTH,
      };
    }

    if (newNode.type === 'attribute') {
      newNode.sourcePosition = Position.Right;
      newNode.draggable = false;
      newNode.data.id = newNode.id;
      newNode.data.pagination = true;

      if (!parentNode?.data?.root) {
        newNode.hidden = true;
      }
    }

    if (newNode.type === 'group') {
      const parentNode = existingNodes.find(
        (n) => n.id === newNode.data.relatesTo
      );
      const children = nodes.filter((n) => n.parentNode === newNode.id);
      const columns =
        parentNode?.data?.paginationData?.columns || DEFAULT_GRID_COLUMNS;
      const rows = Math.ceil(children.length / columns);

      const columnHeights = Array(columns).fill(0);
      children.forEach((child, index) => {
        const column = index % columns;
        columnHeights[column] += calculateNodeHeight(
          child.data?.root,
          child.type as string
        );
      });

      const maxColumnHeight = Math.max(...columnHeights);

      newNode.position = { x: 0, y: 0 };
      newNode.style = {
        border: 'none',
        width:
          columns * DEFAULT_OBJECT_NODE_WIDTH +
          (columns - 1) * DEFAULT_GRID_GUTTER +
          DEFAULT_GRID_PADDING,
        height:
          maxColumnHeight +
          (rows - 1) * DEFAULT_GRID_GUTTER +
          DEFAULT_GRID_PADDING,
      };
    }

    if (newNode.parentNode && parentNode?.type === 'group') {
      const groupParentNode = existingNodes.find(
        (n) => n.id === parentNode?.data.relatesTo
      );
      const columns =
        groupParentNode?.data?.paginationData?.columns || DEFAULT_GRID_COLUMNS;
      const row = Math.floor(k / columns);
      const column = k % columns;

      const maxRowHeight = Math.max(
        ...nodes
          .filter((n) => n.parentNode === newNode.parentNode)
          .slice(row * columns, (row + 1) * columns)
          .map((n) => calculateNodeHeight(n.data?.root, n.type as string))
      );

      const position = {
        x:
          DEFAULT_GRID_PADDING / 2 +
          column * (DEFAULT_GRID_GUTTER + DEFAULT_OBJECT_NODE_WIDTH),
        y:
          DEFAULT_GRID_PADDING / 2 + row * (DEFAULT_GRID_GUTTER + maxRowHeight),
      };

      k++;

      return {
        ...newNode,
        position,
      };
    }

    return newNode;
  });
}

function calculateNodeHeight(isRoot: boolean, type: string): number {
  if (isRoot) {
    return 237;
  }

  if (type === 'image') {
    return DEFAULT_IMAGE_NODE_HEIGHT;
  }

  return DEFAULT_OBJECT_NODE_HEIGHT;
}
