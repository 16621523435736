import { useState } from 'react';
import GridLayout, { WidthProvider } from 'react-grid-layout';

import { EditWidget } from '@/components/edit-widget';
import { ExploreWidget } from '@/components/explore-widget';
import { Page } from '@/components/page';
import { Toggle } from '@/components/toggle';
import { GridPanel } from '@/components/grid-panel';
import { CollectionWidget } from '@/components/collection-widget';
import { MediaViewerWidget } from '@/components/media-viewer-widget';
import { DetailsWidget } from '@/components/details-widget';
import { GeolocationWidget } from '@/components/geolocation-widget/geolocation-widget';
import { RadialBarChartWidget } from '@/components/radial-bar-chart-widget';
import { useImageStats } from '@/hooks/stats/use-image-stats';
import { useBulkStats } from '@/hooks/stats/use-bulk-stats';
import { LineChartWidget } from '@/components/line-chart-widget';
import { ListWidget } from '@/components/list-widget';
import { TreeStoreProvider } from '@/context/tree-context';
import { JobsWidget } from '@/components/jobs-widget';
import { ImageEditorWidget } from '@/components/image-editor-widget';
import { ObjectChainWidget } from '@/components/object-chain-widget';
import { ObjectJsonWidget } from '@/components/object-json-widget';

const layout = [
  { i: 'main', x: 0, y: 0, w: 25, h: 20 },
  { i: 'main_right', x: 25, y: 0, w: 15, h: 20 },
  { i: 'main_sub', x: 0, y: 1, w: 40, h: 20 },
  { i: 'sidebar_left', x: 0, y: 1, w: 20, h: 20 },
  { i: 'sidebar', x: 20, y: 1, w: 20, h: 20 },
  { i: 'footer_top', x: 0, y: 2, w: 40, h: 10 },
  { i: 'footer', x: 0, y: 3, w: 40, h: 10 },
  { i: 'footer_middle', x: 0, y: 4, w: 40, h: 10 },
  { i: 'footer_bottom', x: 0, y: 5, w: 40, h: 10 },
  { i: 'sidebar_1', x: 20, y: 6, w: 40, h: 11 },
];

const AutoWidthGridLayout = WidthProvider(GridLayout);

export function Explore() {
  const [isDraggable, setIsDraggable] = useState<boolean>(false);

  return (
    <Page
      title="Explore & Edit"
      toolbar={
        <Toggle
          enabled={isDraggable}
          label="Enable drag"
          onChange={setIsDraggable}
        />
      }
    >
      <AutoWidthGridLayout
        className="layout"
        layout={layout}
        cols={40}
        rowHeight={30}
        isDraggable={isDraggable}
      >
        <GridPanel key="main">
          <TreeStoreProvider name="explore-a">
            <ExploreWidget />
          </TreeStoreProvider>
        </GridPanel>
        <GridPanel key="main_right">
          <EditWidget />
        </GridPanel>
        <GridPanel key="main_sub">
          <ObjectChainWidget />
        </GridPanel>
        <GridPanel key="sidebar_left">
          <MediaViewerWidget />
        </GridPanel>
        <GridPanel key="sidebar">
          <ImageEditorWidget />
        </GridPanel>
        <GridPanel key="footer_top">
          <DetailsWidget />
        </GridPanel>
        <GridPanel key="footer">
          <CollectionWidget />
        </GridPanel>
        <GridPanel key="footer_middle">
          <JobsWidget title="Downloads" type="download" />
        </GridPanel>
      </AutoWidthGridLayout>
    </Page>
  );
}