import clsx from 'clsx';

export type ViewMode = 'grid' | 'table';

interface ViewModeSwitchProps {
  viewMode: ViewMode;
  onViewModeChange: (viewMode: ViewMode) => void;
}

export function ViewModeSwitch({
  viewMode,
  onViewModeChange,
}: ViewModeSwitchProps) {
  const baseClassName =
    'inline-flex items-center px-4 py-2 text-sm font-medium border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white';
  const inactiveClassName = 'text-gray-900 bg-white';
  const activeClassName =
    'bg-gray-100 text-blue-700 dark:text-white dark:bg-gray-700';
  const gridClassName = clsx(
    baseClassName,
    'rounded-s-lg',
    viewMode === 'table' && inactiveClassName,
    viewMode === 'grid' && activeClassName
  );
  const tableClassName = clsx(
    baseClassName,
    'rounded-e-lg border-l-0',
    viewMode === 'grid' && inactiveClassName,
    viewMode === 'table' && activeClassName
  );

  return (
    <div
      className="inline-flex rounded-md shadow-sm mt-auto self-end"
      role="group"
    >
      <button
        onClick={() => onViewModeChange('grid')}
        type="button"
        className={gridClassName}
      >
        <svg
          className="w-4 h-4 me-2"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9.143 4H4.857A.857.857 0 0 0 4 4.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 10 9.143V4.857A.857.857 0 0 0 9.143 4Zm10 0h-4.286a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 20 9.143V4.857A.857.857 0 0 0 19.143 4Zm-10 10H4.857a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286a.857.857 0 0 0 .857-.857v-4.286A.857.857 0 0 0 9.143 14Zm10 0h-4.286a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286a.857.857 0 0 0 .857-.857v-4.286a.857.857 0 0 0-.857-.857Z"
          />
        </svg>
        Grid
      </button>
      <button
        onClick={() => onViewModeChange('table')}
        type="button"
        className={tableClassName}
      >
        <svg
          className="w-4 h-4 me-2"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            strokeWidth="2"
            d="M3 11h18M3 15h18m-9-4v8m-8 0h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z"
          />
        </svg>
        Table
      </button>
    </div>
  );
}
