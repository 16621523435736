import { useMutation } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

export function useJobStop() {
  const { keycloak } = useKeycloak();

  return useMutation({
    mutationFn: async (jobData: object): Promise<object> => {

      const response = await fetch(
        `${import.meta.env.VITE_JOB_ENGINE_ENDPOINT}/api/v1/bundles/job_bundle/functions/stop_job`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'POST',
          body: JSON.stringify(jobData),
        }
      );
      const data = await response.json();

      // console.log(data);

      const { job } = data?.data;

      return {
        job: job
      };
    },
  });
}