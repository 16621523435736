import { useTreeStore } from '@/context/tree-context';
import { memo } from 'react';
import { NodeProps, NodeResizeControl, NodeResizer } from 'reactflow';
import { NodePagination } from '@/components/node-pagination';
import clsx from 'clsx';
import { DEFAULT_IMAGE_NODE_HEIGHT } from '@/components/image-node';
import {
  DEFAULT_OBJECT_NODE_HEIGHT,
  DEFAULT_OBJECT_NODE_WIDTH,
} from '@/components/object-node';

export const DEFAULT_GRID_GUTTER = 10;
export const DEFAULT_GRID_COLUMNS = 1;
export const DEFAULT_GRID_PADDING = 20;

function GroupNode({ data, id, selected }: NodeProps) {
  const nodes = useTreeStore((state) => state.nodes);
  const setPagination = useTreeStore((state) => state.setPagination);
  const parentNode = nodes.find((node) => node.id === data.relatesTo);
  const baseClassName = 'absolute left-0 flex gap-0.5';
  const prevClassName = clsx(baseClassName, 'bottom-full mb-8');
  const nextClassName = clsx(baseClassName, 'top-full mt-8');

  return (
    <div className="bg-white/70 h-full p-2 rounded-[3px]">
      <NodeResizeControl
        className="absolute right-0 bottom-0 !left-auto !top-auto !w-6 !h-6 !border-none p-1 !translate-x-1/2 !translate-y-1/2"
        onResizeEnd={(_, { width, height }) => {
          if (!parentNode) {
            return;
          }

          const firstChild = nodes.find((n) => n.parentNode === id);
          // Calculate size (columns x rows) based on the new width and height
          const columns = Math.floor(
            (width + DEFAULT_GRID_GUTTER) /
              (DEFAULT_OBJECT_NODE_WIDTH + DEFAULT_GRID_GUTTER)
          );
          const nodeHeight =
            firstChild?.type === 'image'
              ? DEFAULT_IMAGE_NODE_HEIGHT
              : DEFAULT_OBJECT_NODE_HEIGHT;
          const rows = Math.floor(
            (height + DEFAULT_GRID_GUTTER) / (nodeHeight + DEFAULT_GRID_GUTTER)
          );

          const limit = columns * rows;
          setPagination(parentNode.id, { limit, columns });
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 6 6"
          className="w-1.5 h-1.5"
        >
          <path
            d="M 6 6 L 0 6 L 0 4.2 L 4 4.2 L 4.2 4.2 L 4.2 0 L 6 0 L 6 6 L 6 6 Z"
            fill="#585858"
          />
        </svg>
      </NodeResizeControl>
      {parentNode ? (
        <>
          <NodePagination
            id={parentNode.id}
            className={prevClassName}
            total={parentNode?.data.total}
            paginationData={parentNode?.data?.paginationData}
            prev
          />
          <NodePagination
            id={parentNode.id}
            className={nextClassName}
            total={parentNode?.data.total}
            paginationData={parentNode?.data?.paginationData}
            next
          />
        </>
      ) : null}
    </div>
  );
}

export default memo(GroupNode);
