import keycloak from '@/keycloak';
import { ComponentData, Config, Fields } from '@measured/puck';
import { RJSFSchema } from '@rjsf/utils';

export function getChanged(
  newItem: Omit<Partial<ComponentData<any>>, 'type'> | undefined,
  oldItem: Omit<Partial<ComponentData<any>>, 'type'> | undefined
) {
  return newItem
    ? Object.keys(newItem.props || {}).reduce((acc, item) => {
        const newItemProps = newItem?.props || {};
        const oldItemProps = oldItem?.props || {};

        return {
          ...acc,
          [item]:
            JSON.stringify(oldItemProps[item]) !==
            JSON.stringify(newItemProps[item]),
        };
      }, {})
    : {};
}

export async function resolveData(
  { props }: Record<string, any>,
  {
    changed,
    fields,
  }: {
    changed: Record<string, any>;
    fields: Record<string, any>;
  }
) {
  const changedField = Object.keys(changed).find((key) => changed[key]);

  if (!changedField) {
    return { props };
  }

  if (props.source && changed.source) {
    return {
      props: {
        ...(await resolveSourceProps(props)),
      },
    };
  }

  if (fields?.[changedField]?.type === 'relation') {
    if (fields[changedField].objectFields) {
      props[changedField] = await resolveRelationProps(
        props[changedField],
        fields[changedField].objectFields
      );
    } else if (fields[changedField].arrayFields) {
      props[changedField] = await Promise.all(
        props[changedField].map(
          async (item: any) => await resolveRelationProps(item)
        )
      );
    }
  }

  return { props };
}

export function transformConfigToFormSchema(config: Config): RJSFSchema {
  const formSchema: RJSFSchema = {};

  for (const [componentName, component] of Object.entries(config.components)) {
    formSchema[componentName] =
      component?.fields && Object.keys(component?.fields || {}).length > 0
        ? {
            type: 'object',
            properties: transformFields(component.fields),
          }
        : {};
  }

  if (config.root) {
    formSchema.root = config.root.fields
      ? {
          type: 'object',
          properties: transformFields(config.root.fields),
        }
      : {};
  }

  return formSchema;
}

function transformFields(fields: Fields | 'relation'): Record<string, any> {
  const properties: Record<string, any> = {};

  for (const [fieldName, field] of Object.entries(fields)) {
    if (field.type === 'array') {
      properties[fieldName] = {
        type: 'array',
        items: field.arrayFields
          ? { type: 'object', properties: transformFields(field.arrayFields) }
          : { type: 'text' },
      };
    } else if (field.type === 'object') {
      properties[fieldName] = {
        type: 'object',
        properties: field.objectFields
          ? transformFields(field.objectFields)
          : {},
      };
    } else if (field.type === 'relation') {
      if (field.objectFields) {
        properties[fieldName] = {
          type: 'object',
          properties: field.objectFields
            ? transformFields(field.objectFields)
            : {},
        };
      } else if (field.arrayFields) {
        properties[fieldName] = {
          type: 'array',
          items: {
            type: 'object',
            properties: field.arrayFields
              ? transformFields(field.arrayFields)
              : {},
          },
        };
      }
    } else {
      properties[fieldName] = {
        type: field.type === 'text' ? 'string' : field.type,
      };
    }
  }

  return properties;
}

async function resolveRelationProps(
  relation: {
    uuid: string;
    container: string;
  },
  fields: Record<string, any> = {}
): Promise<typeof relation & Record<string, any>> {
  if (!relation?.uuid || !relation?.container) {
    return relation;
  }

  try {
    const objectResponse = await fetch(import.meta.env.VITE_OBJECT_V3_API_URL, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${keycloak.token}`,
      },
      method: 'POST',
      body: JSON.stringify({
        from: 0,
        size: 1,
        uuids: [relation.uuid],
        container: [relation.container],
      }),
    });

    if (!objectResponse.ok) {
      throw new Error(`HTTP error! status: ${objectResponse.status}`);
    }

    const objectData = (await objectResponse.json())?.data?.objects?.[0]?.data;

    if (!objectData) {
      return relation;
    }

    const objectDataFields = Object.keys(fields).reduce((acc, key) => {
      if (key in objectData) {
        acc[key] = objectData[key];
      }

      return acc;
    }, {} as Record<string, unknown>);

    return {
      ...relation,
      ...objectDataFields,
    };
  } catch (error) {
    console.error('Error fetching relation data:', error);
    return relation;
  }
}

async function resolveSourceProps(props: any) {
  if (!props.source.uuid || !props.source.container) {
    return props;
  }

  const body = {
    from: 0,
    size: 1,
    uuids: [props.source.uuid],
    containers: [props.source.container],
  };

  const response = await fetch(import.meta.env.VITE_OBJECT_V3_API_URL, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`,
    },
    method: 'POST',
    body: JSON.stringify(body),
  });

  const data = (await response.json())?.data?.objects?.[0]?.data;

  if (!data) {
    return props;
  }

  if (data?.pictures?.down?.[0]) {
    data.image = await resolveRelationProps(data.pictures.down[0], {
      publicUrl: '',
    });
  }

  return {
    ...props,
    shortTitle: data.shortTitle,
    articleNumber: data.articleNumber,
    description: data.description,
    image: data.image,
  };
}

// async function resolveNestedImageProps(
//   props: any,
//   changed: any,
//   disableCheck = false
// ): Promise<any> {
//   if (typeof props !== 'object' || props === null) {
//     return props;
//   }

//   const result: any = Array.isArray(props) ? [] : {};

//   for (const [key, value] of Object.entries(props)) {
//     const hasChanged = changed[key] || disableCheck;

//     if (key === 'image' && hasChanged) {
//       result[key] = await resolveImageProps(value);
//     } else if (key === 'images' && hasChanged) {
//       result[key] = await Promise.all(
//         value.map(async (item: any) => ({
//           ...item,
//           image: await resolveImageProps(item.image),
//         }))
//       );
//     } else if (Array.isArray(value) && hasChanged) {
//       result[key] = await Promise.all(
//         value.map(
//           async (item: any) =>
//             await resolveNestedImageProps(item, changed || {}, true)
//         )
//       );
//     } else {
//       result[key] = value;
//     }
//   }

//   return result;
// }
