import { useState, useEffect } from 'react';
import { ClockIcon } from '@heroicons/react/24/outline';

interface TimerProps {
  startTime: Date;
}

export function Timer({ startTime }: TimerProps) {
  const [elapsedTime, setElapsedTime] = useState('00:00:00');

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const diff = now.getTime() - startTime.getTime();
      const hours = Math.floor(diff / 3600000)
        .toString()
        .padStart(2, '0');
      const minutes = Math.floor((diff % 3600000) / 60000)
        .toString()
        .padStart(2, '0');
      const seconds = Math.floor((diff % 60000) / 1000)
        .toString()
        .padStart(2, '0');
      setElapsedTime(`${hours}:${minutes}:${seconds}`);
    }, 1000);

    return () => clearInterval(interval);
  }, [startTime]);

  return (
    <span className="text-sm text-gray-500 inline-flex items-center gap-x-1">
      <ClockIcon className="h-4 w-4" />
      Running for <span className="font-mono text-xs">{elapsedTime}</span>
    </span>
  );
}
