import clsx from 'clsx';
import { MouseEventHandler } from 'react';

interface ButtonProps {
  children: React.ReactNode;
  type?: 'button' | 'submit';
  onClick?: MouseEventHandler;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'danger';
  loading?: boolean;
}

export function Button({
  children,
  onClick,
  type = 'button',
  variant = 'primary',
  loading = false,
  ...rest
}: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  const className = clsx(
    'inline-flex items-center rounded-md border border-transparent px-2 py-2 text-sm font-medium focus:outline-none focus:ring-2',
    variant === 'primary' &&
      'bg-indigo-100 text-indigo-700 hover:bg-indigo-200 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-indigo-900 dark:text-indigo-100 dark:hover:bg-indigo-700',
    variant === 'danger' &&
      'bg-red-100 text-red-700 hover:bg-red-200 focus:ring-red-500 focus:ring-offset-2',
    variant === 'secondary' &&
      'bg-gray-100 text-gray-700 hover:bg-gray-200 focus:ring-gray-500 focus:ring-offset-2 dark:bg-gray-700 dark:text-gray-100 dark:hover:bg-gray-900',
    variant === 'tertiary' &&
      'bg-indigo-700 text-white hover:bg-indigo-500 focus:ring-indigo-500 focus:ring-offset-2'
  );

  const inner = !loading ? (
    children
  ) : (
    <>
      <svg
        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      Processing...
    </>
  );

  return (
    <button className={className} onClick={onClick} type={type} {...rest}>
      {inner}
    </button>
  );
}
