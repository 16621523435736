import { useState, useEffect, SyntheticEvent } from 'react';
import { useListener } from '@/hooks/use-listener';
import { Widget } from '@/components/widget';
import clsx from 'clsx';
import { CheckCircleIcon, EllipsisHorizontalCircleIcon, PauseCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { TimeFromUnix } from '@/components/time-from-unix';
import { Editor } from '@monaco-editor/react';

const WIDGET_ID = 'LOG_WIDGET';

const monacoEditorOptions = {
  minimap: {
    enabled: false,
  },
  automaticLayout: true,
  readOnly: true
};

interface LogProps {
  title?: string;
  data?: any;
}

export function LogWidget({ title, data }: LogProps) {
  const [widgetTitle, setWidgetTitle] = useState<string>("Log");

  useEffect(() => {
    // console.log(title);
    if (title && typeof title === 'string') {
      setWidgetTitle(title);
    }
  }, [title]);

  return (
    <Widget id={WIDGET_ID} title={widgetTitle}>
        {data?.map((log, x) => (
          <div key={x} className="w-full mt-3 flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
            <div className="flex justify-between gap-x-4">
              <div className="py-0.5 text-xs leading-5 text-gray-500">Logged with level <span className="font-medium text-gray-900">{log.level}</span></div>
              <div className="flex-none py-0.5 text-xs leading-5 text-gray-500"><TimeFromUnix unixTimestamp={log.time} /></div>
            </div>
            {typeof log.message === 'string' && (
              <p className="text-sm leading-6 text-gray-500">{log.message}</p>
            )}
            {typeof log.message === 'object' && (
              <p className="text-sm leading-6 text-gray-500">This log message can not be displayed as it contains an object.</p>
            )}
            {log.json && (
              <Editor
                value={log.json}
                language="json"
                theme="vs-light"
                height={300}
                options={monacoEditorOptions}
              />
            )}
          </div>
        ))
      }
    </Widget>
  );
}