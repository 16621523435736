import { useMutation } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface CreateAgentData {
  sourceExample?: {
    uuid: string;
    container: string;
  };
  inputObject?: string;
  targetExample?: {
    uuid: string;
    container: string;
  };
  capabilities?: string;
  result?: string;
  need?: string;
  type?: string;
  title?: string;
}

interface CreateAgentResponse {
  uuid: string;
}

export function useCreateAgent() {
  const { keycloak } = useKeycloak();

  return useMutation({
    mutationFn: async (
      generateData: CreateAgentData
    ): Promise<CreateAgentResponse> => {
      const response = await fetch(
        `${
          import.meta.env.VITE_IMPORT_ENGINE_ENDPOINT
        }/api/v1/bundles/generate_bundle/functions/generate_agent`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${keycloak.token}`,
          },
          method: 'POST',
          body: JSON.stringify(generateData),
        }
      );

      const data = await response.json();

      return {
        uuid: data?.data.uuid,
      };
    },
  });
}
