import {
  ADDITIONAL_PROPERTY_FLAG,
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema,
  WrapIfAdditionalTemplateProps,
} from '@rjsf/utils';
import clsx from 'clsx';

export function Wrapper<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(props: WrapIfAdditionalTemplateProps<T, S, F>) {
  const {
    id,
    classNames,
    disabled,
    label,
    onKeyChange,
    onDropPropertyClick,
    readonly,
    required,
    schema,
    children,
    uiSchema,
    registry,
  } = props;
  // Button templates are not overridden in the uiSchema
  const { RemoveButton } = registry.templates.ButtonTemplates;
  const keyLabel = `${label} Key`; // i18n ?
  const additional = ADDITIONAL_PROPERTY_FLAG in schema;
  const className = clsx(classNames);

  if (!additional) {
    return <div className={className}>{children}</div>;
  }

  return (
    <div className={classNames}>
      <div className="grid grid-cols-12">
        <div className="col-span-5">
          <div className="form-group">
            {/* <Label label={keyLabel} required={required} id={`${id}-key`} /> */}
            <input
              className="form-control"
              type="text"
              id={`${id}-key`}
              onBlur={(event) => onKeyChange(event.target.value)}
              defaultValue={label}
            />
          </div>
        </div>
        <div className="col-span-5">{children}</div>
        <div className="col-span-2">
          <RemoveButton
            disabled={disabled || readonly}
            onClick={onDropPropertyClick(label)}
            registry={registry}
            uiSchema={uiSchema}
          />
        </div>
      </div>
    </div>
  );
}
