import { useMutation } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

export function useUserRegister() {
  const { keycloak } = useKeycloak();

  return useMutation({
    mutationFn: async (userData: object): Promise<object> => {

      // console.log(userData);

      const response = await fetch(
        `${import.meta.env.VITE_USER_ENGINE_ENDPOINT}/api/v1/bundles/user_bundle/functions/create_user`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'POST',
          body: JSON.stringify(userData),
        }
      );

      const data = await response.json();

      const { user } = data?.data;

      return {
        user: user
      };
    },
  });
}