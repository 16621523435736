import { XCircleIcon } from '@heroicons/react/24/outline';
import {
  ErrorListProps,
  FormContextType,
  RJSFValidationError,
  RJSFSchema,
  StrictRJSFSchema,
} from '@rjsf/utils';

export function ErrorList<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>({ errors }: ErrorListProps<T, S, F>) {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            There were {errors.length} errors with your submission
          </h3>
          <div className="mt-2 text-sm text-red-700">
            <ul role="list" className="list-disc space-y-1 pl-5">
              {errors.map((error: RJSFValidationError, i: number) => {
                return (
                  <li key={i} className="list-group-item text-danger">
                    {error.stack}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
