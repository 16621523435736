import {
  ArrowsPointingOutIcon,
  CheckCircleIcon,
  DocumentDuplicateIcon,
  PencilSquareIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { memo, useState } from 'react';
import { Handle, NodeProps, NodeToolbar, Position } from 'reactflow';
import { useTreeStore } from '@/context/tree-context';
import { useEmitter } from '@/hooks/use-emitter';
import {
  useRelationInsert,
  RelationPayload,
  ObjectParams,
} from '@/hooks/use-relation-insert';
import { DragDrop, Dragabble } from '@/components/drag-drop';

export const DEFAULT_OBJECT_NODE_WIDTH = 220;
export const DEFAULT_OBJECT_NODE_HEIGHT = 36;

const ObjectNode = ({ id, data, isConnectable, selected }: NodeProps) => {
  const [toolbarVisible, setToolbarVisible] = useState<boolean>(false);
  const mutation = useRelationInsert(data.relatesTo);
  const toggleNode = useTreeStore((state) => state.toggleNode);
  const emit = useEmitter({
    id: 'EXPLORE_WIDGET',
    listeners: ['EDIT_WIDGET', 'IMAGE_EDITOR_WIDGET', 'PAGE_EDITOR_WIDGET'],
  });

  const hasImage = !!data?.publicUrl;
  const processingStatus = data.processingStatus;

  const statusClassName = clsx(
    'absolute -top-2 -left-2 block h-3.5 w-3.5 rounded-full ring-2 ring-white',
    data.processingStatus == 'running' && 'bg-orange-400',
    data.processingStatus == 'failed' && 'bg-red-400',
    data.processingStatus == 'complete' && 'bg-green-400'
  );

  const className = clsx(
    'border bg-white/75 shadow-md shadow-gray-300 rounded-lg h-full relative dark:bg-gray-900/75 dark:shadow-gray-700',
    !selected && 'border-white/75',
    selected && 'border-green-500'
  );

  function handleToggle(id: string) {
    toggleNode(id);
  }

  function handleDrop(
    { value }: { value: ObjectParams[] },
    position: 'after' | 'before'
  ) {
    const payload: RelationPayload = {
      target: {
        uuid: data.upRelation.uuid,
        container: data.upRelation.container,
        attribute: data.upRelation.attribute,
      },
      objects: value,
      [position]: { uuid: data.uuid, container: data.container },
    };

    mutation.mutate(payload);
  }

  function handleMouseOver() {
    setToolbarVisible(true);
  }

  function handleMouseLeave() {
    setToolbarVisible(false);
  }

  return (
    <div className={className}>
      <div onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
        <NodeToolbar
          className="bg-white rounded-md shadow-md p-2 space-x-4 flex items-center justify-center dark:bg-gray-600 dark:text-gray-100"
          isVisible={toolbarVisible}
          offset={-5}
        >
          <button
            className="text-blue-600 underline hover:no-underline dark:text-blue-200 hover:text-black dark:hover:text-white hover:scale-[1.15] active:translate-y-0.5 transition-transform duration-150"
            onClick={() =>
              emit({
                type: 'ObjectCollection',
                data: [
                  {
                    container: data.container,
                    uuid: data.uuid,
                    root: data.root,
                    dataClassUuid: data.dataClassUuid,
                  },
                ],
              })
            }
          >
            <PencilSquareIcon className="h-3.5 w-3.5" aria-hidden="true" />
          </button>
          {data.root ? null : (
            <button
              className="text-blue-600 text-xs underline hover:no-underline dark:text-blue-200 hover:text-black dark:hover:text-white hover:scale-[1.15] active:translate-y-0.5 transition-transform duration-150"
              onClick={() => handleToggle(id)}
            >
              <ArrowsPointingOutIcon
                className="h-3.5 w-3.5"
                aria-hidden="true"
              />
            </button>
          )}
          <Dragabble
            value={[{ uuid: data.uuid, container: data.container }]}
            type="object/insert"
          >
            <button className="text-blue-600 dark:text-blue-200 z-10 relative nopan hover:text-black dark:hover:text-white hover:scale-[1.15] active:translate-y-0.5 transition-transform duration-150">
              <DocumentDuplicateIcon
                className="h-3.5 w-3.5"
                aria-hidden="true"
              />
            </button>
          </Dragabble>
        </NodeToolbar>
        {data.root ? null : (
          <Handle
            type="target"
            position={Position.Left}
            style={{ background: '#555' }}
            // onConnect={(params) => console.log('handle onConnect', params)}
            isConnectable={isConnectable}
          />
        )}
        {selected ? (
          <span className="bg-white rounded-full absolute -top-2.5 -right-2.5 h-5 w-5">
            <CheckCircleIcon
              className="h-5 w-5 text-green-500"
              aria-hidden="true"
            />
          </span>
        ) : null}
        <div className="px-2.5 py-1">
          <DragDrop
            allowedTypes={['object/insert']}
            onDrop={(data) => handleDrop(data, 'before')}
            className="h-5 absolute w-full left-0 top-0 -translate-y-1/2"
            droppingClassName="bg-blue-500/20"
          >
            {({ isDropping }) =>
              isDropping ? (
                <span className="flex justify-center items-center h-5 pointer-events-none">
                  <PlusIcon className="h-4 w-4" />
                </span>
              ) : null
            }
          </DragDrop>
          <h3
            className="text-xs text-left font-normal text-gray-900 py-1 whitespace-nowrap overflow-hidden text-ellipsis dark:text-gray-100"
            title={data?.label}
          >
            {data?.label}
          </h3>
          {hasImage ? (
            <div className="aspect-[3/2] rounded-md mt-1 bg-gray-100">
              <img
                className="object-contain w-full h-full"
                src={data?.publicUrl}
                alt=""
                loading="lazy"
              />
            </div>
          ) : null}
          <DragDrop
            allowedTypes={['object/insert']}
            onDrop={(data) => handleDrop(data, 'after')}
            className="h-5 absolute w-full left-0 bottom-0 translate-y-1/2"
            droppingClassName="bg-blue-500/20"
          >
            {({ isDropping }) =>
              isDropping ? (
                <span className="flex justify-center items-center h-5 pointer-events-none">
                  <PlusIcon className="h-4 w-4" />
                </span>
              ) : null
            }
          </DragDrop>
        </div>
      </div>
      {processingStatus ? <span className={statusClassName} /> : null}
    </div>
  );
};

ObjectNode.displayName = 'ObjectNode';

export default memo(ObjectNode);
