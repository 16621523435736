import { useTheme } from '@/hooks/use-theme';
import { BellIcon, MoonIcon, SunIcon } from '@heroicons/react/24/outline';

export function ThemeToggle() {
  const { theme, toggleTheme } = useTheme('light');

  return (
    <button
      type="button"
      className="rounded-full bg-white p-1.5 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      onClick={toggleTheme}
    >
      <span className="sr-only">Color scheme</span>
      {theme === 'light' ? (
        <SunIcon className="h-5 w-5" aria-hidden="true" />
      ) : null}
      {theme === 'dark' ? (
        <MoonIcon className="h-5 w-5" aria-hidden="true" />
      ) : null}
    </button>
  );
}
