import validator from '@rjsf/validator-ajv8';
import {
  RJSFSchema,
  WidgetProps,
  RegistryWidgetsType,
  ObjectFieldTemplateProps,
} from '@rjsf/utils';
import Form from '@rjsf/core';
import { UiSchema } from '@rjsf/utils';
import { useState, useRef } from 'react';

const termsSchema: RJSFSchema = {
  type: 'object',
  required: ['terms'],
  properties: {
    terms: {
      type: 'boolean',
      title: 'I agree',
      default: false,
      const: true,
    },
  },
};

const uiSchema: UiSchema = {
  'ui:submitButtonOptions': {
    props: {
      disabled: false,
      className:
        'mt-6 mr-auto inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-2.5 py-1.5 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
    },
    norender: false,
    submitText: 'Done',
  },
  terms: {
    'ui:hideError': true,
  },
};

export function RegisterTerms(props: any) {
  const [currentSchema] = useState(termsSchema);

  const onSubmit = ({ formData }: any) => {
    props.progressNextStep(formData);
  };

  const CustomCheckboxWidget = function (props: WidgetProps) {
    return (
      <div>
        <div className="relative flex items-start">
          <div className="flex h-5 items-center">
            <input
              id="comments"
              aria-describedby="comments-description"
              name="comments"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              value={props.value}
              required={props.required}
              onClick={() => props.onChange(!props.value)}
            />
          </div>
          <div className="ml-3 text-sm">
            <label
              htmlFor="comments"
              className="font-medium text-gray-700 dark:text-gray-200"
            >
              {props.label}
            </label>
          </div>
        </div>
        {props.rawErrors &&
          props.rawErrors.map((error) => (
            <p key={error} className="mt-2 text-sm text-red-600">
              {error}
            </p>
          ))}
      </div>
    );
  };

  function ObjectFieldTemplate(props: ObjectFieldTemplateProps) {
    return (
      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        {props.properties.map((element) => element.content)}
      </div>
    );
  }

  const widgets: RegistryWidgetsType = {
    CheckboxWidget: CustomCheckboxWidget,
  };

  return (
    <Form
      schema={currentSchema}
      uiSchema={uiSchema}
      validator={validator}
      widgets={widgets}
      templates={{ ObjectFieldTemplate }}
      onSubmit={onSubmit}
      showErrorList={false}
      noHtml5Validate
    />
  );
}
