import { useQuery } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface ComposeContentData {
  data: any;
}

export function useGetComposeContent(uuid: string, container: string) {
  const { keycloak } = useKeycloak();

  return useQuery(['contentCompose', uuid], {
    enabled: !!uuid && !!container,
    queryFn: async (): Promise<ComposeContentData> => {
      const response = await fetch(
        `${
          import.meta.env.VITE_IMPORT_ENGINE_ENDPOINT
        }/api/v1/bundles/content_builder_bundle/functions/node_transform`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${keycloak.token}`,
          },
          method: 'POST',
          body: JSON.stringify({
            rootUuid: uuid,
            container,
          }),
        }
      );

      const { data } = await response.json();

      if (!data) {
        return { data: {} };
      }

      return {
        data: data?.nodes || {},
      };
    },
  });
}
