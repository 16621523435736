import { useTreeStore } from '@/context/tree-context';
import {
  GRAN_FINE,
  PaginationData,
  useNodePagination,
} from '@/hooks/use-node-pagination';
import {
  ChevronUpIcon,
  ChevronDoubleUpIcon,
  ChevronDownIcon,
  ChevronDoubleDownIcon,
  MinusIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { useRef, useEffect } from 'react';

export const NODE_PAGINATION_HEIGHT = 60;

interface NodePaginationProps {
  className?: string;
  id: string;
  next?: boolean;
  paginationData?: PaginationData;
  prev?: boolean;
  total: number;
}

export function NodePagination({
  className,
  id,
  next,
  paginationData,
  prev,
  total,
}: NodePaginationProps) {
  const scrollTimeout = useRef<number | null>(null);
  const { handleFinePageChange, selectedFinePage } = useNodePagination(
    id!,
    paginationData,
    total
  );
  const setPagination = useTreeStore((state) => state.setPagination);
  const limit = paginationData?.limit || GRAN_FINE;
  const from = paginationData?.from || 0;

  useEffect(() => {
    return () => {
      if (scrollTimeout.current) {
        clearInterval(scrollTimeout.current);
      }
    };
  }, []);

  function handleScrollDownStart() {
    // scrollTimeout.current = setTimeout(() => {
    handleFinePageChange(selectedFinePage + 1);
    // }, 1000);
  }

  function handleScrollDownEnd() {
    if (!scrollTimeout.current) {
      return;
    }

    clearTimeout(scrollTimeout.current);
    scrollTimeout.current = null;
  }

  function handleSingleScrollDownStart() {
    // scrollTimeout.current = setTimeout(() => {
    if (from + limit >= total) {
      return;
    }

    setPagination(id!, { from: from + 1 });
    // }, 1000);
  }

  function handleSingleScrollDownEnd() {
    if (!scrollTimeout.current) {
      return;
    }

    clearTimeout(scrollTimeout.current);
    scrollTimeout.current = null;
  }

  function handleScrollUpStart() {
    // scrollTimeout.current = setTimeout(() => {
    handleFinePageChange(selectedFinePage - 1);
    // }, 1000);
  }

  function handleScrollUpEnd() {
    if (!scrollTimeout.current) {
      return;
    }

    clearTimeout(scrollTimeout.current);
    scrollTimeout.current = null;
  }

  function handleSingleScrollUpStart() {
    // scrollTimeout.current = setTimeout(() => {
    if (from <= 0) {
      return;
    }

    setPagination(id!, { from: from - 1 });
    // }, 1000);
  }

  function handleSingleScrollUpEnd() {
    if (!scrollTimeout.current) {
      return;
    }

    clearTimeout(scrollTimeout.current);
    scrollTimeout.current = null;
  }

  function handleLimitIncrease() {
    setPagination(id!, { limit: limit + 5 });
  }

  function handleLimitDecrease() {
    setPagination(id!, {
      limit: limit - 5 <= GRAN_FINE ? GRAN_FINE : limit - 5,
    });
  }

  return (
    <div className={className}>
      {prev ? (
        <>
          <span
            className="bg-indigo-100 text-indigo-700 hover:bg-indigo-200 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-indigo-900 dark:text-indigo-100 dark:hover:bg-indigo-700 rounded-md text-xs flex items-center justify-center flex-1 py-1 px-4"
            onClick={handleSingleScrollUpStart}
          >
            <ChevronUpIcon
              className="w-5 h-5"
              focusable="false"
              aria-hidden={true}
            />
          </span>
          <span
            className="bg-indigo-100 text-indigo-700 hover:bg-indigo-200 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-indigo-900 dark:text-indigo-100 dark:hover:bg-indigo-700 rounded-md text-xs flex items-center justify-center flex-1 py-1 px-4"
            onClick={handleScrollUpStart}
          >
            <ChevronDoubleUpIcon
              className="w-5 h-5"
              focusable="false"
              aria-hidden={true}
            />
          </span>
        </>
      ) : null}
      {next ? (
        <>
          <span
            className="bg-indigo-100 text-indigo-700 hover:bg-indigo-200 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-indigo-900 dark:text-indigo-100 dark:hover:bg-indigo-700 rounded-md text-xs flex items-center justify-center flex-1 py-1 px-4"
            onClick={handleSingleScrollDownStart}
          >
            <ChevronDownIcon
              className="w-5 h-5"
              focusable="false"
              aria-hidden={true}
            />
          </span>
          <span
            className="bg-indigo-100 text-indigo-700 hover:bg-indigo-200 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-indigo-900 dark:text-indigo-100 dark:hover:bg-indigo-700 rounded-md text-xs flex items-center justify-center flex-1 py-1 px-4"
            onClick={handleScrollDownStart}
          >
            <ChevronDoubleDownIcon
              className="w-5 h-5"
              focusable="false"
              aria-hidden={true}
            />
          </span>
        </>
      ) : null}
      <button
        className="bg-indigo-100 text-indigo-700 hover:bg-indigo-200 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-indigo-900 dark:text-indigo-100 dark:hover:bg-indigo-700 rounded-md text-xs flex items-center justify-center flex-1 py-1 px-4"
        onClick={handleLimitDecrease}
      >
        <MinusIcon className="w-5 h-5" focusable="false" aria-hidden={true} />
      </button>
      <button
        className="bg-indigo-100 text-indigo-700 hover:bg-indigo-200 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-indigo-900 dark:text-indigo-100 dark:hover:bg-indigo-700 rounded-md text-xs flex items-center justify-center flex-1 py-1 px-4"
        onClick={handleLimitIncrease}
      >
        <PlusIcon className="w-5 h-5" focusable="false" aria-hidden={true} />
      </button>
    </div>
  );
}
