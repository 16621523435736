import { Stats } from '@/components/ui/stats';
import { Widget } from '@/components/widget';
import { useListener } from '@/hooks/use-listener';
import { useObjects } from '@/hooks/use-objects';
import { useState } from 'react';

const WIDGET_ID = 'STATS_WIDGET';

export function StatsWidget() {
  const [uuids, setUuids] = useState<string[]>([]);
  const [containers, setContainers] = useState<string[]>([]);
  const { data } = useObjects(uuids, containers);
  const { enabled } = useListener({
    id: WIDGET_ID,
    listenerFn: (data) => {
      setUuids(data.map(({ uuid }: { uuid: string }) => uuid));
      setContainers(data.map(({ container }: { container: string }) => container));
    },
    type: 'ObjectCollection',
  });

  // Transform data to correct shape
  // const stats = data?.data?.resultset?.map((object) => ({}));
  const stats = [
    { name: 'Revenue', value: '$405,091.00', change: 4.75 },
    { name: 'Visits', value: '60.000', change: 54.02 },
    { name: 'Price own', value: '15.14', change: -17 },
    { name: 'Price Competition', value: '17.64', change: 17 }
  ];

  return (
    <Widget id={WIDGET_ID} title="Stats">
      {enabled && stats ? <Stats stats={stats} /> : null}
    </Widget>
  );
}
