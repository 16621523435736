import { useState, useEffect, SyntheticEvent } from 'react';
import { useListener } from '@/hooks/use-listener';
import { Widget } from '@/components/widget';
import { useObjectJson } from '@/hooks/use-object-json';
import { Editor } from '@monaco-editor/react';

const WIDGET_ID = 'OBJECT_JSON_WIDGET';

const monacoEditorOptions = {
  minimap: {
    enabled: false,
  },
  automaticLayout: true,
  readOnly: true
};

export function ObjectJsonWidget() {
  const [uuid, setUuid] = useState<string>("");
  const [container, setContainer] = useState<string>("");
  const { data } = useObjectJson(uuid, container);
  const { enabled } = useListener({
    id: WIDGET_ID,
    listenerFn: (data) => {
      setUuid(data.map(({ uuid }: { uuid: string }) => uuid)[0]);
      setContainer(data.map(({ container }: { container: string }) => container)[0]);
    },
    type: 'ObjectCollection',
  });

  return (
    <Widget id={WIDGET_ID} title="Object JSON">
      {enabled && data?.data && (
        <Editor
          value={data.data}
          language="json"
          theme="vs-light"
          height={650}
          options={monacoEditorOptions}
        />
      )}
    </Widget>
  )
}