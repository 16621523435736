import {
  ColumnDef,
  Row,
  RowSelectionState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import {
  Handle,
  NodeProps,
  NodeResizeControl,
  NodeResizer,
  Position,
} from 'reactflow';
import { Dragabble } from '@/components/drag-drop';
import clsx from 'clsx';
import { useTreeStore } from '@/context/tree-context';
import { NodePagination } from '@/components/node-pagination';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { useUserConfig } from '@/hooks/user/use-user-config';
import { useEmitter } from '@/hooks/use-emitter';
import { Badge } from '../badge';
import moment from 'moment';

interface TableData {
  collectionData: any[];
  collectionSchema: {
    properties: Record<string, any>;
  };
  collectionUiSchema: Record<string, any>;
  relatesTo: string;
  rowSelection?: RowSelectionState;
}

export const DEFAULT_PADDING = 8;
export const DEFAULT_BORDER_WIDTH = 1;

const TableNode = memo(function TableNode({
  data: nodeData,
  selected,
}: NodeProps<TableData>) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const setRowSelection = useTreeStore((state) => state.setRowSelection);
  const setSelectedRows = useTreeStore((state) => state.setSelectedRows);
  const setPagination = useTreeStore((state) => state.setPagination);
  const nodes = useTreeStore((state) => state.nodes);
  const parentNode = nodes.find((node) => node.id === nodeData.relatesTo);
  const { data: userConfig } = useUserConfig();
  const theadRef = useRef<HTMLTableSectionElement>(null);
  const trRef = useRef<HTMLTableRowElement>(null);

  const emit = useEmitter({
    id: 'EXPLORE_WIDGET',
    listeners: [
      'COLLECTION_WIDGET',
      'MEDIA_VIEWER_WIDGET',
      'DETAILS_WIDGET',
      'OBJECT_CHAIN_WIDGET',
      'OBJECT_JSON_WIDGET',
      'STATS_WIDGET',
      'COMPARISON_WIDGET',
      'PRODUCT_DETAILS_WIDGET',
      'PRODUCT_COMPARISON_WIDGET',
      'STACKED_LIST_WIDGET',
      'COMPETITION_SEARCH_WIDGET',
      'COMPETITION_SHOPS_WIDGET',
    ],
  });

  function getRootDataClass(uuid: string) {
    const rootDataClasses = userConfig?.organisationConfig.dataClasses
      .filter((dataClass) => dataClass.container === uuid)
      .map((dataClass) => dataClass.dataClass);

    return rootDataClasses[0];
  }

  const data = useMemo(() => {
    return nodeData.collectionData.map(({ data, ...rest }) => {
      return {
        ...data,
        nodeData: rest,
      };
    });
  }, [nodeData.collectionData]);

  const columns = useMemo<ColumnDef<any>[]>(() => {
    const columns = [];

    for (const [key, value] of Object.entries(
      nodeData?.collectionSchema?.properties || {}
    )) {
      columns.push({
        accessorKey: key,
        header: value.title,
        keyword: value.querySearchKeyword ?? false,
        size: value.tableWidth,
      });
    }

    return columns;
  }, [nodeData.collectionSchema]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection: parentNode?.data?.rowSelection || {},
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: (fn) => {
      if (typeof fn !== 'function' || !parentNode) return;

      setRowSelection(parentNode.id, fn(parentNode.data?.rowSelection || {}));
    },
    getRowId: (row) => row.nodeData.uuid,
  });
  const baseClassName = 'absolute left-0 flex gap-0.5';
  const prevClassName = clsx(baseClassName, 'bottom-full mb-8');
  const nextClassName = clsx(baseClassName, 'top-full mt-8');

  useEffect(() => {
    if (!parentNode) {
      return;
    }

    const allSelectedRowIds = Object.keys(parentNode.data.rowSelection || {});
    const existingSelectedRows = parentNode?.data?.selectedRows || [];
    const newSelectedRows = table
      .getSelectedRowModel()
      .rows.map((row) => row.original);
    const selectedRows = [
      ...new Set([...existingSelectedRows, ...newSelectedRows]),
    ].filter((row) => allSelectedRowIds.includes(row.nodeData.uuid));

    setSelectedRows(parentNode?.id, selectedRows);

    emit({
      type: 'ObjectCollection',
      data: selectedRows.map((row) => ({
        uuid: row.nodeData.uuid,
        container: row.nodeData?.root
          ? row.nodeData.uuid
          : row.nodeData.container,
        publicUrl: row.nodeData?.publicUrl,
        root: row.nodeData?.root,
        dataClassUuid: row.nodeData?.root
          ? getRootDataClass(row.nodeData.uuid)
          : row.nodeData.dataClassUuid,
      })),
    });
  }, [parentNode?.data?.rowSelection]);

  return (
    <div className="bg-white/70 p-2 rounded-[3px] shadow-[0_0_0_2px_#c084fc] h-full">
      <NodeResizeControl
        className="absolute right-0 bottom-0 !left-auto !top-auto !w-6 !h-6 !border-none p-1 !translate-x-1/2 !translate-y-1/2"
        onResizeEnd={(_, { height }) => {
          if (!parentNode) {
            return;
          }

          const limit = Math.floor(
            (height -
              2 * DEFAULT_PADDING -
              (theadRef.current?.clientHeight || 0) -
              2 * DEFAULT_BORDER_WIDTH) /
              (trRef.current?.clientHeight || 0)
          );
          setPagination(parentNode.id, { limit });
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 6 6"
          className="w-1.5 h-1.5"
        >
          <path
            d="M 6 6 L 0 6 L 0 4.2 L 4 4.2 L 4.2 4.2 L 4.2 0 L 6 0 L 6 6 L 6 6 Z"
            fill="#585858"
          />
        </svg>
      </NodeResizeControl>
      <div className="bg-white/30 border border-gray-300 rounded overflow-x-auto">
        <Handle
          type="target"
          position={Position.Left}
          style={{ background: '#555' }}
        />
        <table className="cursor-default nodrag nopan divide-y divide-gray-300 table-fixed w-full">
          <thead className="bg-gray-50" ref={theadRef}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, i) => {
                  const thClassName = clsx(
                    'whitespace-nowrap text-left text-sm font-semibold text-gray-900 overflow-hidden text-ellipsis py-3.5',
                    header.index === 0 && 'pl-4 pr-3 sm:pl-6',
                    header.index !== 0 &&
                      header.index !== headerGroup.headers.length - 1 &&
                      'px-3',
                    header.index === headerGroup.headers.length - 1 &&
                      'pl-3 pr-4 sm:pr-6'
                  );

                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={thClassName}
                      style={{ width: header.getSize() }}
                    >
                      {header.isPlaceholder ? null : (
                        <Dragabble type="query/field" value={header.id}>
                          <div
                            className={
                              header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : ''
                            }
                            onClick={header.column.getToggleSortingHandler()}
                            title={
                              header.column.getCanSort()
                                ? header.column.getNextSortingOrder() === 'asc'
                                  ? 'Sort ascending'
                                  : header.column.getNextSortingOrder() ===
                                    'desc'
                                  ? 'Sort descending'
                                  : 'Clear sort'
                                : undefined
                            }
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: ' 🔼',
                              desc: ' 🔽',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </Dragabble>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {table.getRowModel().rows.map((row) => {
              const trClassName = clsx(row.getIsSelected() && 'bg-green-50');
              return (
                <tr
                  key={row.id}
                  onClick={row.getToggleSelectedHandler()}
                  className={trClassName}
                  ref={row.index === 0 ? trRef : undefined}
                >
                  {row.getVisibleCells().map((cell, i) => {
                    const tdClassName = clsx(
                      'py-4 text-sm text-gray-500 select-text max-w-[0] text-left',
                      cell.column.getIsFirstColumn() && 'pl-4 pr-3 sm:pl-6',
                      !cell.column.getIsFirstColumn() &&
                        !cell.column.getIsLastColumn() &&
                        'px-3',
                      cell.column.getIsLastColumn() && 'pl-3 pr-4 sm:pr-6'
                    );
                    const uiType =
                      nodeData?.collectionUiSchema?.[cell.column.id]?.[
                        'ui:widget'
                      ];

                    return (
                      <td
                        key={cell.id}
                        className={tdClassName}
                        style={{ width: cell.column.getSize() }}
                      >
                        <div className="relative rounded-sm">
                          {cell.column.getIsLastColumn() &&
                          row.getIsSelected() ? (
                            <span className="rounded-full absolute -top-3.5 -right-[22px] h-5 w-5">
                              <CheckCircleIcon
                                className="h-5 w-5 text-green-500"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                          {cell.getValue() ? (
                            <Dragabble
                              className="whitespace-nowrap overflow-hidden text-ellipsis"
                              type="query/value"
                              value={{
                                field: cell.column.id,
                                value: cell.getValue(),
                                keyword: cell.column.columnDef.keyword ?? false,
                              }}
                            >
                              {uiType === 'badge' ? (
                                <Badge
                                  variant={cell.getValue() as string}
                                  size="sm"
                                >
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </Badge>
                              ) : null}
                              {uiType === 'datetime' ? (
                                <time>
                                  {moment
                                    .unix(cell.getValue() as number)
                                    .format('DD/MM/YYYY HH:mm')}
                                </time>
                              ) : null}
                              {!uiType
                                ? flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )
                                : null}
                            </Dragabble>
                          ) : (
                            ''
                          )}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {parentNode ? (
        <>
          <NodePagination
            id={parentNode.id}
            className={prevClassName}
            total={parentNode?.data.total}
            paginationData={parentNode?.data?.paginationData}
            prev
          />
          <NodePagination
            id={parentNode.id}
            className={nextClassName}
            total={parentNode?.data.total}
            paginationData={parentNode?.data?.paginationData}
            next
          />
        </>
      ) : null}
    </div>
  );
});

export default TableNode;
