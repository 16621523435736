import { useState } from 'react';
import GridLayout, { WidthProvider } from 'react-grid-layout';

import { EditWidget } from '@/components/edit-widget';
import { ExploreWidget } from '@/components/explore-widget';
import { Page } from '@/components/page';
import { Toggle } from '@/components/toggle';
import { GridPanel } from '@/components/grid-panel';
import { CollectionWidget } from '@/components/collection-widget';
import { MediaViewerWidget } from '@/components/media-viewer-widget';
import { DetailsWidget } from '@/components/details-widget';
import { GeolocationWidget } from '@/components/geolocation-widget/geolocation-widget';
import { RadialBarChartWidget } from '@/components/radial-bar-chart-widget';
import { useImageStats } from '@/hooks/stats/use-image-stats';
import { useBulkStats } from '@/hooks/stats/use-bulk-stats';
import { useTRStats } from '@/hooks/stats/use-tr-stats';
import { useTRGoogleFilesStats } from '@/hooks/stats/use-tr-google-files-stats';
import { LineChartWidget } from '@/components/line-chart-widget';
import { ListWidget } from '@/components/list-widget';
import { TreeStoreProvider } from '@/context/tree-context';
import { JobsWidget } from '@/components/jobs-widget';
import { ObjectJsonWidget } from '@/components/object-json-widget';
import { ChartProvider } from '@/components/chart-provider';


import { ComparisonWidget } from '@/components/comparison-widget';
import { ProductComparisonWidget } from '@/components/product-comparison-widget';
import { ProductDetailsWidget } from '@/components/product-details-widget';
import { StackedListWidget } from '@/components/stacked-list-widget';
import { CompetitionSearchWidget } from '@/components/competition-search-widget';
import { CompetitionShopsWidget } from '@/components/competition-shops-widget';
import { StatsWidget } from '@/components/stats-widget';


const layout = [
  { i: 'main', x: 0, y: 0, w: 40, h: 10 },
  { i: 'stats', x: 0, y: 1, w: 40, h: 6},
  { i: 'stackedList', x: 0, y: 2, w: 20, h: 10 },
  { i: 'CompetitionSearch', x: 20, y: 2, w: 20, h: 10 },
  { i: 'CompetitionShops', x: 40, y: 3, w: 40, h: 10 },
  { i: 'ProductDetailsWidget', x: 20, y: 4, w: 40, h: 20 },
  { i: 'ProductComparisonWidget', x: 0, y: 5, w: 40, h: 10 },
  { i: 'ComparisonWidget', x: 0, y: 6, w: 40, h: 10 },

];

const AutoWidthGridLayout = WidthProvider(GridLayout);

export function ProductPerformance() {
  const [isDraggable, setIsDraggable] = useState<boolean>(false);
  // const { data: series } = useImageStats();
  // const { data: bulkSeries } = useBulkStats();
  // const { data: trSeries } = useTRStats();

  return (
    <Page
      title="Explore Dev"
      toolbar={
        <Toggle
          enabled={isDraggable}
          label="Enable drag"
          onChange={setIsDraggable}
        />
      }
    >
      <AutoWidthGridLayout
        className="layout"
        layout={layout}
        cols={40}
        rowHeight={30}
        isDraggable={isDraggable}
      >
        <GridPanel key="main">
          <TreeStoreProvider name="explore-a">
            <ExploreWidget />
          </TreeStoreProvider>
        </GridPanel>
        <GridPanel key="stats">
          <StatsWidget />
        </GridPanel>
        <GridPanel key="stackedList">
          <StackedListWidget />
        </GridPanel>
        <GridPanel key="CompetitionSearch">
          <CompetitionSearchWidget />
        </GridPanel>
        <GridPanel key="CompetitionShops">
          <CompetitionShopsWidget />
        </GridPanel>
        {/* <GridPanel key="ProductDetailsWidget">
          <ProductDetailsWidget />
        </GridPanel> */}
        <GridPanel key="ProductComparisonWidget">
          <ProductComparisonWidget />
        </GridPanel>
        <GridPanel key="ComparisonWidget">
          <ComparisonWidget />
        </GridPanel>
      </AutoWidthGridLayout>
    </Page>
  );
}