import { useMutation } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

export function useJobDownload() {
  const { keycloak } = useKeycloak();

  return useMutation({
    mutationFn: async (jobData: object): Promise<object> => {

      const response = await fetch(
        `${import.meta.env.VITE_DOWNLOAD_ENGINE_ENDPOINT}/api/v1/bundles/download_bundle/functions/download_zip`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'POST',
          body: JSON.stringify(jobData),
        }
      );
      const blob = await response.blob();

      // console.log(blob);

      return {
        blob: blob
      };
    },
  });
}