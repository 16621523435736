import { Button } from '@/components/button';
import { WIDGET_ID } from '@/components/page-editor-widget/page-editor-widget';
import { usePuck } from '@measured/puck';
import { useLoadPuckData } from '@/features/populate/hooks/use-load-puck-data';
import { PuckEditorProps } from '@/features/populate/components/puck-editor';

interface PuckHeaderProps extends PuckEditorProps {}

export function PuckHeader({ onSave, isLoading }: PuckHeaderProps) {
  const { appState } = usePuck();
  const { reset, rootNode } = useLoadPuckData(WIDGET_ID);

  function handleSave() {
    onSave?.(appState.data, rootNode);
  }

  function handleNew() {
    reset();
  }

  return (
    <div className="pb-4 flex justify-between">
      <Button onClick={handleNew}>New</Button>
      <Button onClick={handleSave} disabled={isLoading} loading={isLoading}>
        Save
      </Button>
    </div>
  );
}
