import {
  applyEdgeChanges,
  applyNodeChanges,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  OnEdgesChange,
  OnNodesChange,
} from 'reactflow';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { layoutNodes } from '@/hooks/use-auto-layout';
import { PaginationData } from '@/hooks/use-node-pagination';
import {
  DEFAULT_OBJECT_NODE_HEIGHT,
  DEFAULT_OBJECT_NODE_WIDTH,
} from '@/components/object-node';
import { DEFAULT_IMAGE_NODE_HEIGHT } from '@/components/image-node';
import { ViewMode } from '@/components/view-mode-switch';
import { RowSelectionState } from '@tanstack/react-table';

export interface TreeState {
  container: string | undefined;
  nodes: Node[];
  edges: Edge[];
  edgesPositioning: Edge[];
  esQuery?: string;
  searchStringNode?: string;
  openAttributeNode: string | undefined;
}

export interface TreeAction {
  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;
  addData: (nodes: Node[], edges: Edge[], edgesPositioning: Edge[]) => void;
  addNodes: (nodes: Node[]) => void;
  addChildrenNodes: (parentNodeId: string, nodes: Node[]) => void;
  openNode: (nodeId: string) => void;
  closeNode: (nodeId: string) => void;
  openAttributeNodeAction: (nodeId: string) => void;
  addEdges: (parentNodeId: string, edges: Edge[]) => void;
  addEdgesPositioning: (edges: Edge[]) => void;
  removeEdge: (edgeId: string) => void;
  setTotal: (nodeId: string, total: number) => void;
  setContainer: (container: string) => void;
  setEsQuery: (nodeId: string, esQuery: string) => void;
  setSearchStringNode: (nodeId: string, searchStringNode: string) => void;
  setSearchParams: (
    nodeId: string,
    pitId: string,
    searchAfterArray: any
  ) => void;
  toggleNode: (nodeId: string) => void;
  setPagination: (nodeId: string, paginationData: PaginationData) => void;
  setViewMode: (nodeId: string, viewMode: ViewMode) => void;
  setRowSelection: (nodeId: string, rowSelection: RowSelectionState) => void;
  setSelectedRows: (nodeId: string, selectedRows: any[]) => void;
  clear: () => void;
}

export const createTreeStore = (name: string) =>
  create<TreeState & TreeAction>()(
    devtools(
      persist(
        (set, get) => ({
          container: undefined,
          nodes: [],
          edges: [],
          edgesPositioning: [],
          esQuery: undefined,
          searchString: undefined,
          openAttributeNode: undefined,
          openNode: (nodeId: string) => {
            set((state) => ({
              nodes: state.nodes.map((node) => {
                if (node.id === nodeId) {
                  node.data = { ...node.data, open: true };
                }

                return node;
              }),
              openAttributeNode: nodeId,
            }));
          },
          openAttributeNodeAction: (nodeId: string) => {
            set((state) => ({
              openAttributeNode: nodeId,
            }));
          },
          closeNode: (nodeId: string) => {
            set((state) => ({
              nodes: state.nodes.map((node) => {
                if (node.id === nodeId) {
                  node.data = { ...node.data, open: false };
                }

                return node;
              }),
            }));
          },
          clear: () => {
            set(() => ({
              container: undefined,
              nodes: [],
              edges: [],
              edgesPositioning: [],
            }));
          },
          addData: (nodes: Node[], edges: Edge[], edgesPositioning: Edge[]) => {
            set(() => ({
              nodes: nodes,
              edges: edges,
              edgesPositioning: edgesPositioning,
            }));
          },
          addNodes: (nodes: Node[]) => {
            set((state) => ({
              nodes: state.nodes.concat(nodes),
            }));
          },
          addChildrenNodes: (parentNodeId: string, nodes: Node[]) => {
            set((state) => {
              const previousNodes = state.nodes;
              const nodeIdsToRemove = state.nodes
                .filter((node) => node.data.path?.includes(parentNodeId))
                .map((node) => node.id);

              return {
                // Remove existing children nodes and their children from parent
                nodes: state.nodes
                  .filter((node) => !nodeIdsToRemove.includes(node.id))
                  .filter(
                    (node) =>
                      !node.parentNode ||
                      !nodeIdsToRemove.includes(node.parentNode)
                  )
                  .concat(nodes)
                  .map((node) => {
                    const previousNodeState = previousNodes.find(
                      (previousNode) => previousNode.id === node.id
                    );

                    if (previousNodeState) {
                      return {
                        ...node,
                        position: previousNodeState.position,
                      };
                    }

                    return node;
                  }),
                // Remove edge positionings for parent's children
                edgesPositioning: state.edgesPositioning.filter(
                  (edge) => !nodeIdsToRemove.includes(edge.target)
                ),
              };
            });
          },
          addEdges: (parentNodeId: string, edges: Edge[]) => {
            set((state) => {
              return {
                edges: state.edges
                  .filter((edge) => edge.source !== parentNodeId)
                  .concat(edges),
              };
            });
          },
          addEdgesPositioning: (edges: Edge[]) => {
            set((state) => {
              const uniqueEdges = edges.filter(
                (edge) =>
                  !state.edgesPositioning.some(
                    (existingEdge) => existingEdge.id === edge.id
                  )
              );

              if (uniqueEdges.length === 0) {
                return state;
              }

              return {
                edgesPositioning: state.edgesPositioning.concat(uniqueEdges),
              };
            });
          },
          removeEdge: (edgeId: string) => {
            const edgeToRemove = get().edges.find((edge) => edge.id === edgeId);

            if (!edgeToRemove) {
              return;
            }

            set((state) => {
              return {
                nodes: [
                  ...state.nodes.filter(
                    (node) =>
                      node.id !== edgeToRemove.target &&
                      !node.data.path.includes(edgeToRemove.target)
                  ),
                ],
                edges: [
                  ...state.edges.filter((edge) => edge.id !== edgeToRemove.id),
                ],
                edgesPositioning: [
                  ...state.edgesPositioning.filter(
                    (edge) => edge.target !== edgeToRemove.target
                  ),
                ],
              };
            });
          },
          onNodesChange: (changes: NodeChange[]) => {
            set({
              nodes: applyNodeChanges(changes, get().nodes),
            });
          },
          onEdgesChange: (changes: EdgeChange[]) => {
            set({
              edges: applyEdgeChanges(changes, get().edges),
            });
          },
          setContainer: (container: string) => {
            if (get().container === container) {
              return;
            }

            set({ container, nodes: [], edges: [], edgesPositioning: [] });
          },
          setTotal: (nodeId: string, total: number) => {
            set({
              nodes: get().nodes.map((node) => {
                if (node.id === nodeId) {
                  node.data = { ...node.data, total };
                }

                return node;
              }),
            });
          },
          setSearchParams: (
            nodeId: string,
            pitId: string,
            searchAfterArray: any
          ) => {
            set({
              nodes: get().nodes.map((node) => {
                if (node.id === nodeId) {
                  node.data = {
                    ...node.data,
                    pitId,
                    searchAfterArray,
                  };
                }

                return node;
              }),
            });
          },
          setEsQuery: (nodeId: string, esQuery: string) => {
            set({
              nodes: get().nodes.map((node) => {
                if (node.id === nodeId) {
                  node.data = {
                    ...node.data,
                    esQuery: esQuery,
                    pitId: null
                  };
                }

                return node;
              }),
            });
          },
          setSearchStringNode: (nodeId: string, searchStringNode: string) => {
            set({
              nodes: get().nodes.map((node) => {
                if (node.id === nodeId) {
                  node.data = {
                    ...node.data,
                    searchStringNode: searchStringNode,
                  };
                }

                return node;
              }),
            });
          },
          setPagination: (nodeId: string, paginationData: PaginationData) => {
            set({
              nodes: get().nodes.map((node) => {
                if (node.id === nodeId) {
                  const newPaginationData = {
                    ...node.data?.paginationData,
                    ...paginationData,
                  };
                  node.data = {
                    ...node.data,
                    paginationData: newPaginationData,
                  };
                }

                return node;
              }),
            });
          },
          setViewMode: (nodeId: string, viewMode: ViewMode) => {
            set({
              nodes: get().nodes.map((node) => {
                if (node.id === nodeId) {
                  node.data = {
                    ...node.data,
                    viewMode,
                  };
                }

                return node;
              }),
            });
          },
          setRowSelection: (
            nodeId: string,
            rowSelection: RowSelectionState
          ) => {
            set({
              nodes: get().nodes.map((node) => {
                if (node.id === nodeId) {
                  node.data = {
                    ...node.data,
                    rowSelection,
                  };
                }

                return node;
              }),
            });
          },
          setSelectedRows: (nodeId: string, selectedRows: any[]) => {
            set({
              nodes: get().nodes.map((node) => {
                if (node.id === nodeId) {
                  node.data = {
                    ...node.data,
                    selectedRows,
                  };
                }

                return node;
              }),
            });
          },
          toggleNode: (nodeId: string) => {
            set((state) => {
              const childrenIds: string[] = [];

              const nodes = state.nodes.map((node) => {
                if (node.style?.zIndex) {
                  node.style = {
                    ...node.style,
                    zIndex: 999,
                  };
                }

                if (node.id === nodeId) {
                  const prevOpen = node.data.open;

                  const relations = state.nodes.filter(
                    (node) => node.parentNode === nodeId
                  );

                  node.data.open = !prevOpen;
                  const style = {
                    ...node.style,
                    zIndex: prevOpen ? undefined : 1001,
                  };
                  node.style = {
                    ...style,
                    width: DEFAULT_OBJECT_NODE_WIDTH,
                    height: prevOpen
                      ? node.type === 'image'
                        ? DEFAULT_IMAGE_NODE_HEIGHT
                        : DEFAULT_OBJECT_NODE_HEIGHT
                      : node.type === 'image'
                      ? relations.length > 0
                        ? relations.length * 50 + 176
                        : 180
                      : relations.length > 0
                      ? relations.length * 50 + DEFAULT_OBJECT_NODE_HEIGHT
                      : 50,
                  };
                  node.height = prevOpen
                    ? node.type === 'image'
                      ? DEFAULT_IMAGE_NODE_HEIGHT
                      : DEFAULT_OBJECT_NODE_HEIGHT
                    : node.type === 'image'
                    ? relations.length > 0
                      ? relations.length * 50 + 176
                      : 180
                    : relations.length > 0
                    ? relations.length * 50 + DEFAULT_OBJECT_NODE_HEIGHT
                    : 50;
                }

                if (node.parentNode === nodeId) {
                  childrenIds.push(node.id);
                  node.hidden = !node.hidden;
                  node.style = {
                    ...node.style,
                    zIndex: node.hidden ? undefined : 1001,
                  };
                }

                // @TODO: check if this is the best way..
                if (node.data.upRelation) {
                  const nodeUuid = nodeId.split('=')[0];
                  if (
                    node.data.upRelation.uuid === nodeUuid &&
                    node.data.path.includes(nodeId)
                  ) {
                    node.hidden = !node.hidden;
                  }
                }

                if (node.type === 'group' && node.data.path?.includes(nodeId)) {
                  node.hidden = !node.hidden;
                }

                return node;
              });

              const edges = state.edges.map((edge) => {
                if (childrenIds.includes(edge.target)) {
                  edge.hidden = edge.hidden === false ? true : false;
                }

                // @TODO: check if this is the best way..
                const nodeUuid = nodeId.split('=')[0];
                const sourceUuid = edge.source.split('=')[0];

                if (nodeUuid === sourceUuid) {
                  edge.hidden = !edge.hidden;
                }

                return edge;
              });

              return {
                nodes: layoutNodes(nodes, state.edgesPositioning),
                edges: [...edges],
              };
            });
          },
        }),
        {
          name,
        }
      )
    )
  );
