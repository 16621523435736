import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';
import { RuleGroupType } from 'react-querybuilder';

interface UserNotificationData {
  collections: Collection[];
}

type Collection = {
  uuid: string;
  esQuery: string;
  query: RuleGroupType;
  container: string;
  title: string;
};

export function useUserOrganisationCollections() {
  const queryClient = useQueryClient();
  const { keycloak } = useKeycloak();

  return useQuery({
    queryKey: ['userOrganisationCollections'],
    queryFn: async (): Promise<UserNotificationData> => {
      const response = await fetch(
        `${
          import.meta.env.VITE_USER_ENGINE_ENDPOINT
        }/api/v1/bundles/user_bundle/functions/get_organisation_collections`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${keycloak.token}`,
          },
          method: 'GET',
        }
      );

      let data = await response.json();

      // console.log(data);

      const { collections } = data?.data;

      return {
        collections,
      };
    },
  });
}
