import { useUserNotifications } from "@/hooks/user/use-user-notifications";
import { Transition } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { Fragment, useState } from 'react'
import { useUserNotificationRead } from "@/hooks/user/use-user-notification-read";

export function PushNotifications() {
  const { data, refetch } = useUserNotifications();
  const markRead = useUserNotificationRead();

  function handleMarkRead(uuid: string) {
    markRead.mutateAsync({notificationUuid: uuid});
  }

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex flex-col gap-3 px-4 py-6 mt-10 sm:items-end sm:p-6"
      >
        {data?.notifications.map((notification) => (
          <div 
            key={notification.uuid}
            className="flex w-[450px] flex-col items-center space-y-4 sm:items-end"
          >
            {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
            <Transition
              show={true}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 dark:bg-gray-500">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">Successfully saved!</p>
                      <p className="mt-1 text-sm text-gray-500 dark:text-gray-900">{notification.message}</p>
                    </div>
                    <div className="ml-4 flex flex-shrink-0">
                      <button
                        type="button"
                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-gray-600"
                        onClick={() => handleMarkRead(notification.uuid)}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        ))}
      </div>
    </>
  )
}