import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface NotificationData {
    notificationUuid: string
}


export function useUserNotificationRead() {
  const { keycloak } = useKeycloak();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (notificationData: object): Promise<NotificationData> => {

      const response = await fetch(
        `${import.meta.env.VITE_USER_ENGINE_ENDPOINT}/api/v1/bundles/user_bundle/functions/update_user_notification_read`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'POST',
          body: JSON.stringify(notificationData),
        }
      );

      return response.json();
    },
    onSuccess: (notification) => {
      queryClient.invalidateQueries(['userNotifications']);
    },
  });
}