import { DragDrop } from '@/components/drag-drop';
import {
  ArrowUturnLeftIcon,
  ClockIcon,
  LinkIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useRefineAgent } from '@/features/agents/hooks/use-refine-agent';
import { useTestAgent } from '@/features/agents/hooks/use-test-agent';
import { ObjectParams } from '@/hooks/use-relation-insert';
import {
  DefaultValues,
  Step,
} from '@/features/agents/components/agent-creator-widget';
import { useEffect, useRef, useState } from 'react';
import { Navigation } from '@/features/agents/components/navigation';
import { useEmitter } from '@/hooks/use-emitter';
import { MagicButton } from '@/features/agents/components/magic-button';
import { Badge } from '@/components/badge';
import { Timer } from '@/features/agents/components/timer';
import ReactECharts, { EChartsOption } from 'echarts-for-react';
import { useTheme } from '@/hooks/use-theme';
import { useGetAgent } from '@/features/agents/hooks/use-get-agent';
import { useGetAgentRuntime } from '@/features/agents/hooks/use-get-agent-runtime';
import { useStopAgent } from '@/features/agents/hooks/use-stop-agent';
import { useUndoRefineAgent } from '../../hooks/use-undo-refine-agent';
import { useQueryClient } from '@tanstack/react-query';
import { Editor } from '@monaco-editor/react';

type TestFormInputs = {
  sourceExample?: {
    uuid: string;
    container: string;
  };
  inputObject?: string;
  tune?: string;
  title?: string;
};

interface TestFormProps {
  defaultValues: DefaultValues;
  steps: Step[];
}

const monacoEditorOptions = {
  minimap: {
    enabled: false,
  },
  automaticLayout: true,
  readOnly: true,
};

export function TestForm({ defaultValues, steps }: TestFormProps) {
  const queryClient = useQueryClient();
  const { uuid } = useParams();
  const { data: agentData } = useGetAgent(uuid!);
  const [runtimeEnabled, setRuntimeEnabled] = useState(false);
  const { data: agentRuntimeData } = useGetAgentRuntime(uuid!, runtimeEnabled);
  const [error, setError] = useState<string | null>(null);
  const [output, setOutput] = useState<string>('');
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<TestFormInputs>({
    values: {
      ...defaultValues,
      title: agentData?.title,
    },
  });
  const { theme } = useTheme('light');
  const [startTime, setStartTime] = useState<Date | null>(null);
  const logContainerRef = useRef<HTMLPreElement>(null);
  const errorContainerRef = useRef<HTMLPreElement>(null);
  const options: EChartsOption = {
    color: [
      'rgb(199, 210, 254)',
      'rgb(165, 180, 252)',
      'rgb(129, 140, 248)',
      'rgb(99, 102, 241)',
      'rgb(79, 70, 229)',
      'rgb(67, 56, 202)',
      'rgb(55, 48, 163)',
      'rgb(49, 46, 129)',
    ],
    angleAxis: {
      startAngle: 90,
      splitLine: {
        show: false,
      },
      show: false,
      max: 'dataMax',
    },
    radiusAxis: {
      type: 'category',
      z: 10,
      show: false,
      min: -1,
    },
    polar: {
      radius: '85%',
      center: ['65%', '50%'],
    },
    series: (agentRuntimeData?.attributes || []).map((attribute) => ({
      type: 'bar',
      showBackground: true,
      itemStyle: {
        backgroundColor: 'gray',
      },
      data: [attribute.value as number],
      coordinateSystem: 'polar',
      roundCap: true,
      name: attribute.label,
    })),
    legend: {
      show: true,
      orient: 'vertical',
      left: 'left',
      top: '40%',
      selectMode: false,
      textStyle: { color: theme === 'light' ? '#000' : '#fff' },
      formatter: `{name}`,
    },
    tooltip: {
      show: true,
      formatter: '{a}<br /><strong>{c0}</strong>',
    },
  };

  const refineAgentMutation = useRefineAgent();
  const testAgentMutation = useTestAgent();
  const stopAgentMutation = useStopAgent();
  const undoRefineAgentMutation = useUndoRefineAgent();

  const emitHtmlPreview = useEmitter({
    id: 'AGENT_CREATOR_WIDGET',
    listeners: ['HTML_PREVIEW_WIDGET'],
  });

  useEffect(() => {
    if (logContainerRef.current) {
      logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
    }
  }, [agentRuntimeData?.log]);

  useEffect(() => {
    if (errorContainerRef.current) {
      errorContainerRef.current.scrollTop =
        errorContainerRef.current.scrollHeight;
    }
  }, [agentRuntimeData?.error, error]);

  function onTune(data: TestFormInputs) {
    refineAgentMutation.mutate(
      {
        agentUuid: uuid!,
        title: data.title,
        corrections: data.tune,
      },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries({ queryKey: ['agent', uuid] });
        },
      }
    );
  }

  function onTest(data: TestFormInputs) {
    setStartTime(new Date());
    setRuntimeEnabled(true);
    setError(null);
    setOutput('');
    testAgentMutation.mutate(
      {
        agentUuid2: uuid!,
        sourceExample: data.sourceExample,
        inputObject: data.inputObject,
      },
      {
        onSuccess: (data) => {
          setStartTime(null);
          setRuntimeEnabled(false);

          if (data.error) {
            setError(data.error);
          }

          if (data?.output) {
            setOutput(JSON.stringify(data.output, null, 2));
          }

          if (data?.output?.html) {
            emitHtmlPreview({
              type: 'preview/html',
              data: {
                html: data.output.html,
              },
            });
          }
        },
      }
    );
  }

  function onUndo() {
    undoRefineAgentMutation.mutate(uuid!, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['agent', uuid] });
      },
    });
  }

  function onCancel() {
    stopAgentMutation.mutate(uuid!, {
      onSuccess: () => {
        testAgentMutation.abort();
        testAgentMutation.reset();
        setStartTime(null);
        setRuntimeEnabled(false);
      },
    });
  }

  function handleDrop(
    { value }: { value: ObjectParams[] },
    field: keyof TestFormInputs
  ) {
    setValue(field, { uuid: value[0].uuid, container: value[0].container });
  }

  const sourceExample = watch('sourceExample');
  const isLoading =
    refineAgentMutation.isLoading ||
    testAgentMutation.isLoading ||
    stopAgentMutation.isLoading ||
    undoRefineAgentMutation.isLoading;

  return (
    <>
      <Navigation loadingStep={isLoading ? 'test' : undefined} steps={steps} />
      <form className="p-7 grid grid-cols-2 gap-x-7 gap-y-4 items-start">
        <div className="grid gap-y-4">
          <div className="flex gap-x-4 items-center">
            <div className="flex-1">
              <label
                htmlFor="agentTitle"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Agent Title
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  id="title"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  {...register('title')}
                />
                {errors.title && (
                  <p className="text-red-500 text-sm mt-1">
                    Title is required.
                  </p>
                )}
              </div>
            </div>
            <span className="text-sm text-gray-500 mt-8">
              Rev {agentData?.revision}
            </span>
          </div>
          <div>
            <label
              htmlFor="sourceExample"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Test Input
            </label>
            <div className="space-y-1 mt-2">
              <DragDrop
                allowedTypes={['object/insert']}
                onDrop={(data) => handleDrop(data, 'sourceExample')}
                className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-purple px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400 border-dashed justify-center text-center h-5"
                droppingClassName="relative flex items-center space-x-3 rounded-lg border border-gray-300 px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-800 border-dashed bg-purple-50 flex justify-center items-center h-5"
              >
                {({ isDropping }) =>
                  isDropping ? (
                    <PlusIcon className="h-4 w-4" />
                  ) : (
                    <PlusIcon className="h-4 w-4" />
                  )
                }
              </DragDrop>
              {sourceExample?.uuid && sourceExample?.container ? (
                <div className="relative flex items-center gap-3 rounded-lg border border-gray-300 bg-white py-2 pl-2 pr-3 shadow-sm text-xs">
                  <div className="relative w-10 h-10 bg-purple-50 rounded-full flex justify-center items-center text-center">
                    <LinkIcon className="h-5 w-5" />
                  </div>
                  <div className="min-w-0 flex-1">
                    <p className="font-medium text-gray-900">
                      {sourceExample.container}
                    </p>
                    <p className="truncate text-gray-500">
                      {sourceExample.uuid}
                    </p>
                  </div>
                  <button
                    onClick={() =>
                      setValue('sourceExample', { uuid: '', container: '' })
                    }
                    className="text-gray-400 hover:text-gray-700"
                  >
                    <TrashIcon className="h-4 w-4" />
                  </button>
                </div>
              ) : null}
              <input type="hidden" {...register('sourceExample')} />
              {errors.sourceExample && (
                <p className="text-red-500 text-sm mt-1">
                  Test Input is required.
                </p>
              )}
            </div>
          </div>
          <div>
            <label
              htmlFor="inputObject"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Test Input Free Text
            </label>
            <div className="mt-2">
              <textarea
                id="inputObject"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                {...register('inputObject')}
              ></textarea>
              {errors.inputObject && (
                <p className="text-red-500 text-sm mt-1">
                  Input Object Free Text is required.
                </p>
              )}
            </div>
          </div>
          <div>
            <label
              htmlFor="tune"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Tune
            </label>
            <div className="mt-2">
              <textarea
                id="tune"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                {...register('tune')}
              ></textarea>
            </div>
          </div>
          <div className="flex gap-x-4">
            <button
              onClick={handleSubmit(onTune)}
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Tune
            </button>

            <button
              onClick={handleSubmit(onUndo)}
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex items-center gap-x-2"
            >
              <ArrowUturnLeftIcon className="h-5 w-5" />
              Undo
            </button>
          </div>
        </div>
        <div className="col-start-2 row-start-1 row-span-3 bg-slate-50 grid grid-cols-2 gap-4 p-4 rounded-lg">
          <div className="col-span-2 flex gap-x-4 items-center">
            {agentRuntimeData?.status === 'running' ||
            testAgentMutation.isLoading ? (
              <Badge variant="success">Running</Badge>
            ) : (
              <Badge variant="info">Inactive</Badge>
            )}
            {startTime ? (
              <>
                <span className="text-sm text-gray-500 ml-auto inline-flex items-center gap-x-1">
                  <ClockIcon className="h-4 w-4" />
                  Started at{' '}
                  <span className="font-mono text-xs">
                    {startTime.toLocaleTimeString()}
                  </span>
                </span>
                <Timer startTime={startTime} />
              </>
            ) : null}
          </div>
          <div className="row-span-2">
            <ReactECharts
              option={options}
              style={{ width: '100%' }}
              notMerge={true}
            />
          </div>
          <Editor
            value={output || ''}
            language="json"
            theme="vs-light"
            height={350}
            options={monacoEditorOptions}
            className="rounded-md border-gray-300 border overflow-hidden py-2 pr-2 bg-[#fffffe]"
          />
          <div className="col-start-2">
            <span className="block text-sm font-medium leading-6 text-gray-900 mb-2">
              Log
            </span>
            <pre
              className="block h-40 w-full rounded-md border-0 py-1.5 px-3 text-xs font-sans text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 overflow-x-auto bg-white"
              ref={logContainerRef}
            >
              {agentRuntimeData?.log}
            </pre>
          </div>
          <div className="col-start-2">
            <span className="block text-sm font-medium leading-6 text-gray-900 mb-2">
              Error
            </span>
            <pre
              className="block w-full h-20 rounded-md border-0 py-1.5 px-3 font-sans min-h-[2.25rem] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 overflow-x-auto bg-white"
              ref={errorContainerRef}
            >
              {error}
              {agentRuntimeData?.error}
            </pre>
          </div>
          <div className="flex gap-x-4 col-span-2">
            <button
              onClick={handleSubmit(onTest)}
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Test
            </button>
            <button
              onClick={handleSubmit(onCancel)}
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Cancel
            </button>
          </div>
        </div>

        <div className="col-span-2 flex gap-x-4">
          <div className="ml-auto">
            <MagicButton label="Next" type="submit" />
          </div>
        </div>
      </form>
    </>
  );
}
