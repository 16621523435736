
import { useKeycloak } from '@react-keycloak/web'
import { Navigate } from 'react-router-dom';

export function PrivateRoute({ children, roles, ...rest } : any) {
  const { keycloak, initialized } = useKeycloak()

  const isAuthorised = (roles: any) => {
    if (keycloak && roles) {
      return roles.some((r: any) => {
        const resource = keycloak.hasRealmRole(r);
        return resource;
      });
    }
    return false;
  }

  return isAuthorised(roles)
    ? children
    : <Navigate to="/" />
}
