import { useListener } from '@/hooks/use-listener';
import { useObjects } from '@/hooks/use-objects';

import { Widget } from '@/components/widget';
import { useState } from 'react';

const WIDGET_ID = 'MEDIA_VIEWER_WIDGET';

export function MediaViewerWidget() {
  const [uuids, setUuids] = useState<string[]>([]);
  const [containers, setContainers] = useState<string[]>([]);
  const { data } = useObjects(uuids, containers);
  const { enabled } = useListener({
    id: WIDGET_ID,
    listenerFn: (data) => {
      setUuids(data.map(({ uuid }: { uuid: string }) => uuid));
      setContainers(data.map(({ container }: { container: string }) => container));
    },
    type: 'ObjectCollection',
  });

  return (
    <Widget id={WIDGET_ID} title="Media Viewer">
      {enabled && data?.data?.resultset ? (
        <ul className="space-y-6">
          {data.data.resultset.map((object, i) => (
            <li key={i}>
              {object.data.publicUrl ? (
                <>
                  <>
                  {object.data.publicUrl.match(/\.(jpg|jpeg|png|gif)$/i) &&
                    <img src={object.data.publicUrl} alt="" />
                  }
                  </>
                  <>
                  {object.data.publicUrl.match(/\.(pdf)$/i) &&
                    <>
                      <object data={object.data.publicUrl} type="application/pdf" width="100%" className="min-h-screen"/>
                    </>
                  }
                  </>
                </>
              ) : null}
            </li>
          ))}
        </ul>
      ) : null}
    </Widget>
  );
}
