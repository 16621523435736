import { useMutation } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface RefineAgentData {
  title?: string;
  corrections?: string;
  agentUuid: string;
}

interface RefineAgentResponse {
  data: any;
}

export function useRefineAgent() {
  const { keycloak } = useKeycloak();

  return useMutation({
    mutationFn: async (
      refineData: RefineAgentData
    ): Promise<RefineAgentResponse> => {
      const response = await fetch(
        `${
          import.meta.env.VITE_IMPORT_ENGINE_ENDPOINT
        }/api/v1/bundles/generate_bundle/functions/refine_agent`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${keycloak.token}`,
          },
          method: 'POST',
          body: JSON.stringify(refineData),
        }
      );

      const data = await response.json();

      return {
        data: data?.data,
      };
    },
  });
}
