import { Widget } from '@/components/widget';
import ReactECharts from 'echarts-for-react';
import { useEffect, useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { useJobStatus } from '@/hooks/jobs/use-job-status';

const WIDGET_ID = 'JOBS_PROGRESS_WIDGET';

export function JobProgressWidget() {
  const { data } = useJobStatus();

  useEffect(()=> {
    if (!data?.steps || !data?.status) {
      return;
    }

    const newOptions = cloneDeep(options);
    const stepCount = data.steps.length;
    const total = stepCount * 100;
    let remainingTotal = total;

    const currentSteps = newOptions.series[0].data.map((item) => item.name);

    data.steps.forEach((step, i) => {

      if (!currentSteps.includes(step.label)) {
        // insert item at index
        newOptions.series[0].data = [
          ...newOptions.series[0].data.slice(0, i),
          { value: 0, name: step.label, itemStyle: {}, label: { show: false }, emphasis: { label: { show: true } } },
          ...newOptions.series[0].data.slice(i)
        ];
      }

      const status = data.status[step.step];
      let progress: number, remaining: number;
      if (status) {
        progress = Math.round((status.processed / status.total) * 100);
        remaining = 100 - progress;
      } else {
        progress = 0;
        remaining = 100;
      }

      remainingTotal -= progress;

      newOptions.series[0].data.forEach(option => {
        if (option.name == step.label) {
          option.value = progress;
          option.label.show = step.is_current;
        } else if (option.name === "Remaining") {
          option.value = remainingTotal;
        }
      });
    });

    setOptions(newOptions);

    
  }, [data?.status, data?.steps]);

  useEffect(()=> {
    const newOptions = cloneDeep(options);
    newOptions.series[0].data.slice(newOptions.series[0].data.length - 1);
    setOptions(newOptions);
  }, [data?.jobId]);

  const [options, setOptions] = useState({
    color: [
      'rgb(199, 210, 254)',
      'rgb(165, 180, 252)',
      'rgb(129, 140, 248)',
      'rgb(99, 102, 241)',
      'rgb(79, 70, 229)',
      'rgb(67, 56, 202)',
      'rgb(55, 48, 163)',
      'rgb(49, 46, 129)'
    ],
    tooltip: {
      trigger: 'item',
      show: false,
    },
    legend: {
      show: false
    },
    series: [
      {
        name: 'Progress',
        type: 'pie',
        radius: ['75%', '90%'],
        avoidLabelOverlap: true,
        itemStyle: {
        },
        label: {
          show: true,
          fontWeight: 'bold',
          fontSize: 14,
          position: 'center',
          formatter: '{b}\n{c}%'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 14,
            fontWeight: 'bold',
            opacity: 1,
            backgroundColor: '#ffffff',
            width: 100
          },
          vale: {
            show: true
          }
        },
        labelLine: {
          show: false
        },
        data: [
          { value: 100, name: 'Remaining', itemStyle: {color: 'rgb(55, 65, 81, 0.1)'}, label: { show: false }, emphasis: { label: { show: false } } },
        ]
      }
    ]
  });

  return (
    <Widget title="Current Progress" id={WIDGET_ID}>
      <ReactECharts option={options} style={{ height: '90%' }} notMerge={false} />
    </Widget>
  )
}