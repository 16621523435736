import clsx from 'clsx';

import { NavigationItem, NavigationProps } from '@/components/navigation';
import { NavLink } from 'react-router-dom';

export function MobileNavigation({ items }: NavigationProps) {
  return (
    <nav className="space-y-1 px-2">
      {items.map((item: NavigationItem) => (
        <NavLink
          key={item.name}
          to={item.href}
          className={({ isActive }) =>
            clsx(
              isActive
                ? 'bg-gray-900 text-white'
                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
              'group flex items-center px-2 py-2 text-base font-medium rounded-md'
            )
          }
        >
          <item.icon
            className={({ isActive }: { isActive: boolean }) =>
              clsx(
                isActive
                  ? 'text-gray-300'
                  : 'text-gray-400 group-hover:text-gray-300',
                'mr-4 flex-shrink-0 h-6 w-6'
              )
            }
            aria-hidden="true"
          />
          {item.name}
        </NavLink>
      ))}
    </nav>
  );
}
