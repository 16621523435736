import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface UserInvoicesData {
  invoices: {
    id: string,
    number: string,
    status: string,
    created: number,
    lines: {
      data: {
        period: {
          start: number,
          end: number
        }
      }[]
    },
    total: number,
    invoice_pdf: string
  }[]
}

export function useUserInvoices() {
  const queryClient = useQueryClient();
  const { keycloak } = useKeycloak();

  return useQuery({
    queryKey: ['userInvoices'],
    queryFn: async (): Promise<UserInvoicesData> => {

      const response = await fetch(
        `${import.meta.env.VITE_USER_ENGINE_ENDPOINT}/api/v1/bundles/user_bundle/functions/get_user_invoices`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'GET',
        }
      );

      let data = await response.json();

      const { invoices } = data?.data;

      return {
        invoices: invoices
      };
    }
  });
}