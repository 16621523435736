import { ReactNode, createContext, useContext } from 'react';
import { ChainAction, ChainState, createChainStore } from '@/chain-store';
import { useStore } from 'zustand';

const ChainContext = createContext<ReturnType<typeof createChainStore> | null>(
  null
);

const ChainStoreProvider = ({ children }: { children: ReactNode }) => {
  const store = createChainStore();

  return (
    <ChainContext.Provider value={store}>{children}</ChainContext.Provider>
  );
};

const useChainStore = <T,>(
  selector: (state: ChainState & ChainAction) => T
) => {
  const store = useContext(ChainContext);

  if (store === null) {
    throw new Error('No provider');
  }

  return useStore(store, selector);
};

export { ChainStoreProvider, useChainStore };
