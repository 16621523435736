import { Fragment } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ChevronUpDownIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { useState, useEffect } from 'react';

export interface Option {
  label: string;
  value: string;
}

interface ComboBoxProps {
  label: string;
  hideLabel?: boolean;
  name?: string;
  onChange?: (value: Option | null) => void;
  value?: Option | null | undefined;
  options: Option[];
  clearable?: boolean;
  wrapText?: boolean;
}

export function ComboBox({
  label,
  hideLabel,
  name,
  onChange,
  value,
  options,
  clearable = false,
  wrapText = false,
}: ComboBoxProps) {
  const [query, setQuery] = useState('');

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) => {
          return option.label.toLowerCase().includes(query.toLowerCase());
        });

  const clearSelection = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    onChange?.(null);
  };

  const labelClassName = clsx(
    'block text-sm font-medium text-gray-700 dark:text-gray-100',
    hideLabel && 'sr-only'
  );

  return (
    <Combobox value={value} onChange={onChange} name={name}>
      {({ open }) => (
        <>
          <Combobox.Label className={labelClassName}>{label}</Combobox.Label>
          <div className="relative mt-1">
            <Combobox.Button className="w-full">
              {({ open, value }) => (
                <>
                  <Combobox.Input
                    displayValue={(option: Option) => option?.label}
                    className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={(event) => setQuery(event.target.value)}
                    placeholder="Please select..."
                    onClick={(e) => {
                      if (open) e.stopPropagation();
                    }}
                  />
                  {clearable && value ? (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 z-1">
                      <XCircleIcon
                        className="h-5 w-5 text-gray-400 cursor-pointer"
                        aria-hidden="true"
                        onClick={(e) => clearSelection(e)}
                      />
                    </span>
                  ) : (
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  )}
                </>
              )}
            </Combobox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Combobox.Options
                className={clsx(
                  wrapText ? 'max-w-full' : 'w-full',
                  'absolute z-10 mt-1 max-h-60 overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 max-w-full'
                )}
                static
              >
                {filteredOptions.map((option) => (
                  <Combobox.Option
                    key={option.value}
                    className={({ active }) =>
                      clsx(
                        active
                          ? 'text-white bg-indigo-600 dark:text-gray-700 dark:bg-indigo-50'
                          : 'text-gray-900 dark:text-white',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={clsx(
                            selected ? 'font-semibold' : 'font-normal',
                            'block',
                            wrapText ? 'whitespace-normal' : 'whitespace-nowrap'
                          )}
                        >
                          {option.label}
                        </span>

                        {selected ? (
                          <span
                            className={clsx(
                              active
                                ? 'text-white dark:text-gray-700'
                                : 'text-indigo-600 dark:text-indigo-50',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            </Transition>
          </div>
        </>
      )}
    </Combobox>
  );
}
