import { useQuery } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface ObjectData {
  message: string;
  data: {
    uuid: string,
    status: string,
    timestamp: number,
    title: string,
    objectType: string,
    bundleFunction: string,
    chainUuid: string,
    targets: {
        target: string,
        event: string
    }[],
    log: {
      message: string,
      level: string,
      time: number,
      json: string
    }[]
  }[];
}

export function useChainLog(contextUuid: string) {
  const { keycloak } = useKeycloak();

  return useQuery(['context-chain', contextUuid], {
    enabled: !!contextUuid,
    queryFn: async (): Promise<ObjectData> => {
      const body = {
        contextUuid: contextUuid
      };

      const response = await fetch(`${import.meta.env.VITE_IMPORT_ENGINE_ENDPOINT}/api/v1/bundles/query_bundle/functions/get_context_running_chain`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${keycloak.token}`
        },
        method: 'POST',
        body: JSON.stringify(body),
      });

      return response.json();
    },
    refetchInterval: 5000
  });
}