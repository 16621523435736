import {
  Background,
  Connection,
  Controls,
  Edge,
  Node,
  ReactFlow,
  ReactFlowProvider,
  useReactFlow,
} from 'reactflow';
import { ChainNode } from '@/components/chain-node';
import { useAutoChainLayout } from '@/hooks/use-auto-chain-layout';
import { useChainStore } from '@/context/chain-context';
import { ChainAction, ChainState } from '@/chain-store';
import { useChainUpsertData } from '@/hooks/use-chain-upsert-data';
import { ChainRelationEdge } from '@/components/chain-relation-edge';
import { DragEvent, useCallback, useEffect, useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

const edgeTypes = {
  relation: ChainRelationEdge,
};

const nodeTypes = {
  chain: ChainNode,
};

interface ChainFlowProps {
  edges: Edge[];
  nodes: Node[];
  chainUuid: string;
  readonly?: boolean;
}

const chainSelector = (state: ChainState & ChainAction) => ({
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  onConnect: state.onConnect,
  onEventConnect: state.onEventConnect,
  addNodes: state.addNodes,
  toggleEdge: state.toggleEdge,
});

interface ChainStepPayload {
  chainUuid: string;
  chainStepPrototypeUuid: string;
}

function BaseChainFlow({ edges, nodes, chainUuid, readonly }: ChainFlowProps) {
  const {
    addNodes,
    onConnect,
    onEventConnect,
    onNodesChange,
    onEdgesChange,
    toggleEdge,
  } = useChainStore(chainSelector);
  const chainUpsertDataMutation = useChainUpsertData();
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const reactFlowInstance = useReactFlow();
  const { keycloak } = useKeycloak();
  const createChainStepMutation = useMutation({
    mutationFn: async (payload: ChainStepPayload) => {
      const res = await fetch(
        `${
          import.meta.env.VITE_API_URL
        }/bundles/graph_bundle/functions/create_chain_step`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'POST',
          body: payload ? JSON.stringify(payload) : '',
        }
      );

      return res.json();
    },
  });

  useAutoChainLayout();

  useEffect(() => {
    if (!readonly) {
      return;
    }

    nodes.forEach((node) => {
      node.data.readonly = true;
    });
  }, [readonly]);

  function handleConnect(connection: Connection) {
    const [connectionType, sourceAttribute] = connection.sourceHandle?.split(
      '|'
    ) as string[];
    const [targetHandleId] = (connection.targetHandle || '')?.split('|');
    const targetNode = nodes.find((node) => node.id === connection.target);
    const sourceNode = nodes.find((node) => node.id === connection.source);

    if (!targetNode || !sourceNode) {
      return;
    }

    if (connectionType === 'event') {
      const targetSection = sourceNode.data.sections.find(
        (section) => !!section?.schema?.properties?.eventsOut
      );

      if (!targetSection) {
        return;
      }

      const [_, eventId] = connection.sourceHandle?.split('|') as string[];

      const formData = {
        ...targetSection.formData,
        eventsOut: targetSection.formData.eventsOut.map((event) => {
          if (eventId === event.event) {
            return {
              event: eventId,
              target: connection.target,
            };
          }

          return event;
        }),
      };

      const variables = {
        abstractUuid: connection.source as string,
        abstractData: formData,
      };

      chainUpsertDataMutation.mutate(variables, {
        onSuccess: () => {
          onEventConnect(connection, formData);
        },
      });
    } else {
      const targetSection = targetNode.data.sections.find(
        (section) => section.type === 'abstract_handles'
      );

      if (!targetSection?.schema?.properties) {
        return;
      }

      let connectionData;
      let targetAttribute: string = '';

      Object.keys(targetSection.schema.properties).forEach((attributeKey) => {
        // Handle array fields separately
        if (targetSection.schema.properties[attributeKey].type === 'array') {
          const [parentField, childField, index] =
            connection.targetHandle?.split('_') as string[];

          if (parentField === attributeKey) {
            targetAttribute = parentField;
            connectionData = [
              ...(targetSection.formData?.[attributeKey] || []),
            ].map((data, i) => {
              if (i === parseInt(index)) {
                return {
                  ...data,
                  [childField]: {
                    attribute: sourceAttribute,
                    type: 'value_v2',
                    abstractUuid: connection.source,
                  },
                };
              }

              return data;
            });
          }
        } else {
          if (
            targetSection.schema.properties[attributeKey]?.targets?.find(
              (target) => target.id === targetHandleId
            )
          ) {
            targetAttribute = attributeKey;
            connectionData = {
              attribute: sourceAttribute,
              type: 'value_v2',
              abstractUuid: connection.source,
            };
          }
        }
      });

      const formData = {
        ...targetSection.formData,
        [targetAttribute]: connectionData,
      };

      const variables = {
        abstractUuid: connection.target as string,
        abstractData: formData,
      };

      chainUpsertDataMutation.mutate(variables, {
        onSuccess: () => {
          onConnect(connection, formData);
        },
      });
    }
  }

  const onDragOver = useCallback((event: DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event: DragEvent) => {
      event.preventDefault();

      if (reactFlowWrapper.current === null) {
        return;
      }

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();

      const type = event.dataTransfer.getData('application/reactflow');

      createChainStepMutation.mutate(
        {
          chainUuid,
          chainStepPrototypeUuid: type,
        },
        {
          onSuccess: (data) => {
            // check if the dropped element is valid
            if (typeof type === 'undefined' || !type) {
              return;
            }

            const position = reactFlowInstance.project({
              x: event.clientX - reactFlowBounds.left,
              y: event.clientY - reactFlowBounds.top,
            });

            const node = {
              ...data.data,
              position,
            };

            addNodes([node]);
          },
        }
      );
    },
    [reactFlowInstance]
  );

  return (
    <div className="h-full" ref={reactFlowWrapper}>
      <ReactFlow
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        fitViewOptions={{ padding: 3 }}
        fitView={true}
        zoomOnScroll={false}
        panOnScroll={true}
        onConnect={handleConnect}
        proOptions={{ hideAttribution: true }}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onEdgeMouseEnter={(event, edge) => {
          if (edge.type === 'relation') {
            toggleEdge(edge.id);
          }
        }}
        onEdgeMouseLeave={(event, edge) => {
          if (edge.type === 'relation') {
            toggleEdge(edge.id);
          }
        }}
      >
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
}

export function ChainFlow(props: ChainFlowProps) {
  return (
    <ReactFlowProvider>
      <BaseChainFlow {...props} />
    </ReactFlowProvider>
  );
}
