import Form, { IChangeEvent } from '@rjsf/core';
import { RJSFSchema, RegistryFieldsType, UiSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { memo } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import {
  AddButton,
  ConnectionField,
  MoveDownButton,
  MoveUpButton,
  RelationField,
  RemoveButton,
  SubmitButton,
} from '@/components/form';
import { useChainStore } from '@/context/chain-context';
import { useChainUpsertData } from '@/hooks/use-chain-upsert-data';
import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  EyeSlashIcon,
  MinusIcon,
  PowerIcon,
} from '@heroicons/react/24/outline';
import { useMutation } from '@tanstack/react-query';
import { useTheme } from '@/hooks/use-theme';
import { useKeycloak } from '@react-keycloak/web';
import clsx from 'clsx';
import { AuthorisedFunction } from '@/utilities/authorised-function';

interface Field {
  label: string;
  value: string;
}

interface ChainHeaderSection {
  type: 'header';
  fields: Field[];
}

interface ChainHandlersSection {
  type: 'abstract_handles' | 'runtime_handles';
  schema: RJSFSchema;
  uiSchema: UiSchema | null;
  formData: any;
}

export type ChainNodeSection = ChainHeaderSection | ChainHandlersSection;

const fields: RegistryFieldsType = {
  relation: RelationField,
  connection: ConnectionField,
};

interface DeleteChainStepPayload {
  chainStepUuid: string;
}

interface ToggleEnabledChainStepPayload {
  chainStepUuid: string;
  enabled: boolean;
}

function ChainNode({ id: nodeId, data, ...rest }: NodeProps) {
  const updateNodeFormData = useChainStore((state) => state.updateNodeFormData);
  const deleteNode = useChainStore((state) => state.deleteNode);
  const toggleNode = useChainStore((state) => state.toggleNode);
  const toggleEnabled = useChainStore((state) => state.toggleEnabled);
  const toggleNeighbourNodes = useChainStore(
    (state) => state.toggleNeighbourNodes
  );
  const replaceEdges = useChainStore((state) => state.replaceEdges);
  const { keycloak } = useKeycloak();
  const chainUpsertDataMutation = useChainUpsertData();
  const deleteChainStepNodeMutation = useMutation({
    mutationFn: async (payload: DeleteChainStepPayload) => {
      const res = await fetch(
        `${
          import.meta.env.VITE_API_URL
        }/bundles/graph_bundle/functions/delete_chain_step`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'POST',
          body: payload ? JSON.stringify(payload) : '',
        }
      );

      return res.json();
    },
  });
  const { theme, toggleTheme } = useTheme('light');
  const toogleEnabledChainStepNodeMutation = useMutation({
    mutationFn: async (payload: ToggleEnabledChainStepPayload) => {
      const res = await fetch(
        `${
          import.meta.env.VITE_API_URL
        }/bundles/graph_bundle/functions/toggle_enabled_chain_step`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'POST',
          body: payload ? JSON.stringify(payload) : '',
        }
      );

      return res.json();
    },
  });

  function handleBlur(sectionType: string) {
    const targetSection = data.sections.find(
      (section: ChainNodeSection) => section?.type === sectionType
    );

    if (!targetSection?.formData) {
      return;
    }

    const variables = {
      abstractUuid: nodeId,
      abstractData: targetSection.formData,
    };

    chainUpsertDataMutation.mutate(variables);
  }

  function handleChange(
    changeData: IChangeEvent,
    sectionType: string,
    id?: string | undefined
  ) {
    let fieldKey;

    Object.keys(changeData.idSchema).forEach((key) => {
      if (changeData.idSchema[key]?.$id === id) {
        fieldKey = key;
      }
    });

    // In case of an array field we send the updated data to the api because we
    // need to update the edges based on the returned response.
    if (fieldKey && changeData.schema.properties[fieldKey].type === 'array') {
      const variables = {
        abstractUuid: nodeId,
        abstractData: changeData.formData,
      };

      updateNodeFormData(nodeId, sectionType, changeData.formData);

      chainUpsertDataMutation.mutate(variables, {
        onSuccess: (responseData) => {
          replaceEdges(responseData.data.edges);
        },
      });
    } else {
      updateNodeFormData(nodeId, sectionType, changeData.formData);
    }
  }

  function handleDeleteClick() {
    deleteChainStepNodeMutation.mutate(
      {
        chainStepUuid: nodeId,
      },
      {
        onSuccess: () => deleteNode(nodeId),
      }
    );
  }

  function handleToggleNode() {
    toggleNode(nodeId);
  }

  function handleToggleNeighbours() {
    toggleNeighbourNodes(nodeId);
  }

  function handleEnabledToggle() {
    if (!data) {
      return;
    }

    toogleEnabledChainStepNodeMutation.mutate(
      {
        chainStepUuid: nodeId,
        enabled: !data.enabled,
      },
      {
        onSuccess: () => toggleEnabled(nodeId),
      }
    );
  }

  return (
    <div className="p-4 bg-white/60 shadow-lg rounded w-[300px] relative dark:bg-gray-900/60">
      <div className="absolute top-0 -right-3.5 -translate-y-1/2 flex gap-2">
        <button
          onClick={handleEnabledToggle}
          type="button"
          className="rounded-full bg-blue-200 p-1 text-blue-500 shadow-sm hover:bg-blue-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        >
          <PowerIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        {!data?.parentCollapsed ? (
          <button
            onClick={handleToggleNeighbours}
            type="button"
            className="rounded-full bg-blue-200 p-1 text-blue-500 shadow-sm hover:bg-blue-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            <EyeSlashIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        ) : null}
        <button
          onClick={handleToggleNode}
          type="button"
          className="rounded-full bg-blue-200 p-1 text-blue-500 shadow-sm hover:bg-blue-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        >
          {data?.open ? (
            <ArrowsPointingInIcon className="h-5 w-5" aria-hidden="true" />
          ) : (
            <ArrowsPointingOutIcon className="h-5 w-5" aria-hidden="true" />
          )}
        </button>
      </div>
      {data?.readonly !== true && (
        <button
          onClick={handleDeleteClick}
          type="button"
          className="absolute top-0 left-0 -translate-x-1/2 -translate-y-1/2 rounded-full bg-red-200 p-1 text-red-500 shadow-sm hover:bg-red-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
        >
          <MinusIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      )}

      <Handle type="target" position={Position.Top} />

      <div className="space-y-4">
        {data?.sections
          .filter((section: ChainNodeSection) =>
            !data.open ? section.type === 'header' : true
          )
          .map((section: ChainNodeSection, i: number) => {
            if (section.type === 'header') {
              return (
                <div
                  key={i}
                  className={clsx('bg-gray-100 py-3 px-4 text-center rounded-sm text-sm dark:bg-gray-800 dark:text-gray-100 relative', data.enabled ? '' : 'opacity-50')}
                >
                  {section.imageUrl && (
                    <div className="w-7 h-7 object-contain m-auto bg-gray-300 dark:bg-gray-700 p-1 rounded-full mb-1 flex justify-center items-center">
                      {theme === 'dark' ? (
                        <img
                          style={{ filter: 'brightness(0) invert(1)' }}
                          src={section.imageUrl}
                          alt="Chain step icon"
                        />
                      ) : (
                        <img
                          style={{ filter: 'brightness(0)' }}
                          src={section.imageUrl}
                          alt="Chain step icon"
                        />
                      )}
                    </div>
                  )}
                  {section.fields.map((field) => (
                    <>
                      {field.label === 'Abstract UUID' ? (
                        <div
                          key={field.label}
                          className="overflow-hidden text-ellipsis text-[10px]"
                        >
                          {field.value}
                        </div>
                      ) : field.label === 'Bundle Function' ? (
                        <>
                          {AuthorisedFunction(['developer']) && (
                            <div
                              key={field.label}
                              className="overflow-hidden text-ellipsis"
                            >
                              {field.value}
                            </div>
                          )}
                        </>
                      ) : (
                        <div
                          key={field.label}
                          className="overflow-hidden text-ellipsis"
                        >
                          {field.value}
                        </div>
                      )}
                    </>
                  ))}
                </div>
              );
            }

            if (section.type === 'abstract_handles') {
              return !Array.isArray(section.schema) && section.schema ? (
                <div
                  key={i}
                  className="bg-gray-100/30 p-4 space-y-1 dark:bg-gray-800/30 dark:text-gray-100"
                >
                  <Form
                    onChange={(data, id) => {
                      handleChange(data, section.type, id);
                    }}
                    onBlur={() => handleBlur(section.type)}
                    formData={section.formData}
                    schema={section.schema}
                    uiSchema={section.uiSchema ?? {}}
                    fields={fields}
                    validator={validator}
                    templates={{
                      ButtonTemplates: {
                        AddButton,
                        MoveDownButton,
                        MoveUpButton,
                        RemoveButton,
                        SubmitButton,
                      },
                    }}
                  >
                    <div />
                  </Form>
                </div>
              ) : null;
            }

            if (section.type === 'runtime_handles') {
              return !Array.isArray(section.schema) && section.schema ? (
                <div
                  key={i}
                  className="bg-gray-100/30 p-4 space-y-1 dark:bg-gray-800/30 dark:text-gray-100"
                >
                  <Form
                    onChange={(data) => {
                      updateNodeFormData(nodeId, section.type, data.formData);
                    }}
                    formData={section.formData}
                    schema={section.schema}
                    uiSchema={section.uiSchema ?? {}}
                    fields={fields}
                    validator={validator}
                    templates={{
                      ButtonTemplates: {
                        AddButton,
                        MoveDownButton,
                        MoveUpButton,
                        RemoveButton,
                        SubmitButton,
                      },
                    }}
                  >
                    <div />
                  </Form>
                </div>
              ) : null;
            }
          })}
      </div>

      <Handle id="source" type="source" position={Position.Bottom} />
    </div>
  );
}

ChainNode.displayName = 'ChainNode';

export default memo(ChainNode);
