import {
  FormContextType,
  TitleFieldProps,
  RJSFSchema,
  StrictRJSFSchema,
} from '@rjsf/utils';

const REQUIRED_FIELD_SYMBOL = '*';

export function TitleField<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>({ id, title, required }: TitleFieldProps<T, S, F>) {
  return (
    <legend
      className="text-xl font-bold leading-6 text-black dark:text-slate-300 mb-2"
      id={id}
    >
      {title}
      {required && <span className="required">{REQUIRED_FIELD_SYMBOL}</span>}
    </legend>
  );
}
