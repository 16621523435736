import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

export function usePlanCreate() {
  const { keycloak } = useKeycloak();
  const queryClient = useQueryClient();

  interface PlanData {
    priceId: string;
  }

  interface CreatedPlanData {
    clientSecret: string;
    plan: {
      id: string,
      amount: number,
      product: {
        description: string,
        name: string
      }
    };
  }

  return useMutation({
    mutationFn: async (planData: PlanData): Promise<CreatedPlanData> => {

      const response = await fetch(
        `${import.meta.env.VITE_USER_ENGINE_ENDPOINT}/api/v1/bundles/user_bundle/functions/create_import_payment`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'POST',
          body: JSON.stringify(planData),
        }
      );
      const data = await response.json();

      const { clientSecret, plan } = data?.data;

      return {
        clientSecret: clientSecret,
        plan: plan
      };
    },
  });
}