import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface ActionTriggerData {
  triggeringNodes: {
    uuid: string | unknown;
    container: string | unknown;
  }[];
  event: string;
}

interface ActionTriggerResponseData {
  message: string;
}

export function useActionTrigger() {
  const { keycloak } = useKeycloak();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (actionTrigger: ActionTriggerData): Promise<ActionTriggerResponseData> => {

      const response = await fetch(
        `${import.meta.env.VITE_IMPORT_ENGINE_ENDPOINT}/api/v1/bundles/graph_bundle/functions/trigger_graph_action_event`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'POST',
          body: JSON.stringify(actionTrigger),
        }
      );

      const data = await response.json();

      return {
        message: data?.message
      };
    },
  });
}