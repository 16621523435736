import { DragEvent, useState } from 'react';
import { Option, SelectMenu } from '@/components/select-menu';
import { useQuery } from '@tanstack/react-query';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useKeycloak } from '@react-keycloak/web';

export function ChainStepsSelector() {
  const { keycloak } = useKeycloak();
  const [chainStep, setChainStep] = useState<Option>();
  const { data: chainStepsData } = useQuery(['chain-steps'], {
    queryFn: async () => {
      const response = await fetch(
        `${
          import.meta.env.VITE_API_URL
        }/bundles/graph_bundle/functions/get_available_chain_steps`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${keycloak.token}`,
          },
          method: 'POST',
          body: '',
        }
      );

      return response.json();
    },
  });

  function handleDragStart(event: DragEvent) {
    event.dataTransfer.setData(
      'application/reactflow',
      chainStep?.value as string
    );
    event.dataTransfer.effectAllowed = 'move';
  }

  return (
    <form>
      <div className="flex items-end space-x-2">
        <div className="flex-1">
          {chainStepsData && chainStepsData.data && (
            <SelectMenu
              label="Chain Steps"
              options={chainStepsData.data}
              name="chain"
              selected={chainStepsData.data[0]}
              onChange={setChainStep}
            />
          )}
        </div>
        <button
          className="grab inline-flex items-center rounded-md border border-transparent px-2 py-2 text-sm font-medium focus:outline-none focus:ring-2 bg-indigo-100 text-indigo-700 hover:bg-indigo-200 focus:ring-indigo-500 focus:ring-offset-2  dark:bg-indigo-900 dark:text-indigo-100 dark:hover:bg-indigo-700"
          draggable
          onDragStart={handleDragStart}
          type="button"
        >
          <PlusIcon className="w-5 h-5" />
        </button>
      </div>
      <p className="text-xs p-3 dark:text-gray-100">
        {chainStep ? chainStep?.description : chainStepsData?.data?.[0]?.description}
      </p>
    </form>
  );
}
