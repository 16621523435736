import { useQuery } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

const PREVIEW_CONTAINER = 'preview-schema';

interface PreviewData {
  schema?: { previewUrl?: string } & Record<string, any>[];
  data?: Record<string, any>[];
}

export function usePreview({
  uuid,
  container,
  previewUuid,
}: {
  uuid?: string;
  container?: string;
  previewUuid?: string;
}) {
  const { keycloak } = useKeycloak();

  return useQuery(['object', uuid, container, previewUuid], {
    enabled: !!uuid && !!container && !!previewUuid,
    queryFn: async (): Promise<PreviewData> => {
      const body = {
        uuids: [uuid],
        container,
      };

      const objectResponse = await fetch(import.meta.env.VITE_OBJECT_API_URL, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${keycloak.token}`
        },
        method: 'POST',
        body: JSON.stringify(body),
      });

      const previewResponse = await fetch(import.meta.env.VITE_OBJECT_API_URL, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${keycloak.token}`
        },
        method: 'POST',
        body: JSON.stringify({
          uuids: [previewUuid],
          container: PREVIEW_CONTAINER,
        }),
      });

      const res = await Promise.all([objectResponse, previewResponse]);

      const [objectData, previewData] = await Promise.all(
        res.map((r) => r.json())
      );

      return {
        data: objectData?.data?.resultset?.[0]?.data,
        schema: previewData?.data?.resultset?.[0]?.data,
      };
    },
  });
}
