import { ChainAction, ChainState } from '@/chain-store';
import { useChainStore } from '@/context/chain-context';
import { useChainUpsertData } from '@/hooks/use-chain-upsert-data';
import { MinusCircleIcon } from '@heroicons/react/24/outline';
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
} from 'reactflow';

const chainSelector = (state: ChainState & ChainAction) => ({
  nodes: state.nodes,
  edges: state.edges,
  removeRelation: state.removeRelation,
  removeConnection: state.removeConnection,
});

export function ChainRelationEdge({
  data,
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}: EdgeProps) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const { edges, nodes, removeConnection, removeRelation } =
    useChainStore(chainSelector);
  const chainUpsertDataMutation = useChainUpsertData();

  function onEdgeClick(id: string): void {
    const edgeToBeRemoved = edges.find((edge) => edge.id === id);
    const sourceType = edgeToBeRemoved?.sourceHandle?.split('|')[0];

    if (!edgeToBeRemoved) {
      return;
    }

    if (sourceType === 'event') {
      const sourceEventId = edgeToBeRemoved.sourceHandle?.split('|')[1];
      const sourceNode = nodes.find(
        (node) => node.id === edgeToBeRemoved.source
      );

      const sourceSection = sourceNode?.data?.sections?.find(
        (section) => !!section?.schema?.properties?.eventsOut
      );

      if (!sourceSection) {
        return;
      }

      const newFormData = {
        ...sourceSection.formData,
        eventsOut: sourceSection.formData.eventsOut.map((event) => {
          if (
            sourceEventId === event.event &&
            event.target === edgeToBeRemoved.target
          ) {
            return {
              event: event.event,
              target: null,
            };
          }

          return event;
        }),
      };

      const variables = {
        abstractUuid: edgeToBeRemoved.source as string,
        abstractData: newFormData,
      };

      chainUpsertDataMutation.mutate(variables, {
        onSuccess: () => {
          removeConnection(id, sourceSection.type, newFormData);
        },
      });
    } else {
      const targetNode = nodes.find(
        (node) => node.id === edgeToBeRemoved.target
      );

      if (!targetNode) {
        return;
      }

      let targetAttribute;
      let targetSection;

      targetNode.data.sections.forEach((section) => {
        if (section?.schema?.properties) {
          Object.keys(section.schema.properties).forEach((attributeKey) => {
            if (section.schema.properties[attributeKey].type === 'array') {
              const [parentField, childField, index] =
                edgeToBeRemoved.targetHandle?.split('_') as string[];

              if (parentField === attributeKey) {
                targetAttribute = parentField;
                targetSection = section;

                targetSection.formData = {
                  ...targetSection.formData,
                  [targetAttribute]: targetSection.formData[
                    targetAttribute
                  ].map((data, i) => {
                    if (i === parseInt(index)) {
                      return {};
                    }

                    return data;
                  }),
                };
              }
            } else {
              if (
                section.schema.properties[attributeKey]?.targets?.find(
                  (target) => target.id === edgeToBeRemoved.targetHandle
                )
              ) {
                targetAttribute = attributeKey;
                targetSection = section;
                delete targetSection.formData[targetAttribute as string];
              }
            }
          });
        }
      });

      if (!targetSection) {
        return;
      }

      const variables = {
        abstractUuid: edgeToBeRemoved.target as string,
        abstractData: targetSection.formData,
      };

      chainUpsertDataMutation.mutate(variables, {
        onSuccess: () => {
          removeRelation(id, targetSection.type, targetSection.formData);
        },
      });
    }
  }

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: 'all',
          }}
          className="nodrag nopan absolute leading-none"
        >
          {data?.visible ? (
            <button
              className="bg-white rounded-full"
              onClick={() => onEdgeClick(id)}
            >
              <MinusCircleIcon className="w-4 h-4" />
            </button>
          ) : null}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
