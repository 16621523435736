import { useQuery } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface ObjectJson {
  message: string;
  data: string;
}

export function useObjectsWithParents(parents: string[], containers: string[], pitId: string, searchAfter: any[], includeTotalCount: boolean) {
  const { keycloak } = useKeycloak();

  return useQuery(['objects-with-parents', parents, containers, searchAfter], {
    enabled: !!parents.length && !!containers.length,
    queryFn: async (): Promise<ObjectJson> => {
      const body = {
        parents: parents,
        containers: containers,
        size: 1000,
        includeTotalCount: includeTotalCount
      };

      if (pitId) {
        body['pitId'] = pitId;
      }

      if (searchAfter.length) {
        body['searchAfter'] = searchAfter;
      }

      const response = await fetch(`${import.meta.env.VITE_IMPORT_ENGINE_ENDPOINT}/api/v1/bundles/query_bundle/functions/get_objects_with_parents`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${keycloak.token}`
        },
        method: 'POST',
        body: JSON.stringify(body),
      });

      return response.json();
    },
    keepPreviousData : true
  });
}
