import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

export function useObjectFormDelete() {
  const { keycloak } = useKeycloak();
  const queryClient = useQueryClient();

  interface BulkResponse {
    status: string
  }

  interface ObjectData {
    container: string,
    uuid: string
  }

  return useMutation({
    mutationFn: async (objectData: ObjectData): Promise<BulkResponse> => {

      const deleteData = {
      'data': {
          'container': objectData.container,
          'uuid': objectData.uuid
        }
      }

      const response = await fetch(
        `${import.meta.env.VITE_CRUD_ENGINE_ENDPOINT}/bundles/crud_bundle/functions/delete`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'POST',
          body: JSON.stringify(deleteData),
        }
      );
      const data = await response.json();

      const { status } = data;

      return {
        status: status
      };
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['objectForm', [variables.uuid], [variables.container]]);
    },
  });
}