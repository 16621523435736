import GridLayout, { WidthProvider } from 'react-grid-layout';

import { Page } from '@/components/page';
import { GridPanel } from '@/components/grid-panel';
import { useTRStats } from '@/hooks/stats/use-tr-stats';
import { useTRGoogleFilesStats } from '@/hooks/stats/use-tr-google-files-stats';
import { ChartProvider } from '@/components/chart-provider';
import { RadialBarChartWidget } from '@/components/radial-bar-chart-widget';

const layout = [
  { i: 'sidebar_left', x: 0, y: 0, w: 20, h: 25 },
  { i: 'sidebar_right', x: 20, y: 0, w: 20, h: 25 },
];

const AutoWidthGridLayout = WidthProvider(GridLayout);

export function TrumpfMetrics() {
  return (
    <Page title="TRUMPF Metrics">
      <AutoWidthGridLayout
        className="layout"
        layout={layout}
        cols={40}
        rowHeight={25}
        isDraggable={false}
      >
        <GridPanel key="sidebar_left">
          <ChartProvider provider={useTRStats}>
            <RadialBarChartWidget title="TR Statistics" />
          </ChartProvider>
        </GridPanel>
        <GridPanel key="sidebar_right">
          <ChartProvider provider={useTRGoogleFilesStats}>
            <RadialBarChartWidget title="TR Image Files Statistics" />
          </ChartProvider>
        </GridPanel>
      </AutoWidthGridLayout>
    </Page>
  );
}
