import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface UserNotificationData {
  notifications: Notification[];
}

type Notification = {
  message: string;
  uuid: string;
};

export function useUserNotifications() {
  const queryClient = useQueryClient();
  const { keycloak } = useKeycloak();

  return useQuery({
    queryKey: ['userNotifications'],
    queryFn: async (): Promise<UserNotificationData> => {
      const response = await fetch(
        `${
          import.meta.env.VITE_USER_ENGINE_ENDPOINT
        }/api/v1/bundles/user_bundle/functions/get_user_notifications`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${keycloak.token}`,
          },
          method: 'GET',
        }
      );

      let data = await response.json();

      // console.log(data);

      const { notifications } = data?.data;

      return {
        notifications,
      };
    },
    refetchInterval: 5000,
  });
}
