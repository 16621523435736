import { AgentCreatorWidget } from '@/features/agents/components/agent-creator-widget';
import { Page } from '@/components/page';
import { HtmlPreviewWidget } from '@/features/agents/components/html-preview-widget';

export function AgentCreator() {
  return (
    <Page title="Agent Studio">
      <div className="grid gap-4">
        <AgentCreatorWidget />
        <HtmlPreviewWidget />
      </div>
    </Page>
  );
}
