import { memo } from 'react';
import { NodeProps } from 'reactflow';
import { ObjectNode } from '@/components/object-node';

export const DEFAULT_IMAGE_NODE_HEIGHT = 173;
export const DEFAULT_IMAGE_NODE_WIDTH = 220;

function ImageNode(props: NodeProps) {
  return <ObjectNode {...props} />;
}

export default memo(ImageNode);
