import { useQuery } from '@tanstack/react-query';
import { Response } from '@/types';
import { RJSFSchema, UiSchema } from '@rjsf/utils';
import { useKeycloak } from '@react-keycloak/web';

interface ObjectForm {
  data: any;
  structure: RJSFSchema;
  uiSchema: UiSchema;
}

interface FormData {
  count: number;
  resultset: ObjectForm[];
}

export function useObjectForm(uuids: string[], containers: string[], create: boolean, dataClass: string) {
  const { keycloak } = useKeycloak();

  return useQuery(['objectForm', uuids, containers, create, dataClass], {
    enabled: create || (!!uuids.length && !!containers.length),
    queryFn: async (): Promise<Response<FormData>> => {

      // console.log("step 1 "+create);
      if (create) {
        // console.log("step 2");

        //create new object case
        const body = {
          dataClass: dataClass,
        };
  
        const response = await fetch(import.meta.env.VITE_GET_EMPTY_OBJECT_API_URL, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'POST',
          body: JSON.stringify(body)
        });
  
        return response.json();

      } else {

        // console.log("step 3");

        //fetch existing object case
        const body = {
          uuids: uuids,
          container: containers[0],
          create: create,
        };
  
        const response = await fetch(import.meta.env.VITE_OBJECT_FORM_API_URL, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'POST',
          body: JSON.stringify(body)
        });
  
        return response.json();

      }
      
    },
  });
}
