import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface DataClassData {
  dataClass: {
    'schema': {
      [key:string]: unknown
    },
    'uiSchema': {
      [key:string]: unknown
    },
  }
}

// export function useDataClass(uuid: string) {
//   const queryClient = useQueryClient();
//   const { keycloak } = useKeycloak();

//   return useQuery({
//     enabled: !!uuid,
//     queryKey: ['dataClass', uuid],
//     queryFn: async (): Promise<DataClassData> => {
//       const response = await fetch(
//         `${import.meta.env.VITE_IMPORT_ENGINE_ENDPOINT}/api/v1/bundles/query_bundle/functions/get_data_class?uuid=${uuid}`,
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${keycloak.token}`
//           },
//           method: 'GET',
//         }
//       );

//       let data = await response.json();

//       const { dataClass } = data?.data

//       return {
//         dataClass: dataClass
//       };
//     },
//   });
// }

export function useDataClass() {
  const { keycloak } = useKeycloak();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (uuid: string): Promise<DataClassData> => {

      const response = await fetch(
        `${import.meta.env.VITE_IMPORT_ENGINE_ENDPOINT}/api/v1/bundles/query_bundle/functions/get_data_class?uuid=${uuid}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'GET'
        }
      );

      let data = await response.json();

      const { dataClass } = data?.data

      return {
        dataClass: dataClass
      };
    },
  });
}
