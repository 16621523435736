import { Page } from '@/components/page';
import { PageEditorWidget } from '@/components/page-editor-widget';

export function PageEditor() {
  return (
    <Page title="Compose Content">
      <PageEditorWidget />
    </Page>
  );
}
