import { SelectMenu } from '@/components/select-menu';
import { ChainFlow } from '@/components/chain-flow';
import { Widget } from '@/components/widget';
import { Button } from '@/components/button';
import { useChain } from '@/hooks/use-chain';
import { SyntheticEvent, useState, useEffect, useRef } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useChainStore } from '@/context/chain-context';
import { ChainAction, ChainState } from '@/chain-store';
import { useUserChains } from '@/hooks/user/use-user-chains';
import { ChainStepsSelector } from '@/components/chain-steps-selector';
import { PanelProvider } from '@/context/panel-context';
import { useUserDataclasses } from '@/hooks/user/use-user-dataclasses';
import { useUserConfig } from '@/hooks/user/use-user-config';
import { useChainCreate } from '@/hooks/use-chain-create';
import { useParams } from 'react-router-dom';
import { useChainDuplicate } from '@/hooks/use-chain-duplicate';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { ComboBox } from '@/components/ui/combo-box';

const WIDGET_ID = 'CHAIN_WIDGET';

interface ChainWidgetProps {}

interface Chain {
  value: string;
  label: string;
}

const chainSelector = (state: ChainState & ChainAction) => ({
  nodes: state.nodes,
  edges: state.edges,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
});

export function ChainWidget(props: ChainWidgetProps) {
  const [chainUuid, setChainUuid] = useState<string>('');
  const [contextUuid, setContextUuid] = useState<string>('');
  const { data: chainsData, refetch: refetchChainsData } = useUserChains();
  const { edges, nodes } = useChainStore(chainSelector);
  const queryClient = useQueryClient();
  const { data: dataClassData } = useUserDataclasses();
  const { data: userConfigData } = useUserConfig();
  const chainCreateMutation = useChainCreate();
  const [selectedChain, setSelectedChain] = useState<Chain | null>(null);
  const { contextUuid: contextUuidParam, chainUuid: chainUuidParam } =
    useParams();
  const { isFetching } = useChain(chainUuid, contextUuid);
  const [showDuplicateForm, setShowDuplicateForm] = useState(false);
  const loadChainRef = useRef();
  const chainDuplicateMutation = useChainDuplicate();
  const [duplicating, setDuplicating] = useState(false);

  useEffect(() => {
    // @TODO find a fix for the timeout
    setTimeout(() => {
      if (chainUuidParam) {
        setChainUuid(chainUuidParam);
      }

      if (contextUuidParam) {
        setContextUuid(contextUuidParam);
      }
    }, 1000);
  }, [chainUuidParam, contextUuidParam]);

  useEffect(() => {
    if (!chainsData?.chains) {
      return;
    }

    // setChainUuid(chainsData.chains[0].value);
  }, [chainsData]);

  useEffect(() => {
    if (!chainsData?.chains) {
      return;
    }

    const chain = chainsData.chains.find((chain) => chain.value === chainUuid);
    setSelectedChain(chain || chainsData.chains[0]);
  }, [chainUuid, chainsData?.chains]);

  async function handleLoad(event: SyntheticEvent) {
    event.preventDefault();

    const target = event.target as HTMLFormElement;
    const formData = new FormData(target);
    const chainValue = formData.get('chain[value]') as string | null;

    queryClient.removeQueries(['chain']);

    if (chainValue) {
      setChainUuid(chainValue);
    }
  }

  async function handleCreate(event: SyntheticEvent) {
    event.preventDefault();

    const target = event.target as HTMLFormElement;
    const formData = new FormData(target);
    const dataClassUuid = formData.get('data_class[value]') as string;
    const chainTitle = formData.get('chain_title') as string;
    const organisationUuid = userConfigData?.organisationConfig
      .organisation_uid as string | null;

    const variables = {
      title: chainTitle,
      dataClassUuid: dataClassUuid,
      organisationUuid: organisationUuid,
    };

    chainCreateMutation.mutateAsync(variables, {
      onSuccess: (response) => {
        const chainValue = response.data.chainUuid;

        queryClient.invalidateQueries(['chain']);

        if (chainValue) {
          refetchChainsData().then(() => {
            setChainUuid(chainValue);
          });
        }
      },
    });
  }

  async function handleDuplicate(event: SyntheticEvent) {
    event.preventDefault();

    setDuplicating(true);

    const loadChainFormData = new FormData(loadChainRef.current);
    const chainValue = loadChainFormData.get('chain[value]') as string | null;

    if (!chainValue) {
      return;
    }

    const formData = new FormData(event.target as HTMLFormElement);

    const variables = {
      chainUuid: chainValue,
      title: formData.get('duplicate_chain_title') as string,
    };

    chainDuplicateMutation.mutateAsync(variables, {
      onSuccess: (response) => {
        setDuplicating(false);
        setShowDuplicateForm(false);

        const chainValue = response.data.chainUuid;

        queryClient.invalidateQueries(['chain']);

        if (chainValue) {
          refetchChainsData().then(() => {
            setChainUuid(chainValue);
          });
        }
      },
    });
  }

  return (
    <Widget id={WIDGET_ID} title="Chain widget">
      <div className="relative min-h-[800px] h-full grid">
        {!contextUuid && !contextUuidParam && (
          <div className="bg-white shadow-lg sm:rounded-lg absolute top-4 right-4 bottom-4 max-w-[400px] w-full z-10 px-4 py-5 sm:p-6 space-y-8 transform translate-x-full hover:translate-x-0 ease-in-out duration-500 dark:bg-gray-600">
            <form onSubmit={handleLoad} ref={loadChainRef}>
              <div className="flex w-full gap-2">
                <div className="shrink-1 min-w-0 grow">
                  {chainsData && chainsData.chains && selectedChain && (
                    <ComboBox
                      label="Chain"
                      options={chainsData.chains}
                      name="chain"
                      value={selectedChain}
                      onChange={(value) => setSelectedChain(value)}
                      wrapText={true}
                    />
                  )}
                </div>
                <div className="self-end shrink-0 gap-2 flex">
                  <Button type="submit">Load</Button>
                  {!showDuplicateForm && (
                    <Button
                      type="button"
                      onClick={() => setShowDuplicateForm(true)}
                    >
                      <DocumentDuplicateIcon className="h-4 w-4" />
                    </Button>
                  )}
                </div>
              </div>
            </form>
            {showDuplicateForm && (
              <form className="!mt-2" onSubmit={handleDuplicate}>
                <div className="flex w-full gap-2">
                  <div className="shrink-1 min-w-0 grow">
                    {chainsData && chainsData.chains && selectedChain && (
                      <>
                        <label
                          htmlFor="duplicateChainTitle"
                          className="block text-sm font-medium text-gray-700 mb-1 dark:text-gray-100"
                        >
                          Duplicate Chain Title
                        </label>
                        <input
                          id="duplicateChainTitle"
                          type="text"
                          name="duplicate_chain_title"
                          className="block flex-1 w-full rounded-md border py-2 text-gray-900 shadow-sm border-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-indigo-500 sm:text-sm sm:leading-5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </>
                    )}
                  </div>
                  <div className="self-end shrink-0 gap-2 flex">
                    <Button type="submit" loading={duplicating}>
                      Duplicate
                    </Button>
                  </div>
                </div>
              </form>
            )}
            <form onSubmit={handleCreate}>
              <div className="grid grid-cols-[1fr,auto] gap-2">
                <div>
                  {dataClassData?.dataClasses && (
                    <SelectMenu
                      label="Data classes"
                      options={dataClassData?.dataClasses}
                      name="data_class"
                      selected={dataClassData?.dataClasses[0]}
                    />
                  )}
                </div>
                <div className="col-start-1">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 mb-1 dark:text-gray-100"
                  >
                    Chain Title
                  </label>
                  <input
                    id="chain_title"
                    type="text"
                    name="chain_title"
                    className="block flex-1 w-full rounded-md border py-2 text-gray-900 shadow-sm border-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-indigo-500 sm:text-sm sm:leading-5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <div className="col-start-2 self-end">
                  <Button type="submit">Create</Button>
                </div>
              </div>
            </form>
            <ChainStepsSelector />
          </div>
        )}
        {!isFetching && (
          <ChainFlow
            nodes={nodes}
            edges={edges}
            chainUuid={chainUuid}
            readonly={contextUuid ? true : false}
          />
        )}
      </div>
    </Widget>
  );
}
