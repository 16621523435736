import GridLayout, { WidthProvider } from 'react-grid-layout';

import { Page } from '@/components/page';
import { GridPanel } from '@/components/grid-panel';
import { JobsWidget } from '@/components/jobs-widget';

const layout = [
  { i: 'main', x: 0, y: 0, w: 40, h: 25 },
];

const AutoWidthGridLayout = WidthProvider(GridLayout);

export function Downloads() {
  return (
    <Page title="Downloads">
      <AutoWidthGridLayout
        className="layout"
        layout={layout}
        cols={40}
        rowHeight={25}
        isDraggable={false}
      >
        <GridPanel key="main">
          <JobsWidget title="Downloads" type="download" />
        </GridPanel>
      </AutoWidthGridLayout>
    </Page>
  );
}
