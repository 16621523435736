import { useState, useEffect } from 'react';
import {
  ActionProps,
  Option,
  QueryBuilder as ReactQueryBuilder,
  RuleGroupType,
  ValueSelectorProps,
} from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
import { DragDrop } from '../drag-drop';
import { PlusIcon } from '@heroicons/react/24/outline';
import { SelectMenu } from '../select-menu';

function RemoveButton({ handleOnClick }: ActionProps) {
  return (
    <button onClick={handleOnClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        className="w-5 h-5"
      >
        <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
      </svg>
    </button>
  );
}

function ValueSelector({
  className,
  options,
  title,
  value,
  handleOnChange,
  ...rest
}: ValueSelectorProps) {
  const newOptions = options.map((option) => {
    return {
      label: option.label,
      value: option.value,
    };
  });

  return (
    <div className={className}>
      <SelectMenu
        hideLabel
        label={title as string}
        options={newOptions}
        selected={newOptions.find((opt) => opt.value === value) || null}
        onChange={(value) => handleOnChange(value?.value)}
        {...rest}
      />
    </div>
  );
}

export function QueryBuilder({ query: passedQuery, fields, onQueryChange }) {
  const [query, setQuery] = useState<RuleGroupType>(passedQuery);

  useEffect(() => {
    onQueryChange(query);
  }, [query]);

  useEffect(() => {
    setQuery(passedQuery);
  }, [passedQuery]);

  function handleDrop(data: Record<string, unknown>) {
    if (data.type === 'query/field') {
      setQuery((prevQuery) => {
        return {
          ...prevQuery,
          rules: [
            ...prevQuery.rules,
            {
              field: data.value.keyword
                ? `data.${data.value.field}.keyword`
                : `data.${data.value.field}`,
              operator: '=',
              value: '',
            },
          ],
        };
      });
    } else if (data.type === 'query/value') {
      setQuery((prevQuery) => {
        return {
          ...prevQuery,
          rules: [
            ...prevQuery.rules,
            {
              field: data.value.keyword
                ? `data.${data.value.field}.keyword`
                : `data.${data.value.field}`,
              operator: '=',
              value: data.value.value,
            },
          ],
        };
      });
    }
  }

  return (
    <DragDrop
      allowedTypes={['query/field', 'query/value']}
      onDrop={(data) => handleDrop(data)}
      droppingClassName="bg-blue-500/20"
    >
      {({ isDropping }) => {
        return (
          <div className="relative">
            {isDropping ? (
              <span className="absolute inset-0 grid place-content-center pointer-events-none z-10 bg-slate-400/40">
                <PlusIcon className="h-4 w-4" />
              </span>
            ) : null}
            <ReactQueryBuilder
              fields={fields}
              query={query}
              onQueryChange={setQuery}
              controlElements={{
                removeGroupAction: RemoveButton,
                removeRuleAction: RemoveButton,
                valueSelector: ValueSelector,
              }}
            />
          </div>
        );
      }}
    </DragDrop>
  );
}
