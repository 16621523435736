import { useQuery } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface ObjectData {
  message: string;
  data: {
    count: number;
    resultset: Record<string, any>[];
  };
}

export function useObjects(uuids: string[], containers: string[]) {
  const { keycloak } = useKeycloak();

  return useQuery(['object', uuids, containers], {
    enabled: !!uuids.length && !!containers.length,
    queryFn: async (): Promise<ObjectData> => {
      const body = {
        uuids: uuids,
        container: containers[0]
      };

      const response = await fetch(import.meta.env.VITE_OBJECT_API_URL, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${keycloak.token}`
        },
        method: 'POST',
        body: JSON.stringify(body),
      });

      return response.json();
    },
    refetchInterval: 6 * 1000,
  });
}
