import clsx from 'clsx';

interface BadgeProps {
  children: React.ReactNode;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  variant?: 'info' | 'success' | 'warning' | 'error' | string;
}

export function Badge({
  children,
  className,
  size = 'md',
  variant = 'success',
}: BadgeProps) {
  const baseClassName = clsx(
    'inline-flex items-center rounded-full py-0.5 font-medium',
    size === 'md' && 'text-sm px-3',
    size === 'sm' && 'text-xs px-1.5 gap-x-1.5',
    variant === 'info' && 'bg-blue-100 text-blue-700',
    variant === 'success' && 'bg-green-100 text-green-700',
    variant === 'warning' && 'bg-yellow-100 text-yellow-800',
    variant === 'error' && 'bg-red-100 text-red-700',
    className
  );
  const svgClassName = clsx(
    size === 'md' && '-ml-1 mr-1.5 h-2 w-2',
    size === 'sm' && 'h-1.5 w-1.5',
    variant === 'info' && 'fill-blue-500',
    variant === 'success' && 'fill-green-500',
    variant === 'warning' && 'fill-yellow-500',
    variant === 'error' && 'fill-red-500'
  );

  let svgViewBox = '';
  let circlePosition = 0;
  if (size === 'md') {
    svgViewBox = '0 0 8 8';
    circlePosition = 4;
  } else if (size === 'sm') {
    svgViewBox = '0 0 6 6';
    circlePosition = 3;
  }

  return (
    <span className={baseClassName}>
      <svg className={svgClassName} viewBox={svgViewBox} aria-hidden="true">
        <circle cx={circlePosition} cy={circlePosition} r={3} />
      </svg>
      {children}
    </span>
  );
}
