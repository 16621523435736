import { Widget } from '@/components/widget';
import { DescriptionList } from '@/components/ui/description-list';
import { useListener } from '@/hooks/use-listener';
import { useObjects } from '@/hooks/use-objects';
import { useState } from 'react';

const WIDGET_ID = 'COMPARISON_WIDGET';

export function ComparisonWidget() {
  
  const [uuids, setUuids] = useState<string[]>([]);
  const [containers, setContainers] = useState<string[]>([]);
  const { data } = useObjects(uuids, containers);
  const { enabled } = useListener({
    id: WIDGET_ID,
    listenerFn: (data) => {
      setUuids(data.map(({ uuid }: { uuid: string }) => uuid));
      setContainers(data.map(({ container }: { container: string }) => container));
    },
    type: 'ObjectCollection',
  });

  const comparison = data?.data?.resultset[0] ? data?.data?.resultset[0].data.comparison : null;
  const userFeatures = data?.data?.resultset[0]?.data?.userFeatures?.userFeatures ? data?.data?.resultset[0]?.data?.userFeatures.userFeatures.join('•') : "" ;

  const items = comparison ? [
    {
      label: 'Differences',
      text: comparison.differences,
    },
    {
      label: 'Benefits',
      text: comparison.benefits,
    },
    {
      label: 'User Features',
      text: userFeatures,
    },
    /*
    {
      label: 'Attachments',
      files: [
        {
          name: 'resume_front_end_developer.pdf',
          size: 256,
        },
      ],
    },
    */
  ] : [
    {
      label: 'No Items',
      text: '',
    }
  ];


  return (
    <Widget id={WIDGET_ID} title="Comparison">
      {enabled ? (
        <DescriptionList
          items={items}
          subtitle="X <-> Y."
          title="Direct product comparison"
        />
      ) : null}
    </Widget>
  );
}
