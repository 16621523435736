import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface UserPlansData {
  plans: {
    id: string,
    subscription: string,
    plan: {
      product: {
        name: string
      }
    },
    price: {
      unit_amount: number,
    },
    cancel_at_period_end: boolean,
    current_period_end: number,
    status: string
  }[]
}

export function useUserPlans() {
  const queryClient = useQueryClient();
  const { keycloak } = useKeycloak();

  return useQuery({
    queryKey: ['userPlans'],
    queryFn: async (): Promise<UserPlansData> => {

      const response = await fetch(
        `${import.meta.env.VITE_USER_ENGINE_ENDPOINT}/api/v1/bundles/user_bundle/functions/get_user_plans`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'GET',
        }
      );

      let data = await response.json();

      const { subscriptions } = data?.data;

      return {
        plans: subscriptions
      };
    }
  });
}