import { Widget } from '@/components/widget';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx';
import { useChainStepsLog } from '@/hooks/use-chain-steps-log';
import { TimeFromUnix } from '@/components/time-from-unix';
import { useState, useEffect } from 'react';
import { SelectMenu } from '@/components/select-menu';
import { useQueryClient } from '@tanstack/react-query';
import { useEmitter } from '@/hooks/use-emitter';
import { Dragabble } from '@/components/drag-drop';
import { Button } from '@/components/button';
import { LinkIcon } from '@heroicons/react/24/outline';
import { useRerunGraphAction } from '@/hooks/use-rerun-graph-action';

const statuses = {
  'complete': 'text-green-700 bg-green-50 ring-green-600/20',
  'created': 'text-gray-600 bg-gray-50 ring-gray-500/10',
  'failed': 'text-red-800 bg-red-50 ring-red-600/20',
  'running': 'text-orange-800 bg-orange-50 ring-orange-600/20',
  'skipped': 'text-purple-800 bg-purple-50 ring-purple-600/20',
}

export interface Option {
  label: string;
  value: string;
}

const WIDGET_ID = 'CHAIN_LOG_WIDGET';

export function ChainLogWidget() {
  const [latestTimeStamp, setLatestTimeStamp] = useState<number|null>(null);
  const [statusOptions, setStatusOptions] = useState([]);
  const [bundleFunctionOptions, setBundleFunctionOptions] = useState([]);
  const [organisationOptions, setOrganisationOptions] = useState([]);
  const [chainUuidOptions, setChainUuidOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState<string|null>(null);
  const [selectedBundleFunction, setSelectedBundleFunction] = useState<string|null>(null);
  const [selectedOrganisation, setSelectedOrganisation] = useState<string|null>(null);
  const [selectedChainUuid, setSelectedChainUuid] = useState<string|null>(null);
  const [forceRefetchAggregations, setForceRefetchAggregations] = useState<boolean>(false)
  const { data, refetch } = useChainStepsLog(latestTimeStamp, selectedStatus, selectedBundleFunction, selectedOrganisation, selectedChainUuid);
  const queryClient = useQueryClient();
  const rerunGraphAction = useRerunGraphAction();

  const emit = useEmitter({
    id: WIDGET_ID,
    listeners: [
      'CHAIN_STATS_WIDGET',
    ],
  });

  useEffect(() => {
    if (!data?.latestTimestamp) {
      return;
    }
    
    setLatestTimeStamp(data?.latestTimestamp);
  }, [data?.latestTimestamp]);

  useEffect(() => {
    if(!data?.aggregations || data?.aggregations.length === 0) { return; }

    const statusAgs = [];
    data?.aggregations.status_aggregation.buckets.forEach((aggregation) => {
      statusAgs.push({ label: aggregation.key, value: aggregation.key });
    });

    setStatusOptions(statusAgs);

    const bundleFunctionAgs = [];
    data?.aggregations.bundle_function_aggregation.buckets.forEach((aggregation) => {
      bundleFunctionAgs.push({ label: aggregation.key, value: aggregation.key });
    });

    setBundleFunctionOptions(bundleFunctionAgs);

    const organisationAgs = [];
    data?.aggregations.organisation_aggregation.buckets.forEach((aggregation) => {
      organisationAgs.push({ label: aggregation.key, value: aggregation.key });
    });

    setOrganisationOptions(organisationAgs);

    const chainUuidAgs = [];
    data?.aggregations.chain_uuid_aggregation.buckets.forEach((aggregation) => {
      chainUuidAgs.push({ label: aggregation.key, value: aggregation.key });
    });

    setChainUuidOptions(chainUuidAgs);
  }, [data?.aggregations]);

  // useEffect(() => {
  //   refetch();
  // }, [selectedStatus, selectedBundleFunction, selectedOrganisation, selectedChainUuid]);

  useEffect(() => {
    const refetchData = () => {
      refetch().then(() => {
        setForceRefetchAggregations(false);
      });
    };

    const intervalId = setInterval(refetchData, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const handleStatusChange = (option: Option) => {
    queryClient.removeQueries(['chain-steps-log']);
    setLatestTimeStamp(null);
    setSelectedStatus(option ? option.value : null);
    setForceRefetchAggregations(true);


    emit({
      type: 'ChainLogFilters',
      data: [
        {
          'timestamp': null,
          'status': option ? option.value : null,
          'bundleFunction': selectedBundleFunction,
          'organisation': selectedOrganisation,
          'chainUuid': selectedChainUuid,
          'forceRefetchAggregations': true,
        }
      ]
    });
  }

  const handleBundleFunctionChange = (option: Option) => {
    queryClient.removeQueries(['chain-steps-log']);
    setLatestTimeStamp(null);
    setSelectedBundleFunction(option ? option.value : null);
    setForceRefetchAggregations(true);

    emit({
      type: 'ChainLogFilters',
      data: [
        {
          'timestamp': null,
          'status': selectedStatus,
          'bundleFunction': option ? option.value : null,
          'organisation': selectedOrganisation,
          'chainUuid': selectedChainUuid,
          'forceRefetchAggregations': true,
        }
      ]
    });
  }

  const handleOrganisationChange = (option: Option) => {
    queryClient.removeQueries(['chain-steps-log']);
    setLatestTimeStamp(null);
    setSelectedOrganisation(option ? option.value : null);
    setForceRefetchAggregations(true);

    emit({
      type: 'ChainLogFilters',
      data: [
        {
          'timestamp': null,
          'status': selectedStatus,
          'bundleFunction': selectedBundleFunction,
          'organisation': option ? option.value : null,
          'chainUuid': selectedChainUuid,
          'forceRefetchAggregations': true,
        }
      ]
    });
  }

  const handleChainUuidChange = (option: Option) => {
    queryClient.removeQueries(['chain-steps-log']);
    setLatestTimeStamp(null);
    setSelectedChainUuid(option ? option.value : null);
    setForceRefetchAggregations(true);

    emit({
      type: 'ChainLogFilters',
      data: [
        {
          'timestamp': null,
          'status': selectedStatus,
          'bundleFunction': selectedBundleFunction,
          'organisation': selectedOrganisation,
          'chainUuid': option ? option.value : null,
          'forceRefetchAggregations': true,
        }
      ]
    });
  }

  const handleRepeatStep = (uuid: string) => {
    // console.log(uuid);
    rerunGraphAction.mutateAsync({ uuid });
  }

  return (
    <Widget 
      id={WIDGET_ID}
      title="Automation Log"
      toolbar={
        <Dragabble value={{ widgetId: WIDGET_ID }} type="link">
          <Button>
            <LinkIcon className="h-4 w-4" aria-hidden="true" />
          </Button>
        </Dragabble>
      }
    >
      <div className="grid grid-cols-5 gap-4 w-full border-solid border-b pb-4 border-gray-200 sticky top-[-1.5rem] pt-4 bg-white">
        <div className="">
        <SelectMenu label={'Status'} options={statusOptions} clearable={true} onChange={(option) => handleStatusChange(option)} />
        </div>
        <div className="">
        <SelectMenu label={'Bundle Function'} options={bundleFunctionOptions} clearable={true} onChange={(option) => handleBundleFunctionChange(option)} />
        </div>
        <div className="">
        <SelectMenu label={'Organisation'} options={organisationOptions} clearable={true} onChange={(option) => handleOrganisationChange(option)} />
        </div>
        <div className="">
        <SelectMenu label={'Chain UUID'} options={chainUuidOptions} clearable={true} onChange={(option) => handleChainUuidChange(option)} />
        </div>
      </div>
      <ul role="list" className="divide-y divide-gray-100">
        {data?.chainSteps.map((chainStep) => (
          <li key={chainStep.uuid + '-' + chainStep.updatedTimestamp} className="flex items-center justify-between gap-x-6 py-5">
            <div className="min-w-0">
              <div className="flex items-start gap-x-3">
                <p className="text-sm font-semibold leading-6 text-gray-900">{chainStep.title}</p>
                <p
                  className={clsx(
                    statuses[chainStep.status],
                    'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                  )}
                >
                  {chainStep.status}
                </p>
              </div>
              <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                {/* <p className="whitespace-nowrap">
                  Created <TimeFromUnix unixTimestamp={chainStep.timestamp} />
                </p>
                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                  <circle cx={1} cy={1} r={1} />
                </svg> */}
                <p className="whitespace-nowrap">
                  Updated <TimeFromUnix unixTimestamp={chainStep.updatedTimestamp} />
                </p>
                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                  <circle cx={1} cy={1} r={1} />
                </svg>
                <p className="truncate">Context <a href={`/chain-log/${chainStep.contextUuid}`} target="_blank">{chainStep.contextUuid}</a></p>
                {chainStep.bundleFunction && (
                  <>
                    <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                      <circle cx={1} cy={1} r={1} />
                    </svg>
                    <p className="truncate">Bundle function {chainStep.bundleFunction}</p>
                  </>
                )}
              </div>
            </div>
            <div className="flex flex-none items-center gap-x-4">
              <a
                href={`/log/${chainStep.uuid}`}
                target="_blank"
                className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
              >
                View logs<span className="sr-only">, {chainStep.title}</span>
              </a>
              <Menu as="div" className="relative flex-none">
                <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                  <span className="sr-only">Open options</span>
                  <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href={`/chain-log/${chainStep.contextUuid}`}
                          target="_blank"
                          className={clsx(
                            active ? 'bg-gray-50' : '',
                            'block px-3 py-1 text-sm leading-6 text-gray-900'
                          )}
                        >
                          Full chain log<span className="sr-only">, {chainStep.title}</span>
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          onClick={() => handleRepeatStep(chainStep.uuid)}
                          target="_blank"
                          className={clsx(
                            active ? 'bg-gray-50' : '',
                            'block px-3 py-1 text-sm leading-6 text-gray-900'
                          )}
                        >
                          Rerun step<span className="sr-only">, {chainStep.title}</span>
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href={`/chain/${chainStep.chainUuid}/${chainStep.contextUuid}`}
                          target="_blank"
                          className={clsx(
                            active ? 'bg-gray-50' : '',
                            'block px-3 py-1 text-sm leading-6 text-gray-900'
                          )}
                        >
                          Chain model<span className="sr-only">, {chainStep.title}</span>
                        </a>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </li>
        ))}
      </ul>
    </Widget>
  );
}
