import { ViewMode } from '@/components/view-mode-switch';
import { useTreeStore } from '@/context/tree-context';
import { transformNodes } from '@/utils/transform-nodes';
import { useQuery } from '@tanstack/react-query';
import { Edge, Node } from 'reactflow';
import { useKeycloak } from '@react-keycloak/web';

interface NodeData {
  id?: string;
  parentId?: string;
  parentUuid?: string;
  parentContainer?: string;
  label?: string;
  size?: number;
  from?: number;
  container?: string | null;
  path?: string[];
  searchString?: string | null;
  searchStringNode?: string | null;
  pitId?: string;
  searchAfterArray?: any;
  esQuery?: string;
  viewMode?: ViewMode;
}

interface SearchQuery {
  queryText?: string;
}

interface TreeData {
  nodes: Node[];
  edges: Edge[];
  edgesPositioning: Edge[];
}

interface TreeOptions {
  enabled?: boolean;
}

export function useTree(
  nodeData: NodeData & SearchQuery,
  { enabled = false }: TreeOptions
) {
  const addData = useTreeStore((state) => state.addData);
  const addEdges = useTreeStore((state) => state.addEdges);
  const addEdgesPositioning = useTreeStore(
    (state) => state.addEdgesPositioning
  );
  const setTotal = useTreeStore((state) => state.setTotal);
  const addChildrenNodes = useTreeStore((state) => state.addChildrenNodes);
  const container = useTreeStore((state) => state.container);
  const setSearchParams = useTreeStore((state) => state.setSearchParams);
  const existingNodes = useTreeStore((state) => state.nodes);
  const { keycloak } = useKeycloak();
  const openNode = useTreeStore((state) => state.openNode);
  const setSearchStringNode = useTreeStore((state) => state.setSearchStringNode);

  let queryKey = [];

  if (nodeData.id) {
    queryKey = ['container_objects', container, nodeData.parentUuid, nodeData.label, nodeData];
  } else {
    queryKey = ['container', container, nodeData?.searchStringNode];
  }

  let wrapperId: string | null = null;

  if (nodeData?.parentId) {
    const wrapperNodeId = existingNodes.find(
      (node) => node.id === nodeData.parentId
    )?.parentNode;
    const wrapperNode = existingNodes.find((node) => node.id === wrapperNodeId);

    wrapperId = wrapperNode?.type === 'group' ? wrapperNode?.id : null;
  }

  return useQuery({
    enabled,
    queryKey,
    queryFn: async (): Promise<TreeData> => {
      const response = await fetch(import.meta.env.VITE_EXPLORE_API_URL, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${keycloak.token}`
        },
        method: 'POST',
        body: nodeData
          ? JSON.stringify({
              sourceAttributeId: nodeData?.id,
              sourceObjectId: nodeData?.parentId,
              uuid: nodeData?.parentUuid,
              container: nodeData?.parentContainer || container,
              attribute: nodeData?.label,
              size: nodeData?.size,
              from: nodeData?.from ? nodeData.from + 1 : 0,
              path: nodeData?.path,
              searchStringNode: nodeData?.searchStringNode,
              queryText: nodeData?.queryText,
              pitId: nodeData?.pitId,
              searchAfterArray: nodeData?.searchAfterArray,
              esQuery: nodeData?.esQuery,
              parentNode: wrapperId,
              viewFormat: nodeData?.viewMode,
            })
          : '',
      });
      const data = await response.json();

      const { nodes, edges, edgesPositioning, total } = data?.data;

      const transformedNodes = transformNodes(nodes, existingNodes);

      const edgesWithType = [
        ...edges.map((edge: Edge) => ({
          ...edge,
        })),
      ];

      if (nodeData?.id) {
        setTotal(nodeData.id, total);
        setSearchParams(
          nodeData.id,
          data?.data.pitId,
          data?.data.searchAfterArray
        );
        addChildrenNodes(nodeData.id, transformedNodes);
        addEdges(nodeData.id, edgesWithType);
        addEdgesPositioning(edgesPositioning);
      } else {
        addData(transformedNodes, edgesWithType, edgesPositioning);
      }

      if (!nodeData?.hasOwnProperty('id')) {
        setSearchStringNode(nodes[1].id, nodeData?.searchString);
        openNode(nodes[1].id);
      }

      return {
        nodes: transformedNodes,
        edges: edgesWithType,
        edgesPositioning,
      };
    },
  });
}
