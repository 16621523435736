import { Page } from '@/components/page';
import { UserPlans } from '@/components/user-plans';
import { NavLink } from 'react-router-dom';

export function ProfilePlans() {
  return (
    <Page title="Your Plans" toolbar={
      <NavLink to="/plans" type="button" className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">Add a plan</NavLink>
    }>
      <UserPlans />
    </Page>
  );
}