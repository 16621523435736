import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface UserConfigData {
  userConfig: {
    desktops: {
      uuid: string,
      title: string,
      widgets: {
        widget: string,
        i: string,
        x: number,
        y: number,
        w: number,
        h: number,
        data: {
          allowed_file_types: {
            type: string,
            label: string
          }[]
        }
      }[]
    }[]
  },
  organisationConfig: {
    desktops: {
      uuid: string,
      title: string,
      widgets: {
        widget: string,
        i: string,
        x: number,
        y: number,
        w: number,
        h: number,
        data: {
          allowed_file_types: {
            type: string,
            label: string
          }[]
        }
      }[]
    }[],
    organisation_uid: string
  },
  organisationName: string,
  bulkActions: {
    uuid: string,
    title: string
  }[] | []
}

export function useUserConfig() {
  const queryClient = useQueryClient();
  const { keycloak } = useKeycloak();

  return useQuery({
    enabled: false,
    queryKey: ['userConfig'],
    queryFn: async (): Promise<UserConfigData> => {
      const existingData = queryClient.getQueryData<UserConfigData>(['userConfig']);

      const response = await fetch(
        `${import.meta.env.VITE_USER_ENGINE_ENDPOINT}/api/v1/bundles/user_bundle/functions/get_user_config`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'GET',
        }
      );

      let data = await response.json();

      const { user_config, organisation_config, organisation_name, bulk_actions } = data?.data

      return {
        userConfig: user_config,
        organisationConfig: organisation_config,
        organisationName: organisation_name,
        bulkActions: bulk_actions
      };
    },
  });
}
