import GridLayout, { WidthProvider } from 'react-grid-layout';
import { GridPanel } from '@/components/grid-panel';

import { MagicPreviewWidget } from '@/components/magic-preview-widget';
import { Page } from '@/components/page';
import { TreeStoreProvider } from '@/context/tree-context';
import { ExploreWidget } from '@/components/explore-widget';
import { PreviewWidget } from '@/components/preview-widget';

const layout = [
  { i: 'main', x: 0, y: 0, w: 40, h: 20 },
  { i: 'sidebar_left', x: 0, y: 0, w: 20, h: 25 },
  { i: 'sidebar_right', x: 20, y: 0, w: 20, h: 10 },
];

const AutoWidthGridLayout = WidthProvider(GridLayout);

export function Preview() {
  return (
    <Page title="Magic Preview">
      <AutoWidthGridLayout
        className="layout"
        layout={layout}
        cols={40}
        rowHeight={25}
        isDraggable={false}
      >
        <GridPanel key="main">
          <TreeStoreProvider name="preview-explorer">
            <ExploreWidget />
          </TreeStoreProvider>
        </GridPanel>
        <GridPanel key="sidebar_left">
          <MagicPreviewWidget />
        </GridPanel>
        <GridPanel key="sidebar_right">
          <PreviewWidget />
        </GridPanel>
      </AutoWidthGridLayout>
    </Page>
  );
}
