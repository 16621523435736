import { LinkIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { DragDrop } from '@/components/drag-drop';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useCreateAgent } from '@/features/agents/hooks/use-create-agent';
import { ObjectParams } from '@/hooks/use-relation-insert';
import { useNavigate } from 'react-router-dom';
import { DefaultValues } from '@/features/agents/components/agent-creator-widget';
import { Navigation } from '@/features/agents/components/navigation';
import { MagicButton } from '@/features/agents/components/magic-button';
import { LoadAgentForm } from '@/features/agents/components/load-agent-form';

type CreateFormProps = {
  onProceed: (data: DefaultValues) => void;
  steps: { id: string; href: string; name: string; status: string }[];
};

interface RelationField {
  uuid: string;
  container: string;
}

type CreateFormInputs = {
  title?: string;
  type?: string;
  capabilities?: string;
  need?: string;
  testInput?: RelationField;
  inputObject?: string;
  expectedTestOutput?: RelationField;
  result?: string;
};

export function CreateForm({ onProceed, steps }: CreateFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<CreateFormInputs>();
  const createAgentMutation = useCreateAgent();
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<CreateFormInputs> = (data) => {
    const createData = {
      sourceExample: data.testInput,
      inputObject: data.inputObject,
      targetExample: data.expectedTestOutput,
      capabilities: data.capabilities,
      need: data.need,
      result: data.result,
      type: data.type,
      title: data.title,
    };

    createAgentMutation.mutate(createData, {
      onSuccess: (data) => {
        onProceed({
          sourceExample: createData.sourceExample,
          inputObject: createData.inputObject,
        });

        navigate(`/agents/${data.uuid}/test`);
      },
    });
  };

  function handleDrop(
    { value }: { value: ObjectParams[] },
    field: keyof CreateFormInputs
  ) {
    setValue(field, { uuid: value[0].uuid, container: value[0].container });
  }

  const testInput = watch('testInput');
  const expectedTestOutput = watch('expectedTestOutput');

  return (
    <>
      <Navigation
        loadingStep={createAgentMutation.isLoading ? 'create' : undefined}
        steps={steps}
      />

      <div className="p-7 grid grid-cols-2 gap-x-7">
        <form onSubmit={handleSubmit(onSubmit)} className="grid gap-4">
          <div>
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-900"
            >
              Title
            </label>
            <div className="mt-1">
              <input
                type="text"
                id="title"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                {...register('title')}
              />
              {errors.title && (
                <p className="text-red-500 text-sm mt-1">Title is required.</p>
              )}
            </div>
          </div>
          <div>
            <label
              htmlFor="type"
              className="block text-sm font-medium text-gray-900"
            >
              Type
            </label>
            <div className="mt-1">
              <select
                {...register('type')}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                <option value="transformer">Transformer</option>
                <option value="datafetcher">Data Fetcher</option>
                <option value="anything">Anything</option>
              </select>
            </div>
          </div>
          <div>
            <label
              htmlFor="testInput"
              className="block text-sm font-medium text-gray-900"
            >
              Test Input
            </label>
            <div className="space-y-1 mt-2">
              <DragDrop
                allowedTypes={['object/insert']}
                onDrop={(data) => handleDrop(data, 'testInput')}
                className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-purple px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400 border-dashed justify-center text-center h-5"
                droppingClassName="relative flex items-center space-x-3 rounded-lg border border-gray-300 px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-800 border-dashed bg-purple-50 flex justify-center items-center h-5"
              >
                {({ isDropping }) =>
                  isDropping ? (
                    <PlusIcon className="h-4 w-4" />
                  ) : (
                    <PlusIcon className="h-4 w-4" />
                  )
                }
              </DragDrop>
              {testInput?.uuid && testInput?.container ? (
                <div className="relative flex items-center gap-3 rounded-lg border border-gray-300 bg-white py-2 pl-2 pr-3 shadow-sm text-xs">
                  <div className="relative w-10 h-10 bg-purple-50 rounded-full flex justify-center items-center text-center">
                    <LinkIcon className="h-5 w-5" />
                  </div>
                  <div className="min-w-0 flex-1">
                    <p className="font-medium text-gray-900">
                      {testInput.container}
                    </p>
                    <p className="truncate text-gray-500">{testInput.uuid}</p>
                  </div>
                  <button
                    onClick={() =>
                      setValue('testInput', { uuid: '', container: '' })
                    }
                    className="text-gray-400 hover:text-gray-700"
                  >
                    <TrashIcon className="h-4 w-4" />
                  </button>
                </div>
              ) : null}

              <input type="hidden" {...register('testInput')} />
              {errors.testInput && (
                <p className="text-red-500 text-sm mt-1">
                  Test Input is required.
                </p>
              )}
            </div>
          </div>
          <div>
            <label
              htmlFor="inputObject"
              className="block text-sm font-medium text-gray-900"
            >
              Test Input Free Text
            </label>
            <div className="mt-1">
              <textarea
                id="inputObject"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                {...register('inputObject')}
              ></textarea>
              {errors.inputObject && (
                <p className="text-red-500 text-sm mt-1">
                  Input Object Free Text is required.
                </p>
              )}
            </div>
          </div>
          <div>
            <label
              htmlFor="capabilities"
              className="block text-sm font-medium text-gray-900"
            >
              Capabilities
            </label>
            <div className="mt-1">
              <textarea
                id="capabilities"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                {...register('capabilities')}
              ></textarea>
              {errors.capabilities && (
                <p className="text-red-500 text-sm mt-1">
                  Capabilities are required.
                </p>
              )}
            </div>
          </div>
          <div>
            <label
              htmlFor="expectedTestOutput"
              className="block text-sm font-medium text-gray-900"
            >
              Expected Test Output
            </label>
            <div className="space-y-1 mt-2">
              <DragDrop
                allowedTypes={['object/insert']}
                onDrop={(data) => handleDrop(data, 'expectedTestOutput')}
                className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-purple px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400 border-dashed justify-center text-center h-5"
                droppingClassName="relative flex items-center space-x-3 rounded-lg border border-gray-300 px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-800 border-dashed bg-purple-50 flex justify-center items-center h-5"
              >
                {({ isDropping }) =>
                  isDropping ? (
                    <PlusIcon className="h-4 w-4" />
                  ) : (
                    <PlusIcon className="h-4 w-4" />
                  )
                }
              </DragDrop>
              {expectedTestOutput?.uuid && expectedTestOutput?.container ? (
                <div className="relative flex items-center gap-3 rounded-lg border border-gray-300 bg-white py-2 pl-2 pr-3 shadow-sm text-xs">
                  <div className="relative w-10 h-10 bg-purple-50 rounded-full flex justify-center items-center text-center">
                    <LinkIcon className="h-5 w-5" />
                  </div>
                  <div className="min-w-0 flex-1">
                    <p className="font-medium text-gray-900">
                      {expectedTestOutput.container}
                    </p>
                    <p className="truncate text-gray-500">
                      {expectedTestOutput.uuid}
                    </p>
                  </div>
                  <button
                    onClick={() =>
                      setValue('expectedTestOutput', {
                        uuid: '',
                        container: '',
                      })
                    }
                    className="text-gray-400 hover:text-gray-700"
                  >
                    <TrashIcon className="h-4 w-4" />
                  </button>
                </div>
              ) : null}
              <input type="hidden" {...register('expectedTestOutput')} />
              {errors.expectedTestOutput && (
                <p className="text-red-500 text-sm mt-1">
                  Expected Test Output is required.
                </p>
              )}
            </div>
          </div>
          <div>
            <label
              htmlFor="need"
              className="block text-sm font-medium text-gray-900"
            >
              Need
            </label>
            <div className="mt-1">
              <textarea
                id="need"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                {...register('need')}
              ></textarea>
              {errors.need && (
                <p className="text-red-500 text-sm mt-1">Need is required.</p>
              )}
            </div>
          </div>

          <div>
            <label
              htmlFor="result"
              className="block text-sm font-medium text-gray-900"
            >
              Result
            </label>
            <div className="mt-1">
              <textarea
                id="result"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                {...register('result')}
              ></textarea>
              {errors.result && (
                <p className="text-red-500 text-sm mt-1">Result is required.</p>
              )}
            </div>
          </div>

          <div className="flex justify-end">
            <MagicButton label="Create" type="submit" />
          </div>
        </form>
        <LoadAgentForm />
      </div>
    </>
  );
}
