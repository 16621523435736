import { useMutation } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface StopAgentResponse {
  success: boolean;
}

export function useStopAgent() {
  const { keycloak } = useKeycloak();

  return useMutation({
    mutationFn: async (uuid: string): Promise<StopAgentResponse> => {
      const response = await fetch(
        `${
          import.meta.env.VITE_IMPORT_ENGINE_ENDPOINT
        }/api/v1/bundles/generate_bundle/functions/stop_agent`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${keycloak.token}`,
          },
          method: 'POST',
          body: JSON.stringify({ uuid }),
        }
      );

      const data = await response.json();

      return {
        success: true,
      };
    },
  });
}
