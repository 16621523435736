import { Button } from '@/components/button';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import {
  FormContextType,
  IconButtonProps,
  RJSFSchema,
  StrictRJSFSchema,
} from '@rjsf/utils';
import clsx from 'clsx';

/** The `AddButton` renders a button that represent the `Add` action on a form
 */
export function AddButton<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>({ className, onClick, disabled }: IconButtonProps<T, S, F>) {
  const containerClassName = clsx(className, 'flex justify-end');
  return (
    <div className={containerClassName}>
      <Button type="button" onClick={onClick}>
        <PlusCircleIcon className="h-4 w-4" aria-hidden="true" />
      </Button>
    </div>
  );
}
