import moment from 'moment';
import { useUserPlans } from '@/hooks/user/use-user-plans';
import { usePlanCancel } from '@/hooks/plans/use-plan-cancel';
import clsx from 'clsx';
import { LoadingScreenSpinner } from '@/components/loading-screen-spinner';

export function UserPlans() {
  const { data, isLoading } = useUserPlans();
  const cancelPlan = usePlanCancel();

  const handleCancelSubscription = (e: any, subscriptionId: string) => {
    e.preventDefault();
    cancelPlan.mutateAsync({
      subscriptionId: subscriptionId
    });
  };

  if (isLoading) {
    return <LoadingScreenSpinner />
  }

  return (
    <div>
      {data && (
        <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {data.plans.map((plan) => (
            <li key={plan.id} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
              <div className="flex w-full items-center justify-between space-x-6 p-6">
                <div className="flex-1 truncate">
                  <div className="flex items-center space-x-3">
                    <h3 className="truncate text-sm font-medium text-gray-900">{plan.plan.product.name}</h3>
                    <span className={clsx("inline-block flex-shrink-0 rounded-full px-2 py-0.5 text-xs font-medium capitalize", plan.cancel_at_period_end ? 'bg-amber-100 text-amber-800' : 'bg-green-100 text-green-800')}>
                      {plan.cancel_at_period_end ? 'Cancelled' : plan.status}
                    </span>
                  </div>
                  <p className="mt-1 truncate text-sm text-gray-500">{plan.cancel_at_period_end ? 'Expires' : 'Next payment'}: {moment.unix(plan.current_period_end).format('MMM DD, YYYY')}</p>
                  <p className="mt-1 truncate text-sm text-gray-500">Price: €{(plan.price.unit_amount/100).toFixed(2)}</p> 
                  {/* {plan.price.currency} */}
                </div>
              </div>
              <div>
                <div className="-mt-px flex divide-x divide-gray-200">
                  <div className="flex w-0 flex-1">
                    {!plan.cancel_at_period_end &&
                    <span
                      className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500 cursor-pointer"
                      onClick={(e) => handleCancelSubscription(e, plan.subscription)}
                    >
                      <span className="ml-3">Cancel</span>
                    </span>
                    }
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};