
import { useKeycloak } from '@react-keycloak/web';

export function AuthorisedFunction(roles: string[]) {
  const { keycloak, initialized } = useKeycloak();

    const isAuthorised = () => {
        if (keycloak && roles) {
            return roles.some((r: string) => {
                const resource = keycloak.hasRealmRole(r);
                return resource;
            });
        }
    }

    return isAuthorised();
}