import { Widget } from '@/components/widget';
import { useTheme } from '@/hooks/use-theme';
import ReactECharts from 'echarts-for-react';
import { useEffect, useState } from 'react';

const WIDGET_ID = 'RAIDAL_BAR_CHART_WIDGET';

interface RadialBarChartProps {
  title?: string;
  data?: {
    name: string;
    value: number;
  }[];
}

type Data = number | { value: number };

interface BarSeries {
  type: string;
  showBackground: boolean;
  itemStyle: {
    backgroundColor: string;
  };
  data: Data[];
  coordinateSystem: string;
  roundCap: boolean;
  name: string;
}

export function RadialBarChartWidget({ title, data }: RadialBarChartProps) {
  const { theme } = useTheme('light');
  const [series, setSeries] = useState<BarSeries[]>();

  const options = {
    color: [
      'rgb(199, 210, 254)',
      'rgb(165, 180, 252)',
      'rgb(129, 140, 248)',
      'rgb(99, 102, 241)',
      'rgb(79, 70, 229)',
      'rgb(67, 56, 202)',
      'rgb(55, 48, 163)',
      'rgb(49, 46, 129)',
    ],
    angleAxis: {
      startAngle: 90,
      splitLine: {
        show: false,
      },
      show: false,
      max: 'dataMax',
    },
    radiusAxis: {
      type: 'category',
      z: 10,
      show: false,
      min: -1,
    },
    polar: {
      radius: '85%',
      center: ['65%', '50%'],
    },
    series: series,
    legend: {
      show: true,
      orient: 'vertical',
      left: 'left',
      top: '40%',
      selectMode: false,
      textStyle: { color: theme === 'light' ? '#000' : '#fff' },
      formatter: data
        ? function (name: string) {
            let itemValue = data?.filter((item) => item.name === name);
            return `${name}: ${itemValue ? itemValue[0].value : ''}`;
          }
        : '{name}',
    },
    tooltip: {
      show: true,
      formatter: '{a}<br /><strong>{c0}</strong>',
    },
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    setSeries(
      data?.map(({ value, name }) => {
        return {
          type: 'bar',
          // barMaxWidth: '15',
          showBackground: true,
          itemStyle: {
            backgroundColor: 'gray',
          },
          data: [value],
          coordinateSystem: 'polar',
          roundCap: true,
          name: name,
        };
      })
    );
  }, [data]);

  return (
    <Widget title={title ? title : 'Statistics'} id={WIDGET_ID}>
      {data && (
        <ReactECharts
          option={options}
          style={{ width: '100%' }}
          notMerge={false}
        />
      )}
    </Widget>
  );
}
