import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface ChainSteps {
  chainSteps: ChainStep[];
}

type ChainStep = {
  status: string,
  timestamp: number,
  updatedTimestamp: number,
  title: string,
  objectType: string,
  bundleFunction: string,
  contextUuid: string,
  uuid: string
};

interface LatestTimeStamp {
  latestTimestamp: number;
}

interface Refresh {
  refresh: boolean;
}

interface Aggregations {
  aggregations: any;
}

export function useChainStats(timestamp: number|null, status: string|null, bundleFunction: string|null, organisation: string|null, chainUuid: string|null, forceRefetchAggregations: boolean) {
  const queryClient = useQueryClient();
  const { keycloak } = useKeycloak();

  return useQuery(['chain-stats'], {
    queryFn: async (): Promise<ChainSteps & LatestTimeStamp & Aggregations & Refresh> => {
      const body = {
        timestamp,
        status,
        bundleFunction,
        organisation,
        chainUuid,
        forceRefetchAggregations
      };

      const response = await fetch(`${import.meta.env.VITE_IMPORT_ENGINE_ENDPOINT}/api/v1/bundles/query_bundle/functions/get_running_chain_steps`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${keycloak.token}`
        },
        method: 'POST',
        body: JSON.stringify(body),
      });

      let data = await response.json();

      const { aggregations } = data?.data;

      // console.log('agg data', data);

      const existingData = queryClient.getQueryData<Aggregations>(['chain-stats']);

      const stats = [];

      // if (existingData && forceRefetchAggregations === false) {
      //   return existingData;
      // }


      aggregations?.status_aggregation?.buckets?.map((bucket: any) => {
        stats.push({
          name: bucket.key,
          value: bucket.doc_count
        });
      });

      return {
        series: stats
      };
    },
    refetchInterval: 60000
  });
}
