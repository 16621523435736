import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface BulkStatsData {
  series: number[];
}

export function useBulkStats() {
  const queryClient = useQueryClient();
  const { keycloak } = useKeycloak();

  return useQuery({
    queryKey: ['bulkStats'],
    queryFn: async (): Promise<BulkStatsData> => {

      const response = await fetch(
        `${import.meta.env.VITE_IMPORT_ENGINE_ENDPOINT}/api/v1/bundles/query_bundle/functions/bulk_job_stats`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'GET',
        }
      );

      let data = await response.json();

      const { size } = data?.data;

      const existingData = queryClient.getQueryData<BulkStatsData>(['bulkStats']);

      let series = [];

      if (existingData === undefined) {
        series = [null, null, null, null, null, size];
      } else {
        series = [
          ...existingData.series
        ];
        series.shift();
        series.push(size);
      }

      return {
        series: series
      };
    },
    refetchInterval: 6 * 1000
  });
}
