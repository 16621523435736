import { useEffect, useState } from 'react';
import { useListener } from '@/hooks/use-listener';
import { ActionsForm } from '@/components/actions-form';
import { Widget } from '@/components/widget';
import { Option } from '@/components/select-menu';
import { IChangeEvent } from '@rjsf/core';
import { useActionTrigger } from '@/hooks/use-action-trigger';
import { useUserConfig } from '@/hooks/user/use-user-config';
import { useUserContainers } from '@/hooks/user/use-user-containers';
import { SelectMenu } from '@/components/select-menu';
import { Button } from '@/components/button';
import { useUserDataclasses } from '@/hooks/user/use-user-dataclasses';
import { useUserCollectionSize } from '@/hooks/user/use-user-collection-size';
import { use } from 'echarts';

const WIDGET_ID = 'COLLECTION_WIDGET';

export function CollectionWidget() {
  const [data, setData] = useState<{ uuid: string, dataClassUuid: string }[]>([]);
  const actionTrigger = useActionTrigger();
  const { data: userConfig } = useUserConfig();
  const { data: userContainers } = useUserContainers();
  const { data: userDataclasses } = useUserDataclasses();
  const [actionOptions, setActionOptions] = useState<Option[]>([]);
  const [collection, setCollection] = useState(null);
  // const { data: collectionSize } = useUserCollectionSize({collection: collection});

  useListener({ id: WIDGET_ID, listenerFn: (data) => { setData(data); setCollection(null) }, type: 'ObjectCollection' });

  useListener({ id: WIDGET_ID, listenerFn: (data) => { setCollection(data); setData([]) }, type: 'CollectionObject' });

  const actionOptionsUnfiltered = userConfig?.bulkActions ?? [];

  // get all collected data class uuids
  useEffect(() => {
    const dataClassUuidCollection = data.map((item) => (item.dataClassUuid));

    const actionOptions = actionOptionsUnfiltered.filter((item) => dataClassUuidCollection.every(uuid => item.dataClassUuids.includes(uuid)));

    setActionOptions(actionOptions);

  }, [data, actionOptionsUnfiltered]);

  function handleActionRun({
    action,
    formData,
    event
  }: {
    action: Option;
    formData: IChangeEvent;
    event: string;
  }) {
    const payload = {
      event: event,
      triggeringNodes: data.map((item) => ( { uuid: item.uuid, container: item.container, root: item.root } )),
      collection: collection,
      configuration: formData
    };

    actionTrigger.mutateAsync(payload);
  }

  function handleLoadUuids(event: SyntheticEvent) {
    event.preventDefault();

    const target = event.target as HTMLFormElement;
    const formData = new FormData(target);

    const containerValue = formData.get('container[value]') as string | null;
    const dataclassValue = formData.get('dataclass[value]') as string | null;
    const uuids = formData.get('manualUuids') as string | null;
    // console.log(uuids);

    if (uuids) {
      const uuidsArray = uuids.split('\n').map((uuid) => uuid.trim());
      setData(uuidsArray.map((uuid) => ({ uuid, dataClassUuid: dataclassValue, container: containerValue })));
    }
  }

  return (
    <Widget id={WIDGET_ID} title="Collection">
      <div className="grid grid-cols-2 gap-4">
        <div>
          {collection ? (
            <>
            <ActionsForm actions={actionOptions} onActionRun={handleActionRun} />
            <div className="font-mono mt-8">
              Collection - {collection.title} ({collection.size} items)
            </div>
            </>
          ) : data.length ? (
            <>
              <ActionsForm actions={actionOptions} onActionRun={handleActionRun} />
              <ul className="font-mono mt-8">
                {data.map((item) => (
                  <li key={item.uuid}>{item.uuid}</li>
                ))}
              </ul>
            </>
          ) : null}
        </div>
        <form onSubmit={handleLoadUuids}>
          {userContainers && userContainers.containers && (
            <SelectMenu
              label="Container"
              options={userContainers.containers}
              name="container"
              selected={userContainers.containers[0]}
            />
          )}
          {userDataclasses && userDataclasses.dataClasses && (
            <div className="mt-4">
              <SelectMenu
                label="Dataclass"
                options={userDataclasses?.dataClasses}
                name="dataclass"
                selected={userDataclasses?.dataClasses[0]}
              />
            </div>
          )}
          <div className="mt-4 mb-4">
            <label htmlFor="manualUuids" className="block text-sm font-medium leading-6 text-gray-900">
              UUIDs
            </label>
            <div className="mt-2">
              <textarea
                rows={4}
                name="manualUuids"
                id="manualUuids"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={''}
              />
            </div>
          </div>
          <Button type="submit">Load UUIDs</Button>
        </form>
      </div>
    </Widget>
  );
}
