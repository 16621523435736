import { Map } from '@/components/map';
import { FieldProps } from '@rjsf/utils';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Label } from '../templates/field/field';

interface Location {
  lat?: number;
  lng?: number;
}

export function GeoLocation({
  formData,
  hideError,
  onChange,
  rawErrors,
}: FieldProps) {
  const [state, setState] = useState({ ...formData });

  const hasError = rawErrors && rawErrors.length > 0 && !hideError;

  useEffect(() => {
    onChange(state);
  }, [state]);

  function handleChange(
    key: keyof Location,
    e: React.FormEvent<HTMLInputElement>
  ) {
    setState({
      [key]: parseFloat(e.currentTarget.value),
    });
  }

  function handleMarkersSet(position: google.maps.LatLng) {
    setState(position.toJSON());
  }

  const className = clsx(
    'block w-full rounded-md shadow-sm focus:outline-none sm:text-sm',
    !hasError &&
      'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500',
    hasError &&
      'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500'
  );

  return (
    <div className="space-y-2">
      <div className="flex space-x-2">
        <div>
          <Label label="Latitude" id="latitude" />
          <input
            className={className}
            id="latitude"
            type="text"
            value={state?.lat ? state.lat : ''}
            onChange={(e) => handleChange('lat', e)}
          />
        </div>
        <div>
          <Label label="Longitude" id="longitude" />
          <input
            className={className}
            id="longitude"
            type="text"
            value={state?.lng ? state.lng : ''}
            onChange={(e) => handleChange('lng', e)}
          />
        </div>
      </div>
      <div>
        <Map onMarkersSet={handleMarkersSet} />
      </div>
    </div>
  );
}
