import { NavLink, useParams } from 'react-router-dom';
import { Step } from '@/features/agents/components/agent-creator-widget';
import clsx from 'clsx';
import { Particle } from '@/features/agents/components/particle';
import { random } from '@/features/agents/lib/helpers';

interface NavigationProps {
  loadingStep?: string;
  steps: Step[];
}

const particles = Array.from({ length: 30 }, (_, i) => (
  <Particle
    key={i}
    style={{
      '--x': `${random(20, 80)}`,
      '--y': `${random(20, 80)}`,
      '--duration': `${random(6, 20)}`,
      '--delay': `${random(1, 10)}`,
      '--alpha': `${random(40, 90) / 100}`,
      '--origin-x': `${
        Math.random() > 0.5 ? random(300, 800) * -1 : random(300, 800)
      }%`,
      '--origin-y': `${
        Math.random() > 0.5 ? random(300, 800) * -1 : random(300, 800)
      }%`,
      '--size': `${random(40, 90) / 100}`,
    }}
  />
));

export function Navigation({ loadingStep, steps }: NavigationProps) {
  const { uuid } = useParams();
  const level = uuid ? '../../' : '../';

  return (
    <nav aria-label="Progress" className="sticky top-[90px] z-[1]">
      <ol
        role="list"
        className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0 overflow-hidden bg-white"
      >
        {steps.map((step: Step, stepIdx: number) => {
          const requiresUuid = step.href.includes(':uuid');
          const isLoading = loadingStep === step.id;
          const loadingOverlayClassName = clsx(
            'absolute flex justify-center items-center fancy-bg',
            stepIdx === 0 &&
              '[clip-path:polygon(0_0,_calc(100%_-_20px)_0,_calc(100%_-_2px)_50%,_calc(100%_-_20px)_100%,_0_100%,_0_0)] inset-0',
            stepIdx === steps.length - 1 &&
              '[clip-path:polygon(1.5px_0,_100%_0,_100%_50%,_100%_100%,_1.5px_100%,_19px_50%)] top-0 right-0 bottom-0 -left-5',
            stepIdx > 0 &&
              stepIdx < steps.length - 1 &&
              '[clip-path:polygon(1.5px_0,_calc(100%_-_20px)_0,_calc(100%_-_2px)_50%,_calc(100%_-_20px)_100%,_1.5px_100%,_19px_50%)] top-0 right-0 bottom-0 -left-5'
          );

          return (
            <li key={step.name} className="relative md:flex md:flex-1">
              {isLoading ? (
                <>
                  {isLoading ? (
                    <div className={loadingOverlayClassName}></div>
                  ) : null}
                  <span
                    aria-hidden="true"
                    className={clsx(
                      'particle-pen',
                      isLoading && 'absolute inset-0'
                    )}
                    style={{
                      '--active': isLoading ? 1 : 0,
                      '--play-state': isLoading ? 'running' : 'paused',
                    }}
                  >
                    {particles}
                  </span>
                </>
              ) : null}
              {requiresUuid && !uuid ? (
                <span className="flex items-center flex-1">
                  <span className="flex items-center px-6 py-4 text-sm font-medium">
                    <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300">
                      <span className="text-gray-500">{`0${stepIdx + 1}`}</span>
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-500">
                      {step.name}
                    </span>
                  </span>
                </span>
              ) : (
                <NavLink
                  to={`${level}${step.href.replace(':uuid', uuid)}`}
                  className={({ isActive }) =>
                    `flex items-center flex-1 relative ${
                      isActive ? 'px-6 py-4 text-sm font-medium' : 'group'
                    }`
                  }
                >
                  {({ isActive }) =>
                    isActive ? (
                      <>
                        <span
                          className={clsx(
                            'flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2',
                            !isLoading && 'border-indigo-600',
                            isLoading && 'border-white'
                          )}
                        >
                          <span
                            className={clsx(
                              !isLoading && 'text-indigo-600',
                              isLoading && 'text-white'
                            )}
                          >
                            {`0${stepIdx + 1}`}
                          </span>
                        </span>
                        <span
                          className={clsx(
                            'ml-4 text-sm font-medium',
                            !isLoading && 'text-indigo-600',
                            isLoading && 'text-white'
                          )}
                        >
                          {step.name}
                        </span>
                      </>
                    ) : (
                      <span className="flex items-center px-6 py-4 text-sm font-medium">
                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                          <span className="text-gray-500 group-hover:text-gray-900">
                            {`0${stepIdx + 1}`}
                          </span>
                        </span>
                        <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                          {step.name}
                        </span>
                      </span>
                    )
                  }
                </NavLink>
              )}
              {stepIdx !== steps.length - 1 ? (
                <>
                  <div
                    aria-hidden="true"
                    className="absolute right-0 top-0 hidden h-full w-5 md:block"
                  >
                    <svg
                      fill="none"
                      viewBox="0 0 22 80"
                      preserveAspectRatio="none"
                      className="h-full w-full text-gray-300"
                    >
                      <path
                        d="M0 -2L20 40L0 82"
                        stroke="currentcolor"
                        vectorEffect="non-scaling-stroke"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </>
              ) : null}
            </li>
          );
        })}
      </ol>
    </nav>
  );
}
