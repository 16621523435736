export interface StackedListItem {
  title: string;
  text: string;
  subtitle: string;
  imageUrl: string;
  meta?: string;
}

interface StackedListProps {
  items: StackedListItem[];
}

export function StackedList({ items }: StackedListProps) {
  return (
    <ul role="list" className="divide-y divide-gray-100">
      {items.map((item) => (
        <li key={item.title} className="flex justify-between gap-x-6 py-5">
          <div className="flex min-w-0 gap-x-4">
            <img
              className="h-12 w-12 flex-none rounded-full bg-gray-50"
              src={item.img}
              alt=""
            />
            <div className="min-w-0 flex-auto">
              <p className="text-sm font-semibold leading-6 text-gray-900">
                {item.site}
              </p>
              <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                {item.title}
              </p>
            </div>
          </div>
          <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
            <p className="text-sm leading-6 text-gray-900"><a target="_blank" href={item.url}>Visit</a></p>
            {item.meta ? (
              <p className="mt-1 text-xs leading-5 text-gray-500">
                {item.meta}
              </p>
            ) : null}
          </div>
        </li>
      ))}
    </ul>
  );
}
