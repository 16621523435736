import { useState, useEffect, useRef } from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { RegisterProfile } from '@/components/register-profile/register-profile';
import { RegisterCompany } from '@/components/register-company';
import { RegisterTerms } from '@/components/register-terms';
import { useKeycloak } from '@react-keycloak/web';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { rgb } from 'd3';
import { useUserRegister } from '@/hooks/user/use-user-register';
import clsx from 'clsx';
import { useUserEmailUnqiue } from '@/hooks/user/use-user-email-unique';
import { useUserOrganisationUnqiue } from '@/hooks/user/use-user-organisation-unique';

const stripe = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

export function RegisterStepper() {
  const [email, setEmail] = useState<string>('');
  const [organisation, setOrganisation] = useState<string>('');
  const { data } = useUserEmailUnqiue(email);
  const { data: organisationUniqueData } =
    useUserOrganisationUnqiue(organisation);

  const options = {
    appearance: {
      theme: 'none',
      variables: {
        fontFamily: 'ui-sans-serif, system-ui',
      },
      rules: {
        '.Label': {
          fontWeight: '500',
          fontSize: '0.875rem',
          color: 'rgb(55 65 81)',
          lineHeight: '1.25rem',
          marginTop: '0.75rem',
        },
        '.Input': {
          borderColor: 'rgb(209 213 219)',
          borderWidth: '1px',
          borderStyle: 'solid',
          fontSize: '0.875rem',
          padding: '0.5rem 0.75rem',
          lineHeight: '1.25rem',
          color: 'rgb(107, 114, 128)',
          fontWeight: '400',
          boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
        },
        '.Input:focus': {
          borderColor: 'rgb(99, 102, 241)',
          borderWidth: '1px',
          borderStyle: 'solid',
          outline: '2px solid transparent',
          outlineOffset: '2px',
          boxShadow: '0 0 0 1px rgba(99, 102, 241, 1)',
        },
        '.Input--invalid': {
          borderColor: 'rgb(252, 165, 165)',
          borderWidth: '1px',
          borderStyle: 'solid',
        },
        '.Error': {
          color: 'rgb(220, 38, 38)',
          fontSize: '0.875rem',
          marginTop: '0.5rem',
        },
      },
    },
  };

  const { keycloak, initialized } = useKeycloak();
  const registerUser = useUserRegister();

  const [formData, setFormData] = useState({});

  const handleBlur = (_id: string, value: string) => {
    setEmail(value);
  };

  const handleOrganisationChange = (value: string) => {
    setOrganisation(value);
  };

  const [steps, setSteps] = useState([
    {
      name: 'Provide information for your user profile',
      form: (
        <RegisterProfile
          progressNextStep={handleNextStep}
          handleBlur={handleBlur}
        />
      ),
      href: '#',
      status: 'current',
    },
    {
      name: 'Provide information for your organisation',
      form: (
        <RegisterCompany
          progressNextStep={handleNextStep}
          handleOrganisationChange={handleOrganisationChange}
        />
      ),
      href: '#',
      status: 'upcoming',
    },
    {
      name: 'Agree to our terms',
      form: <RegisterTerms progressNextStep={handleFinalStep} />,
      href: '#',
      status: 'upcoming',
    },
    {
      name: 'Login',
      form: <LoginForm />,
      href: '#',
      status: 'upcoming',
    },
  ]);

  function LoginForm() {
    return (
      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <span className="sm:col-span-4">
          You have succesfully registered, you may now login
        </span>
        <div className="mt-1 sm:col-start-1">
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={() => keycloak.login()}
          >
            Login
          </button>
        </div>
      </div>
    );
  }

  const currentStep = useRef(0);

  function handleNextStep(savedFormData: object) {
    setFormData((prevValues) => ({ ...prevValues, ...savedFormData }));
    let newSteps = [...steps];
    newSteps[currentStep.current].status = 'complete';
    if (currentStep.current + 1 < steps.length) {
      newSteps[currentStep.current + 1].status = 'current';
    }
    setSteps(newSteps);
    currentStep.current += 1;
  }

  function handleFinalStep(savedFormData: object) {
    setFormData((prevValues) => ({ ...prevValues, ...savedFormData }));
    currentStep.current += 1;
  }

  useEffect(() => {
    if (currentStep.current === steps.length - 1) {
      const createUser = async () => {
        const userResponse = await registerUser.mutateAsync(formData);
        // console.log(userResponse);
        if (userResponse.hasOwnProperty('error')) {
        } else {
          let newSteps = [...steps];
          newSteps[currentStep.current - 1].status = 'complete';
          if (currentStep.current < steps.length) {
            newSteps[currentStep.current].status = 'current';
          }
          setSteps(newSteps);
        }
      };

      // call the function
      createUser().catch(
        // console.error
      );
    }
  }, [formData]);

  return (
    <Elements stripe={stripe} options={options}>
      <nav aria-label="Progress">
        <ol role="list" className="overflow-visible">
          {steps.map((step, stepIdx) => (
            <li
              key={step.name}
              className={clsx(
                stepIdx !== steps.length - 1 ? 'pb-10' : '',
                'relative'
              )}
            >
              {step.status === 'complete' ? (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div
                      className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-indigo-600"
                      aria-hidden="true"
                    />
                  ) : null}
                  <a
                    href={step.href}
                    className="group relative flex items-start"
                  >
                    <span className="flex h-9 items-center">
                      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                        <CheckIcon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </span>
                    <span className="ml-4 flex min-w-0 flex-col mt-2">
                      <span className="text-sm font-medium dark:text-gray-200">
                        {step.name}
                      </span>
                    </span>
                  </a>
                </>
              ) : step.status === 'current' ? (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div
                      className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                      aria-hidden="true"
                    />
                  ) : null}
                  <a
                    href={step.href}
                    className="group relative flex items-start"
                    aria-current="step"
                  >
                    <span className="flex h-9 items-center" aria-hidden="true">
                      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white">
                        <span className="h-2.5 w-2.5 rounded-full bg-indigo-600" />
                      </span>
                    </span>
                    <div className="ml-4 flex min-w-0 flex-col mt-2 grow">
                      <span className="text-sm font-medium text-indigo-600 dark:text-indigo-100">
                        {step.name}
                      </span>
                      <div className="text-sm text-gray-500 dark:text-gray-200">
                        {step.form}
                      </div>
                    </div>
                  </a>
                </>
              ) : (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div
                      className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                      aria-hidden="true"
                    />
                  ) : null}
                  <a
                    href={step.href}
                    className="group relative flex items-center"
                  >
                    <span className="flex h-9 items-center" aria-hidden="true">
                      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                        <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                      </span>
                    </span>
                    <span className="ml-4 flex min-w-0 flex-col">
                      <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                        {step.name}
                      </span>
                    </span>
                  </a>
                </>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </Elements>
  );
}
