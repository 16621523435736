import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import {
  ariaDescribedByIds,
  examplesId,
  FormContextType,
  GenericObjectType,
  getInputProps,
  RJSFSchema,
  StrictRJSFSchema,
  WidgetProps,
} from '@rjsf/utils';
import clsx from 'clsx';

export function BaseInput<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>({
  schema,
  id,
  options,
  label,
  value,
  type,
  placeholder,
  required,
  disabled,
  readonly,
  autofocus,
  onChange,
  onBlur,
  onFocus,
  rawErrors,
  hideError,
  uiSchema,
  registry,
  formContext,
  hideLabel,
  ...rest
}: WidgetProps<T, S, F>) {
  const handleTextChange = ({
    target: { value: val },
  }: React.ChangeEvent<HTMLInputElement>) => {
    // Use the options.emptyValue if it is specified and newVal is also an empty string
    onChange(val === '' ? options.emptyValue || '' : val);
  };
  const handleBlur = ({
    target: { value: val },
  }: React.FocusEvent<HTMLInputElement>) => onBlur(id, val);
  const handleFocus = ({
    target: { value: val },
  }: React.FocusEvent<HTMLInputElement>) => onFocus(id, val);

  const inputProps = { ...rest, ...getInputProps(schema, type, options) };
  const { readonlyAsDisabled = true } = formContext as GenericObjectType;
  const hasError = rawErrors && rawErrors.length > 0 && !hideError;

  const className = clsx(
    'block w-full rounded-md shadow-sm focus:outline-none sm:text-sm dark:bg-slate-900 dark:text-slate-300',
    !hasError &&
      !disabled &&
      !readonly &&
      'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500',
    hasError &&
      'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500'
  );

  return (
    <div>
      <div className="relative">
        <input
          className={className}
          disabled={disabled || (readonlyAsDisabled && readonly)}
          id={id}
          onBlur={!readonly ? handleBlur : undefined}
          onChange={!readonly ? handleTextChange : undefined}
          onFocus={!readonly ? handleFocus : undefined}
          placeholder={placeholder}
          list={schema.examples ? examplesId<T>(id) : undefined}
          {...inputProps}
          value={value}
          aria-describedby={ariaDescribedByIds<T>(id, !!schema.examples)}
        />
        {hasError ? (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : null}
      </div>
      {Array.isArray(schema.examples) && (
        <datalist id={examplesId<T>(id)}>
          {(schema.examples as string[])
            .concat(
              schema.default && !schema.examples.includes(schema.default)
                ? ([schema.default] as string[])
                : []
            )
            .map((example) => {
              return <option key={example} value={example} />;
            })}
        </datalist>
      )}
    </div>
  );
}
