import { useQuery } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface ObjectJson {
  message: string;
  data: string;
}

export function useObjectsWithAggregations(uuids: string[], containers: string[], includeRelatedObjects: string[]) {
  const { keycloak } = useKeycloak();

  return useQuery(['objects-with-aggregations', uuids, containers], {
    enabled: !!uuids.length && !!containers.length,
    queryFn: async (): Promise<ObjectJson> => {
      const body = {
        uuids: uuids,
        containers: containers,
        includeRelatedObjects: includeRelatedObjects,
        from: 0,
        size: 30,
        cache: false
      };

      const response = await fetch(`${import.meta.env.VITE_IMPORT_ENGINE_ENDPOINT}/api/v1/bundles/query_bundle/functions/get_objects_with_aggregations_v3`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${keycloak.token}`
        },
        method: 'POST',
        body: JSON.stringify(body),
      });

      return response.json();
    }
  });
}
