import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface JobStatusData {
  status: [],
  jobId: string,
  steps: {
    step: string,
    label: string,
    is_current: boolean
  }[]
}
export function useJobStatus() {
  const queryClient = useQueryClient();
  const { keycloak } = useKeycloak();

  return useQuery({
    queryKey: ['jobStatus'],
    enabled: !!queryClient.getQueryData<JobStatusData>(['jobStatus'])?.jobId,
    queryFn: async (): Promise<JobStatusData> => {
      const existingData = queryClient.getQueryData<JobStatusData>(['jobStatus']);

      if (!existingData?.jobId) {
        return {
          status: [],
          jobId: '',
          steps: []
        }
      }

      const response = await fetch(
        `${import.meta.env.VITE_JOB_ENGINE_ENDPOINT}/api/v1/bundles/job_bundle/functions/get_job_status?job_id=${existingData?.jobId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'GET',
        }
      );

      let data = await response.json();

      const { status, job_steps } = data?.data;

      return {
        status: status,
        jobId: existingData?.jobId,
        steps: job_steps
      };
    },
    refetchInterval: 1000
  });
}
