import { Edge, Node } from 'reactflow';

import { ChainEditorWidget } from '@/components/chain-editor-widget';
import { Page } from '@/components/page';
import { LogWidget } from '@/components/log-widget/log-widget';
import { useParams } from 'react-router-dom';
import { useLog } from '@/hooks/use-log';

export function Log() {

  const { uuid } = useParams();
  const { data } = useLog(uuid);

  // console.log(data?.title);
  const title = data?.title;

  return (
    <Page title="Log">
      <LogWidget data={data?.logs ? data.logs : [] } title={title} />
    </Page>
  );
}
