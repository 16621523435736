import { useState, useEffect, SyntheticEvent } from 'react';
import { useListener } from '@/hooks/use-listener';
import { Widget } from '@/components/widget';
import { useObjectChain } from '@/hooks/use-object-chain';
import clsx from 'clsx';
import { CheckCircleIcon, EllipsisHorizontalCircleIcon, PauseCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { TimeFromUnix } from '@/components/time-from-unix';
import { Editor } from '@monaco-editor/react';
import { PlayCircleIcon } from '@heroicons/react/20/solid';
import { useRerunGraphAction } from '@/hooks/use-rerun-graph-action';
import { AuthorisedFunction } from '@/utilities/authorised-function';
import { ComboBox } from '@/components/ui/combo-box';

const WIDGET_ID = 'OBJECT_CHAIN_WIDGET';

function toJson(val: unknown) {
  return JSON.stringify(val, null, 2);
}

const monacoEditorOptions = {
  minimap: {
    enabled: false,
  },
  automaticLayout: true,
  readOnly: true
};

interface Chain {
  value: string;
  label: string;
}

export function ObjectChainWidget() {
  const [uuid, setUuid] = useState<string>("");
  const [container, setContainer] = useState<string>("");
  const [selectedChain, setSelectedChain] = useState<Chain | null>(null);
  const { data } = useObjectChain(uuid, container, selectedChain?.value);
  const { enabled } = useListener({
    id: WIDGET_ID,
    listenerFn: (data) => {
      setUuid(data.map(({ uuid }: { uuid: string }) => uuid)[0]);
      setContainer(data.map(({ container }: { container: string }) => container)[0]);
    },
    type: 'ObjectCollection',
  });
  const [iconHover, setIconHover] = useState<string|null>(null);
  const [iconLoading, setIconLoading] = useState<string|null>(null);
  const rerunGraphAction = useRerunGraphAction();

  const handleRepeatStep = (uuid: string) => {
    // console.log(uuid);
    setIconLoading(uuid);
    rerunGraphAction.mutateAsync({ uuid }).then(() => {
      setIconLoading(null);
    });
  }

  useEffect(() => {
    if (data && data.data && data.data.steps && selectedChain === null) {
      setSelectedChain({ value: data.data.steps[0].chainUuid, label: data.data.steps[0].title });
    }
  }, [data]);

  return (
    <Widget id={WIDGET_ID} title="Running Object Chain">
      <div className="flow-root">
        <p className="mb-4 text-sm text-gray-400">UUID: { uuid }</p>
        {data && data.data && data.data.steps && data.data.steps && (
          <p className="mb-4 text-sm text-gray-400">Chain: {data.data.steps[0].title ?? 'N/A'}</p>
        )}
        <div className="w-80 mb-4">
          <ComboBox
            label="Chain"
            options={[
              { label: "Latest", value: "latest" },
              ...(data?.data?.chains ?? [])
            ]}
            onChange={(value) => setSelectedChain(value)}
            value={selectedChain}
            name="chain"
            wrapText={true}
          />
        </div>
        <ul role="list" className="-mb-8">
          {enabled &&
            data &&
            data.data &&
            data.data.steps &&
            data.data.steps.map((item, i) => (
              <li key={i}>
                <div className="relative pb-8">
              {i !== data.data.length - 1 ? (
                <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span
                    className={clsx(
                      'h-8 w-8 rounded-full flex items-center justify-center'
                    )}
                    onMouseEnter={() => setIconHover(item.uuid)}
                    onMouseLeave={() => setIconHover(null)}
                  >
                    {iconHover === item.uuid && item.bundleFunction && AuthorisedFunction(['developer']) ? (
                      <PlayCircleIcon className="h-8 w-8 text-indigo-500 bg-white hover:cursor-pointer" aria-hidden="true" title="Rerun step" onClick={() => handleRepeatStep(item.uuid)} />
                    ) : iconLoading === item.uuid ? ( 
                      <div className="relative inline-flex items-center justify-center w-6 h-6 overflow-hidden bg-indigo-500 rounded-full">
                        <svg
                          className="animate-spin h-4 w-4 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </div>
                    ) : (
                      <>
                        {item.status === "completed" || item.status === "complete" &&
                          <CheckCircleIcon className="h-8 w-8 text-indigo-500 bg-white" aria-hidden="true" />   
                        }
                        {item.status === "created" &&
                          <PauseCircleIcon className="h-8 w-8 text-indigo-500 bg-white" aria-hidden="true" />   
                        }
                        {item.status === "running" &&
                          <EllipsisHorizontalCircleIcon className="h-8 w-8 text-indigo-500 bg-white" aria-hidden="true" />   
                        }
                        {item.status === "failed" &&
                          <ExclamationCircleIcon className="h-8 w-8 text-indigo-500 bg-white" aria-hidden="true" />   
                        }
                      </>
                    )}
                  </span>
                </div>
                <div className="flex flex-wrap min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                  <div>
                    <p className="text-sm text-gray-500">
                      {item.title}{' with status '}
                      <span className="font-medium text-gray-900">
                        {item.status}
                      </span>
                    </p>
                  </div>
                  <div className="whitespace-nowrap text-right text-sm text-gray-500">
                    <TimeFromUnix unixTimestamp={item.timestamp} />
                  </div>
                  {item.log &&
                    item.log.map((log, x) => (
                      <div key={x} className="w-full mt-3 flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                        <div className="flex justify-between gap-x-4">
                          <div className="py-0.5 text-xs leading-5 text-gray-500">Logged with level <span className="font-medium text-gray-900">{log.level}</span></div>
                          <div className="flex-none py-0.5 text-xs leading-5 text-gray-500"><TimeFromUnix unixTimestamp={log.time} /></div>
                        </div>
                        {typeof log.message === 'string' && (
                          <p className="text-sm leading-6 text-gray-500">{log.message}</p>
                        )}
                        {typeof log.message === 'object' && (
                          <p className="text-sm leading-6 text-gray-500">This log message can not be displayed as it contains an object.</p>
                        )}
                        {log.json && typeof log.json === 'string' && (
                          <Editor
                            value={log.json}
                            language="json"
                            theme="vs-light"
                            height={300}
                            options={monacoEditorOptions}
                          />
                        )}
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
              </li>
            ))
          }
        </ul>
      </div>
    </Widget>
  );
}