import { ReactNode, createContext, useContext } from 'react';
import { createTreeStore } from '@/tree-store';
import { useStore } from 'zustand';
import { TreeState, TreeAction } from '../tree-store';

const TreeContext = createContext<ReturnType<typeof createTreeStore> | null>(
  null
);

const TreeStoreProvider = ({
  children,
  name,
}: {
  children: ReactNode;
  name: string;
}) => {
  const store = createTreeStore(name);

  return <TreeContext.Provider value={store}>{children}</TreeContext.Provider>;
};

const useTreeStore = <T,>(selector: (state: TreeState & TreeAction) => T) => {
  const store = useContext(TreeContext);

  if (store === null) {
    throw new Error('No provider');
  }

  return useStore(store, selector);
};

export { TreeStoreProvider, useTreeStore };
