import { FieldProps } from '@rjsf/utils';
import { ChangeEvent, FocusEvent } from 'react';
import { Handle, Position, useNodeId, useUpdateNodeInternals } from 'reactflow';

export function ConnectionField({
  formData,
  name,
  schema,
  onBlur,
  onChange,
}: FieldProps) {
  const nodeId = useNodeId() as string;
  const updateNodeInternals = useUpdateNodeInternals();

  let hasConnection = false;
  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    onChange({
      event: e.target.value,
      target: formData?.target || '',
    });
    updateNodeInternals(nodeId);
  }

  function handleBlur(e: FocusEvent<HTMLInputElement>) {
    onBlur(name, { event: e.target.value, target: formData?.target || '' });
  }

  return (
    <div className="form-group">
      <label className="control-label">{schema.title}</label>
      <div className="relative">
        <Handle
          id={`event|${formData?.event}`}
          type="source"
          position={Position.Right}
          className="'after:h-5 after:w-5 -right-[5px] after:absolute h-3 w-3 bg-black after:rounded-full after:-translate-x-1/2 after:-translate-y-1/2 after:top-1/2 after:left-1/2'"
        />
        <input
          name={name}
          className="form-control !ring-[#b1b1b7] bg-[#b1b1b7]"
          type="text"
          value={formData?.event || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={hasConnection}
        />
      </div>
    </div>
  );
}
