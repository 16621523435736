import { Editor } from '@monaco-editor/react';
import Form from '@rjsf/core';
import { RJSFSchema, RegistryWidgetsType, UiSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { useState, useEffect } from 'react';

import {
  AddButton,
  ArrayField,
  ArrayFieldItem,
  BaseInput,
  CheckboxWidget,
  ErrorList,
  Field,
  FieldError,
  MoveDownButton,
  MoveUpButton,
  RemoveButton,
  RteWidget,
  SelectWidget,
  SubmitButton,
  TextareaWidget,
  TitleField,
  Wrapper,
} from '@/components/form';
import { useTheme } from '@/hooks/use-theme';

interface FormSchemaEditorProps {
  initialSchema: RJSFSchema;
  initialUiSchema: UiSchema;
  onSchemaChange: (json: object) => void;
  onUiSchemaChange: (json: object) => void;
}

const monacoEditorOptions = {
  minimap: {
    enabled: false,
  },
  automaticLayout: true,
};

const widgets: RegistryWidgetsType = {
  CheckboxWidget,
  SelectWidget,
  TextareaWidget,
  rte: RteWidget,
};

function toJson(val: unknown) {
  return JSON.stringify(val, null, 2);
}

export function FormSchemaEditor({
  initialSchema,
  initialUiSchema,
  onSchemaChange,
  onUiSchemaChange,
}: FormSchemaEditorProps) {
  const [schema, setSchema] = useState(initialSchema);
  const [uiSchema, setUiSchema] = useState(initialUiSchema);
  const { theme } = useTheme('light');

  useEffect(() => {
    setSchema(initialSchema);
    onSchemaChange(initialSchema);
  }, [initialSchema]);

  useEffect(() => {
    setUiSchema(initialUiSchema);
    onUiSchemaChange(initialUiSchema);
  }, [initialUiSchema]);

  function handleSchemaChange(value: string | undefined) {
    try {
      const json = JSON.parse(value ?? '{}');
      setSchema(json);
      onSchemaChange(json);
    } catch (e) {}
  }

  function handleUiSchemaChange(value: string | undefined) {
    try {
      const json = JSON.parse(value ?? '{}');
      setUiSchema(json);
      onUiSchemaChange(json);
    } catch (e) {}
  }

  return (
    <div className="grid grid-cols-2 grid-rows-2 gap-4">
      <div className="col-start-1 border rounded dark:bg-gray-600 dark:border-none">
        <h2 className="px-4 py-3 dark:text-gray-100">JSON Schema</h2>
        <Editor
          value={toJson(schema)}
          language="json"
          theme={theme === 'light' ? 'vs-light' : 'vs-dark'}
          height={300}
          options={monacoEditorOptions}
          onChange={handleSchemaChange}
        />
      </div>
      <div className="col-start-1 row-start-2 border rounded dark:bg-gray-600 dark:border-none">
        <h2 className="px-4 py-3 dark:text-gray-100">UI Schema</h2>
        <Editor
          value={toJson(uiSchema)}
          language="json"
          theme={theme === 'light' ? 'vs-light' : 'vs-dark'}
          height={300}
          options={monacoEditorOptions}
          onChange={handleUiSchemaChange}
        />
      </div>
      <div className="col-start-2 row-start-1 row-span-full">
        <Form
          templates={{
            ArrayFieldTemplate: ArrayField,
            ArrayFieldItemTemplate: ArrayFieldItem,
            BaseInputTemplate: BaseInput,
            ButtonTemplates: {
              AddButton,
              MoveDownButton,
              MoveUpButton,
              RemoveButton,
              SubmitButton,
            },
            ErrorListTemplate: ErrorList,
            FieldTemplate: Field,
            FieldErrorTemplate: FieldError,
            TitleFieldTemplate: TitleField,
            WrapIfAdditionalTemplate: Wrapper,
          }}
          widgets={widgets}
          schema={schema}
          uiSchema={uiSchema}
          validator={validator}
        />
      </div>
    </div>
  );
}
