import { Data, Puck } from '@measured/puck';
import { PuckHeader } from './puck-header';
import { PuckPreview } from './puck-preview';
import { PuckFields } from './puck-fields';
import { useExternalConfig } from '@/features/populate/hooks/use-external-config';
import { useEffect } from 'react';
import { RootNode } from '@/features/populate/hooks/use-load-puck-data';
import '@measured/puck/puck.css';

function save() {}

export interface PuckEditorProps {
  isLoading: boolean;
  onSave: (data: Data, rootNode?: RootNode) => void;
}

export function PuckEditor({ onSave, isLoading }: PuckEditorProps) {
  const config = useExternalConfig();

  if (!config) {
    return null;
  }

  return (
    <Puck
      config={config.editor}
      data={{}}
      onPublish={save}
      overrides={{
        iframe: ({ children, document }) => {
          useEffect(() => {
            if (document) {
              const script = document.createElement('script');
              script.src = 'https://cdn.tailwindcss.com';
              script.async = true;
              document.head.appendChild(script);
            }
          }, [document]);

          return <>{children}</>;
        },
      }}
    >
      <PuckHeader onSave={onSave} isLoading={isLoading} />
      <div className="grid grid-cols-[300px,auto,300px] border border-slate-100">
        <div className="p-4">
          <Puck.Components />
        </div>
        <PuckPreview />
        <div className="p-4">
          <PuckFields uiSchema={config.uiSchema} />
        </div>
      </div>
    </Puck>
  );
}
