import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

import { useTreeStore } from '@/context/tree-context';
import {
  GRAN_FINE,
  calculatePaginationData,
} from '@/hooks/use-node-pagination';

export interface ObjectParams {
  uuid: string;
  container: string;
}

export interface RelationPayload {
  target: ObjectParams & { attribute: string };
  objects: ObjectParams[];
  before?: ObjectParams;
  after?: ObjectParams;
}

export function useRelationInsert(parentNodeId: string) {
  const queryClient = useQueryClient();
  const container = useTreeStore((state) => state.container);
  const nodes = useTreeStore((state) => state.nodes);
  const { keycloak } = useKeycloak();

  return useMutation({
    mutationFn: async (payload: RelationPayload) => {
      const res = await fetch(
        `${
          import.meta.env.VITE_API_URL
        }/bundles/crud_bundle/functions/insert_relation`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'POST',
          body: payload ? JSON.stringify(payload) : '',
        }
      );

      return res.json();
    },
    onSuccess: () => {
      // Handle insertion of node
      const parentNode = nodes.find((node) => node.id === parentNodeId);

      if (!parentNode) {
        return;
      }

      const { from } = calculatePaginationData(
        parentNode.data.paginationData?.[0] || 1,
        parentNode.data.paginationData?.[1] || 1,
        parentNode.data?.total
      );

      if (container === undefined) {
        return;
      }

      const { value } = container;

      const nodeData = {
        id: parentNode.data?.id,
        parentId: parentNode.data?.parentId,
        parentUuid: parentNode.data?.parentUuid,
        label: parentNode.data?.label,
        from,
        size: GRAN_FINE,
        path: parentNode.data?.path,
      };

      queryClient.invalidateQueries({
        queryKey: ['container_objects', value, nodeData],
      });
    },
  });
}
