import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface ChainSteps {
  chainSteps: ChainStep[];
}

type ChainStep = {
  status: string,
  timestamp: number,
  updatedTimestamp: number,
  title: string,
  objectType: string,
  bundleFunction: string,
  contextUuid: string,
  uuid: string
};

interface LatestTimeStamp {
  latestTimestamp: number;
}

interface Refresh {
  refresh: boolean;
}

interface Aggregations {
  aggregations: any;
}

export function useChainStepsLog(timestamp: number|null, status: string|null, bundleFunction: string|null, organisation: string|null, chainUuid: string|null) {
  const queryClient = useQueryClient();
  const { keycloak } = useKeycloak();

  return useQuery(['chain-steps-log'], {
    queryFn: async (): Promise<ChainSteps & LatestTimeStamp & Aggregations & Refresh> => {
      const body = {
        timestamp,
        status,
        bundleFunction,
        organisation,
        chainUuid,
        forceRefetchAggregations: false
      };

      const response = await fetch(`${import.meta.env.VITE_IMPORT_ENGINE_ENDPOINT}/api/v1/bundles/query_bundle/functions/get_running_chain_steps`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${keycloak.token}`
        },
        method: 'POST',
        body: JSON.stringify(body),
      });

      let data = await response.json();

      const { chainSteps, aggregations, refresh } = data?.data;

      const existingData = queryClient.getQueryData<ChainSteps & LatestTimeStamp>(['chain-steps-log']);

      if (existingData) {
        const mergedChainSteps = [
          ...chainSteps,
          ...existingData.chainSteps
        ].slice(0, 250);
        return {
          chainSteps: mergedChainSteps,
          latestTimestamp: mergedChainSteps[0]?.updatedTimestamp,
          aggregations: [],
          refresh
        };
      }

      return {
        chainSteps,
        latestTimestamp: chainSteps[0]?.updatedTimestamp,
        aggregations,
        refresh
      };
    }
  });
}
