import { useQuery } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface AgentRuntimeData {
  status: string;
  log: string;
  error: string;
  attributes: {
    label: string;
    value: number;
  }[];
}

export function useGetAgentRuntime(uuid: string, enabled: boolean) {
  const { keycloak } = useKeycloak();

  return useQuery({
    queryKey: ['agentRuntime', uuid],
    queryFn: async (): Promise<AgentRuntimeData> => {
      const response = await fetch(
        `${
          import.meta.env.VITE_IMPORT_ENGINE_ENDPOINT
        }/api/v1/bundles/generate_bundle/functions/get_agent`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${keycloak.token}`,
          },
          method: 'POST',
          body: JSON.stringify({ uuid }),
        }
      );

      const data = await response.json();

      return data?.data?.runtimeStatus;
    },
    refetchInterval: (data) => {
      if (enabled) {
        return 1000;
      }

      if (data?.status === 'running') {
        return 1000;
      }

      return false;
    },
    enabled,
  });
}
