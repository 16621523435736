import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface DataClassData {
  dataClass: {
    'schema': {
      [key:string]: unknown
    },
    'uiSchema': {
      [key:string]: unknown
    },
  }
}

interface RefineData {
  input: string,
  schema: {},
  uiSchema: {}
}

export function useDataClassRefine() {
  const { keycloak } = useKeycloak();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (refineData: RefineData): Promise<DataClassData> => {

      const response = await fetch(
        `${import.meta.env.VITE_IMPORT_ENGINE_ENDPOINT}/api/v1/bundles/generate_bundle/functions/generate_dataclass_change`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'POST',
          body: JSON.stringify(refineData),
        }
      );

      let data = await response.json();

      const { dataClass } = data?.data;

      return {
        dataClass: dataClass
      };
    }
  });
}
