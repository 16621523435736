import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

export function useObjectFormUpsert() {
  const { keycloak } = useKeycloak();
  const queryClient = useQueryClient();

  /*interface BulkResponse {
    status: string
  }*/

  interface BulkResponse {
    uuid: string;
    lastUpdateTimestamp: string;
  }

  interface ObjectData {
    container: string;
    uuid?: string;
    dataClassUuid: string;
    data: {
      [key: string]: any;
    };
  }

  return useMutation({
    mutationFn: async (objectData: ObjectData): Promise<BulkResponse> => {
      const upsertData = {
        data: {
          container: objectData.container,
          dataClassUuid: objectData.dataClassUuid,
          uuid: objectData.uuid,
          data: objectData.data,
        },
      };

      const response = await fetch(
        `${
          import.meta.env.VITE_CRUD_ENGINE_ENDPOINT
        }/bundles/crud_bundle/functions/upsert`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${keycloak.token}`,
          },
          method: 'POST',
          body: JSON.stringify(upsertData),
        }
      );
      const data = await response.json();

      // console.log("result");
      // console.dir(data);

      const { status } = data;

      /*return {
        status: status
      };*/

      return {
        uuid: data.data.uuid,
        lastUpdateTimestamp: data.data.timestamp,
      };
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([
        'objectForm',
        [variables.uuid],
        [variables.container],
      ]);
    },
  });
}
