import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

export function useJobUpload() {
  const { keycloak } = useKeycloak();

  return useMutation({
    mutationFn: async (jobUploadData: FormData): Promise<object> => {

      const response = await fetch(
        `${import.meta.env.VITE_IMPORT_ENGINE_ENDPOINT}/api/v1/bundles/import_bundle/functions/upload_file`,
        {
          headers: {
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'POST',
          body: jobUploadData,
        }
      );

      const data = await response.json();

      return {
        message: data?.data.message
      };
    },
  });
}