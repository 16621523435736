import { Widget } from '@/components/widget';
import { useRef, useState } from 'react';
import { useListener } from '@/hooks/use-listener';
import { useKeycloak } from '@react-keycloak/web';

const WIDGET_ID = 'CUSTOM_APP_WIDGET';

export function CustomAppWidget() {
  const { keycloak } = useKeycloak();
  const [iframeSrc, setIframeSrc] = useState<string>(
    'https://app1-test1.libelle-hygiene.ch'
  );
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useListener({
    id: WIDGET_ID,
    listenerFn: (data) => {
      iframeRef.current?.contentWindow?.postMessage(
        {
          type: 'dyta-message',
          payload: data,
        },
        '*'
      );

      const searchParams = new URLSearchParams({
        payload: btoa(
          JSON.stringify({
            data,
            sales: {
              user: {
                name: `${keycloak.tokenParsed?.given_name} ${keycloak.tokenParsed?.family_name}`,
              },
            },
          })
        ),
      });
      setIframeSrc(
        `https://app1-test1.libelle-hygiene.ch?${searchParams.toString()}`
      );
    },
    type: 'ObjectCollection',
  });

  return (
    <Widget id={WIDGET_ID} title="Libelle Sales">
      <iframe
        className="w-full h-full flex border-none"
        src={iframeSrc}
        ref={iframeRef}
      ></iframe>
    </Widget>
  );
}
