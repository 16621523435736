import { StackedList } from '@/components/ui/stacked-list';
import { Widget } from '@/components/widget';
import { useListener } from '@/hooks/use-listener';
import { useObjects } from '@/hooks/use-objects';
import { useState } from 'react';
import { useObjectJson } from '@/hooks/use-object-json';

const WIDGET_ID = 'COMPETITION_SHOPS_WIDGET';

export function CompetitionShopsWidget() {

  const [uuids, setUuids] = useState<string[]>([]);
  const [containers, setContainers] = useState<string[]>([]);
  const { data } = useObjects(uuids, containers);
  const { enabled } = useListener({
    id: WIDGET_ID,
    listenerFn: (data) => {
      setUuids(data.map(({ uuid }: { uuid: string }) => uuid));
      setContainers(data.map(({ container }: { container: string }) => container));
    },
    type: 'ObjectCollection',
  });

  const items = data?.data?.resultset[0]?.data?.competitionShops ? data?.data?.resultset[0].data.competitionShops : [] ;

  return (
    <Widget id={WIDGET_ID} title="Competition Market Places">
      {enabled && items ? <StackedList items={items} /> : null}
    </Widget>
  );
}
