import { Widget } from "@/components/widget";
import { useState, useEffect } from "react";
import { LeafletMap } from "@/components/leaflet-map";
import { useListener } from '@/hooks/use-listener';
import { useObjectsWithAggregations } from '@/hooks/use-objects-with-aggregations';
import { useObjectsWithParents } from '@/hooks/use-objects-with-parents';
import { set } from "lodash";

const WIDGET_ID = 'LEAFLET_MAP_WIDGET';


export function LeafletMapWidget() {
  const [uuid, setUuid] = useState<string>("");
  const [container, setContainer] = useState<string>("");
  const { data, isLoading, isFetching } = useObjectsWithAggregations(uuid ? [uuid] : [], container ? [container] : [], []);
  const [geometries, setGeometries] = useState<any[]>([]);
  const [pitId, setPitId] = useState<string>("");
  const [searchAfter, setSearchAfter] = useState<any[]>([]);
  const [includeTotalCount, setIncludeTotalCount] = useState<boolean>(true);
  const [totalItems, setTotalItems] = useState<number>(0);
  const { enabled } = useListener({
    id: WIDGET_ID,
    listenerFn: (data) => {
      setUuid(data.map(({ uuid }: { uuid: string }) => uuid)[0]);
      setContainer(data.map(({ container }: { container: string }) => container)[0]);
      setGeometries([]);
      setPitId("");
      setSearchAfter([]);
      setIncludeTotalCount(true);
      setTotalItems(0);
    },
    type: 'ObjectCollection',
  });
  const { data: childData, isLoading: isChildLoading, isFetching: isChildFetching } = useObjectsWithParents(uuid ? [uuid]: [], container ? [container]: [], pitId, searchAfter, includeTotalCount);

  useEffect(() => {
    if (!childData) {
      return;
    }

    childData.data.hits?.hits?.forEach((hit) => {
      if (hit._source?.data.geometry) {
        setGeometries((prev) => [...prev, hit._source.data.geometry]);
      }
    });

    if (childData.data.count) {
      setTotalItems(childData.data.count);
    }

    setIncludeTotalCount(false);
    setPitId(childData?.data.pit_id);

    const lastItem = childData?.data?.hits?.hits?.[childData.data?.hits?.hits?.length - 1];

    if (lastItem) {
      setSearchAfter(lastItem.sort);
    }
  }, [childData]);
  
  useEffect(() => {
    if (!data) {
      return;
    }

    data.data?.objects?.forEach((object) => {
      if (object.data?.geometry) {
        setGeometries((prev) => [...prev, object.data.geometry]);
      }
    });
  }, [data]);

  return (
    <Widget title={"Leaflet Map"} id={WIDGET_ID}>
      <div className="relative overflow-hidden w-full h-full">
        <LeafletMap geometries={geometries} loading={isFetching || isChildFetching} totalCount={totalItems} />
      </div>
    </Widget>
  )
}