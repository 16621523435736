import GridLayout, { WidthProvider } from 'react-grid-layout';

import { DetailsWidget } from '@/components/details-widget';
import { ExploreWidget } from '@/components/explore-widget';
import { Page } from '@/components/page';
import { Toggle } from '@/components/toggle';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ImportWidget } from '@/components/import-widget';
import { useUserDesktop } from '@/hooks/user/use-user-desktop';
import { JobsWidget } from '@/components/jobs-widget';
import { JobProgressWidget } from '@/components/job-progress-widget';

const AutoWidthGridLayout = WidthProvider(GridLayout);

export function Desktop() {
  const [isDraggable, setIsDraggable] = useState(false);

  const location = useLocation();

  const uuid = location.pathname.split("/")[2];

  const desktop = useUserDesktop(uuid);

  const layout = desktop?.widgets;

  return (
    <Page
      title={desktop?.title ?? "Desktop"}
      toolbar={
        <Toggle
          enabled={isDraggable}
          label="Enable drag"
          onChange={setIsDraggable}
        />
      }
    >
      <AutoWidthGridLayout
        className="layout"
        layout={layout}
        cols={40}
        rowHeight={30}
        isDraggable={isDraggable}
      >
        {layout?.map((widget) => (
        <div key={widget.i}>
          <div className="h-full w-full flex flex-col">
            {widget.widget === "import" &&
              <ImportWidget config={widget.data} />
            }
            {widget.widget === "info" &&
              <DetailsWidget />
            }
            {widget.widget === "jobs" &&
              <JobsWidget />
            }
            {widget.widget === "jobs_progress" &&
              <JobProgressWidget />
            }
          </div>
        </div>
        ))}
      </AutoWidthGridLayout>
    </Page>
  );
}