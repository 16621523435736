import clsx from 'clsx';
import React, { Fragment, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { userNavigation } from '@/config/navigation';
import { useKeycloak } from '@react-keycloak/web';
import { NavLink } from 'react-router-dom';
import { useUserConfig } from '@/hooks/user/use-user-config';

export function UserNavigation() {
  const { keycloak, initialized } = useKeycloak();
  const { data: userConfig } = useUserConfig();

  const handleLogout = (e: any) => {
    e.preventDefault();
    keycloak.logout();
  };

  return (
    <Menu as="div" className="relative">
      <div>
        {keycloak.authenticated && (
          <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <span className="sr-only">Open user menu</span>
            <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-indigo-500 rounded-full">
              <span className="font-medium text-white">
                <>
                  {Array.from(keycloak.tokenParsed?.given_name)[0]}
                  {Array.from(keycloak.tokenParsed?.family_name)[0]}
                </>
              </span>
            </div>
            {/* <span className="rounded-full text-white bg-indigo-500 w-8 h-8 grid items-center font-medium p-1.5">
              <>
                {Array.from(keycloak.tokenParsed?.given_name)[0]}
                {Array.from(keycloak.tokenParsed?.family_name)[0]}
              </>
            </span> */}
          </Menu.Button>
        )}
        {!keycloak.authenticated && initialized && (
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={() => keycloak.login()}
          >
            Login
          </button>
        )}
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-52 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100">
          <div className="px-4 py-3 text-sm text-gray-900">
            <div>
              {keycloak.tokenParsed?.given_name}{' '}
              {keycloak.tokenParsed?.family_name}
            </div>
            <div
              className="font-medium truncate"
              title={userConfig?.organisationName}
            >
              {userConfig?.organisationName}
            </div>
          </div>
          <div>
            {userNavigation.map((item) => (
              <Menu.Item key={item.name}>
                {({ active }) => (
                  <NavLink
                    to={item.href}
                    className={clsx(
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700'
                    )}
                  >
                    {item.name}
                  </NavLink>
                )}
              </Menu.Item>
            ))}
          </div>
          <Menu.Item key={'Sign Out'}>
            {({ active }) => (
              <span
                className={clsx(
                  active ? 'bg-gray-100' : '',
                  'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                )}
                onClick={handleLogout}
              >
                {'Sign Out'}
              </span>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
