import { useNavigate } from 'react-router-dom';
import { usePlans } from '@/hooks/plans/use-plans';
import clsx from 'clsx';
//import { Buffer } from 'buffer/';
//import { Buffer } from 'buffer';
import { useKeycloak } from '@react-keycloak/web';

export function PricingPlans() {
  const navigate = useNavigate();
  const { data } = usePlans();
  const { keycloak, initialized } = useKeycloak();


  const handleClick = (priceId: string) => {
    const encodedPrice = Buffer.from(priceId).toString('base64');
    return navigate(`/purchase-plan/${encodedPrice}`);
  }

  const actionButton = (product) => {
    if (keycloak.hasRealmRole(product.metadata.role_name)) {
      return (
        <button
        disabled={true}
        className={clsx(
          product.metadata.most_popular
            ? 'bg-indigo-500 text-white hover:bg-indigo-600'
            : 'bg-indigo-50 text-indigo-700 hover:bg-indigo-100',
          'mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'
        )}
      >
        Purchased
      </button>
      );
    } else {
      return (
      <button
        onClick={() => handleClick(product.default_price.id)}
        className={clsx(
          product.metadata.most_popular
            ? 'bg-indigo-500 text-white hover:bg-indigo-600'
            : 'bg-indigo-50 text-indigo-700 hover:bg-indigo-100',
          'mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'
        )}
      >
        Subscribe
      </button>
      );
    }
  }

  return (
    <div className="mx-auto max-w-7xl bg-white py-24 px-6 lg:px-8">
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none lg:text-6xl">
        Pricing plans for teams of all sizes
      </h2>
      <p className="mt-6 max-w-2xl text-xl text-gray-500">
        Choose an affordable plan that's packed with the best features for engaging your audience, creating customer
        loyalty, and driving sales.
      </p>

      {/* Tiers */}
      <div className="mt-24 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
        {data?.plans?.map((product) => (
          <div
            key={product.id}
            className="relative flex flex-col rounded-2xl border border-gray-200 bg-white p-8 shadow-sm"
          >
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-gray-900">{product.name}</h3>
              {product.metadata.most_popular ? (
                <p className="absolute top-0 -translate-y-1/2 transform rounded-full bg-indigo-500 py-1.5 px-4 text-sm font-semibold text-white">
                  Most popular
                </p>
              ) : null}
             
              {product.default_price &&
                <>
                  <p className="mt-4 flex items-baseline text-gray-900">
                    <span className="text-5xl font-bold tracking-tight">€{product.default_price.unit_amount/100}</span>
                    <span className="ml-1 text-xl font-semibold">/{product.default_price.recurring.interval}</span>
                  </p>
                </>
              }

              <p className="mt-6 text-gray-500">{product.description}</p> 
            </div>
            {actionButton(product)}
          </div>
        ))}
      </div>
    </div>
  )
}
