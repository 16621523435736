import { useGetComposeContent } from '@/features/populate/hooks/use-get-compose-content';
import { useListener } from '@/hooks/use-listener';
import { usePuck } from '@measured/puck';
import { useEffect, useState } from 'react';

export interface RootNode {
  uuid: string;
  container: string;
}

export function useLoadPuckData(id: string) {
  const { dispatch } = usePuck();
  const [dataNode, setDataNode] = useState<RootNode>({
    uuid: crypto.randomUUID(),
    container: '',
  });
  const { removeMessage } = useListener({
    id,
    listenerFn: (data) => {
      setDataNode({
        uuid: data[0].uuid,
        container: data[0].container,
      });
    },
    type: 'ObjectCollection',
  });
  const { data: composeContentData } = useGetComposeContent(
    dataNode.uuid,
    dataNode.container
  );

  useEffect(() => {
    if (composeContentData) {
      dispatch({
        type: 'setData',
        data: composeContentData.data,
      });
    }
  }, [composeContentData?.data]);

  function reset() {
    setDataNode({ uuid: crypto.randomUUID(), container: '' });
    removeMessage();
    dispatch({
      type: 'setData',
      data: {
        root: {},
        content: [],
        zones: {},
      },
    });
  }

  return { reset, rootNode: dataNode };
}
