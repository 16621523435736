import { RefObject, createContext, useContext, useRef } from 'react';

interface PanelContextProps {
  value: RefObject<HTMLDivElement> | null;
  children: React.ReactNode;
}

const PanelContext = createContext<RefObject<HTMLDivElement> | null>(null);

function PanelProvider(props: Omit<PanelContextProps, 'value'>) {
  const panelRef = useRef<HTMLDivElement>(null);

  return <PanelContext.Provider value={panelRef} {...props} />;
}

function usePanel() {
  const context = useContext(PanelContext);

  if (context === undefined) {
    throw new Error('usePanel must be used within a PanelProvider');
  }

  return context;
}

export { PanelProvider, usePanel };
