import validator from '@rjsf/validator-ajv8';
import {
  FieldTemplateProps,
  RJSFSchema,
  WidgetProps,
  RegistryWidgetsType,
  ObjectFieldTemplateProps,
  ErrorListProps,
  FieldErrorProps,
} from '@rjsf/utils';
import Form from '@rjsf/core';
import { UiSchema } from '@rjsf/utils';
import { useState, useRef } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { useElements } from '@stripe/react-stripe-js';
import { AddressElement } from '@stripe/react-stripe-js';
import { StripeAddressElementChangeEvent } from '@stripe/stripe-js';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';

const companySchema: RJSFSchema = {
  type: 'object',
  // required: ["companyName", "country", "address1", "zipCode", "city"],
  properties: {
    // companyName: {
    //   type: "string",
    //   title: "Company"
    // },
    // country: {
    //   type: "string",
    //   title: "Country",
    //   enum: ["US", "UK", "DE", "AT"],
    //   enumNames: ["United States", "United Kingdom", "Germany", "Austria"],
    //   default: "Germany"
    // },
    taxId: {
      type: 'string',
      title: 'Tax ID',
    },
    // address1: {
    //   type: "string",
    //   title: "Address 1"
    // },
    // address2: {
    //   type: "string",
    //   title: "Address 2"
    // },
    // zipCode: {
    //   type: "string",
    //   title: "ZIP"
    // },
    // city: {
    //   type: "string",
    //   title: "City"
    // }
  },
};

const uiSchema: UiSchema = {
  'ui:submitButtonOptions': {
    props: {
      disabled: false,
      className:
        'mt-6 mr-auto inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-2.5 py-1.5 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
    },
    norender: false,
    submitText: 'Done',
  },
  companyName: {
    'ui:classNames': 'sm:col-span-3',
    'ui:hideError': true,
  },
  country: {
    'ui:classNames': 'sm:col-span-3 sm:col-start-1',
    'ui:hideError': true,
  },
  taxId: {
    'ui:classNames': 'sm:col-span-3 sm:col-start-1',
    'ui:hideError': true,
  },
  address1: {
    'ui:classNames': 'sm:col-span-3 sm:col-start-1',
    'ui:hideError': true,
  },
  address2: {
    'ui:classNames': 'sm:col-span-3 sm:col-start-1',
    'ui:hideError': true,
  },
  zipCode: {
    'ui:classNames': 'sm:col-span-3 sm:col-start-1',
    'ui:hideError': true,
  },
  city: {
    'ui:classNames': 'sm:col-span-3',
    'ui:hideError': true,
  },
};

function transformErrors(errors) {
  return errors.map((error) => {
    // console.log(error);
    if (error.name === 'required') {
      error.message = 'This field is required';
    }
    return error;
  });
}

export function RegisterCompany(props: any) {
  const queryClient = useQueryClient();
  const elements = useElements();

  const [currentSchema] = useState(companySchema);
  const [userAddress, setUserAddress] =
    useState<StripeAddressElementChangeEvent['value']>();

  const handleAddressChange = (event: StripeAddressElementChangeEvent) => {
    if (event.complete) {
      props.handleOrganisationChange(event.value.name);
    }
    setUserAddress(event.value);
  };

  const customValidate = function (formData: any, errors: any, uiSchema: any) {
    const data = queryClient.getQueryData([
      'userOrganisationUnique',
      userAddress?.name,
    ]);

    if (userAddress?.name && data === undefined) {
      if (
        queryClient.getQueryState(['userOrganisationUnique', userAddress?.name])
          ?.status === 'loading'
      ) {
        errors.addError('Organisation is validating, please try again');
      }
    }
    if (data && data?.isUnique !== true) {
      // console.log('block');
      errors.addError('Organisation is already registered');
    }
    // need to wait for data...
    return errors;
  };

  const onSubmit = async ({ formData }: any) => {
    if (!elements) {
      return;
    }

    const addressElement = elements.getElement('address');

    if (!addressElement) {
      return;
    }

    const { complete, value } = await addressElement.getValue();

    if (complete) {
      props.progressNextStep({ ...formData, ...value });
    }
  };

  function CustomFieldTemplate(props: FieldTemplateProps) {
    const {
      id,
      classNames,
      label,
      help,
      required,
      description,
      errors,
      children,
    } = props;
    return (
      <div className={classNames}>
        <label
          className={
            'block text-sm font-medium text-gray-700 dark:text-gray-200'
          }
          htmlFor={id}
        >
          {label}
          {required ? '*' : null}
        </label>
        {description}
        {children}
        {errors}
        {help}
      </div>
    );
  }

  const CustomTextWidget = function (props: WidgetProps) {
    return (
      <div>
        <div className="mt-1 relative">
          <input
            type="text"
            className={clsx(
              'block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:border-gray-600',
              props.rawErrors?.length > 0 ? 'border-red-300' : ''
            )}
            value={props.value ?? ''}
            required={props.required}
            onChange={(event) =>
              props.onChange(
                event.target.value !== '' ? event.target.value : undefined
              )
            }
          />
          {props.rawErrors?.length > 0 && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 ">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
        {props.rawErrors &&
          props.rawErrors.map((error) => (
            <p key={error} className="mt-2 text-sm text-red-600">
              {error}
            </p>
          ))}
      </div>
    );
  };

  const CustomSelectWidget = function (props: WidgetProps) {
    return (
      <div className="mt-1">
        <select
          className={
            'mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm dark:bg-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:border-gray-600'
          }
          value={props.value}
          required={props.required}
          onChange={(event) => props.onChange(event.target.value)}
        >
          {props.options.enumOptions?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  };

  function ObjectFieldTemplate(props: ObjectFieldTemplateProps) {
    return (
      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        {props.properties.map((element) => element.content)}
      </div>
    );
  }

  function FieldErrorTemplate(props: FieldErrorProps) {
    const { errors } = props;
    // console.log(props);
    return (
      <div>
        {errors && (
          <div className="mt-1">
            {errors.map((error, i) => {
              return (
                <div key={i} className="mt-2 text-sm text-red-600">
                  {error}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  const widgets: RegistryWidgetsType = {
    TextWidget: CustomTextWidget,
    SelectWidget: CustomSelectWidget,
  };

  return (
    <div>
      <div className={'mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'}>
        <div className={'sm:col-span-3'}>
          <AddressElement
            options={{
              mode: 'billing',
              autocomplete: {
                mode: 'google_maps_api',
                apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
              },
              display: {
                name: 'organization',
              },
            }}
            onChange={(event) => handleAddressChange(event)}
          />
        </div>
      </div>
      <Form
        schema={currentSchema}
        uiSchema={uiSchema}
        validator={validator}
        customValidate={customValidate}
        templates={{
          FieldTemplate: CustomFieldTemplate,
          ObjectFieldTemplate,
          FieldErrorTemplate,
        }}
        widgets={widgets}
        onSubmit={onSubmit}
        transformErrors={transformErrors}
        showErrorList={false}
        noHtml5Validate
      />
    </div>
  );
}
