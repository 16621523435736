import { DescriptionList } from '@/components/ui/description-list';
import { Widget } from '@/components/widget';
import { useListener } from '@/hooks/use-listener';
import { useObjects } from '@/hooks/use-objects';
import { useState } from 'react';

const WIDGET_ID = 'PRODUCT_DETAILS_WIDGET';

export function ProductDetailsWidget() {
  const [uuids, setUuids] = useState<string[]>([]);
  const [containers, setContainers] = useState<string[]>([]);
  const { data } = useObjects(uuids, containers);
  const { enabled } = useListener({
    id: WIDGET_ID,
    listenerFn: (data) => {
      setUuids(data.map(({ uuid }: { uuid: string }) => uuid));
      setContainers(data.map(({ container }: { container: string }) => container));
    },
    type: 'ObjectCollection',
  });

  const productRightSource = data?.data?.resultset[0]?.data?.ProductData ? data?.data?.resultset[0].data.ProductData : null;
  const productLeftSource = data?.data?.resultset[0]?.data?.ProductData ? data?.data?.resultset[0].data.baseProduct : null;
  const productRightVariantCount = data?.data?.resultset[0]?.data?.object ? data?.data?.resultset[0].data.object.length : 0;
  const userFeatures = data?.data?.resultset[0]?.data?.userFeatures?.userFeatures ? data?.data?.resultset[0]?.data?.userFeatures.userFeatures.join('•') : "" ;
  const items = data?.data?.resultset[0]?.data?.ProductData ? [data?.data?.resultset[0].data.ProductData]?.map((object) => ({

      id: 1,
      name: object.title,
      imageUrl: object.img,
      items: [
        { label: 'Brandklasse', value: object.technical_detail.Brandklasse },
        { label: 'Eigenschaften', value: object.technical_detail.Eigenschaften },
        { label: 'Schutzart IP (EN 60529)', value: object.technical_detail['Schutzart IP (EN 60529)'] },
        { label: 'Temperaturbereich', value: object.technical_detail.Temperaturbereich },
      ]

  })) : [];

  // Transform data to correct shape
  // const [productLeft, productRight] = data?.data?.resultset?.map((object) => ({}));

  
  const productLeft = productLeftSource ? {
    items: [
      { label: 'Description', text: productLeftSource.description },
    ],
    title: productLeftSource.title,
    subtitle: '',
  } : {
    items: [{ label: 'Key A', text: 'Text A' }],
    title: 'A',
    subtitle: 'A',
  };

  const productRight = productRightSource ? {
    items: [
      { label: 'Description', text: productRightSource.description },
      { label: 'Advantages', text: productRightSource.advantages },
      { label: 'Variant Count', text: productRightSource.productRightVariantCount },
      { label: 'User features', text: userFeatures},
      
    ],
    title: productRightSource.title,
    subtitle: productRightSource.title2,
  } : {
    items: [
    ],
    title: '',
    subtitle: '',
  };


  return (
    <Widget id={WIDGET_ID} title="Product Details">
      {enabled && productLeft && productRight ? (
        <div className="grid lg:grid-cols-2 gap-6">
          <DescriptionList {...productLeft} />
          <DescriptionList {...productRight} />
        </div>
      ) : null}
    </Widget>
  );
}
