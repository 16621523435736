import { FieldProps } from '@rjsf/utils';
import { DragDrop } from '@/components/drag-drop';
import { PlusIcon, LinkIcon, TrashIcon } from '@heroicons/react/24/outline';
import { ObjectParams } from '@/hooks/use-relation-insert';

export function RelationField({ formData, onChange, uiSchema }: FieldProps) {
  function handleDrop({ value }: { value: ObjectParams[] }) {
    const newState = { uuid: value[0].uuid, container: value[0].container };
    onChange(newState);
  }

  function handleRemoveClick() {
    const newState = { uuid: '', container: '' };
    onChange(newState);
  }

  const label = uiSchema?.['ui:label'];

  return (
    <div className="space-y-1">
      {label && (
        <label className="block text-md font-bold text-black dark:text-slate-300 mb-1">
          {label}
        </label>
      )}
      <DragDrop
        allowedTypes={['object/insert']}
        onDrop={handleDrop}
        className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-purple px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400 border-dashed justify-center text-center h-5"
        droppingClassName="relative flex items-center space-x-3 rounded-lg border border-gray-300 px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-800 border-dashed bg-purple-50 flex justify-center items-center h-5"
      >
        {({ isDropping }) =>
          isDropping ? (
            <PlusIcon className="h-4 w-4" />
          ) : (
            <PlusIcon className="h-4 w-4" />
          )
        }
      </DragDrop>
      {formData?.uuid && formData?.container ? (
        <div className="relative flex items-center gap-3 rounded-lg border border-gray-300 bg-white py-2 pl-2 pr-3 shadow-sm text-xs">
          <div className="relative w-10 h-10 bg-purple-50 rounded-full flex justify-center items-center text-center">
            <LinkIcon className="h-5 w-5" />
          </div>
          <div className="min-w-0 flex-1">
            <p className="font-medium text-gray-900">{formData.container}</p>
            <p className="text-gray-500">{formData.uuid}</p>
          </div>
          <button
            onClick={handleRemoveClick}
            className="text-gray-400 hover:text-gray-700"
          >
            <TrashIcon className="h-4 w-4" />
          </button>
        </div>
      ) : null}
    </div>
  );
}
