import { useWidgetStore, WidgetID } from '@/widget-store';
import { useEffect } from 'react';

interface UseListenerParams {
  id: WidgetID;
  listenerFn: (data: any) => void;
  type: string;
}

export function useListener({ id, listenerFn, type }: UseListenerParams): {
  enabled: boolean;
  removeMessage: () => void;
} {
  const widget = useWidgetStore((state) => state.widgets[id]);
  const removeMessageFromStore = useWidgetStore((state) => state.removeMessage);
  const enabled = !!(
    widget?.message &&
    widget?.emitters &&
    widget.emitters.includes(widget?.message?.origin || '')
  );

  useEffect(() => {
    if (widget?.message?.type === type) {
      listenerFn(widget?.message?.data);
    }
  }, [widget]);

  function removeMessage() {
    removeMessageFromStore(id);
  }

  return { enabled, removeMessage };
}
