import { useState } from 'react';
import GridLayout, { WidthProvider } from 'react-grid-layout';

import { EditWidget } from '@/components/edit-widget';
import { ExploreWidget } from '@/components/explore-widget';
import { Page } from '@/components/page';
import { Toggle } from '@/components/toggle';
import { GridPanel } from '@/components/grid-panel';
import { CollectionWidget } from '@/components/collection-widget';
import { MediaViewerWidget } from '@/components/media-viewer-widget';
import { DetailsWidget } from '@/components/details-widget';
import { GeolocationWidget } from '@/components/geolocation-widget/geolocation-widget';
import { RadialBarChartWidget } from '@/components/radial-bar-chart-widget';
import { useImageStats } from '@/hooks/stats/use-image-stats';
import { useBulkStats } from '@/hooks/stats/use-bulk-stats';
import { useTRStats } from '@/hooks/stats/use-tr-stats';
import { useTRGoogleFilesStats } from '@/hooks/stats/use-tr-google-files-stats';
import { LineChartWidget } from '@/components/line-chart-widget';
import { ListWidget } from '@/components/list-widget';
import { TreeStoreProvider } from '@/context/tree-context';
import { JobsWidget } from '@/components/jobs-widget';
import { ObjectJsonWidget } from '@/components/object-json-widget';
import { ChartProvider } from '@/components/chart-provider';
import { ChainLogWidget } from '@/components/chain-log-widget';
import { ChainStatsWidget } from '@/components/chain-stats-widget';
import { LeafletMapWidget } from '@/components/leaflet-map-widget';
import { AuthorisedFunction } from '@/utilities/authorised-function';

const layout = [
  { i: 'main', x: 0, y: 0, w: 40, h: 20 },
  { i: 'main_next', x: 0, y: 1, w: 40, h: 20 },
  { i: 'sidebar_left', x: 0, y: 2, w: 40, h: 20 },
  { i: 'sidebar', x: 20, y: 2, w: 20, h: 20 },
  { i: 'footer_top', x: 0, y: 3, w: 40, h: 10 },
  { i: 'footer', x: 0, y: 4, w: 40, h: 10 },
  { i: 'footer_middle', x: 0, y: 5, w: 40, h: 10 },
  { i: 'footer_bottom', x: 0, y: 6, w: 40, h: 10 },
  { i: 'sidebar_1', x: 20, y: 7, w: 20, h: 11 },
  { i: 'sidebar_1_left', x: 0, y: 7, w: 20, h: 11 },
  { i: 'sidebar_2_left', x: 0, y: 8, w: 20, h: 11 },
  { i: 'sidebar_2', x: 20, y: 8, w: 20, h: 11 },
  { i: 'sidebar_3', x: 20, y: 12, w: 20, h: 11 },
  { i: 'sidebar_4', x: 20, y: 12, w: 40, h: 20 },
  { i: 'sidebar_5', x: 0, y: 12, w: 20, h: 11 },
  { i: 'map', x: 0, y: 12, w: 40, h: 20 },
];

const AutoWidthGridLayout = WidthProvider(GridLayout);

export function ExploreDev() {
  const [isDraggable, setIsDraggable] = useState<boolean>(false);
  // const { data: series } = useImageStats();
  // const { data: bulkSeries } = useBulkStats();
  // const { data: trSeries } = useTRStats();

  return (
    <Page
      title="View Object"
      toolbar={
        <Toggle
          enabled={isDraggable}
          label="Enable drag"
          onChange={setIsDraggable}
        />
      }
    >
      <AutoWidthGridLayout
        className="layout"
        layout={layout}
        cols={40}
        rowHeight={30}
        isDraggable={isDraggable}
      >
        <GridPanel key="main">
          <TreeStoreProvider name="explore-a">
            <ExploreWidget />
          </TreeStoreProvider>
        </GridPanel>
        <GridPanel key="main_next">
          <ObjectJsonWidget />
        </GridPanel>
        <GridPanel key="sidebar_left">
          <MediaViewerWidget />
        </GridPanel>
        {/* <GridPanel key="sidebar">
          <EditWidget />
        </GridPanel> */}
        <GridPanel key="footer_top">
          <DetailsWidget />
        </GridPanel>
        <GridPanel key="footer">
          <CollectionWidget />
        </GridPanel>
        {AuthorisedFunction(['developer']) && (
          <GridPanel key="map">
            <LeafletMapWidget />
          </GridPanel>
        )}
        {/* <GridPanel key="footer_middle">
          <JobsWidget title="Downloads" type="download" />
        </GridPanel> */}
        {/* <GridPanel key="footer_bottom">
          <ListWidget />
        </GridPanel> */}
        {/* <GridPanel key="sidebar_1">
          <ChartProvider provider={useImageStats}>
            <RadialBarChartWidget title="Image Statistics" />
          </ChartProvider>
        </GridPanel>
        <GridPanel key="sidebar_1_left">
          <GeolocationWidget />
        </GridPanel> */}
        {/* <GridPanel key="sidebar_2_left">
          <ChartProvider provider={useBulkStats}>
            <LineChartWidget
              title="Bulk Manager Load"
              xAxis={['-5', '-4', '-3', '-2', '-1', 'now']}
            />
          </ChartProvider>
        </GridPanel>
        <GridPanel key="sidebar_2">
          <ChartProvider provider={useTRStats}>
            <RadialBarChartWidget title="TR Statistics" />
          </ChartProvider>
        </GridPanel>
        <GridPanel key="sidebar_3">
          <ChartProvider provider={useTRGoogleFilesStats}>
            <RadialBarChartWidget title="TR Image Files Statistics" />
          </ChartProvider>
        </GridPanel> */}
        {/* <GridPanel key="sidebar_4">
          <ChainLogWidget />
        </GridPanel>
        <GridPanel key="sidebar_5">
          <ChainStatsWidget />
        </GridPanel> */}
      </AutoWidthGridLayout>
    </Page>
  );
}
