import { ComputerDesktopIcon } from '@heroicons/react/20/solid';
import {
  HomeIcon,
  DocumentIcon,
  PhotoIcon,
  DocumentDuplicateIcon,
  QueueListIcon,
  PencilSquareIcon,
  ArrowDownTrayIcon,
  ChartPieIcon,
  ArrowUpTrayIcon,
  EyeIcon,
  PresentationChartLineIcon,
  ShoppingBagIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';

export const navigation = [
  {
    name: 'Dashboard',
    href: '/',
    icon: HomeIcon,
    showLoggedIn: true,
    showLoggedOut: true,
  },
  {
    name: 'Explore & Edit',
    href: '/explore',
    icon: MagnifyingGlassIcon,
    showLoggedIn: true,
    showLoggedOut: false,
  },
  {
    name: 'View Object',
    href: '/view-object',
    icon: EyeIcon,
    showLoggedIn: true,
    showLoggedOut: false,
    permissions: ['developer', 'general.fe.jsonViewer'],
  },
  {
    name: 'Product Performance',
    href: '/product-performance',
    icon: PresentationChartLineIcon,
    showLoggedIn: true,
    showLoggedOut: false,
    permissions: ['product_performance', 'developer'],
  },
  {
    name: 'Import',
    href: '/import',
    icon: ArrowUpTrayIcon,
    showLoggedIn: true,
    showLoggedOut: false,
    permissions: ['developer'],
  },
  {
    name: 'Register',
    href: '/register',
    icon: DocumentIcon,
    showLoggedIn: false,
    showLoggedOut: true,
  },
  {
    name: 'Plans',
    href: '/plans',
    icon: ShoppingBagIcon,
    showLoggedIn: true,
    showLoggedOut: false,
    permissions: ['developer'],
  },
  {
    name: 'Media',
    href: '/media',
    icon: PhotoIcon,
    showLoggedIn: true,
    showLoggedOut: false,
    permissions: ['developer'],
  },
  {
    name: 'Explore Side by Side',
    href: '/drag-drop',
    icon: DocumentDuplicateIcon,
    showLoggedIn: true,
    showLoggedOut: false,
    permissions: ['developer', 'general.fe.edit'],
  },
  {
    name: 'Automate',
    href: '/chain',
    icon: QueueListIcon,
    showLoggedIn: true,
    showLoggedOut: false,
    permissions: ['developer', 'general.fe.automate'],
  },
  {
    name: 'Preview',
    href: '/preview',
    icon: QueueListIcon,
    showLoggedIn: true,
    showLoggedOut: false,
    permissions: ['developer'],
  },
  {
    name: 'Schemas',
    href: '/schemas',
    icon: PencilSquareIcon,
    showLoggedIn: true,
    showLoggedOut: false,
    permissions: ['developer', 'general.fe.schemas'],
  },
  {
    name: 'Comp Analysis',
    href: '/comp-analysis',
    icon: ComputerDesktopIcon,
    showLoggedIn: true,
    showLoggedOut: false,
    permissions: ['developer'],
  },
  {
    name: 'Monitor',
    href: '/monitor',
    icon: ComputerDesktopIcon,
    showLoggedIn: true,
    showLoggedOut: false,
    permissions: ['developer', 'general.fe.monitorAutomation'],
  },
  {
    name: 'Downloads',
    href: '/downloads',
    icon: ArrowDownTrayIcon,
    showLoggedIn: true,
    showLoggedOut: false,
    permissions: ['developer', 'general.fe.edit'],
  },
  {
    name: 'TRUMPF metrics',
    href: '/trumpf-metrics',
    icon: ChartPieIcon,
    showLoggedIn: true,
    showLoggedOut: false,
    permissions: ['developer', 'custom.trumpf.fe.metrics'],
  },
  {
    name: 'Onlineshopzugang',
    href: '/libelle-onlineshop',
    icon: ComputerDesktopIcon,
    showLoggedIn: true,
    showLoggedOut: false,
    permissions: ['developer'],
  },
  {
    name: 'Compose Content',
    href: '/compose-content',
    icon: ComputerDesktopIcon,
    showLoggedIn: true,
    showLoggedOut: false,
    permissions: ['populate'],
  },
  {
    name: 'Agents',
    href: '/agents/create',
    icon: ComputerDesktopIcon,
    showLoggedIn: true,
    showLoggedOut: false,
    permissions: ['agentsStudio'],
  },
];

export const userNavigation = [
  // { name: 'Your Profile', href: '/profile' },
  // { name: 'Your Plans', href: '/your-plans' },
  // { name: 'Invoices', href: '/invoices' },
  // { name: 'Settings', href: '#' },
];
