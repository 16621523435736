import Form, { IChangeEvent } from '@rjsf/core';
import validator from '@rjsf/validator-ajv8';
import { useState } from 'react';

import { Option, SelectMenu } from '@/components/select-menu';

export interface Action {
  title: string;
  uuid: string;
  form: Record<string, unknown>;
  event: string;
  dataClassUuids: string[];
}

interface ActionsFormProps {
  actions: Action[];
  onActionRun: ({
    action,
    formData,
    event
  }: {
    action: Option;
    formData: IChangeEvent;
    event: string;
  }) => void;
}

export function ActionsForm({ actions, onActionRun }: ActionsFormProps) {
  const [action, setAction] = useState<Option>();

  const selectedAction = action && actions.find((a) => a.uuid === action.value);
  const form = selectedAction?.form;
  const event = selectedAction?.event;

  const options = actions.map(({ title, uuid }) => ({
    label: title,
    value: uuid
  }));

  function handleSubmit({ formData }: IChangeEvent) {
    action &&
    event &&
      onActionRun({
        action,
        formData,
        event
      });
  }

  return (
    <div className="flex items-end space-x-4">
      <div className="min-w-[200px]">
        <SelectMenu
          label="Action"
          onChange={setAction}
          options={options}
          selected={action}
        />
      </div>

      {form ? (
        <Form schema={form} validator={validator} onSubmit={handleSubmit}>
          <button className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
            Run
          </button>
        </Form>
      ) : null}
    </div>
  );
}
