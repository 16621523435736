import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';
import { options } from '../config/map';

interface BulkResponse {
  uuid: string;
  lastUpdateTimestamp: string;
}

export interface ObjectData {
  uuid?: string;
  schema: any;
  uiSchema: any;
  title?: string;
}

export function useObjectSchemaUpsert() {
  const { keycloak } = useKeycloak();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (objectData: ObjectData): Promise<BulkResponse> => {
      const response = await fetch(
        `${
          import.meta.env.VITE_CRUD_ENGINE_ENDPOINT
        }/bundles/crud_bundle/functions/upsert_schema`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${keycloak.token}`,
          },
          method: 'POST',
          body: JSON.stringify({
            data: objectData,
          }),
        }
      );

      const data = await response.json();

      return {
        uuid: data.data.uuid,
        lastUpdateTimestamp: data.data.timestamp,
      };
    },
    onSuccess: () => {
      // Do the invalidation 1 second after the mutation
      setTimeout(() => {
        queryClient.invalidateQueries(['userDataclasses']);
      }, 1000);
    },
  });
}
