import { useState } from 'react';
import GridLayout, { WidthProvider } from 'react-grid-layout';

import { Page } from '@/components/page';
import { Toggle } from '@/components/toggle';
import { GridPanel } from '@/components/grid-panel';
import { MediaViewerWidget } from '@/components/media-viewer-widget';

const layout = [{ i: 'main', x: 0, y: 0, w: 30, h: 20 }];

const AutoWidthGridLayout = WidthProvider(GridLayout);

export function Media() {
  const [isDraggable, setIsDraggable] = useState<boolean>(false);

  return (
    <Page
      title="Media"
      toolbar={
        <Toggle
          enabled={isDraggable}
          label="Enable drag"
          onChange={setIsDraggable}
        />
      }
    >
      <AutoWidthGridLayout
        className="layout"
        layout={layout}
        cols={40}
        rowHeight={30}
        isDraggable={isDraggable}
      >
        <GridPanel key="main">
          <MediaViewerWidget />
        </GridPanel>
      </AutoWidthGridLayout>
    </Page>
  );
}
