import { LinkIcon } from '@heroicons/react/24/outline';
import { XCircleIcon } from '@heroicons/react/20/solid';

import { useWidgetStore, WidgetID } from '@/widget-store';

import { Badge } from '@/components/badge';
import { Button } from '@/components/button';
import { DragDrop } from '@/components/drag-drop';
import { useEffect, useState } from 'react';
import { FullscreenToggle } from '@/components/fullscreen-toggle';
import clsx from 'clsx';

interface WidgetProps {
  id: WidgetID;
  children: React.ReactNode;
  title?: string;
  toolbar?: React.ReactNode;
  scrollable?: boolean;
  connectable?: boolean;
}

export function Widget({
  id,
  children,
  title,
  toolbar,
  scrollable = true,
  connectable = false,
}: WidgetProps) {
  const registerWidget = useWidgetStore((state) => state.registerWidget);
  const connect = useWidgetStore((state) => state.connect);
  const removeConnection = useWidgetStore((state) => state.removeConnection);
  const widget = useWidgetStore((state) => state.widgets[id]);
  const [isFullscreen, setFullscreen] = useState(false);

  useEffect(() => {
    registerWidget(id);
  }, []);

  if (!widget) {
    return null;
  }

  function handleDrop(emitterId: string) {
    connect(emitterId, id);
  }

  function handleRemoveClick(emitterId: string) {
    removeConnection(emitterId, id);
  }

  function handleFullscreenToggle() {
    setFullscreen((prev) => !prev);
  }

  const className = clsx(
    'divide-y divide-gray-200 rounded-lg bg-white dark:bg-gray-900 dark:divide-none shadow flex-1 flex flex-col min-w-0',
    isFullscreen && 'fixed inset-0 z-50',
    scrollable && 'overflow-hidden'
  );

  const contentClassName = clsx(
    'px-4 py-5 sm:p-6 flex-1',
    scrollable ? 'overflow-y-auto overflow-x-hidden' : ''
  );

  return (
    <div className={className}>
      {toolbar ? (
        <div className="px-4 py-5 sm:px-6">
          <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
            <h2 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-50">
              {title}
            </h2>
            <div className="flex-shrink-0 flex gap-2 items-center">
              {connectable ? (
                <DragDrop
                  allowedTypes={['link']}
                  onDrop={(data) => handleDrop(data.value.widgetId)}
                  key="drag-drop"
                  className="flex items-center"
                >
                  {() => {
                    if (widget?.emitters.length === 0) {
                      return (
                        <Button>
                          <LinkIcon className="h-4 w-4" aria-hidden="true" />
                        </Button>
                      );
                    }

                    return widget?.emitters.map((emitter) => (
                      <Badge key={emitter} className="my-[0.3125rem]">
                        Connected to: {emitter}
                        <button
                          title="Remove connection"
                          className="-mr-2.5 ml-1 w-5 h-5 inline-flex items-center justify-center rounded-full text-green-800 hover:text-red-600 transition-colors"
                          onClick={() => {
                            handleRemoveClick(emitter);
                          }}
                        >
                          <XCircleIcon className="w-5 h-5" />
                        </button>
                      </Badge>
                    ));
                  }}
                </DragDrop>
              ) : null}
              {toolbar}
              <FullscreenToggle onClick={handleFullscreenToggle} />
            </div>
          </div>
        </div>
      ) : (
        <div className="px-4 py-5 sm:px-6">
          <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
            <h2 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-50">
              {title}
            </h2>
            <div className="flex-shrink-0 flex gap-2">
              <DragDrop
                allowedTypes={['link']}
                onDrop={(data) => handleDrop(data.value.widgetId)}
                key="drag-drop"
              >
                {() => {
                  if (widget?.emitters.length === 0) {
                    return (
                      <Button>
                        <LinkIcon className="h-4 w-4" aria-hidden="true" />
                      </Button>
                    );
                  }

                  return widget?.emitters.map((emitter) => (
                    <Badge key={emitter} className="my-[0.3125rem]">
                      Connected to: {emitter}
                      <button
                        title="Remove connection"
                        className="-mr-2.5 ml-1 w-5 h-5 inline-flex items-center justify-center rounded-full text-green-800 hover:text-red-600 transition-colors"
                        onClick={() => {
                          handleRemoveClick(emitter);
                        }}
                      >
                        <XCircleIcon className="w-5 h-5" />
                      </button>
                    </Badge>
                  ));
                }}
              </DragDrop>
              <FullscreenToggle onClick={handleFullscreenToggle} />
            </div>
          </div>
        </div>
      )}
      <div className={contentClassName}>{children}</div>
    </div>
  );
}
