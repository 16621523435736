import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import '@/widget-store';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from '@/keycloak';
import { AppRouter } from '@/components/app-router/app-router';
import * as Sentry from '@sentry/react';
import { FullscreenProvider } from './context/fullscreen-context';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        import.meta.env.VITE_JOB_ENGINE_ENDPOINT,
        import.meta.env.VITE_IMPORT_ENGINE_ENDPOINT,
        import.meta.env.VITE_USER_ENGINE_ENDPOINT,
        import.meta.env.VITE_DOWNLOAD_ENGINE_ENDPOINT,
      ],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

export function App() {
  return (
    <ReactKeycloakProvider authClient={keycloak}>
      <QueryClientProvider client={queryClient}>
        <FullscreenProvider>
          <Router>
            <AppRouter />
          </Router>
        </FullscreenProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ReactKeycloakProvider>
  );
}
