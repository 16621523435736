import { useListener } from '@/hooks/use-listener';
import { useObjects } from '@/hooks/use-objects';
import { useActionTrigger } from '@/hooks/use-action-trigger';
import { Widget } from '@/components/widget';
import { useState, useRef, useEffect } from 'react';

// Import the editor styles
import '@pqina/pintura/pintura.css';

// Import the editor default configuration
import { getEditorDefaults, PinturaDefaultImageWriterResult } from '@pqina/pintura';

// Import the editor component from `react-pintura`
import { PinturaEditor } from '@pqina/react-pintura';

// get default properties
const editorConfig = getEditorDefaults();

const WIDGET_ID = 'IMAGE_EDITOR_WIDGET';

export function ImageEditorWidget() {
  const [uuids, setUuids] = useState<string[]>([]);
  const [containers, setContainers] = useState<string[]>([]);
  const [imageUrl, setImageUrl] = useState<string>();
  const { data } = useObjects(uuids, containers);
  const editorRef = useRef(null);
  const actionTrigger = useActionTrigger();
  const { enabled } = useListener({
    id: WIDGET_ID,
    listenerFn: (data) => {
      setUuids(data.map(({ uuid }: { uuid: string }) => uuid));
      setContainers(data.map(({ container }: { container: string }) => container));
    },
    type: 'ObjectCollection',
  });

  useEffect(() => {
    if (!data?.data?.resultset) {
      return;
    }

    let imageUrl = data.data.resultset[0].data.publicUrl;

    if (!imageUrl) {
      return;
    }

    setImageUrl(imageUrl.replace("https://storage.googleapis.com", "https://imagefetch.testserver123.de"));
  }, [data?.data?.resultset]);

  const handleEditorProcess = (imageWriterResult: PinturaDefaultImageWriterResult) => {
    if (!data) {
      return;
    }
  
    const payload = {
      event: "image.edited",
      triggeringNodes: uuids.map((uuid) => ( { uuid: uuid, container: containers[0], root: false } )),
      configuration: imageWriterResult.imageState
    };

    // console.log(payload);

    actionTrigger.mutateAsync(payload);
  };

  return (
    <Widget id={WIDGET_ID} title="Image Editor">
      {enabled && (
        <PinturaEditor
          ref={editorRef}
          src={imageUrl}
          {...editorConfig}
          onProcess={handleEditorProcess}
          cropSelectPresetFilter={'landscape'}
          cropSelectPresetOptions={[
              [undefined, 'Custom'],
              [1, 'Square'],

              // shown when cropSelectPresetFilter is set to 'landscape'
              [2 / 1, '2:1'],
              [3 / 2, '3:2'],
              [4 / 3, '4:3'],
              [16 / 10, '16:10'],
              [16 / 9, '16:9'],

              // shown when cropSelectPresetFilter is set to 'portrait'
              [1 / 2, '1:2'],
              [2 / 3, '2:3'],
              [3 / 4, '3:4'],
              [10 / 16, '10:16'],
              [9 / 16, '9:16'],
          ]}
        ></PinturaEditor>
      )}
    </Widget>
  );
}
