import { ComparisonWidget } from '@/components/comparison-widget';
import { GridPanel } from '@/components/grid-panel';
import { Page } from '@/components/page';
import { ProductComparisonWidget } from '@/components/product-comparison-widget';
import { ProductDetailsWidget } from '@/components/product-details-widget';
import { StackedListWidget } from '@/components/stacked-list-widget';
import { StatsWidget } from '@/components/stats-widget';
import { Toggle } from '@/components/toggle';
import { useState } from 'react';
import GridLayout, { WidthProvider } from 'react-grid-layout';

const layout = [
  { i: 'main_1', x: 0, y: 0, w: 40, h: 8 },
  { i: 'main_2', x: 0, y: 0, w: 40, h: 17 },
  { i: 'main_3', x: 0, y: 0, w: 40, h: 20 },
  { i: 'main_4', x: 0, y: 0, w: 40, h: 30 },
  { i: 'main_5', x: 0, y: 0, w: 40, h: 10 },
];

const AutoWidthGridLayout = WidthProvider(GridLayout);

export function CompAnalysis() {
  const [isDraggable, setIsDraggable] = useState<boolean>(false);

  return (
    <Page
      title="CompAnalysis"
      toolbar={
        <Toggle
          enabled={isDraggable}
          label="Enable drag"
          onChange={setIsDraggable}
        />
      }
    >
      <AutoWidthGridLayout
        className="layout"
        layout={layout}
        cols={40}
        rowHeight={30}
        isDraggable={isDraggable}
      >
        <GridPanel key="main_1">
          <StatsWidget />
        </GridPanel>
        <GridPanel key="main_2">
          <StackedListWidget />
        </GridPanel>
        <GridPanel key="main_3">
          <ProductDetailsWidget />
        </GridPanel>
        <GridPanel key="main_4">
          <ComparisonWidget />
        </GridPanel>
        <GridPanel key="main_5">
          <ProductComparisonWidget />
        </GridPanel>
      </AutoWidthGridLayout>
    </Page>
  );
}
