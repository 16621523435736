import {
  FullscreenContextType,
  useFullscreen,
} from '@/context/fullscreen-context';
import clsx from 'clsx';
import {
  CSSProperties,
  forwardRef,
  MouseEventHandler,
  ReactNode,
  TouchEventHandler,
} from 'react';

interface GridPanelProps {
  children: ReactNode;
  className?: string;
  onMouseDown?: MouseEventHandler;
  onMouseUp?: MouseEventHandler;
  onTouchEnd?: TouchEventHandler;
  style?: CSSProperties;
}

type Ref = HTMLDivElement;

export const GridPanel = forwardRef<Ref, GridPanelProps>(
  ({ children, className, onMouseDown, onMouseUp, onTouchEnd, style }, ref) => {
    const { isFullscreen } = useFullscreen() as FullscreenContextType;

    return (
      <div
        className={clsx(
          'h-full w-full flex flex-col',
          className,
          isFullscreen && '!transform-none !transition-none'
        )}
        ref={ref}
        style={style}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchEnd={onTouchEnd}
      >
        {children}
      </div>
    );
  }
);
