import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface GenerateData {
  formData: {
    [key:string]: unknown;
  };
  action: string;
}

interface GenerateResponseData {
  formData: {
    [key:string]: unknown;
  }
}

export function useGenerateRefine() {
  const { keycloak } = useKeycloak();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (generateData: GenerateData): Promise<GenerateResponseData> => {

      const response = await fetch(
        `${import.meta.env.VITE_IMPORT_ENGINE_ENDPOINT}/api/v1/bundles/generate_bundle/functions/generate_refined_data`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'POST',
          body: JSON.stringify(generateData),
        }
      );

      const data = await response.json();

      // console.log(data);

      return {
        formData: data?.data.formData
      };
    },
  });
}