import { useState } from 'react';
import GridLayout, { WidthProvider } from 'react-grid-layout';

import { Page } from '@/components/page';
import { Toggle } from '@/components/toggle';
import { GridPanel } from '@/components/grid-panel';
import { useBulkStats } from '@/hooks/stats/use-bulk-stats';
import { LineChartWidget } from '@/components/line-chart-widget';
import { ChartProvider } from '@/components/chart-provider';
import { ChainLogWidget } from '@/components/chain-log-widget';
import { ChainStatsWidget } from '@/components/chain-stats-widget';

const layout = [
  { i: 'sidebar_1', x: 0, y: 0, w: 20, h: 11 },
  { i: 'sidebar_2', x: 20, y: 0, w: 20, h: 11 },
  { i: 'main', x: 0, y: 1, w: 40, h: 25 },
];

const AutoWidthGridLayout = WidthProvider(GridLayout);

export function Monitor() {
  const [isDraggable, setIsDraggable] = useState<boolean>(false);

  return (
    <Page
      title="Monitor"
      toolbar={
        <Toggle
          enabled={isDraggable}
          label="Enable drag"
          onChange={setIsDraggable}
        />
      }
    >
      <AutoWidthGridLayout
        className="layout"
        layout={layout}
        cols={40}
        rowHeight={30}
        isDraggable={isDraggable}
      >
        <GridPanel key="sidebar_1">
          <ChartProvider provider={useBulkStats}>
            <LineChartWidget
              title="Bulk Manager Load"
              xAxis={['-5', '-4', '-3', '-2', '-1', 'now']}
            />
          </ChartProvider>
        </GridPanel>
        <GridPanel key="sidebar_2">
          <ChainStatsWidget />
        </GridPanel>
        <GridPanel key="main">
          <ChainLogWidget />
        </GridPanel>
      </AutoWidthGridLayout>
    </Page>
  );
}
