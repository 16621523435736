import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface UserDataClassesData {
  dataClasses: {
    'value': string,
    'label': string,
    'container': string,
  }[]
}

export function useUserDataclasses() {
  const queryClient = useQueryClient();
  const { keycloak } = useKeycloak();

  return useQuery({
    queryKey: ['userDataclasses'],
    queryFn: async (): Promise<UserDataClassesData> => {
      const response = await fetch(
        `${import.meta.env.VITE_USER_ENGINE_ENDPOINT}/api/v1/bundles/user_bundle/functions/get_user_dataclasses`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'GET',
        }
      );

      let data = await response.json();

      // console.log("dataclassses1");
      // console.dir(data);

      const { dataClasses } = data?.data

      // console.log("returning:");
      // console.dir(dataClasses);

      return {
        dataClasses: dataClasses
      };
    },
  });
}
