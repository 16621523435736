import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface UserEmailUniqueData {
  isUnique: boolean
}

export function useUserEmailUnqiue(email: string) {
  const queryClient = useQueryClient();
  const { keycloak } = useKeycloak();

  return useQuery({
    enabled: !!email,
    queryKey: ['userEmailUnique', email],
    queryFn: async (): Promise<UserEmailUniqueData> => {
      const response = await fetch(
        `${import.meta.env.VITE_USER_ENGINE_ENDPOINT}/api/v1/bundles/user_bundle/functions/get_user_email_unique?email=${email}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'GET',
        }
      );

      let data = await response.json();

      const { is_unique } = data?.data;

      return {
        isUnique: is_unique ? is_unique : false
      };
    }
  });
}