import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface UserChainData {
  chains: {
    'label': string,
    'value': string
  }[]
}

export function useUserChains() {
  const queryClient = useQueryClient();
  const { keycloak } = useKeycloak();

  return useQuery({
    queryKey: ['userChains'],
    queryFn: async (): Promise<UserChainData> => {
      const response = await fetch(
        `${import.meta.env.VITE_USER_ENGINE_ENDPOINT}/api/v1/bundles/user_bundle/functions/get_user_chains`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'GET',
        }
      );

      let data = await response.json();

      const { chains } = data?.data

      return {
        chains: chains,
      };
    },
  });
}
