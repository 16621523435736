import { create, Mutate, StoreApi } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export type WidgetID = string;

export interface Message {
  type: string;
  data: Record<string, unknown>[];
  origin?: WidgetID;
}

interface Widget {
  message?: Message;
  emitters: WidgetID[];
  listeners: WidgetID[];
}

interface WidgetState {
  widgets: {
    [key: WidgetID]: Widget;
  };
}

interface WidgetAction {
  registerWidget: (id: WidgetID) => void;
  publish: (id: WidgetID, message: Message) => void;
  removeMessage: (id: WidgetID) => void;
  connect: (emitterId: WidgetID, listenerId: WidgetID) => void;
  removeConnection: (emitterId: WidgetID, listenerId: WidgetID) => void;
}

type StoreWithPersist = Mutate<
  StoreApi<WidgetAction & WidgetState>,
  [['zustand/persist', WidgetAction & WidgetState], ['zustand/devtools', never]]
>;

const withStorageDOMEvents = (store: StoreWithPersist) => {
  const storageEventCallback = (e: StorageEvent) => {
    if (e.key === store.persist.getOptions().name && e.newValue) {
      store.persist.rehydrate();
    }
  };

  window.addEventListener('storage', storageEventCallback);

  return () => {
    window.removeEventListener('storage', storageEventCallback);
  };
};

export const useWidgetStore = create<WidgetState & WidgetAction>()(
  devtools(
    persist(
      (set) => ({
        widgets: {},
        registerWidget: (id: WidgetID) =>
          set((state) => {
            // Register widget only if it doesn't exist in the store yet
            if (state.widgets[id]) {
              return state;
            }

            return {
              widgets: {
                ...state.widgets,
                [id]: { message: null, emitters: [], listeners: [] },
              },
            };
          }),
        publish: (id: WidgetID, message: Message) =>
          set((state) => {
            // Do not publish a message if there are no connected widgets or the widget isn't registered
            if (
              !state.widgets[id] ||
              state.widgets?.[id]?.emitters?.length === 0
            ) {
              return state;
            }

            const widget = {
              ...state.widgets[id],
              message,
            };

            return {
              widgets: {
                ...state.widgets,
                [id]: { ...widget },
              },
            };
          }),
        removeMessage: (id: WidgetID) =>
          set((state) => {
            const widget = {
              ...state.widgets[id],
              message: null,
            };

            return {
              widgets: { ...state.widgets, [id]: widget },
            };
          }),
        connect: (emitterId: WidgetID, listenerId: WidgetID) =>
          set((state) => {
            // Connection is already established, change nothing
            if (state.widgets[listenerId].emitters.includes(emitterId)) {
              return state;
            }

            const listenerWidget = {
              ...state.widgets[listenerId],
              emitters: [
                ...(state.widgets[listenerId]?.emitters || []),
                emitterId,
              ],
            };

            const emitterWidget = {
              ...state.widgets[emitterId],
              listeners: [
                ...(state.widgets[emitterId]?.listeners || []),
                listenerId,
              ],
            };

            return {
              widgets: {
                ...state.widgets,
                [listenerId]: { ...listenerWidget },
                [emitterId]: { ...emitterWidget },
              },
            };
          }),
        removeConnection: (emitterId: WidgetID, listenerId: WidgetID) =>
          set((state) => {
            // Connection is not found, change nothing
            if (!state.widgets[listenerId].emitters.includes(emitterId)) {
              return state;
            }

            const listenerWidget = {
              ...state.widgets[listenerId],
              message: null,
              emitters: state.widgets[listenerId].emitters.filter(
                (emitter) => emitter !== emitterId
              ),
            };

            const emitterWidget = {
              ...state.widgets[emitterId],
              listeners: state.widgets[emitterId].listeners.filter(
                (listener) => listener !== listenerId
              ),
            };

            return {
              widgets: {
                ...state.widgets,
                [listenerId]: { ...listenerWidget },
                [emitterId]: { ...emitterWidget },
              },
            };
          }),
      }),
      {
        name: 'widget-store',
      }
    )
  )
);

withStorageDOMEvents(useWidgetStore);
