import { createContext, useContext, useState } from 'react';

interface FullscreenProviderProps {
  children: React.ReactNode;
}

export interface FullscreenContextType {
  isFullscreen: boolean;
  toggleFullscreen: () => void;
}

const FullscreenContext = createContext<FullscreenContextType | null>(null);

function FullscreenProvider(props: FullscreenProviderProps) {
  const [isFullscreen, setIsFullscreen] = useState(false);

  function toggleFullscreen() {
    setIsFullscreen((prev) => !prev);
  }

  return (
    <FullscreenContext.Provider
      value={{ isFullscreen, toggleFullscreen }}
      {...props}
    />
  );
}

function useFullscreen() {
  const context = useContext(FullscreenContext);

  if (context === undefined) {
    throw new Error('useFullscreen must be used within a FullscreenProvider');
  }

  return context;
}

export { FullscreenProvider, useFullscreen };
