import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface JobData {
  job: {
    job_id: string
  }
}


export function useJobCreate() {
  const { keycloak } = useKeycloak();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (jobData: object): Promise<JobData> => {

      const response = await fetch(
        `${import.meta.env.VITE_JOB_ENGINE_ENDPOINT}/api/v1/bundles/job_bundle/functions/create_job`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'POST',
          body: JSON.stringify(jobData),
        }
      );

      const data = await response.json();

      return {
        job: data?.data
      };
    },
    onSuccess: (newJob) => {
      queryClient.setQueryData(['jobStatus'], {jobId: newJob.job.job_id})
      queryClient.invalidateQueries(['jobStatus']);
    },
  });
}