import { useQuery } from '@tanstack/react-query';
import { Edge, Node } from 'reactflow';
import { useChainStore } from '@/context/chain-context';
import { useKeycloak } from '@react-keycloak/web';

interface ChainData {
  edges: Edge[];
  edgesPositioning: Edge[];
  nodes: Node[];
}

export function useChain(chainUuid: string, contextUuid: string) {
  const initialize = useChainStore((state) => state.initialize);
  const { keycloak } = useKeycloak();

  return useQuery(['chain', chainUuid, contextUuid], {
    enabled: !!chainUuid || !!contextUuid,
    queryFn: async (): Promise<ChainData> => {
      const body = {
        chainUuid: chainUuid,
        contextUuid: contextUuid,
      };
      const response = await fetch(
        `${
          import.meta.env.VITE_API_URL
        }/bundles/graph_bundle/functions/get_chain_v2`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'POST',
          body: JSON.stringify(body),
        }
      );

      const { data } = await response.json();

      const nodes: Node[] =
        data.nodes.map((node, i) => ({
          ...node,
          position: { x: 0, y: 0 }, // @todo Why was x set to -400?
        })) ?? [];

      const edges: Edge[] =
        data.edges.map((edge) => ({
          ...edge,
          style: {
            ...edge.style,
            transition: 'stroke-width 100ms', // Add default transition style for edges
          },
          type: 'relation',
        })) ?? [];

      initialize(nodes, edges, data.edgesPositioning);

      return {
        nodes,
        edges,
        edgesPositioning: data.edgesPositioning,
      };
    },
  });
}
