import {
  errorId,
  FieldErrorProps,
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema,
} from '@rjsf/utils';

export function FieldError<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>({ errors = [], idSchema }: FieldErrorProps<T, S, F>) {
  if (errors.length === 0) {
    return null;
  }
  const id = errorId<T>(idSchema);

  return (
    <div>
      <ul id={id} className="mt-1 text-sm text-red-600">
        {errors
          .filter((elem) => !!elem)
          .map((error, index: number) => {
            return <li key={index}>{error}</li>;
          })}
      </ul>
    </div>
  );
}
