import { Fragment, useState } from 'react';

import { useListener } from '@/hooks/use-listener';
import { useObjects } from '@/hooks/use-objects';

import { Widget } from '@/components/widget';

const WIDGET_ID = 'DETAILS_WIDGET';

export function DetailsWidget() {
  const [uuids, setUuids] = useState<string[]>([]);
  const [containers, setContainers] = useState<string[]>([]);
  const { data } = useObjects(uuids, containers);
  const { enabled } = useListener({
    id: WIDGET_ID,
    listenerFn: (data) => {
      setUuids(data.map(({ uuid }: { uuid: string }) => uuid));
      setContainers(data.map(({ container }: { container: string }) => container));
    },
    type: 'ObjectCollection',
  });

  return (
    <Widget id={WIDGET_ID} title="Object">
      {enabled &&
        data?.data?.resultset.map((object, i) => (
          <dl key={i}>
            {Object.keys(object.data).map((key) => {
              if (typeof object.data[key] === 'object') {
                return null;
              }

              return (
                <Fragment key={key}>
                  <dt className="font-bold">{key}:</dt>
                  <dd
                    className="mb-5"
                    dangerouslySetInnerHTML={{
                      __html: object.data[key],
                    }}
                  />
                </Fragment>
              );
            })}
          </dl>
        ))}
    </Widget>
  );
}
