import React, { useEffect, useRef } from 'react';
import { Fragment, useState } from 'react';

import { useListener } from '@/hooks/use-listener';
import { useObjects } from '@/hooks/use-objects';

import { Widget } from '@/components/widget';

const WIDGET_ID = 'DETAILS_WIDGET';

export function MagicPreviewWidget() {
  const [uuids, setUuids] = useState<string[]>([]);
  const [containers, setContainers] = useState<string[]>([]);
  const { data } = useObjects(uuids, containers);
  const { enabled } = useListener({
    id: WIDGET_ID,
    listenerFn: (data) => {
      setUuids(data.map(({ uuid }: { uuid: string }) => uuid));
      setContainers(data.map(({ container }: { container: string }) => container));
    },
    type: 'ObjectCollection',
  });

  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (!iframe) {
      return;
    }

    const iframeWindow = iframe.contentWindow;
    if (!iframeWindow) {
      return;
    }

    //ar iframe = document.getElementById("app-frame-id");
    iframe.contentWindow.addEventListener("DOMContentLoaded", onFrameDOMContentLoaded, true);
    function onFrameDOMContentLoaded () { 
        // console.log("DOMContentLoaded");
        const iframeDocument = iframeWindow.document;

      const scriptElement = iframeDocument.createElement('script');
      scriptElement.text = `
        window.addEventListener('message', function(event) {
          // Extract data from the message
          var data = event.data;

          for (item in data) {
            var targetElement = document.querySelector(data[item].querySelector);
            if (data[item].type == 'innerText') {
              targetElement.innerText = data[item].content;
            }
            if (data[item].type == 'setAttribute') {
              var targetElementAll = document.querySelectorAll(data[item].querySelector);
              // console.log("hello");
              // console.dir(targetElementAll);
              for (tE in targetElementAll) {
                // console.dir(targetElementAll[tE]);
                targetElementAll[tE].setAttribute('data-srcset', data[item].content);
              }
            }
          }

        });
      `;

      iframeDocument.body.appendChild(scriptElement);

      // Post a message to the iframe
      
      const data = [
        {
          querySelector: '#tabOverview > div.row > div.gr-10.push-1 > div.gr-12.u-clearfix.u-marginBottom-30 > ux-same-height.row > div.gr-6.ux-u-margin-bottom-25 > div.ux-u-margin-bottom-10 > span.ux-text-title.ux-text-title--green-square.ux-text-title--bold',
          content: 'New USP title',
          type: "innerText",
        },
        {
          querySelector: '#tabOverview > div.row > div.gr-10.push-1 > div.gr-10.push-1.u-textCenter.u-marginBottom-60.u-headline-inModule > h3.headline-small',
          content: 'I am a custom title',
          type: "innerText",
        },
        {
          querySelector: '#tabCustomerBenefits > div.row > div.gr-5.push-1.content-wrapper > div.block > div.gr-12 > div.text-normal > p',
          content: '<p>Lorem Ipsum</p><p>Lorem Ipsum2</p>',
          type: "innerText",
        },
        {
          querySelector: '#product-detail-page > ux-iws-slider-slide-zoomable > div.block.block-hero.block-hero-subpage.no-gutter > div.block-image > picture > source',
          content: 'https://static6.depositphotos.com/1014550/624/i/600/depositphotos_6240474-stock-photo-test-word-on-keyboard.jpg',
          type: "setAttribute",
        }

        
      ];
      iframeWindow.postMessage(data, '*');
    };

    // Wait until the iframe is loaded
    /*
    iframe.onload = function () {
      const iframeDocument = iframeWindow.document;

      const scriptElement = iframeDocument.createElement('script');
      scriptElement.text = `
        window.addEventListener('message', function(event) {
          // Extract data from the message
          var data = event.data;

          for (item in data) {
            var targetElement = document.querySelector(data[item].querySelector);
            if (data[item].type == 'innerText') {
              targetElement.innerText = data[item].content;
            }
            if (data[item].type == 'setAttribute') {
              targetElement.setAttribute('data-srcset', data[item].content);
            }
          }

        });
      `;

      iframeDocument.body.appendChild(scriptElement);

      // Post a message to the iframe
      
      const data = [
        {
          querySelector: '#tabOverview > div.row > div.gr-10.push-1 > div.gr-12.u-clearfix.u-marginBottom-30 > ux-same-height.row > div.gr-6.ux-u-margin-bottom-25 > div.ux-u-margin-bottom-10 > span.ux-text-title.ux-text-title--green-square.ux-text-title--bold',
          content: 'New USP title',
          type: "innerText",
        },
        {
          querySelector: '#tabOverview > div.row > div.gr-10.push-1 > div.gr-10.push-1.u-textCenter.u-marginBottom-60.u-headline-inModule > h3.headline-small',
          content: 'I am a custom title',
          type: "innerText",
        },
        {
          querySelector: '#tabCustomerBenefits > div.row > div.gr-5.push-1.content-wrapper > div.block > div.gr-12 > div.text-normal > p',
          content: '<p>Lorem Ipsum</p><p>Lorem Ipsum2</p>',
          type: "innerText",
        },
        {
          querySelector: '#product-detail-page > ux-iws-slider-slide-zoomable > div.block.block-hero.block-hero-subpage.no-gutter > div.block-image > picture > source',
          content: 'https://static6.depositphotos.com/1014550/624/i/600/depositphotos_6240474-stock-photo-test-word-on-keyboard.jpg',
          type: "setAttribute",
        }

        
      ];
      iframeWindow.postMessage(data, '*');
    };
    */
  }, []);
  


  return (
    <Widget id={WIDGET_ID} title="Object">
      <iframe ref={iframeRef} width="100%" height="100%" src="http://81.169.173.229:8080/iframetest/de_AT/produkte/elektrowerkzeuge/schlitzscheren/trutool-c-160-mit-spanabtrenner-lihd-12v/" />
    </Widget>
  );
}

