import L from 'leaflet';

L.Draw.Locate = L.Draw.Feature.extend({
	statics: {
		TYPE: 'locate'
	},

	options: {
		icon: new L.Icon.Default(),
		repeatMode: false,
		zIndexOffset: 2000 // This should be > than the highest z-index any markers
	},

	// @method initialize(): void
	initialize: function (map, options) {
		// Save the type so super can fire, need to do this as cannot do this.TYPE :(
		this.type = L.Draw.Locate.TYPE;

		this._initialLabelText = L.drawLocal.draw.handlers.marker.tooltip.start;

		L.Draw.Feature.prototype.initialize.call(this, map, options);
	},

	// @method addHooks(): void
	// Add listener hooks to this handler.
	addHooks: function () {
		L.Draw.Feature.prototype.addHooks.call(this);

		if (this._map) {
			this._tooltip.updateContent({text: this._initialLabelText});

      this._map.on('draw:drawstart', this._onDrawStart, this);
		}
	},

	// @method removeHooks(): void
	// Remove listener hooks from this handler.
	removeHooks: function () {
		L.Draw.Feature.prototype.removeHooks.call(this);

		if (this._map) {
      this._map.off('draw:start', this._onDrawStart, this);
		}
	},

  _onDrawStart: function (e) {
    console.log('draw start');
    if (this._map) {
      console.log('we have a map.. 445546sds', e);
      console.log('layerType', e.layerType, this.type);
      if (e.layerType === this.type) {
        console.log('we have a match');
        // this._map.setView(e.latlng, 15);
        this._map.locate({setView: true, maxZoom: 15}).on('locationfound', this._onLocationFound, this);
      }
    }
    this.disable();
  },

  _onLocationFound: function (e) {
    if (this._map) {
      var radius = e.accuracy / 2;
      L.marker(e.latlng).addTo(this._map);
      L.circle(e.latlng, { radius }).addTo(this._map);
    }
  },
});


export default L.Draw.Locate;
