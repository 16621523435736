export interface PageProps {
  title: string;
  children?: React.ReactNode;
  toolbar?: React.ReactNode;
}

export function Page({ children, title, toolbar }: PageProps) {
  return (
    <div className="py-6">
      <div className="mx-auto px-4 sm:px-6 md:px-8 py-4 sm:py-6 md:py-8">
        <div className="md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <h1 className="text-2xl font-bold leading-7 text-gray-900 dark:text-gray-100 sm:truncate sm:text-3xl sm:tracking-tight">
              {title}
            </h1>
          </div>
          {toolbar ? (
            <div className="mt-4 flex md:mt-0 md:ml-4">{toolbar}</div>
          ) : null}
        </div>
      </div>
      <div className="mx-auto px-4 sm:px-6 md:px-8">{children}</div>
    </div>
  );
}
