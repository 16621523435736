import { useEffect, useRef, useState } from 'react';
import { Widget } from '@/components/widget';
import { useQuery, useMutation } from "react-query";
import { saveAs } from 'file-saver';
import { ArrowUpTrayIcon } from '@heroicons/react/20/solid';
import { useJobCreate } from '@/hooks/jobs/use-job-create';
import { useJobUpload } from '@/hooks/jobs/use-job-upload';
import clsx from 'clsx';
import { upload } from '@testing-library/user-event/dist/types/utility';

const WIDGET_ID = 'IMPORT_WIDGET';

interface ImportProps {
  config?: {
    allowed_file_types?: {
      type: string,
      label: string
    }[]
  };
}

export function ImportWidget({ config }: ImportProps) {
  // ref
  const inputRef = useRef(null);
  const inputTargetRef = useRef(null);

  const [dragActive, setDragActive] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [currentDownloadJobId, setCurrentDownloadJobId] = useState(null);
  const [currentJobId, setCurrentJobId] = useState('');

  const createJob = useJobCreate();
  const uploadFile = useJobUpload();

  const [allowedFileTypeLabels, setAllowedFileTypeLabels] = useState<string[]>();
  const [allowedFileTypes, setAllowedFileTypes] = useState<string[]>();
  const [isValidFileTypes, setIsValidFileTypes] = useState(true);

  useEffect(() => {
    const labels = config?.allowed_file_types?.map((fileType) => fileType.label);
    setAllowedFileTypeLabels(labels);

    const types = config?.allowed_file_types?.map((fileType) => fileType.type);
    setAllowedFileTypes(types);
  }, [config]);
  // const { mutateAsync: postUploadFile } = useMutation(vars => {
  //     return ImportService.postUploadFile(vars.formData, vars.uploadProgress);
  //   }
  // );

  // const { mutateAsync: postCreateJob } = useMutation(body => {
  //     return JobsService.postCreateJob(body);
  //   }
  // );

  // const { mutateAsync: patchUpdateJobStatus } = useMutation(body => {
  //    return JobsService.patchUpdateJobStatus(body);
  //   }
  // );

  // useEffect(()=> {
  //   let fetchJobProgressInterval: any;
  //   if (currentJobId !== null) {
  //     fetchJobProgressInterval = setInterval(() => {
  //       // dispatch(fetchJobProgress({}));
  //     }, 2000);
  //   }

  //   return () => {
  //     // Anything in here is fired on component unmount.
  //     clearInterval(fetchJobProgressInterval);
  //   }
  // }, [currentJobId]);

  // const { data: jobStatus } = useQuery(["status"],
  //   () => {
  //   return JobsService.getJobStatus({'job_id': currentJobId});
  //   }, {
  //     refetchInterval: 1000,
  //     enabled: currentJobId == null,
  //   }
  // );

  // const { data: downloadJobStatus } = useQuery(["downloadStatus"],
  //   () => {
  //   return JobsService.getJobStatus({'job_id': currentDownloadJobId});
  //   }, {
  //     refetchInterval: 1000,
  //     enabled: currentDownloadJobId !== null,
  //   }
  // );

  // const { mutateAsync: postDownloadFile } = useMutation(body => {
  //     return DownloadService.postDownloadFile(body);
  //   }
  // );

  // const { mutateAsync: postDownloadZip } = useMutation(body => {
  //     return DownloadService.postDownloadZip(body);
  //   }
  // );

  // useEffect(() => {
  //   let overallProgress = 0;
  //   let currentProgress = 0;
  //   const labels = {
  //     'upload_file': 'Upload File',
  //     'validate_import': 'Validate Import',
  //     'object_identification': 'Object Identification',
  //     'file_object_transformation': 'File Object Transformation',
  //     'import_data': 'Import Data'
  //   }
  //   if (jobStatus) {
  //     const stepCount = jobStatus.job_steps.length
  //     jobStatus.job_steps.forEach(step => {
  //       const status = jobStatus['status'][step]
  //       if (status) {
  //         const progress = (status.processed / status.total) / stepCount
  //         overallProgress += progress
  //       }
  //       if (jobStatus.current_job_step == step) {
  //         if (status) {
  //           currentProgress = (status.processed / status.total) * 100;
  //         }
  //       }
  //     })
  //   }
  // }, [jobStatus]);

  // useEffect(() => {
  //   async function getDownloadStatus() {
  //     if (downloadJobStatus) {
  //       if (downloadJobStatus.zip_download_path) {
  //         const postDownloadZipResponse = await postDownloadZip({
  //           "zipPath": downloadJobStatus.zip_download_path,
  //         });
  //         saveAs(postDownloadZipResponse.data, currentDownloadJobId + ".zip");
  //         setCurrentDownloadJobId(null);
  //       }
  //     }
  //   }

  //   getDownloadStatus();

  // }, [downloadJobStatus]);

  // handle drag events
  const handleDrag = function(e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const files = e.dataTransfer.files;
    uploadFiles(files);
    // setSeries([0, 0])
    // dispatch....
    // dispatch(clearCurrentJob({}));

    // dispatch(createJob(
    //   {
    //     "type": "import",
    //     "status": {
    //       "upload_file": {
    //         "processed": 0,
    //         "total": files.length
    //       }
    //     },
    //     "config": config ?? []
    //   }
    // )).then(onfulfilled => {
    //   // dispatch action to upload files...
    //   if (files && files[0]) {
    //     setFileList([
    //       ...files,
    //       ...fileList
    //     ]);

    //     [...files].map((file) => {
    //       dispatch(
    //         uploadFile(
    //           {
    //             file: file,
    //             jobId: onfulfilled.payload.currentJobId
    //           }
    //         )
    //       );
    //     })
    //   }
    // })
  };

  const uploadFiles = async (files: FileList) => {

    setIsValidFileTypes(true);

    if (allowedFileTypes && allowedFileTypes.length > 0) {
      [...files].forEach((file) => {
        // console.log(file);
        if (!allowedFileTypes.includes(file.type)) {
          setIsValidFileTypes(false);
        }
      });
    }

    if (!isValidFileTypes) {
      // console.log('file type is not allowed...');
      return;
    }

    const createJobResponse = await createJob.mutateAsync(
      {
        "type": "import",
        "status": {
          "upload_file": {
            "processed": 0,
            "total": files.length
          }
        },
        "config": config ?? []
      }
    );

    setCurrentJobId(createJobResponse.job.job_id);

    if (files && files[0]) {
      setFileList([
        ...files,
        ...fileList
      ]);

      [...files].map((file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('jobId', createJobResponse.job.job_id);
        uploadFile.mutateAsync(formData);
      });
    }
  }
  
  // triggers when file is selected with click
  const handleChange = function(e) {
    e.preventDefault();
    // console.log(e.target.files);
    uploadFiles(e.target.files);
  };

  // const handleDownload = async (e) => {
  //   const createDownloadJobResponse = await postCreateJob({
  //     "type": "download",
  //     "status": {
  //       "initialise_download": {
  //         "processed": 0,
  //         "total": 1
  //       }
  //     }
  //   });

  //   const jobId = createDownloadJobResponse.job_id;

  //   setCurrentDownloadJobId(jobId);

  //   const postDownloadFileResponse = await postDownloadFile({
  //     "jobId": jobId,
  //     "downloadFiles": ["uploads/hJStRoLcqprFnuHAJVGpSAm9jpxLHwiWIb4WXukB.jpg"],
  //   });

  //   return;
  // }

  return (
    <Widget title="Upload Files" id={WIDGET_ID}>
      <form id="upload-form" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
        {/* <select ref={inputTargetRef}>
          <option>Select a Target</option>
          <option value="test">Test</option>
        </select> */}
        <div className="flex items-center justify-center w-full" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}>
          <label htmlFor="dropzone-file" className={clsx("flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer dark:border-gray-500", dragActive ? " bg-gray-100 dark:bg-gray-700" : " bg-gray-50 hover:bg-gray-100 dark:bg-gray-600 dark:hover:bg-gray-700")}>
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <ArrowUpTrayIcon className="h-8 w-8 text-gray-500 mb-4" />
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Drag and drop</span> or click to upload</p>
              <p className="text-xs text-gray-500 dark:text-gray-400">{ allowedFileTypeLabels?.join(", ") }</p>
            </div>
            <input id="dropzone-file" type="file" className="hidden" accept={allowedFileTypes?.join(", ")} multiple onChange={(e) => handleChange(e)} />
          </label>
        </div> 
        {/* <button onClick={handleDownload}>Download Test</button> */}
      </form>
      {!isValidFileTypes &&
        <div className="mt-4 p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300" role="alert">
          <span className="font-medium">Warning!</span> Only the following file types are allowed: { allowedFileTypeLabels?.join(", ") }
        </div>
      }
    </Widget>
  );
}
