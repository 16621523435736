import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  OnEdgesChange,
  OnNodesChange,
} from 'reactflow';
import { create } from 'zustand';
import { getLayoutedNodes } from './hooks/use-auto-chain-layout';

export interface ChainState {
  nodes: Node[];
  edges: Edge[];
  edgesPositioning: Edge[];
  prevEdgesPositioning: Edge[] | null;
}

export interface ChainAction {
  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;
  onEventConnect: (connection: Connection, formData: any) => void;
  onConnect: (connection: Connection, formData: any) => void;
  initialize: (nodes: Node[], edges: Edge[], edgesPositioning: Edge[]) => void;
  addData: (nodes: Node[], edges: Edge[], edgesPositioning: Edge[]) => void;
  addNodes: (nodes: Node[]) => void;
  deleteNode: (nodeId: string) => void;
  toggleEnabled: (nodeId: string) => void;
  toggleNode: (nodeId: string) => void;
  toggleNeighbourNodes: (nodeId: string) => void;
  addEdges: (edges: Edge[]) => void;
  addEdgesPositioning: (edges: Edge[]) => void;
  replaceEdges: (edges: Edge[]) => void;
  toggleEdge: (edgeId: string) => void;
  updateNodeFormData: (
    nodeId: string,
    sectionType: string,
    formData: any
  ) => void;
  removeRelation: (edgeId: string, sectionType: string, formData: any) => void;
  removeConnection: (
    edgeId: string,
    sectionType: string,
    formData: any
  ) => void;
}

export const createChainStore = () =>
  create<ChainState & ChainAction>((set, get) => ({
    nodes: [],
    edges: [],
    edgesPositioning: [],
    prevEdgesPositioning: null,
    initialize: (nodes: Node[], edges: Edge[], edgesPositioning: Edge[]) => {
      set({
        nodes,
        edges,
        edgesPositioning,
      });
    },
    addData: (nodes: Node[], edges: Edge[], edgesPositioning: Edge[]) => {
      set((state) => ({
        nodes: state.nodes.concat(nodes),
        edges: state.edges.concat(edges),
        edgesPositioning: state.edgesPositioning.concat(edgesPositioning),
      }));
    },
    addNodes: (nodes: Node[]) => {
      set((state) => ({
        nodes: state.nodes.concat(nodes),
      }));
    },
    deleteNode: (nodeId: string) => {
      set({
        nodes: get().nodes.filter((node) => node.id !== nodeId),
        edges: get().edges.filter(
          (edge) => edge.source !== nodeId && edge.target !== nodeId
        ),
      });
    },
    toggleEnabled: (nodeId: string) => {
      set({
        nodes: get().nodes.map((node) => {
          if (node.id === nodeId) {
            return {
              ...node,
              data: {
                ...node.data,
                enabled: !node.data?.enabled,
              },
            };
          }

          return node;
        }),
      });
    },
    toggleNode: (nodeId: string) => {
      set({
        nodes: get().nodes.map((node) => {
          if (node.id === nodeId) {
            return {
              ...node,
              data: {
                ...node.data,
                open: !node.data?.open,
              },
            };
          }

          return node;
        }),
      });
    },
    toggleNeighbourNodes: (nodeId: string) => {
      const targetNeighbourNodeIds = get()
        .edges.filter((edge) => edge.source === nodeId)
        .map((edge) => edge.target);
      const sourceNeighbourNodeIds = get()
        .edges.filter((edge) => edge.target === nodeId)
        .map((edge) => edge.source);

      const nodeIds = new Set([
        ...targetNeighbourNodeIds,
        ...sourceNeighbourNodeIds,
        nodeId,
      ]);

      let hasChanges = false;
      let collapsed = false;

      const newNodes = get().nodes.map((node) => {
        if (node.id === nodeId) {
          // Set collapsed on clicked node
          collapsed = !node.data?.collapsed;

          return {
            ...node,
            data: {
              ...node.data,
              collapsed: !node.data?.collapsed,
            },
          };
        }

        if (!nodeIds.has(node.id)) {
          hasChanges = true;

          return {
            ...node,
            hidden: !node.hidden,
          };
        } else {
          return {
            ...node,
            data: {
              ...node.data,
              parentCollapsed: !node.data?.parentCollapsed,
            },
          };
        }
      });

      if (!hasChanges) {
        return;
      }

      // Save the previous edges positioning if the node is collapsed
      const prevEdgesPositioning = collapsed ? get().edgesPositioning : null;
      const visibleEdges = get()
        .edges.filter(
          (edge) => edge.source === nodeId || edge.target === nodeId
        )
        .map((edge) => {
          // Flip source and target if the node is the target
          if (edge.target === nodeId) {
            return {
              ...edge,
              source: edge.target,
              sourceHandle: edge.targetHandle,
              target: edge.source,
              targetHandle: edge.sourceHandle,
            };
          }

          return edge;
        });

      set({
        nodes: getLayoutedNodes(
          newNodes,
          collapsed ? visibleEdges : get().prevEdgesPositioning || []
        ),
        edges: get().edges.map((edge) => {
          if (edge.source !== nodeId && edge.target !== nodeId) {
            return {
              ...edge,
              hidden: !edge.hidden,
            };
          }

          return edge;
        }),
        edgesPositioning: collapsed
          ? visibleEdges
          : get().prevEdgesPositioning || [],
        prevEdgesPositioning,
      });
    },
    addEdges: (edges: Edge[]) => {
      set((state) => ({
        edges: state.edges.concat(edges),
      }));
    },
    replaceEdges: (edges: Edge[]) => {
      set((state) => ({
        edges: [...edges],
      }));
    },
    toggleEdge: (edgeId: string) => {
      set({
        edges: get().edges.map((edge) => {
          if (edge.id === edgeId) {
            // Make stroke width 5 times bigger on mouse over
            const newStrokeWidth =
              !edge.style?.strokeWidth || edge.style.strokeWidth === 1 ? 5 : 1;

            const newEdge = {
              ...edge,
              style: {
                ...edge.style,
                strokeWidth: newStrokeWidth,
              },
              data: {
                ...edge.data,
                visible: !edge.data?.visible,
              },
            };

            return newEdge;
          }

          return edge;
        }),
      });
    },
    addEdgesPositioning: (edges: Edge[]) => {
      set((state) => ({
        edgesPositioning: state.edgesPositioning.concat(edges),
      }));
    },
    onNodesChange: (changes: NodeChange[]) => {
      set({
        nodes: applyNodeChanges(changes, get().nodes),
      });
    },
    onEdgesChange: (changes: EdgeChange[]) => {
      set({
        edges: applyEdgeChanges(changes, get().edges),
      });
    },
    onEventConnect: (connection: Connection, formData: any) => {
      const edgeParams = {
        ...connection,
        type: 'relation',
      };

      set({
        edges: addEdge(edgeParams, get().edges),
        nodes: get().nodes.map((node) => {
          if (node.id === connection.source) {
            const nodeData = node.data;
            nodeData.sections = nodeData.sections.map((section: any) => {
              if (!!section?.schema?.properties?.eventsOut) {
                return {
                  ...section,
                  formData,
                };
              }

              return section;
            });

            node.data = { ...node.data, ...nodeData };
          }

          return node;
        }),
      });
    },
    onConnect: (connection: Connection, formData: any) => {
      const [type] = (connection.sourceHandle as string).split('|');

      const edgeParams = {
        ...connection,
        type: 'relation',
        style: {
          stroke:
            type === 'value' || type === 'value_v2' ? 'orange' : '#2f6af4',
        },
      };

      set({
        edges: addEdge(edgeParams, get().edges),
        nodes: get().nodes.map((node) => {
          if (node.id === connection.target) {
            const nodeData = node.data;
            nodeData.sections = nodeData.sections.map((section: any) => {
              if (section.type === 'abstract_handles') {
                return {
                  ...section,
                  formData,
                };
              }

              return section;
            });

            node.data = { ...node.data, ...nodeData };
          }

          return node;
        }),
      });
    },
    updateNodeFormData: (
      nodeId: string,
      sectionType: string,
      formData: any
    ) => {
      set({
        nodes: get().nodes.map((node) => {
          if (nodeId === node.id) {
            const nodeData = node.data;
            nodeData.sections = nodeData.sections.map((section: any) => {
              if (section.type === sectionType) {
                return {
                  ...section,
                  formData,
                };
              }

              return section;
            });

            node.data = { ...node.data, ...nodeData };
          }

          return node;
        }),
      });
    },
    removeRelation: (edgeId: string, sectionType, formData) => {
      const edgeToBeRemoved = get().edges.find((edge) => edge.id === edgeId);

      if (!edgeToBeRemoved) {
        return;
      }

      set({
        edges: get().edges.filter((edge) => edge.id !== edgeId),
        nodes: get().nodes.map((node) => {
          if (node.id === edgeToBeRemoved.target) {
            const nodeData = node.data;
            nodeData.sections = nodeData.sections.map((section: any) => {
              if (section.type === sectionType) {
                return {
                  ...section,
                  formData,
                };
              }

              return section;
            });

            node.data = { ...node.data, ...nodeData };
          }

          return node;
        }),
      });
    },
    removeConnection: (edgeId: string, sectionType, formData) => {
      const edgeToBeRemoved = get().edges.find((edge) => edge.id === edgeId);

      if (!edgeToBeRemoved) {
        return;
      }

      set({
        edges: get().edges.filter((edge) => edge.id !== edgeId),
        nodes: get().nodes.map((node) => {
          if (node.id === edgeToBeRemoved.source) {
            const nodeData = node.data;
            nodeData.sections = nodeData.sections.map((section: any) => {
              if (section.type === sectionType) {
                return {
                  ...section,
                  formData,
                };
              }

              return section;
            });

            node.data = { ...node.data, ...nodeData };
          }

          return node;
        }),
      });
    },
  }));
