import { useMutation } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';
import { useRef } from 'react';

interface TestAgentData {
  inputObject?: string;
  sourceExample?: {
    uuid: string;
    container: string;
  };
  agentUuid2: string;
}

interface TestAgentResponse {
  output: string;
  log: string[];
  error: string;
}

export function useTestAgent() {
  const { keycloak } = useKeycloak();
  const abortController = useRef<AbortController | null>(null);

  const mutation = useMutation({
    mutationFn: async (testData: TestAgentData): Promise<TestAgentResponse> => {
      abortController.current = new AbortController();

      const body = {
        data: {
          uuid: 'test_agent',
          abstractUuid: 'test_agent',
          chainUuid: 'test_agent',
          title: 'test_agent',
          type: 'object',
          objectType: 'bundleFunction',
          bundleFunction: 'ai.test_agent',
          abstract: {
            data: {},
          },
          runtime: {
            data: testData,
          },
        },
      };

      const response = await fetch(
        `${
          import.meta.env.VITE_AI_ENGINE_ENDPOINT
        }/api/v1/bundles/ai_bundle/functions/test_agent`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${keycloak.token}`,
          },
          method: 'POST',
          body: JSON.stringify(body),
          signal: abortController.current.signal,
        }
      );

      const data = await response.json();
      const responseData =
        typeof data.data.response === 'string'
          ? JSON.parse(data.data.response)
          : data.data.response;

      return {
        output: responseData.output,
        log: responseData.log,
        error: responseData.error,
      };
    },
  });

  return {
    ...mutation,
    abort: () => abortController.current?.abort(),
  };
}
