import { StackedList } from '@/components/ui/stacked-list';
import { Widget } from '@/components/widget';
import { useListener } from '@/hooks/use-listener';
import { useObjects } from '@/hooks/use-objects';
import { useState } from 'react';
import { useObjectJson } from '@/hooks/use-object-json';

const WIDGET_ID = 'STACKED_LIST_WIDGET';

export function StackedListWidget() {

  const [uuids, setUuids] = useState<string[]>([]);
  const [containers, setContainers] = useState<string[]>([]);
  const { data } = useObjects(uuids, containers);
  const { enabled } = useListener({
    id: WIDGET_ID,
    listenerFn: (data) => {
      setUuids(data.map(({ uuid }: { uuid: string }) => uuid));
      setContainers(data.map(({ container }: { container: string }) => container));
    },
    type: 'ObjectCollection',
  });

  const items = data?.data?.resultset[0]?.data?.top10Sites ? data?.data?.resultset[0].data.top10Sites : [] ;
  

  // Transform data to correct shape
  // const items = data?.data?.resultset?.map((object) => ({}));
  /*const items = [
    {
      title: 'Leslie Alexander',
      text: 'leslie.alexander@example.com',
      subtitle: 'Co-Founder / CEO',
      imageUrl:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      meta: '3h ago',
    },
    {
      title: 'Michael Foster',
      text: 'michael.foster@example.com',
      subtitle: 'Co-Founder / CTO',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      meta: '3h ago',
    },
    {
      title: 'Dries Vincent',
      text: 'dries.vincent@example.com',
      subtitle: 'Business Relations',
      imageUrl:
        'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
      title: 'Lindsay Walton',
      text: 'lindsay.walton@example.com',
      subtitle: 'Front-end Developer',
      imageUrl:
        'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      meta: '3h ago',
    },
    {
      title: 'Courtney Henry',
      text: 'courtney.henry@example.com',
      subtitle: 'Designer',
      imageUrl:
        'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      meta: '3h ago',
    },
    {
      title: 'Tom Cook',
      text: 'tom.cook@example.com',
      subtitle: 'Director of Product',
      imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  ];*/

  return (
    <Widget id={WIDGET_ID} title="Top 10">
      {enabled && items ? <StackedList items={items} /> : null}
    </Widget>
  );
}
