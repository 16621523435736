import { createRoot } from 'react-dom/client';

import { App } from '@/app';

import '@/index.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(<App />);
}
