import { LinkIcon } from '@heroicons/react/24/outline';
import { OnSelectionChangeParams } from 'reactflow';
import { Button } from '@/components/button';
import { Dragabble } from '@/components/drag-drop';
import { Flow } from '@/components/flow';
import { SelectMenu } from '@/components/select-menu';
import { Widget } from '@/components/widget';
import { useTree } from '@/hooks/use-tree';
import { TreeAction, TreeState } from '@/tree-store';
import { useEmitter } from '@/hooks/use-emitter';
import { useUserContainers } from '@/hooks/user/use-user-containers';
import { useTreeStore } from '@/context/tree-context';
import { SyntheticEvent, useState, useEffect } from 'react';
import { PanelProvider, usePanel } from '@/context/panel-context';
import { useQueryClient } from '@tanstack/react-query';
import { CollectionsPalette } from '../collections-palette';
import { useUserConfig } from '@/hooks/user/use-user-config';
import { ComboBox } from '@/components/ui/combo-box';

const WIDGET_ID = 'EXPLORE_WIDGET';

const treeSelector = (state: TreeState & TreeAction) => ({
  container: state.container,
  nodes: state.nodes,
  edges: state.edges,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  setContainer: state.setContainer,
  setSearchStringNode: state.setSearchStringNode,
});

export function ExploreWidget() {
  const emitObjectCollection = useEmitter({
    id: WIDGET_ID,
    listeners: [
      'COLLECTION_WIDGET',
      'MEDIA_VIEWER_WIDGET',
      'DETAILS_WIDGET',
      'OBJECT_CHAIN_WIDGET',
      'OBJECT_JSON_WIDGET',
      'STATS_WIDGET',
      'COMPARISON_WIDGET',
      'PRODUCT_DETAILS_WIDGET',
      'PRODUCT_COMPARISON_WIDGET',
      'STACKED_LIST_WIDGET',
      'COMPETITION_SEARCH_WIDGET',
      'COMPETITION_SHOPS_WIDGET',
      'CUSTOM_APP_WIDGET',
      'LEAFLET_MAP_WIDGET',
    ],
  });

  const emitPreview = useEmitter({
    id: WIDGET_ID,
    listeners: ['PREVIEW_WIDGET'],
  });

  const { data } = useUserContainers();
  const queryClient = useQueryClient();
  const { data: userConfig } = useUserConfig();

  const { nodes, edges, onNodesChange, onEdgesChange, setContainer, setSearchStringNode } =
    useTreeStore(treeSelector);

  useEffect(() => {
    if (!data?.containers.length) {
      return;
    }

    setSelectedContainer(data.containers[0]);

    setParams({
      container: data.containers[0].value,
      searchString: null,
    });

    setContainer(data.containers[0].value);
  }, [data]);

  const [params, setParams] = useState<{
    container: string | null;
    searchString: string | null;
  }>({ container: null, searchString: null });

  const { refetch } = useTree(params, {
    enabled: !!params.container || !!params.searchString,
  });

  const [selectedContainer, setSelectedContainer] = useState<
    { label: string; value: string } | null | undefined
  >(data?.containers[0]);

  function getRootDataClass(uuid: string) {
    const rootDataClasses = userConfig?.organisationConfig.dataClasses
      .filter((dataClass) => dataClass.container === uuid)
      .map((dataClass) => dataClass.dataClass);

    return rootDataClasses[0];
  }

  function handleSelectionChange({ nodes }: OnSelectionChangeParams) {
    const selectedNodes = nodes.filter(
      (node) => node.type === 'object' || node.type === 'image'
    );

    if (selectedNodes.length === 0) {
      return;
    }

    emitObjectCollection({
      type: 'ObjectCollection',
      data: selectedNodes.map((node) => ({
        uuid: node.data.uuid,
        container: node.data.root ? node.data.uuid : node.data.container,
        publicUrl: node.data?.publicUrl,
        root: node.data.root,
        dataClassUuid: node.data.root
          ? getRootDataClass(node.data.uuid)
          : node.data.dataClassUuid,
      })),
    });

    emitPreview({
      type: 'Preview',
      data: selectedNodes.map((node) => ({
        uuid: node.data.uuid,
        container: node.data.container,
        previewUuid: node.data.previewUuid,
      })),
    });
  }

  function handleSubmit(event: SyntheticEvent) {
    event.preventDefault();

    const target = event.target as HTMLFormElement;
    const formData = new FormData(target);
    const container = formData.get('container[value]') as string | null;
    const searchString = formData.get('searchString') as string | null;
    let allowRefetch = false;

    if (nodes[1]?.id) {
      setSearchStringNode(nodes[1].id, searchString);
    }

    if (params.container !== container) {
      queryClient.removeQueries(['container_objects']);
    }

    if (
      params.container === container &&
      params.searchString === searchString
    ) {
      allowRefetch = true;
    }

    if (container || searchString) {
      setParams({
        container,
        searchString,
      });
    }

    if (container) {
      setContainer(container);
    }

    if (allowRefetch) {
      refetch();
    }
  }

  return (
    <Widget
      id={WIDGET_ID}
      title="Explorer"
      toolbar={
        <Dragabble value={{ widgetId: WIDGET_ID }} type="link">
          <Button>
            <LinkIcon className="h-4 w-4" aria-hidden="true" />
          </Button>
        </Dragabble>
      }
    >
      <div className="absolute z-10 w-[500px]">
        <form onSubmit={handleSubmit}>
          <div className="flex items-end space-x-2">
            <div className="flex-1">
              {data && data.containers && (
                <ComboBox
                  label="Container"
                  options={data.containers}
                  name="container"
                  value={selectedContainer}
                  onChange={(value) => setSelectedContainer(value)}
                />
              )}
            </div>
            <input
              type="search"
              name="searchString"
              id="searchString"
              className="block flex-1 w-full rounded-md border py-2 text-gray-900 shadow-sm border-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-indigo-500 sm:text-sm sm:leading-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Your keywords"
            />
            <Button type="submit">Search</Button>
          </div>
        </form>
      </div>
      <PanelProvider>
        {nodes.length ? (
          <Flow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onSelectionChange={handleSelectionChange}
          />
        ) : null}
      </PanelProvider>
    </Widget>
  );
}
