import { useChainStore } from '@/context/chain-context';
import { flextree } from 'd3-flextree';
import { stratify } from 'd3-hierarchy';
import { useEffect } from 'react';
import { Node, Edge, ReactFlowState, useStore, useReactFlow } from 'reactflow';

const layout = flextree<Node>({
  spacing: 50,
});

const nodeCountSelector = (state: ReactFlowState) => state.nodeInternals.size;
const edgeCountSelector = (state: ReactFlowState) => state.edges.length;
const nodesInitializedSelector = (state: ReactFlowState) =>
  Array.from(state.nodeInternals.values()).every(
    (node) => node.width && node.height
  );

export function layoutNodes(nodes: Node[], edgesPositioning: Edge[]) {
  const sizedNodes = nodes
    .filter((node) => !node.parentNode)
    .map((node) => ({
      ...node,
      size: [node.width, (node.height ?? 0) + 100],
    }));

  const hierarchy = stratify<Node>()
    .id((d) => d.id)
    .parentId(
      (d) => edgesPositioning.find((e: Edge) => e.target === d.id)?.source
    )(sizedNodes);

  const root = layout(hierarchy);
  const rootNode = root.find((d) => d.parent === null);
  if (!rootNode) {
    return nodes;
  }

  return nodes.map((node) => {
    if (!node.width || !node.height) {
      return node;
    }

    const currentNode = root.find((d) => d.id === node.id);

    if (!currentNode) {
      return node;
    }

    const { x, y } = currentNode;

    // If the node is the root node, we want to keep the position as is.
    // Otherwise, we want to add the root node's position to the current
    // node's position to keep root node in its currentplace when toggling.
    const offsetPosition = !!rootNode.data.data.collapsed
      ? rootNode?.data.position
      : { x: 0, y: 0 };
    const position =
      rootNode?.id === currentNode?.id
        ? offsetPosition
        : {
            x: offsetPosition.x + x,
            y: offsetPosition.y + y,
          } || {
            x: node.position.x,
            y: node.position.y,
          };

    return {
      ...node,
      position,
    };
  });
}

export function getLayoutedNodes(nodes: Node[], edgesPositioning: Edge[]) {
  const currentNodes = nodes.filter((node) =>
    edgesPositioning.some(
      (edge) => edge.source === node.id || edge.target === node.id
    )
  );
  const unConnectedNodes = nodes.filter(
    (node) =>
      !edgesPositioning.some(
        (edge) => edge.source === node.id || edge.target === node.id
      )
  );

  // We layout nodes only in case they are connected to other nodes
  const layoutedNodes =
    currentNodes.length === 0
      ? []
      : layoutNodes(currentNodes, edgesPositioning);

  return [
    ...layoutedNodes,
    ...unConnectedNodes.map((node) => ({
      ...node,
    })),
  ];
}

export function useAutoChainLayout() {
  const nodeCount = useStore(nodeCountSelector);
  const edgeCount = useStore(edgeCountSelector);
  const nodesInitialized = useStore(nodesInitializedSelector);
  const { getNodes, getEdges, setNodes } = useReactFlow();
  const edgesPositioning = useChainStore((state) => state.edgesPositioning);

  useEffect(() => {
    // only run the layout if there are nodes and they have been initialized with their dimensions
    if (!nodeCount || !nodesInitialized || !edgeCount) {
      return;
    }

    const nodes = getLayoutedNodes(getNodes(), edgesPositioning);
    setNodes(nodes);
  }, [nodeCount, nodesInitialized, getNodes, getEdges, setNodes]);
}
