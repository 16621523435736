import { Button } from '@/components/button';
import { Widget } from '@/components/widget';
import { useListener } from '@/hooks/use-listener';
import { PrinterIcon } from '@heroicons/react/24/outline';
import { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

const WIDGET_ID = 'HTML_PREVIEW_WIDGET';

export function HtmlPreviewWidget() {
  const [html, setHtml] = useState<string>('');
  const printRef = useRef<HTMLDivElement>(null);
  const reactToPrint = useReactToPrint({ contentRef: printRef });

  useListener({
    id: WIDGET_ID,
    listenerFn: (data) => {
      setHtml(data.html);
    },
    type: 'preview/html',
  });

  const handlePrintClick = () => {
    reactToPrint();
  };

  return (
    <Widget
      id={WIDGET_ID}
      title="HTML Preview"
      toolbar={
        <Button onClick={handlePrintClick}>
          <PrinterIcon className="h-5 w-5" aria-hidden="true" />
        </Button>
      }
      connectable
    >
      <div
        ref={printRef}
        dangerouslySetInnerHTML={{ __html: html }}
        className="h-[50rem] overflow-auto print:h-full print:overflow-visible print:m-10"
      />
    </Widget>
  );
}
