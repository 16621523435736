import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
} from '@heroicons/react/24/outline';
import { Button } from '@/components/button';
import {
  FullscreenContextType,
  useFullscreen,
} from '@/context/fullscreen-context';

export function FullscreenToggle({ onClick }: { onClick?: () => void }) {
  const { isFullscreen, toggleFullscreen } =
    useFullscreen() as FullscreenContextType;

  function handleClick() {
    toggleFullscreen();

    onClick?.();
  }

  return (
    <Button onClick={handleClick}>
      {isFullscreen ? (
        <ArrowsPointingInIcon className="h-4 w-4" aria-hidden="true" />
      ) : (
        <ArrowsPointingOutIcon className="h-4 w-4" aria-hidden="true" />
      )}
    </Button>
  );
}
