import { useTreeStore } from '@/context/tree-context';
import { useRelationDelete } from '@/hooks/use-relation-delete';
import { ObjectParams } from '@/hooks/use-relation-insert';
import { MinusCircleIcon } from '@heroicons/react/24/outline';
import { MouseEvent } from 'react';
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
} from 'reactflow';

export function RelationEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}: EdgeProps) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const container = useTreeStore((state) => state.container);
  const edges = useTreeStore((state) => state.edges);
  const nodes = useTreeStore((state) => state.nodes);
  const removeEdge = useTreeStore((state) => state.removeEdge);
  const relationDeleteMutation = useRelationDelete();

  function onEdgeClick(
    event: MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string
  ): void {
    const edgeToBeRemoved = edges.find((edge) => edge.id === id);

    if (!edgeToBeRemoved) {
      return;
    }

    const sourceNode = nodes.find((node) => node.id === edgeToBeRemoved.source);

    if (!sourceNode) {
      return;
    }

    const targetNode = nodes.find((node) => node.id === edgeToBeRemoved.target);

    if (!targetNode) {
      return;
    }

    const objectsToBeRemoved: ObjectParams[] = [
      {
        uuid: targetNode.data.uuid,
        container: targetNode.data.container,
      },
    ];

    const variables = {
      target: {
        uuid: sourceNode.data.parentUuid,
        container: container as string,
      },
      objects: objectsToBeRemoved,
      attribute: sourceNode.data.attribute,
    };

    relationDeleteMutation.mutate(variables, {
      onSuccess: () => {
        removeEdge(id);
      },
    });
  }

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: 'all',
          }}
          className="nodrag nopan"
        >
          <button
            className="bg-white rounded-full opacity-0 hover:opacity-100"
            onClick={(event) => onEdgeClick(event, id)}
          >
            <MinusCircleIcon className="w-4 h-4" />
          </button>
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
