'use client';

import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { ComputerDesktopIcon } from '@heroicons/react/24/outline';
import { Disclosure } from '@headlessui/react';
import { useKeycloak } from '@react-keycloak/web';
import { useUserConfig  } from '@/hooks/user/use-user-config';
import { Fragment } from 'react';
import { AuthorisedFunction } from '@/utilities/authorised-function';

export interface NavigationItem {
  name: string;
  href: string;
  icon: Function;
  showLoggedIn: boolean;
  showLoggedOut: boolean;
  children: NavigationItem[];
  permissions?: string[];
}

export interface NavigationProps {
  items: NavigationItem[];
}

export function Navigation({ items }: NavigationProps) {
  const { keycloak, initialized } = useKeycloak();
  const { data } = useUserConfig();

  return (
    <nav className="flex-1 space-y-1 px-2 py-4">
      {items.map((item: NavigationItem) => (
        <Fragment key={item.name}> 
          {(item.hasOwnProperty('permissions') ? AuthorisedFunction(item.permissions) : true ) && ((keycloak.authenticated === item.showLoggedIn) || (!keycloak.authenticated === item.showLoggedOut)) &&
            <NavLink
              key={item.name}
              to={item.href}
              className={({ isActive }) =>
                clsx(
                  'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                  isActive
                    ? 'bg-gray-900 text-white'
                    : 'text-gray-300 hover:bg-gray-700 hover:text-white'
                )
              }
            >
              {({ isActive }) => (
                <Fragment>
                  <item.icon
                    className={clsx(
                      isActive
                        ? 'text-gray-300'
                        : 'text-gray-400 group-hover:text-gray-300',
                      'mr-3 flex-shrink-0 h-6 w-6'
                    )}
                    aria-hidden="true"
                  />

                  {item.name}
                </Fragment>
              )}
            </NavLink>
          }
        </Fragment>
      ))}
      {keycloak.authenticated &&
        <Fragment>
        {AuthorisedFunction(['developer']) && (
        <Disclosure as="div" key="personal-desktops" className="space-y-1">
          {({ open }) => (
            <Fragment>
              <Disclosure.Button
                className={clsx(
                  'text-gray-300 hover:bg-gray-700 hover:text-white',
                  'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none'
                )}
              >
                <ComputerDesktopIcon
                  className={clsx(
                    'text-gray-400 group-hover:text-gray-300',
                    'mr-3 flex-shrink-0 h-6 w-6'
                  )}
                  aria-hidden="true"
                />
                <span className="flex-1">Personal Desktops</span>
                <svg
                  className={clsx(
                    open ? 'text-gray-400 rotate-90' : 'text-gray-300',
                    'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400'
                  )}
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                </svg>
              </Disclosure.Button>
              <Disclosure.Panel className="space-y-1">
                {data?.userConfig?.desktops.map((desktop) =>(
                  <NavLink
                    key={desktop.uuid}
                    to={'desktop/' + desktop.uuid}
                    className={({ isActive }) =>
                      clsx(
                        'group flex items-center items-center rounded-md py-2 pl-11 pr-2 text-sm font-medium rounded-md',
                        isActive
                          ? 'bg-gray-900 text-white'
                          : 'text-gray-300 hover:bg-gray-700 hover:text-white'
                      )
                    }
                  >
                    {({ isActive }) => (
                      desktop.title
                    )}
                  </NavLink>
                ))}
              </Disclosure.Panel>
            </Fragment>
          )}
      </Disclosure>
      )}
      <Disclosure as="div" key="organisation-desktops" className="space-y-1">
        {({ open }) => (
          <Fragment>
            <Disclosure.Button
              className={clsx(
                'text-gray-300 hover:bg-gray-700 hover:text-white',
                'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none'
              )}
            >
              <ComputerDesktopIcon
                className={clsx(
                  'text-gray-400 group-hover:text-gray-300',
                  'mr-3 flex-shrink-0 h-6 w-6'
                )}
                aria-hidden="true"
              />
              <span className="flex-1">Organisation Desktops</span>
              <svg
                className={clsx(
                  open ? 'text-gray-400 rotate-90' : 'text-gray-300',
                  'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400'
                )}
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
              </svg>
            </Disclosure.Button>
            <Disclosure.Panel className="space-y-1">
              {data?.organisationConfig?.desktops.map((desktop) =>(
                <NavLink
                  key={desktop.uuid}
                  to={'desktop/' + desktop.uuid}
                  className={({ isActive }) =>
                    clsx(
                      'group flex items-center items-center rounded-md py-2 pl-11 pr-2 text-sm font-medium rounded-md',
                      isActive
                        ? 'bg-gray-900 text-white'
                        : 'text-gray-300 hover:bg-gray-700 hover:text-white'
                    )
                  }
                >
                  {({ isActive }) => (
                    desktop.title
                  )}
                </NavLink>
              ))}
            </Disclosure.Panel>
          </Fragment>
        )}
      </Disclosure>
      </Fragment>
      }
    </nav>
  );
}
