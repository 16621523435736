import { useState } from 'react';
import {
  GoogleMap,
  LoadScript,
  Autocomplete,
  Marker,
  MarkerProps,
} from '@react-google-maps/api';

import { libraries, options } from '@/config/map';

type Location = google.maps.LatLng | google.maps.LatLngLiteral;

interface MapProps {
  onMarkersSet?: (position: google.maps.LatLng) => void;
}

export function Map({ onMarkersSet }: MapProps) {
  const [_, setMap] = useState<google.maps.Map>();
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete>();
  const [markers, setMarkers] = useState<MarkerProps[]>([]);
  const [center, setCenter] = useState<Location>({
    lat: 49.9485894,
    lng: -41.1561922,
  });
  const [zoom, setZoom] = useState<number>(2);

  function handleMapClick(e: google.maps.MapMouseEvent) {
    if (!e.latLng) {
      return;
    }

    setMarkers([
      {
        position: e.latLng,
      },
    ]);

    onMarkersSet?.(e.latLng);
  }

  function handlePlaceChange() {
    if (!autocomplete) {
      return;
    }

    const place = autocomplete.getPlace();

    if (!place?.geometry?.location) {
      return;
    }

    setMarkers([
      {
        position: place.geometry.location,
      },
    ]);

    onMarkersSet?.(place.geometry.location);

    setCenter(place.geometry.location);
    setZoom(16);
  }

  function handleMapLoad(map: google.maps.Map) {
    setMap(map);
  }

  function handleAutocompleteLoad(
    autocomplete: google.maps.places.Autocomplete
  ) {
    setAutocomplete(autocomplete);
  }

  return (
    <LoadScript
      googleMapsApiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
      libraries={libraries}
    >
      <GoogleMap
        mapContainerClassName="w-full h-[400px]"
        center={center}
        zoom={zoom}
        onClick={handleMapClick}
        onLoad={handleMapLoad}
        options={options}
      >
        {markers
          ? markers.map((marker, i) => <Marker key={i} {...marker} />)
          : null}
        <Autocomplete
          onLoad={handleAutocompleteLoad}
          onPlaceChanged={handlePlaceChange}
        >
          <input
            type="text"
            placeholder="Search for a location"
            className="border-none shadow-md rounded text-ellipsis text-sm absolute left-2 bottom-10 w-[15rem] outline-none focus-within:ring focus-within:ring-offset-2 focus-within:ring-blue-200"
          />
        </Autocomplete>
      </GoogleMap>
    </LoadScript>
  );
}
