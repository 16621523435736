import { ChainWidget } from '@/components/chain-widget';
import { Page } from '@/components/page';
import { ChainStoreProvider } from '@/context/chain-context';

export function Chain() {
  return (
    <Page title="Automate">
      <ChainStoreProvider>
        <ChainWidget />
      </ChainStoreProvider>
    </Page>
  );
}
