import { useState, useEffect } from 'react';
import moment, { unix } from 'moment';

interface TimeFromUnixProps {
  unixTimestamp: number
}

export function TimeFromUnix({ unixTimestamp }: TimeFromUnixProps) {
  const [currentTime, setCurrentTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 60000); // Update every second

    return () => clearInterval(interval);
  }, []);

  return (
    <span>
      {moment.unix(unixTimestamp).fromNow()}
    </span>
  );
}