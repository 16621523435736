import React from 'react';
import {
  ariaDescribedByIds,
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema,
  WidgetProps,
  GenericObjectType,
} from '@rjsf/utils';

export function CheckboxWidget<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(props: WidgetProps<T, S, F>) {
  const {
    autofocus,
    disabled,
    formContext,
    id,
    label,
    onBlur,
    onChange,
    onFocus,
    readonly,
    value,
  } = props;
  const { readonlyAsDisabled = true } = formContext as GenericObjectType;

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
    onChange(target.checked);

  const handleBlur = ({ target }: React.FocusEvent<HTMLInputElement>) =>
    onBlur(id, target.checked);

  const handleFocus = ({ target }: React.FocusEvent<HTMLInputElement>) =>
    onFocus(id, target.checked);

  // Antd's typescript definitions do not contain the following props that are actually necessary and, if provided,
  // they are used, so hacking them in via by spreading `extraProps` on the component to avoid typescript errors
  const extraProps = {
    onBlur: !readonly ? handleBlur : undefined,
    onFocus: !readonly ? handleFocus : undefined,
  };

  return (
    <div className="relative flex items-start">
      <div className="flex h-5 items-center">
        <input
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
          type="checkbox"
          autoFocus={autofocus}
          checked={typeof value === 'undefined' ? false : value}
          disabled={disabled || (readonlyAsDisabled && readonly)}
          id={id}
          name={id}
          onChange={!readonly ? handleChange : undefined}
          {...extraProps}
          aria-describedby={ariaDescribedByIds<T>(id)}
        />
        <div className="ml-2 text-sm">
          <label htmlFor={id} className="font-medium text-gray-700">
            {label}
          </label>
        </div>
      </div>
    </div>
  );
}
