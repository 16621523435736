import { Puck, usePuck } from '@measured/puck';

export function PuckPreview() {
  const { appState, dispatch } = usePuck();

  return (
    <div
      className="bg-slate-100 p-4 min-h-[306mm]"
      onClick={() => {
        dispatch({ type: 'setUi', ui: { ...appState.ui, itemSelector: null } });
      }}
    >
      <Puck.Preview />
    </div>
  );
}
