import { Widget } from '@/components/widget';
import { Comparison } from '@/components/ui/comparison';
import { useListener } from '@/hooks/use-listener';
import { useObjects } from '@/hooks/use-objects';
import { useState } from 'react';

const WIDGET_ID = 'PRODUCT_COMPARISON_WIDGET';

export function ProductComparisonWidget() {
  const [uuids, setUuids] = useState<string[]>([]);
  const [containers, setContainers] = useState<string[]>([]);
  const { data } = useObjects(uuids, containers);
  const { enabled } = useListener({
    id: WIDGET_ID,
    listenerFn: (data) => {
      setUuids(data.map(({ uuid }: { uuid: string }) => uuid));
      setContainers(data.map(({ container }: { container: string }) => container));
    },
    type: 'ObjectCollection',
  });

  // console.log("data:")
  // console.dir(data?.data?.resultset[0]?.data);


  const items = data?.data?.resultset[0]?.data?.competitors ? data?.data?.resultset[0].data.competitors?.map((object, id) => ({

      // id: 2,
      // name: object.productData[0]?.title ? (object.competitor + " " + object.productData[0].title) : object.competitor,
      // imageUrl: object.productData[0]?.img ? ((object.competitor == 'icotek'? 'https://www.icotek.com/' : '')+JSON.parse(object.productData[0].img)) : 'https://karanzi.websites.co.in/obaju-turquoise/img/product-placeholder.png',// resultset[0].data['ProductData.img'],
      // items: [
      //   { label: 'Untertitel', value: object.productData[0]?.title2 ? object.productData[0].title2 : "" },
      //   { label: 'Beschreibung', value: object.productData[0]?.description ? object.productData[0].description : "" },
      //   { label: 'Eigenschaften', value: object.productData[0]?.Material?.Eigenschaften ? object.productData[0].technical_detail.Eigenschaften : "" },
      //   { label: 'Schutzklasse', value: object.productData[0]?.Schutzklasse ? object.productData[0].Schutzklasse : "" },
      //   { label: 'Brandklasse', value: object.productData[0]?.technical_detail?.Brandklasse ? object.productData[0].technical_detail.Brandklasse : "" },
      //   { label: 'Material', value: object.productData[0]?.Material?.Brandklasse ? object.productData[0].technical_detail.Material : "" },
      //   { label: 'Temperaturbereich', value: object.productData[0]?.Material?.Temperaturbereich ? object.productData[0].technical_detail.Temperaturbereich : "" },
      // ]
      id: id,
      name: object.competitor,
      imageUrl: object.mainImg,
      items: [
        { label: 'Produkt Titel', value: object.productData.produktTitel ?? "" },
        { label: 'Eigenschaften', value: object.productData.eigenschaften ?? "" },
        { label: 'Schutzklasse', value: object.productData.Schutzklasse ?? "" },
        { label: 'Material', value: object.productData.Material ?? "" },
        { label: 'Farbe', value: object.productData.fabre ?? "" },
        { label: 'Temperatur Min', value: object.productData.temperatur_min ?? "" },
        { label: 'Temperatur Max', value: object.productData.temperatur_max ?? "" },
        { label: "Customer Benefits", value: object.userFeatures3 ? object.userFeatures3.join("\n") : "" },
      ]

  })) : [];




/*
  const items = data?.data?.resultset[0]?.data?.ProductData ? [data?.data?.resultset[0].data.ProductData]?.map((object) => ({

      id: 2,
      name: 'murrplastik '+object.title ,
      imageUrl: data?.data?.resultset[0].data['ProductData.img'],
      items: [
        { label: 'Brandklasse', value: object.technical_detail.Brandklasse },
        { label: 'Eigenschaften', value: object.technical_detail.Eigenschaften },
        { label: 'Schutzart IP (EN 60529)', value: object.technical_detail['Schutzart IP (EN 60529)'] },
        { label: 'Temperaturbereich', value: object.technical_detail.Temperaturbereich },
      ]

  })) : [];

  const mainProduct = data?.data?.resultset[0]?.data?.baseProduct ? {
    id: 1,
      name: 'icotek '+data?.data?.resultset[0].data.baseProduct.title,
      imageUrl: 'https://shop.murrplastik.de/_next/image?url=https%3A%2F%2Fmurrplastik.canto.global%2Fpreview%2Fimage%2F5rbupaqn117154d4ro8nio221m%2F800&w=1080&q=75',//data?.data?.resultset[0].data["ProductData.img"],
      items: [
        { label: 'Brandklasse', value: data?.data?.resultset[0].data.baseProduct.Brandklasse[0] },
        { label: 'Eigenschaften', value: ''},
        { label: 'Schutzart IP (EN 60529)', value: '' },
        { label: 'Temperaturbereich', value: data?.data?.resultset[0].data.baseProduct.Temperaturbereich["temperatureFrom"] + "-" + data?.data?.resultset[0].data.baseProduct.Temperaturbereich["temperatureUp"] },
      ]
  } : null;

  if (mainProduct) {
    items.push(mainProduct);
  }
  */

  // console.log("resultitems");

  // console.dir(items);

  // Transform data to correct shape
  // const items = data?.data?.resultset?.map((object) => ({}));
  /*const items = [
    {
      id: 1,
      name: 'Tuple',
      imageUrl: 'https://tailwindui.com/img/logos/48x48/tuple.svg',
      items: [
        { label: 'Brandklasse', value: 'UL94-HB' },
        { label: 'Preis', value: '$2,000.00' },
      ],
    },
    {
      id: 2,
      name: 'SavvyCal',
      imageUrl: 'https://tailwindui.com/img/logos/48x48/savvycal.svg',
      items: [
        { label: 'Brandklasse', value: 'UL94-HB' },
        { label: 'Preis', value: '$2,000.00' },
      ],
    },
    {
      id: 3,
      name: 'Reform',
      imageUrl: 'https://tailwindui.com/img/logos/48x48/reform.svg',
      items: [
        { label: 'Brandklasse', value: 'UL94-HB' },
        { label: 'Preis', value: '$2,000.00' },
      ],
    },
  ];
  */

  return (
    <Widget id={WIDGET_ID} title="Product Comparison">
      {enabled && items ? (
        <Comparison items={items} title="Own vs. Competitors" />
      ) : null}
    </Widget>
  );
  
}
