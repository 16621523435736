import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface JobData {
  jobs: Job[]
}

type Job = {
  job_id: string,
  completed: boolean,
  type: string,
  created: number,
  user_uid: string,
  job_steps: {
    step: string,
    label: string
  }[],
  container_id: string,
  current_job_step: string
  [key: string]: unknown
}

interface JobOptions {
  type: string;
}

export function useJobs({ type }: JobOptions) {
  const queryClient = useQueryClient();
  const { keycloak } = useKeycloak();

  return useQuery({
    enabled: true,
    queryKey: ['jobs'],
    queryFn: async (): Promise<JobData> => {
      const existingData = queryClient.getQueryData<JobData>(['jobs']);

      const response = await fetch(
        `${import.meta.env.VITE_JOB_ENGINE_ENDPOINT}/api/v1/bundles/job_bundle/functions/get_user_jobs?type=${type}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'GET',
        }
      );

      let data = await response.json();

      const { jobs } = data?.data;

      return {
        jobs: jobs
      };
    },
    refetchInterval: 5000
  });
}
