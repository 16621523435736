import { useState, useEffect, SyntheticEvent } from 'react';
import validator from '@rjsf/validator-ajv8';
import Form, { IChangeEvent, getDefaultRegistry } from '@rjsf/core';
import { RegistryFieldsType, RegistryWidgetsType, ObjectFieldTemplateProps } from '@rjsf/utils';
import { PanelProvider, usePanel } from '@/context/panel-context';
import { SelectMenu } from '@/components/select-menu';
import { Button } from '@/components/button';
import moment from 'moment';
import { modalText } from '@/config/modal';
import { CheckCircleIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

import {
  AddButton,
  ArrayField,
  ArrayFieldItem,
  BaseInput,
  CheckboxWidget,
  ErrorList,
  Field,
  FieldError,
  MoveDownButton,
  MoveUpButton,
  RemoveButton,
  RteWidget,
  SelectWidget,
  SubmitButton,
  TextareaWidget,
  TitleField,
  Wrapper,
  GroupAccordion
} from '@/components/form';

import { GeoLocation } from '@/components/form/fields/geo-location';
import { Widget } from '@/components/widget';

import { useObjectForm } from '@/hooks/use-object-form';
import { useListener } from '@/hooks/use-listener';
import { useObjectFormUpsert } from '@/hooks/use-object-form-upsert';
import * as Dialog from '@radix-ui/react-dialog';
import clsx from 'clsx';

import {
  DocumentDuplicateIcon,
  ChatBubbleOvalLeftEllipsisIcon,
} from '@heroicons/react/24/outline';

import { useGenerate } from '@/hooks/use-generate';
import { useObjectFormDelete } from '@/hooks/use-object-form-delete';
import { Modal } from '@/components/modal';
import { DragDrop, Dragabble } from '@/components/drag-drop';

import { useUserDataclasses } from '@/hooks/user/use-user-dataclasses';
import { useGenerateRefine } from '@/hooks/use-generate-refine';

const WIDGET_ID = 'EDIT_WIDGET';

const fields: RegistryFieldsType = { geo: GeoLocation };

const widgets: RegistryWidgetsType = {
  CheckboxWidget,
  SelectWidget,
  TextareaWidget,
  rte: RteWidget,
};

interface Headers {
  lastUpdateTimestamp: string;
  uuid: string;
}

interface DataClassContainer {
  dataClass: string;
  container: string;
}

const formattingOptions = [
  { value: 'trim', label: 'Trim' },
  { value: 'professional', label: 'Make sound more professional' },
  { value: 'toenglish', label: 'Translate to english' },
  { value: 'togerman', label: 'Translate to german' },
];

export function EditWidget() {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleted, setDeleted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [objectSaved, setObjectSaved] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [generateLoading, setGenerateLoading] = useState<boolean>(false);
  const [nodeFormData, setNodeFormData] = useState();
  const [uuids, setUuids] = useState<string[]>([]);
  const [containers, setContainers] = useState<string[]>([]);
  const [dataClassUuids, setDataClasses] = useState<string[]>([]);
  const [headers, setHeaders] = useState<Headers>({});
  const [create, setCreate] = useState<boolean>(false);
  const [refineLoading, setRefineLoading] = useState<boolean>(false);
  //const [dataClassContainers, setDataClassContainers] = useState<DataClassContainer[]>([]);
  const [createDataClass, setCreateDataclass] = useState<string>(
    'dataclass-general-image'
  );
  const [open, setOpen] = useState<boolean>(false);
  const { data, refetch } = useObjectForm(
    uuids,
    containers,
    create,
    createDataClass
  );
  const { data: dataClassData } = useUserDataclasses();
  const { enabled } = useListener({
    id: WIDGET_ID,
    listenerFn: (data) => {
      setUuids(data.map(({ uuid }: { uuid: string }) => uuid));
      setContainers(
        data.map(({ container }: { container: string }) => container)
      );
      setDataClasses(
        data.map(
          ({ dataClassUuid }: { dataClassUuid: string }) => dataClassUuid
        )
      );
      setCreate(false);
      setOpen(false);
      setObjectSaved(false);
      refetch();
    },
    type: 'ObjectCollection',
  });
  const objectFormUpsert = useObjectFormUpsert();
  const objectFormDelete = useObjectFormDelete();
  const generate = useGenerate();
  const generateRefine = useGenerateRefine();

  //handler creating/upserting an object. can only handle one item atm.
  const handleObjectUpsert = (event: IChangeEvent) => {
    setLoading(true);
    // console.log('calling handleObjectUpsert');
    const objectData = {
      uuid: uuids[0],
      dataClassUuid: dataClassUuids[0],
      container: containers[0],
      data: event.formData,
    };
    setNodeFormData(event.formData);
    objectFormUpsert.mutateAsync(objectData).then((result) => {
      setLoading(false);
      setCreate(false); //not an object any more being created
      setHeaders(result); // result contains timestamp and uuid
      setUuids([result.uuid]); //required to keep the form showin gwhen creating, then saving again. @todo: remove this eventually, especially when creating bulk functionality.
      setObjectSaved(true);
    });
  };

  //receives data coming in
  const {
    structure: schema,
    data: formData,
    uiSchema,
    /*headers: headers*/
  } = data?.data.resultset?.[0] ?? {};

  // const uiSchema = {
  //   "ui:groups": [{
  //     title: "Address", 
  //     fields: ['field_strasse', 'field_plz'],
  //     template: 'accordion',
  //     params: {
  //       isOpen: true
  //     }
  //   },
  //   {
  //     title: "Other", 
  //     fields: ['nid', 'admin_title'],
  //     template: 'accordion'
  //   }]
  // };

  function doGrouping({ properties, groups, props }) {
    const grouped = [];
    const mapped = groups.map(g => {
      const { templates } = props.formContext;
      const GroupComponent = templates ? templates[g.template] : DefaultTemplate;
      const children = properties.filter(p => g.fields.includes(p.name));
      grouped.push(...children);
      const _properties = [{
        name: g.title,
        children: children.map(c => c.content),
        params: g.params ?? {}
      }];
      return <GroupComponent properties={_properties} />
    });

    const remaining = properties.filter(p => !grouped.includes(p));
    if (remaining.length) {
      const _remaining = [{
        name: 'Remaining',
        children: remaining.map(c => c.content)
      }];
      mapped.push(<DefaultTemplate properties={_remaining} />);
    }

    return mapped;
  }

  function DefaultTemplate(props) {
    return props.properties.map(p => p.children);
  }

  const ObjectFieldTemplateGrouped = (props) => {
    const { title, DescriptionField } = props;
  
    return (
      <fieldset>
        <legend className="text-xl font-bold leading-6 text-black dark:text-slate-300 mb-2">{title}</legend>
        {doGrouping({
          properties: props.properties,
          groups: props.uiSchema["ui:groups"] ?? [],
          props: props
        })}
      </fieldset>
    );
  }

  useEffect(() => {
    if (!data || !data?.data.resultset[0]) {
      return;
    }

    setHeaders(data?.data.resultset[0].headers ?? {});
  }, [data]);

  useEffect(() => {
    if (!formData) {
      return;
    }
    setDeleted(false);
    setNodeFormData(formData);
    setOpen(false);
  }, [formData]);

  useEffect(() => {
    if (objectSaved === false) {
      return;
    }

    setTimeout(() => {
      setObjectSaved(false);
    }, 5000);
  }, [objectSaved]);

  const { dataClasses } = {
    dataClasses: [
      {
        value: '170ce9d7-830d-4504-8223-26b07eb86aff',
        label: 'Category',
        container: 'categories_index',
      },
      { value: 'dataclass-page', label: 'Page', container: 'pages_index' },
      {
        value: '570ce9d7-830d-4504-8223-26b07eb86aff',
        label: 'Component Header',
        container: 'components_index',
      },
      {
        value: 'dataclass-component-content-section',
        label: 'Component Content Section',
        container: 'components_index',
      },
      {
        value: 'dataclass-component-2-feature',
        label: 'Component Feature 2',
        container: 'components_index',
      },

      {
        value: 'dataclass-libelle-category',
        label: 'Kategorie',
        container: 'libelle-categories',
      },

      {
        value: 'dataClass-chain-libelle-content-page',
        label: 'Seite',
        container: 'libelle-categories',
      },
      {
        value: 'dataClass-chain-libelle-contentblock-doubletext',
        label: 'Komponente Doppeltext',
        container: 'libelle-categories',
      },
      {
        value: 'dataClass-chain-libelle-contentblock-textwithimage',
        label: 'Komponente Text mit Bild',
        container: 'libelle-categories',
      },
      {
        value: 'dataClass-chain-libelle-news',
        label: 'News',
        container: 'libelle-categories',
      },

      { value: 'dataclass-we-part', label: 'Part', container: 'we-part' },

      /* {value: {dataClass: "170ce9d7-830d-4504-8223-26b07eb86aff", container: "categories_index"}, label: "Category"},
       {value: {dataClass: "570ce9d7-830d-4504-8223-26b07eb86aff", container: "components_index"}, label: "Component Header"},
       {value: {dataClass: "dataclass-component-content-section", container: "components_index"}, label: "Component Content Section"},
       {value: {dataClass: "dataclass-component-2-feature", container: "components_index"}, label: "Component Feature 2"},*/
    ],
  }; /*useUserDataclasses();*/
  // console.log(dataClassData);

  const { dataClassContainers } = {
    dataClassContainers: [
      {
        dataClass: '170ce9d7-830d-4504-8223-26b07eb86aff',
        container: 'categories_index',
      },
      { dataClass: 'dataclass-page', container: 'pages_index' },
      {
        dataClass: '570ce9d7-830d-4504-8223-26b07eb86aff',
        container: 'components_index',
      },
      {
        dataClass: 'dataclass-component-content-section',
        container: 'components_index',
      },
      {
        dataClass: 'dataclass-libelle-category',
        container: 'libelle-categories',
      },

      {
        dataClass: 'dataClass-chain-libelle-content-page',
        container: 'container-libelle-content',
      },
      {
        dataClass: 'dataClass-chain-libelle-contentblock-doubletext',
        container: 'container-libelle-content',
      },
      {
        dataClass: 'dataClass-chain-libelle-contentblock-textwithimage',
        container: 'container-libelle-content',
      },
      {
        dataClass: 'dataClass-chain-libelle-news',
        container: 'container-libelle-news',
      },

      { dataClass: 'dataclass-we-part', container: 'we-part' },
    ],
  };

  // useEffect(() => {
  //   console.log("hello");
  //   console.dir(dataClasses);
  //   if (!dataClasses?.dataClasses) {
  //     return;
  //   }
  // }, [dataClasses]);

  const className = clsx(
    'block overflow-hidden text-ellipsis text-xs text-center p-3 rounded focus:ring focus:ring-offset-1',
    !open && 'bg-gray-200 focus:ring-gray-100',
    open && 'bg-indigo-100 focus:ring-indigo-500 text-indigo-700'
  );

  const handleSearch = (event: SyntheticEvent) => {
    setGenerateLoading(true);
    event.preventDefault();

    const target = event.target as HTMLFormElement;
    const formData = new FormData(target);
    const queryText = (formData.get('queryText') ?? '') as string;

    const generateData = {
      dataClassUuid: dataClassUuids[0],
      schema: schema,
      query: queryText,
    };

    generate.mutateAsync(generateData).then((response) => {
      // console.log(response);
      if (response.formData) {
        // console.log(response.formData);
        setNodeFormData(response.formData);
      }
      setOpen(false);
      setGenerateLoading(false);
    });
  };

  const handleRefine = (event: SyntheticEvent) => {
    event.preventDefault();
    setRefineLoading(true);

    const target = event.target as HTMLFormElement;
    const formData = new FormData(target);
    const action = (formData.get('refineSelect[value]') ?? '') as string;

    const generateData = {
      formData: nodeFormData,
      action: action,
    };

    generateRefine.mutateAsync(generateData).then((response) => {
      // console.log(response);
      if (response.formData) {
        // console.log(response.formData);
        setOpen(false);
        setNodeFormData(response.formData);
      }
      setRefineLoading(false);
    });
  };

  const handleObjectDelete = () => {
    setDeleteLoading(true);
    const objectData = {
      uuid: uuids[0],
      container: containers[0],
    };
    objectFormDelete.mutateAsync(objectData).then(() => {
      setShowDeleteModal(false);
      setDeleteLoading(false);
      setDeleted(true);
    });
  };

  const handleObjectDeleteConfirmation = () => {
    setShowDeleteModal(true);
  };

  const handleObjectDeleteCancel = () => {
    setShowDeleteModal(false);
  };

  const handleCreateNewObject = (event: SyntheticEvent) => {
    event.preventDefault();

    const target = event.target as HTMLFormElement;
    const formData = new FormData(target);
    const dataClass = formData.get('dataclass[value]') as string | null;
    const container = formData.get('dataclass[container]') as string | null;

    // console.log(dataClass);
    // console.log(container);

    if (!dataClass || !container) {
      return;
    }

    /*setDataClassContainers(dataClasses.map((item) => {
      return {dataClass: item.dataClass, container: item.container}:DataClassContainer
    }));*/

    // var container = dataClassContainers.filter(obj => {
    //   return obj.dataClass === dataClass
    // })

    setCreateDataclass(dataClass);
    setCreate(true);
    setContainers([container]);
    setDataClasses([dataClass]);
    //setDataClasses([item.dataClass]);
    setUuids([]);
    setNodeFormData({});
  };

  const [container, setContainer] = useState(null);

  function handleSubmit(event: SyntheticEvent) {}

  return (
    <Widget id={WIDGET_ID} title="Edit Object">
      {!deleted && (
        <div className="relative" ref={setContainer}>
          <PanelProvider>
            <div className="flex justify-between">
              {headers && (
                <div className="flex flex-col text-sm text-gray-400 dark:text-gray-200">
                  {headers.uuid && (
                    <Dragabble
                      value={[{ uuid: headers.uuid, container: containers[0] }]}
                      type="object/insert"
                    >
                      <button className="z-10 relative nopan">
                        <DocumentDuplicateIcon
                          className="h-3.5 w-3.5"
                          aria-hidden="true"
                        />
                      </button>
                    </Dragabble>
                  )}
                  <span>UUID: {headers.uuid}</span>
                  <span>
                    Updated:{' '}
                    {moment(headers.lastUpdateTimestamp).format(
                      'MMM DD, YYYY, h:mm:ss A'
                    )}
                  </span>
                </div>
              )}
              <Dialog.Root open={open} onOpenChange={setOpen} modal={false}>
                <Dialog.Trigger
                  className={className}
                  onClick={() => {}}
                  title={'...'}
                >
                  <ChatBubbleOvalLeftEllipsisIcon
                    className="w-5 h-5"
                    aria-hidden={true}
                  />
                </Dialog.Trigger>
                <Dialog.Portal container={container}>
                  <Dialog.Content
                    className="bg-white shadow-lg sm:rounded-lg absolute top-12 right-0 max-w-[400px] w-full dark:bg-gray-600"
                    onPointerDownOutside={(event) => {
                      event.preventDefault();
                    }}
                  >
                    <div className="px-4 py-5 sm:p-6">
                      <form onSubmit={handleCreateNewObject}>
                        <div className="flex items-end space-x-2">
                          <div className="flex-1">
                            {dataClassData?.dataClasses && (
                              <SelectMenu
                                label=""
                                options={dataClassData?.dataClasses}
                                name="dataclass"
                                selected={dataClassData?.dataClasses[0]}
                              />
                            )}
                          </div>
                          <button
                            type="submit"
                            className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:ml-3 sm:mt-0 sm:w-auto self-end  dark:bg-indigo-900 dark:text-indigo-100 dark:hover:bg-indigo-700"
                          >
                            Create
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="px-4 py-5 sm:p-6">
                      <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100">
                        Create Object
                      </h3>
                      <form
                        className="mt-5 sm:flex flex-col gap-2"
                        onSubmit={handleSearch}
                      >
                        <div className="w-full sm:max-w-xs">
                          <label htmlFor="queryText" className="sr-only">
                            Message
                          </label>
                          <textarea
                            rows={5}
                            name="queryText"
                            id="queryText"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:ring-0"
                            placeholder="i.e. Give me articles with the name Test"
                          />
                        </div>
                        <button
                          type="submit"
                          className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:ml-3 sm:mt-0 sm:w-auto self-end  dark:bg-indigo-900 dark:text-indigo-100 dark:hover:bg-indigo-700"
                        >
                          {generateLoading && (
                            <svg
                              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          )}
                          <ChatBubbleOvalLeftEllipsisIcon
                            className="w-5 h-5"
                            aria-hidden={true}
                          />
                          <span className="sr-only">Ask</span>
                        </button>
                      </form>
                    </div>
                    <div className="px-4 py-5 sm:p-6">
                      <form onSubmit={handleRefine}>
                        <div className="flex items-end space-x-2">
                          <div className="flex-1">
                            <SelectMenu
                              label=""
                              options={formattingOptions}
                              name="refineSelect"
                              selected={formattingOptions[0]}
                            />
                          </div>
                          <Button
                            type="submit"
                            variant="tertiary"
                            loading={refineLoading}
                          >
                            Apply
                          </Button>
                        </div>
                      </form>
                    </div>
                  </Dialog.Content>
                </Dialog.Portal>
              </Dialog.Root>
            </div>
            {enabled && schema && nodeFormData ? (
              <Form
                key={JSON.stringify(nodeFormData)}
                schema={schema}
                uiSchema={uiSchema}
                fields={fields}
                validator={validator}
                // onChange={(e) => console.log(e)}
                onSubmit={(data) => handleObjectUpsert(data)}
                formData={nodeFormData}
                templates={{
                  ArrayFieldTemplate: ArrayField,
                  ArrayFieldItemTemplate: ArrayFieldItem,
                  BaseInputTemplate: BaseInput,
                  ButtonTemplates: {
                    AddButton,
                    MoveDownButton,
                    MoveUpButton,
                    RemoveButton,
                    SubmitButton,
                  },
                  ErrorListTemplate: ErrorList,
                  FieldTemplate: Field,
                  FieldErrorTemplate: FieldError,
                  TitleFieldTemplate: TitleField,
                  WrapIfAdditionalTemplate: Wrapper,
                  ObjectFieldTemplate: ObjectFieldTemplateGrouped,
                }}
                widgets={widgets}
                formContext={{
                  loading: loading,
                  templates: {
                    accordion: props => props.properties.map(p => <GroupAccordion properties={p} />),
                  }
                }}
              >
                {objectSaved && (
                  <div className="rounded-md bg-green-50 p-4 mt-4 mb-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <CheckCircleIcon
                          className="h-5 w-5 text-green-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-green-800">
                          Object Saved
                        </h3>
                        <div className="mt-2 text-sm text-green-700">
                          <p>The object has been succesfully saved.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex justify-between mt-4">
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    {loading && (
                      <>
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Processing...
                      </>
                    )}
                    {!loading && <>Submit</>}
                  </button>
                  <button
                    onClick={handleObjectDeleteConfirmation}
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    {deleteLoading && (
                      <>
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Processing...
                      </>
                    )}
                    {!deleteLoading && <>Delete</>}
                  </button>
                </div>
              </Form>
            ) : null}
          </PanelProvider>
        </div>
      )}
      {showDeleteModal && (
        <Modal
          onCancel={handleObjectDeleteCancel}
          onSubmit={handleObjectDelete}
          loading={deleteLoading}
          title={modalText.deleteNodeTitle}
          message={modalText.deleteNodeMessage}
          actionName={modalText.deleteNodeActionName}
        />
      )}
    </Widget>
  );
}
