import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface PlanData {
  plans: {
    id: string,
    name: string,
    description: string,
    default_price: {
      id: string,
      unit_amount: number,
      recurring: {
        interval: string
      }
    },
    metadata: {
      most_popular?: boolean
    }
  }[];
}

export function usePlans() {
  const queryClient = useQueryClient();
  const { keycloak } = useKeycloak();

  return useQuery({
    queryKey: ['plans'],
    queryFn: async (): Promise<PlanData> => {

      const response = await fetch(
        `${import.meta.env.VITE_USER_ENGINE_ENDPOINT}/api/v1/bundles/user_bundle/functions/get_products`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'GET',
        }
      );

      let data = await response.json();

      const { products } = data?.data;

      return {
        plans: products
      };
    }
  });
}
