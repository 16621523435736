import {
  FieldTemplateProps,
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema,
  getTemplate,
  getUiOptions,
} from '@rjsf/utils';

const REQUIRED_FIELD_SYMBOL = '*';

export type LabelProps = {
  label?: string;
  required?: boolean;
  id?: string;
};

export function Label(props: LabelProps) {
  const { label, required, id } = props;
  if (!label) {
    return null;
  }
  return (
    <label
      className="block text-md font-bold text-black dark:text-slate-300 mb-1"
      htmlFor={id}
    >
      {label}
      {required && <span className="required">{REQUIRED_FIELD_SYMBOL}</span>}
    </label>
  );
}

export function Field<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(props: FieldTemplateProps<T, S, F>) {
  const {
    id,
    label,
    children,
    errors,
    help,
    rawHelp,
    description,
    hidden,
    required,
    displayLabel,
    registry,
    uiSchema,
  } = props;
  const uiOptions = getUiOptions(uiSchema);
  const WrapIfAdditionalTemplate = getTemplate<
    'WrapIfAdditionalTemplate',
    T,
    S,
    F
  >('WrapIfAdditionalTemplate', registry, uiOptions);
  if (hidden) {
    return <div className="hidden">{children}</div>;
  }

  return (
    <WrapIfAdditionalTemplate {...props}>
      {displayLabel && <Label label={label} required={required} id={id} />}
      {displayLabel && description ? (
        <div className="text-sm text-indigo-300">{description}</div>
      ) : null}
      {children}
      {errors}
      {rawHelp ? <p className="mt-2 text-sm text-gray-500">{rawHelp}</p> : null}
    </WrapIfAdditionalTemplate>
  );
}
