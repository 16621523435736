import { Button } from '@/components/button';
import { TrashIcon } from '@heroicons/react/24/outline';
import {
  StrictRJSFSchema,
  RJSFSchema,
  FormContextType,
  IconButtonProps,
} from '@rjsf/utils';

export function RemoveButton<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>({ onClick }: IconButtonProps<T, S, F>) {
  return (
    <Button onClick={onClick} variant="danger">
      <TrashIcon className="h-4 w-4" focusable="false" aria-hidden="true" />
    </Button>
  );
}
