import { Message, useWidgetStore, WidgetID } from '@/widget-store';

export function useEmitter({
  id,
  listeners = [],
}: {
  id: WidgetID;
  listeners: WidgetID[];
}) {
  const publishListener = useWidgetStore((state) => state.publish);

  function publish(data: Message) {
    listeners.forEach((listener) => {
      publishListener(listener, {
        ...data,
        origin: id,
      });
    });
  }

  return publish;
}
