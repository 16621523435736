import { Widget } from '@/components/widget';
import { useState } from 'react';
import { CreateForm } from '@/features/agents/components/create-form';
import { TestForm } from '@/features/agents/components/test-form';
import { Route, Routes, NavLink } from 'react-router-dom';
import { Navigation } from '@/features/agents/components/navigation';
import './style.css';
import { Dragabble } from '@/components/drag-drop';
import { Button } from '@/components/button';
import { LinkIcon } from '@heroicons/react/24/outline';

const WIDGET_ID = 'AGENT_CREATOR_WIDGET';

export interface DefaultValues {
  sourceExample?: {
    uuid: string;
    container: string;
  };
  inputObject?: string;
}

export interface Step {
  id: string;
  href: string;
  name: string;
  status: string;
}

export function AgentCreatorWidget() {
  const [defaultValues, setDefaultValues] = useState<DefaultValues>({});
  const [steps, setSteps] = useState<Step[]>([
    { id: 'create', href: 'create', name: 'Create', status: 'current' },
    { id: 'test', href: ':uuid/test', name: 'Test', status: 'pending' },
    {
      id: 'automation',
      href: ':uuid/automation',
      name: 'Set up automation',
      status: 'pending',
    },
    { id: 'done', href: ':uuid/done', name: 'Done', status: 'pending' },
  ]);

  function handleStepProceed(stepId: string) {
    let hasPreviousStep = true;

    setSteps((prevSteps) =>
      prevSteps.map((step) => {
        if (step.id === stepId) {
          hasPreviousStep = false;
          return { ...step, status: 'current' };
        } else if (hasPreviousStep) {
          return { ...step, status: 'complete' };
        } else {
          return { ...step, status: 'pending' };
        }
      })
    );
  }

  return (
    <Widget
      id={WIDGET_ID}
      title="Agent Creator"
      toolbar={
        <Dragabble value={{ widgetId: WIDGET_ID }} type="link">
          <Button>
            <LinkIcon className="h-4 w-4" aria-hidden="true" />
          </Button>
        </Dragabble>
      }
      scrollable={false}
    >
      <div>
        <Routes>
          <Route
            index
            path="create"
            element={
              <>
                <CreateForm
                  onProceed={(data) => {
                    setDefaultValues(data);
                  }}
                  steps={steps}
                />
              </>
            }
          />
          <Route path=":uuid/*">
            <Route
              path="test"
              element={
                <>
                  <TestForm
                    defaultValues={defaultValues}
                    onProceed={() => handleStepProceed('automation')}
                    steps={steps}
                  >
                    {/* <LogList /> */}
                  </TestForm>
                </>
              }
            />
            <Route
              path="automation"
              element={<div className="p-7">Automation</div>}
            />
            <Route path="done" element={<div className="p-7">Done</div>} />
          </Route>
        </Routes>
      </div>
    </Widget>
  );
}
