import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface UserContainerData {
  containers: {
    'label': string,
    'value': string
  }[]
}

export function useUserContainers() {
  const queryClient = useQueryClient();
  const { keycloak } = useKeycloak();

  return useQuery({
    queryKey: ['userContainers'],
    queryFn: async (): Promise<UserContainerData> => {
      const response = await fetch(
        `${import.meta.env.VITE_USER_ENGINE_ENDPOINT}/api/v1/bundles/user_bundle/functions/get_user_containers`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'GET',
        }
      );

      let data = await response.json();

      const { containers } = data?.data

      return {
        containers: containers,
      };
    },
  });
}
