import React, { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

export function GroupAccordion({ properties }) {
  // State to manage the visibility of children
  const [isOpen, setIsOpen] = useState(properties.params?.isOpen || false);

  // Function to toggle visibility of children
  const toggleChildren = () => {
    setIsOpen(prevState => (
      !prevState
    ));
  };

  return (
    <div key={properties.name} className="bg-gray-100 mb-4 p-4 rounded-md">
      <legend className="font-bold text-lg text-black flex items-center cursor-pointer" onClick={() => toggleChildren()}>
        {properties.name} <ChevronDownIcon className={`w-5 h-5 ml-2 ${isOpen ? 'transform rotate-180' : ''}`} />
      </legend>
      <div className={`${isOpen ? 'block' : 'hidden'}`}>
        <div className="mt-2">{properties.children}</div>
      </div>
    </div>
  );
};