import { useQuery } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface ObjectData {
  message: string;
  data: {
    status: string,
    timestamp: number,
    title: string,
    objectType: string,
    bundleFunction: string,
    targets: {
        target: string,
        event: string
    }[],
    log: {
      message: string,
      level: string,
      time: number,
      json: string
    }[]
  }[];
}

export function useObjectChain(uuid: string, container: string, chainUuid: string) {
  const { keycloak } = useKeycloak();

  return useQuery(['object-chain', uuid, container, chainUuid], {
    enabled: !!uuid && !!container,
    queryFn: async (): Promise<ObjectData> => {
      const body = {
        uuid: uuid,
        container: container,
        chainUuid: chainUuid
      };

      const response = await fetch(`${import.meta.env.VITE_IMPORT_ENGINE_ENDPOINT}/api/v1/bundles/query_bundle/functions/get_object_running_chain`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${keycloak.token}`
        },
        method: 'POST',
        body: JSON.stringify(body),
      });

      return response.json();
    },
    refetchInterval: 5000
  });
}
