import { FieldProps } from '@rjsf/utils';
import clsx from 'clsx';
import { ChangeEvent, FocusEvent } from 'react';
import { useEdges, Handle, Position, useNodeId } from 'reactflow';

interface RelationHandle {
  id: string;
}

export function RelationField({
  formData,
  name,
  schema,
  onBlur,
  onChange,
  idSchema,
}: FieldProps) {
  const nodeId = useNodeId();
  const edges = useEdges();
  let hasConnection = false;
  const sources: RelationHandle[] = [
    ...(schema?.sources ?? []),
    ...(formData?.sources ?? []),
  ];
  const targets: RelationHandle[] = [
    ...(schema?.targets ?? []),
    ...(formData?.targets ?? []),
  ];

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    onChange({
      attribute: name,
      type: 'absolute',
      value: e.target.value,
    });
  }

  function handleBlur(e: FocusEvent<HTMLInputElement>) {
    onBlur(name, { attribute: name, type: 'absolute', value: e.target.value });
  }

  return (
    <div className="form-group">
      <label className="control-label">{schema.title}</label>
      <div className="relative">
        {sources.map((handle) => {
          const [type] = handle.id.split('|');

          const className = clsx(
            type === 'attribute' && 'bg-blue-500',
            type === 'attribute' && targets.length === 0 && 'top-1',
            type === 'attribute' && targets.length > 0 && 'top-2',
            type === 'value' && 'bg-orange-300',
            type === 'value' && targets.length === 0 && 'top-4',
            type === 'value' && targets.length > 0 && 'top-9',
            type === 'value_v2' && 'bg-orange-300',
            type === 'value_v2' && targets.length === 0 && 'top-4',
            type === 'value_v2' && targets.length > 0 && 'top-9',
            'after:h-5 after:w-5 -right-[4px] after:absolute h-3 w-3 after:rounded-full after:-translate-x-1/2 after:-translate-y-1/2 after:top-1/2 after:left-1/2'
          );

          return (
            <Handle
              key={handle.id}
              id={handle.id}
              type="source"
              position={Position.Right}
              className={className}
            />
          );
        })}
        {targets.map((handle, i: number) => {
          const [_, parentField, index, childField] = idSchema.$id.split('_');
          const targetId = index
            ? `${parentField}_${childField}_${index}`
            : handle.id;

          hasConnection = !!edges.find((edge) => {
            return edge.targetHandle === targetId && edge.target === nodeId;
          });

          const className = clsx(
            i === 0 && 'top-4.5',
            'after:h-5 after:w-5 -left-1.5 after:absolute h-3 w-3 bg-black after:rounded-full after:-translate-x-1/2 after:-translate-y-1/2 after:top-1/2 after:left-1/2'
          );

          return (
            <Handle
              key={idSchema.$id}
              id={targetId}
              type="target"
              position={Position.Left}
              className={className}
            />
          );
        })}
        <input
          name={name}
          className={`form-control ${
            (hasConnection || sources.length > 0) &&
            'bg-gray-100 cursor-not-allowed'
          } ${sources.length > 0 && 'h-5'}`}
          type="text"
          value={
            formData?.runtimeValue !== undefined && formData?.runtimeValue !== null
            ? formData?.runtimeValue
            : (formData?.type === 'value_v2'
                ? `${formData?.abstractUuid}.${formData?.attribute}`
                : formData?.value || '')
          }
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={hasConnection || sources.length > 0}
        />
      </div>
    </div>
  );
}
