import clsx from 'clsx';
import { Switch } from '@headlessui/react';

export interface ToggleProps {
  enabled?: boolean;
  // @todo Set correct type
  onChange?: (() => {}) | any;
  label?: string;
}

export function Toggle({ enabled, label, onChange }: ToggleProps) {
  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={enabled}
        onChange={onChange}
        className={clsx(
          enabled ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
        )}
      >
        <span
          aria-hidden="true"
          className={clsx(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
      {label ? (
        <Switch.Label as="span" className="ml-3">
          <span className="text-sm font-medium text-gray-900 dark:text-gray-100">
            {label}
          </span>
        </Switch.Label>
      ) : null}
    </Switch.Group>
  );
}
