import { useEffect, useState } from 'react';
import { resolveData } from '@/features/populate/lib/helpers';
import { DropZone } from '@measured/puck';

interface ExternalConfig {
  editor: any;
  uiSchema: any;
}

export function useExternalConfig() {
  const [config, setConfig] = useState<ExternalConfig | null>(null);

  useEffect(() => {
    (async () => {
      const module = await import(
        /* @vite-ignore */ `${import.meta.env.VITE_COMPONENTS_URL}`
      );
      setConfig({
        editor: module.config(resolveData, DropZone),
        uiSchema: module.uiSchema,
      });
    })();
  }, []);

  return config;
}
