import React from 'react';

// @TODO: fix the any
interface ChartProviderProps {
  children: React.ReactNode;
  provider: any;
}

export function ChartProvider({provider: DataProvider, children}: ChartProviderProps) {
  const { data: data } = DataProvider();

  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child as React.ReactElement<any>, { data: data?.series })
      )}
    </>
  )
}