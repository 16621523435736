import { useQuery } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

interface LogItems {
  logs: LogItem[];
}

type LogItem = {
  message: string,
  level: string,
  time: number,
  json: string
};

interface Title {
  title: string;
}

export function useLog(uuid: string) {
  const { keycloak } = useKeycloak();

  return useQuery(['log'], {
    queryFn: async (): Promise<LogItems & Title> => {
      const body = {
        uuid,
      };

      const response = await fetch(`${import.meta.env.VITE_IMPORT_ENGINE_ENDPOINT}/api/v1/bundles/query_bundle/functions/get_log`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${keycloak.token}`
        },
        method: 'POST',
        body: JSON.stringify(body),
      });

      let data = await response.json();

      // console.log(data);

      const { logs, title } = data?.data;

      return {
        logs,
        title
      };
    }
  });
}
