import {
  CheckboxWidget,
  RteWidget,
  SelectWidget,
  TextareaWidget,
} from '@/components/form';
import { RelationField } from '@/components/form/fields/relation-field';
import { RegistryFieldsType, RegistryWidgetsType } from '@rjsf/utils';

export const widgets: RegistryWidgetsType = {
  CheckboxWidget,
  SelectWidget,
  TextareaWidget,
  rte: RteWidget,
};

export const fields: RegistryFieldsType = { relation: RelationField };
