import { useMutation } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

export interface UpsertPayload {
  uuid: string;
}

export function useRerunGraphAction() {
  const { keycloak } = useKeycloak();

  return useMutation({
    mutationFn: async (payload: UpsertPayload) => {
      const res = await fetch(
        `${
          import.meta.env.VITE_API_URL
        }/bundles/graph_bundle/functions/rerun_graph_action`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
          },
          method: 'POST',
          body: payload ? JSON.stringify(payload) : '',
        }
      );

      return res.json();
    },
  });
}
