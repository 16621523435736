import GridLayout, { WidthProvider } from 'react-grid-layout';

import { ImportWidget } from '@/components/import-widget';
import { JobsWidget } from '@/components/jobs-widget';
import { JobProgressWidget } from '@/components/job-progress-widget';
import { Page } from '@/components/page';
import { Toggle } from '@/components/toggle';
import { useState } from 'react';

const layout = [
  { i: 'a', x: 0, y: 0, w: 15, h: 10 },
  { i: 'b', x: 0, y: 10, w: 15, h: 10 },
  { i: 'c', x: 15, y: 1, w: 25, h: 20 },
];

const AutoWidthGridLayout = WidthProvider(GridLayout);

export function Import() {
  const [isDraggable, setIsDraggable] = useState(false);


  return (
    <Page
      title="Import"
      toolbar={
        <Toggle
          enabled={isDraggable}
          label="Enable drag"
          onChange={setIsDraggable}
        />
      }
    >
      <AutoWidthGridLayout
        className="layout"
        layout={layout}
        cols={40}
        rowHeight={30}
        isDraggable={isDraggable}
      >
        <div key="a">
          <div className="h-full w-full flex flex-col">
            <ImportWidget />
          </div>
        </div>
        <div key="b">
          <div className="h-full w-full flex flex-col">
            <JobProgressWidget />
          </div>
        </div>
        <div key="c">
          <div className="h-full w-full flex flex-col">
            <JobsWidget />
          </div>
        </div>
      </AutoWidthGridLayout>
    </Page>
  );
}