import React from 'react';
import {
  getTemplate,
  getUiOptions,
  ArrayFieldTemplateProps,
  ArrayFieldTemplateItemType,
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema,
} from '@rjsf/utils';
import clsx from 'clsx';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

export function ArrayField<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(props: ArrayFieldTemplateProps<T, S, F>) {
  const {
    canAdd,
    className,
    disabled,
    idSchema,
    uiSchema,
    items,
    onAddClick,
    readonly,
    registry,
    required,
    schema,
    title,
  } = props;
  const uiOptions = getUiOptions<T, S, F>(uiSchema);
  const isCollapsible = !!uiOptions?.collapsible;
  const [collapsed, setIsCollapsed] = React.useState<boolean>(isCollapsible);
  const ArrayFieldDescriptionTemplate = getTemplate<
    'ArrayFieldDescriptionTemplate',
    T,
    S,
    F
  >('ArrayFieldDescriptionTemplate', registry, uiOptions);
  const ArrayFieldItemTemplate = getTemplate<'ArrayFieldItemTemplate', T, S, F>(
    'ArrayFieldItemTemplate',
    registry,
    uiOptions
  );
  const ArrayFieldTitleTemplate = getTemplate<
    'ArrayFieldTitleTemplate',
    T,
    S,
    F
  >('ArrayFieldTitleTemplate', registry, uiOptions);
  // Button templates are not overridden in the uiSchema
  const {
    ButtonTemplates: { AddButton },
  } = registry.templates;

  const bodyClassName = clsx(isCollapsible && collapsed && 'hidden');

  function handleLegendClick() {
    if (!isCollapsible) {
      return;
    }

    setIsCollapsed(!collapsed);
  }

  return (
    <fieldset className={className} id={idSchema.$id}>
      {isCollapsible ? (
        <button
          type="button"
          onClick={handleLegendClick}
          className="flex justify-between items-center w-full"
        >
          <ArrayFieldTitleTemplate
            idSchema={idSchema}
            title={uiOptions.title || title}
            required={required}
            schema={schema}
            uiSchema={uiSchema}
            registry={registry}
          />

          {collapsed ? (
            <ChevronDownIcon
              focusable={false}
              aria-hidden={true}
              className="w-4 h-4"
            />
          ) : (
            <ChevronUpIcon
              focusable={false}
              aria-hidden={true}
              className="w-4 h-4"
            />
          )}
        </button>
      ) : (
        <ArrayFieldTitleTemplate
          idSchema={idSchema}
          title={uiOptions.title || title}
          required={required}
          schema={schema}
          uiSchema={uiSchema}
          registry={registry}
        />
      )}
      <ArrayFieldDescriptionTemplate
        idSchema={idSchema}
        description={uiOptions.description || schema.description}
        schema={schema}
        uiSchema={uiSchema}
        registry={registry}
      />
      <div className={bodyClassName}>
        <div>
          {items &&
            items.map(
              ({ key, ...itemProps }: ArrayFieldTemplateItemType<T, S, F>) => (
                <ArrayFieldItemTemplate key={key} {...itemProps} />
              )
            )}
        </div>
        {canAdd && (
          <AddButton
            onClick={onAddClick}
            disabled={disabled || readonly}
            registry={registry}
            uiSchema={uiSchema}
          />
        )}
      </div>
    </fieldset>
  );
}
