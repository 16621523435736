import { DragEvent, useState } from 'react';
import clsx from 'clsx';

export enum DropEffect {
  Move = 'move',
  Copy = 'copy',
}

interface DragDropProps {
  activeClassName?: string;
  children: ({
    isActive,
    isIdle,
    isDropping,
    data,
  }: {
    isActive: boolean;
    isIdle: boolean;
    isDropping: boolean;
    data: DragDropData;
  }) => React.ReactNode;
  className?: string;
  droppingClassName?: string;
  onDrop: ((data: Record<string, unknown>) => void) | undefined;
  allowedTypes: string[];
}

interface DragDropData {
  data: { widgetId?: string };
}

export type DragDropChildren = {
  isActive: boolean;
} & DragDropData;

export function DragDrop({
  activeClassName,
  children,
  className,
  droppingClassName,
  onDrop,
  allowedTypes,
}: DragDropProps) {
  const [status, setStatus] = useState<'DROPPED' | 'DROPPING' | 'IDLE'>('IDLE');
  const [data, setData] = useState<DragDropData>({ data: {} });

  const isActive = status === 'DROPPED';
  const isDropping = status === 'DROPPING';
  const isIdle = status === 'IDLE';

  function handleDrop(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();

    const parsedData = JSON.parse(e.dataTransfer.getData('text/plain'));

    if (!allowedTypes.includes(parsedData.type)) {
      setStatus('IDLE');
      return false;
    }

    onDrop?.(parsedData);

    setStatus('DROPPED');
    setData(parsedData.value);
  }

  function handleDragOver(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = DropEffect.Move;

    setStatus('DROPPING');
  }

  function handleDragLeave(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();

    setStatus('IDLE');
  }

  const currentClassName = clsx(
    className,
    isActive && activeClassName,
    isDropping && droppingClassName
  );

  return (
    <div
      className={currentClassName}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {children({ isActive, isDropping, isIdle, data })}
    </div>
  );
}

interface DragabbleProps {
  children: React.ReactNode;
  className?: string;
  type: string;
  value: any;
}

export function Dragabble({
  children,
  className,
  type,
  value,
}: DragabbleProps) {
  function handleDragStart(e: DragEvent) {
    e.dataTransfer.setData(
      'text/plain',
      JSON.stringify({
        type,
        value,
      })
    );
    e.dataTransfer.dropEffect = DropEffect.Copy;
  }

  const baseClassName = clsx('cursor-move nodrag', className);

  return (
    <div
      draggable={true}
      className={baseClassName}
      onDragStart={handleDragStart}
    >
      {children}
    </div>
  );
}
