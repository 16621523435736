import { Widget } from "@/components/widget";
import ReactECharts from 'echarts-for-react';
import { useEffect, useState } from "react";

const WIDGET_ID = 'LINE_CHART_WIDGET';

interface LineChartProps {
  title?: string;
  data?: Data[];
  xAxis: string[]
}

type Data = number | { value: number };

interface LineSeries {
  type: string;
  data: Data[],
  areaStyle: {}
}

export function LineChartWidget({ title, data, xAxis }: LineChartProps) {
  const [series, setSeries] = useState<LineSeries[]>();

  const options = {
    color: [
      'rgb(199, 210, 254)',
      'rgb(165, 180, 252)',
      'rgb(129, 140, 248)',
      'rgb(99, 102, 241)',
      'rgb(79, 70, 229)',
      'rgb(67, 56, 202)',
      'rgb(55, 48, 163)',
      'rgb(49, 46, 129)'
    ],
    xAxis: {
      type: 'category',
      data: xAxis
    },
    yAxis: {
      type: 'value',
      min: 0,
      minInterval: 1
    },
    series: series
  };

  useEffect(()=> {
    if (!data) {
      return;
    }

    setSeries([{
      type: 'line',
      data: data,
      areaStyle: {}
    }]);
  }, [data]);

  return (
    <Widget title={title ? title : "Statistics"} id={WIDGET_ID}>
      {data &&
        <ReactECharts option={options} style={{ width: '100%' }} notMerge={false} />
      }
    </Widget>
  );
}