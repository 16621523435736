import { ArrowUpIcon } from '@heroicons/react/24/outline';
import {
  StrictRJSFSchema,
  RJSFSchema,
  FormContextType,
  IconButtonProps,
} from '@rjsf/utils';

import { Button } from '@/components/button';

export function MoveUpButton<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>({ onClick }: IconButtonProps<T, S, F>) {
  return (
    <Button onClick={onClick} variant="secondary">
      <ArrowUpIcon className="h-4 w-4" aria-hidden="true" focusable="false" />
    </Button>
  );
}
