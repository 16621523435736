import GridLayout, { WidthProvider } from 'react-grid-layout';

import { Page } from '@/components/page';
import { GridPanel } from '@/components/grid-panel';
import { ExploreWidget } from '@/components/explore-widget';
import { TreeStoreProvider } from '@/context/tree-context';

const layout = [
  { i: 'sidebar_left', x: 0, y: 0, w: 20, h: 25 },
  { i: 'sidebar_right', x: 20, y: 0, w: 20, h: 25 },
];

const AutoWidthGridLayout = WidthProvider(GridLayout);

export function DragDrop() {
  return (
    <Page title="Explore Side by Side">
      <AutoWidthGridLayout
        className="layout"
        layout={layout}
        cols={40}
        rowHeight={25}
        isDraggable={false}
      >
        <GridPanel key="sidebar_left">
          <TreeStoreProvider name="explore-b">
            <ExploreWidget />
          </TreeStoreProvider>
        </GridPanel>
        <GridPanel key="sidebar_right">
          <TreeStoreProvider name="explore-c">
            <ExploreWidget />
          </TreeStoreProvider>
        </GridPanel>
      </AutoWidthGridLayout>
    </Page>
  );
}
