import * as Dialog from '@radix-ui/react-dialog';
import { SyntheticEvent, memo, useEffect, useState } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { useNodePagination } from '@/hooks/use-node-pagination';
import { useTree } from '@/hooks/use-tree';
import { PaginationSlider } from '@/components/pagination-slider';
import { useTreeStore } from '@/context/tree-context';
import { usePanel } from '@/context/panel-context';
import {
  ChatBubbleOvalLeftEllipsisIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { DragDrop } from '@/components/drag-drop';
import {
  useRelationInsert,
  RelationPayload,
  ObjectParams,
} from '@/hooks/use-relation-insert';
import { PlusIcon } from '@heroicons/react/24/outline';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { useUserOrganisationCollections } from '@/hooks/user/use-user-organisation-collections';
import { SelectMenu } from '@/components/select-menu';
import { TreeAction, TreeState } from '@/tree-store';
import { useQueryClient } from '@tanstack/react-query';
import { Transition } from '@headlessui/react';
import { Editor } from '@monaco-editor/react';
import { useUserOrganisationAddColletion } from '@/hooks/user/use-user-organisation-add-collection';
import { useUserConfig } from '@/hooks/user/use-user-config';
import { QueryBuilder } from '@/components/query-builder';
import { Button } from '@/components/button';
import {
  formatQuery,
  defaultRuleProcessorElasticSearch,
  Option,
} from 'react-querybuilder';
import { ViewMode, ViewModeSwitch } from '@/components/view-mode-switch';
import { RuleGroupType } from 'react-querybuilder';
import { useEmitter } from '@/hooks/use-emitter';
import { use } from 'echarts';

const treeSelector = (state: TreeState & TreeAction) => ({
  container: state.container,
  openAttributeNode: state.openAttributeNode,
});

const monacoEditorOptions = {
  minimap: {
    enabled: false,
  },
  automaticLayout: true,
  readOnly: false,
};

const AttributeNode = ({ id, data: nodeData, isConnectable }: NodeProps) => {
  const openNode = useTreeStore((state) => state.openNode);
  const closeNode = useTreeStore((state) => state.closeNode);
  const openAttributeNodeAction = useTreeStore((state) => state.openAttributeNodeAction);
  const setSearchStringNode = useTreeStore((state) => state.setSearchStringNode);
  const mutation = useRelationInsert(nodeData.relatesTo);
  const containerRef = usePanel();
  const open = !!nodeData.open;
  const {
    limit,
    handleFinePageChange,
    handleRoughPageChange,
    maxFinePages,
    maxRoughPages,
    resetPagination,
    selectedPage,
    selectedFinePage,
  } = useNodePagination(id, nodeData?.paginationData, nodeData.total);
  const setViewMode = useTreeStore((state) => state.setViewMode);
  const [queryText, setQueryText] = useState<string>('');
  const [finePage, setFinePage] = useState<number>(selectedPage);
  const [roughPage, setRoughPage] = useState<number>(selectedFinePage);
  const { data: collectionData } = useUserOrganisationCollections();
  const [collectionOptions, setCollectionOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const addOrganisationCollection = useUserOrganisationAddColletion();
  const [esQueryInput, setEsQueryInput] = useState<string | null>(null);
  const [showCollectionForm, setShowCollectionForm] = useState<boolean>(false);
  const [query, setQuery] = useState<RuleGroupType>({
    combinator: 'and',
    rules: [],
  });

  const queryClient = useQueryClient();

  const { container, openAttributeNode } = useTreeStore(treeSelector);
  const setEsQuery = useTreeStore((state) => state.setEsQuery);
  const { data: config } = useUserConfig();
  const [isPanelActive, setIsPanelActive] = useState<boolean>(false);
  const [keywords, setKeywords] = useState<string | null>(nodeData.searchStringNode ?? null);
  const [selectedCollection, setSelectedCollection] = useState<Option | null>();

  const emitCollectionObject = useEmitter({
    id: 'EXPLORE_WIDGET',
    listeners: ['COLLECTION_WIDGET'],
  });

  const [queryBuilderFields, setQueryBuilderFields] = useState([
    {
      name: '',
      label: '----',
    },
  ]);

  useEffect(() => {
    setKeywords(nodeData.searchStringNode ?? null);
  }, [nodeData.searchStringNode]);

  useEffect(() => {
    if (nodeData?.collectionSchema?.properties) {
      const updatedFields = Object.entries(nodeData.collectionSchema.properties).map(
        ([key, value]) => ({
          name: value.querySearchKeyword ? `data.${key}.keyword` : `data.${key}`,
          label: value.title,
        })
      );
      
      setQueryBuilderFields([
        {
          name: '',
          label: '----',
        },
        ...updatedFields]);
    }
  }, [nodeData?.collectionSchema?.properties]);

  useEffect(() => {
    if (selectedFinePage === finePage) {
      return;
    }

    setFinePage(selectedFinePage);
  }, [selectedFinePage]);

  useEffect(() => {
    if (selectedPage === roughPage) {
      return;
    }

    setRoughPage(selectedPage);
  }, [selectedPage]);

  useEffect(() => {
    if (!collectionData) {
      return;
    }

    setCollectionOptions(
      collectionData.collections.map((collection) => ({
        label: collection.title,
        value: collection.uuid,
      }))
    );
  }, [collectionData]);

  useTree(
    {
      id: nodeData?.id,
      parentId: nodeData?.parentId,
      parentUuid: nodeData?.parentUuid,
      parentContainer: nodeData?.parentContainer,
      label: nodeData?.label,
      from: nodeData?.paginationData?.from || 0,
      size: limit,
      path: nodeData?.path,
      queryText,
      pitId: nodeData?.pitId,
      searchAfterArray: nodeData?.searchAfterArray,
      esQuery: nodeData?.esQuery,
      viewMode: nodeData?.viewMode,
      searchStringNode: nodeData?.searchStringNode,
    },
    {
      enabled: nodeData.open,
    }
  );

  function handleSearch(event: SyntheticEvent) {
    event.preventDefault();

    const target = event.target as HTMLFormElement;
    const formData = new FormData(target);
    const queryText = (formData.get('queryText') ?? '') as string;

    setQueryText(queryText);
  }

  function handleKeywordSubmit(event: SyntheticEvent) {
    event.preventDefault();

    setSearchStringNode(nodeData.id, keywords);
    resetPagination();
  }

  const className = clsx(
    'block overflow-hidden text-ellipsis text-xs text-center p-3 w-full rounded focus:ring focus:ring-offset-1 dark:text-gray-100',
    !open &&
      'bg-gray-200 focus:ring-gray-100 dark:bg-gray-500 dark:focus:ring-gray-600',
    open &&
      'bg-indigo-200 focus:ring-indigo-200 dark:bg-gray-600 dark:focus-ring-gray-700'
  );

  function handleDrop({ value }: { value: ObjectParams[] }) {
    const payload: RelationPayload = {
      target: {
        uuid: nodeData.parentUuid,
        container: nodeData.parentContainer,
        attribute: nodeData.attribute,
      },
      objects: value,
    };

    mutation.mutate(payload);
  }

  function handleChangeCollection(option: any) {
    if (!option) {
      setQuery({
        combinator: 'and',
        rules: [],
      });

      setKeywords("");
      setSearchStringNode(nodeData.id, null);

      return;
    }

    const collection = collectionData?.collections.find(
      (collection) => collection.uuid === option.value
    );
    if (!collection) {
      return;
    }

    setKeywords(collection.keywords);
    setSearchStringNode(nodeData.id, collection.keywords);

    setQuery(collection.query);

    setSelectedCollection(option);

    // const formattedQuery = formatQuery(collection.query, 'elasticsearch');

    // if (Object.keys(formattedQuery).length === 0) {
    //   setEsQuery(id!, null);
    //   return;
    // }

    // setEsQuery(id!, JSON.stringify(formattedQuery));
  }

  async function handleCreateCollection(form) {
    form.preventDefault();
    const title = form.target.title.value;
    const esQuery = esQueryInput;
    addOrganisationCollection
      .mutateAsync({
        title,
        query,
        organisationUid: config?.organisationConfig?.organisation_uid,
        container,
        keywords,
        baseSetType: nodeData.attribute === "" ? 'container' : 'field',
        baseSetConfig: {
          attribute: nodeData.attribute,
          uuid: nodeData.parentUuid,
          container: nodeData.parentContainer,
        }
      })
      .then(() => {
        queryClient.removeQueries(['userOrganisationCollections']);
      setShowCollectionForm(false);
      });
  }

  function handleCollectionFormCancel(e) {
    e.preventDefault();
    setShowCollectionForm(false);
  }

  const basePanelClassName = clsx(
    'bg-white shadow-lg sm:rounded-lg absolute top-4 right-4 bottom-4 max-w-[400px] w-full transform ease-in-out duration-500 dark:bg-gray-600 pb-5',
    isPanelActive && 'translate-x-0',
    !isPanelActive && 'translate-x-full'
  );

  function handlePanelActivate() {
    setIsPanelActive(true);
  }

  function handlePanelDeactivate() {
    setIsPanelActive(false);
  }

  function handleQueryChange(query) {
    setQuery(query);

    const formattedQuery = formatQuery(query, 'elasticsearch');

    if (Object.keys(formattedQuery).length === 0) {
      setEsQuery(id!, null);
      return;
    }

    setEsQuery(id!, JSON.stringify(formattedQuery));
    resetPagination();
  }

  function handleViewModeChange(viewMode: ViewMode) {
    setViewMode(nodeData.id, viewMode);
  }

  function handleSelectAll() {
    const formattedQuery = formatQuery(query, 'elasticsearch');

    emitCollectionObject({
      type: 'CollectionObject',
      data: {
        query: query,
        container: container,
        esQuery: JSON.stringify(formattedQuery),
        keywords: keywords,
        size: nodeData.total,
        title: selectedCollection?.label ?? '',
        baseSetType: nodeData.attribute === "" ? 'container' : 'field',
        baseSetConfig: {
          attribute: nodeData.attribute,
          uuid: nodeData.parentUuid,
          container: nodeData.parentContainer,
        }
      },
    });
  }

  return (
    <div>
      <div className="border-none w-[200px]">
        <Handle
          type="target"
          position={Position.Left}
          style={{ background: 'orange' }}
          // onConnect={(params) => console.log('handle onConnect', params)}
          isConnectable={isConnectable}
        />
        <Handle
          id="relation"
          type="source"
          position={Position.Right}
          style={{ background: '#555', marginTop: '8px' }}
          // onConnect={(params) => console.log('handle onConnect', params)}
          isConnectable={isConnectable}
        />
        <Handle
          id="transformation"
          type="source"
          position={Position.Right}
          style={{ background: 'orange', marginTop: '-8px' }}
          // onConnect={(params) => console.log('handle onConnect', params)}
          isConnectable={isConnectable}
        />
        <Dialog.Root open={nodeData.id === openAttributeNode} modal={false}>
          <Dialog.Trigger
            className={className}
            title={nodeData?.label}
            onClick={() => {
              if (!open) {
                openNode(nodeData.id);
              } else if (open && nodeData?.id !== openAttributeNode) {
                // closeNode(nodeData.id);
                openAttributeNodeAction(nodeData.id);
              } else {
                closeNode(nodeData.id);
              }
            }}
          >
            {nodeData?.label}
          </Dialog.Trigger>
          <Dialog.Portal container={containerRef?.current}>
            <Dialog.Content
              className={basePanelClassName}
              onPointerDownOutside={(event) => {
                event.preventDefault();
              }}
              onMouseOver={handlePanelActivate}
              onMouseLeave={handlePanelDeactivate}
            >
              <div className="absolute w-10 h-full right-0 translate-x-full"></div>
              <div className="px-4 py-5 sm:p-6 flex flex-col h-full overflow-y-scroll overflow-x-hidden">
                {nodeData?.total ? (
                  <>
                    <div className="space-y-4">
                      <PaginationSlider
                        value={roughPage}
                        min={1}
                        max={maxRoughPages}
                        onPageChange={(page) => {
                          setRoughPage(page);
                          handleRoughPageChange(page);
                        }}
                      />
                      <PaginationSlider
                        value={finePage}
                        min={1}
                        max={maxFinePages}
                        onPageChange={(page) => {
                          setFinePage(page);
                          handleFinePageChange(page);
                        }}
                      />
                      <p className="text-center text-xs dark:text-gray-100">
                        <strong>{nodeData?.paginationData?.from || 0}</strong> /{' '}
                        <strong>{nodeData.total}</strong>
                      </p>
                    </div>
                    {/* <hr className="my-8 dark:border-gray-500" /> */}
                  </>
                ) : null}
                {/* <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100">
                  Create Object
                </h3>
                <form
                  className="mt-5 sm:flex sm:items-center"
                  onSubmit={handleSearch}
                >
                  <div className="w-full sm:max-w-xs">
                    <label htmlFor="queryText" className="sr-only">
                      Message
                    </label>
                    <input
                      type="text"
                      name="queryText"
                      id="queryText"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:ring-0"
                      placeholder="i.e. Give me articles with the name Test"
                    />
                  </div>
                  <button
                    type="submit"
                    className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:ml-3 sm:mt-0 sm:w-auto dark:bg-indigo-900 dark:text-indigo-100 dark:hover:bg-indigo-700"
                  >
                    <ChatBubbleOvalLeftEllipsisIcon
                      className="w-5 h-5"
                      aria-hidden={true}
                    />
                    <span className="sr-only">Ask</span>
                  </button>
                </form> */}
                <hr className="my-8 dark:border-gray-500" />
                <div className="">
                  <div className="flex-1">
                  <form onSubmit={handleKeywordSubmit}>
                    <div className="flex items-end space-x-2">
                      <input
                        type="search"
                        name="searchString"
                        id="searchString"
                        className="block flex-1 w-full rounded-md border py-2 text-gray-900 shadow-sm border-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-indigo-500 sm:text-sm sm:leading-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Your keywords"
                        value={keywords}
                        onChange={(e) => setKeywords(e.target.value)}
                      />
                      <Button type="submit">Search</Button>
                    </div>
                  </form>
                  </div>
                </div>
                <hr className="my-8 dark:border-gray-500" />
                <div className="mb-8">
                  <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100 mb-5">
                    Collection
                  </h3>
                  <div className="flex-1 mb-4">
                    <SelectMenu
                      options={collectionOptions}
                      onChange={(value) => handleChangeCollection(value)}
                      clearable={true}
                    />
                  </div>
                  <QueryBuilder
                    onQueryChange={handleQueryChange}
                    query={query}
                    fields={queryBuilderFields}
                  />
                  {!showCollectionForm && (
                    <div className="mt-2 flex gap-2">
                      <Button
                        onClick={() =>
                          setShowCollectionForm(!showCollectionForm)
                        }
                      >
                        Add Collection
                      </Button>
                      <Button onClick={handleSelectAll}>Select All</Button>
                    </div>
                  )}
                  {showCollectionForm && (
                    <form className="mt-4" onSubmit={handleCreateCollection}>
                      <label
                        htmlFor="title"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Title
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="title"
                          id="title"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      {/* <label htmlFor="esQuery" className="block text-sm font-medium leading-6 text-gray-900 mt-4">
                      Elastic Query
                    </label>
                    <Editor
                      defaultValue='{"query": {}}'
                      language="json"
                      theme="vs-light"
                      height={200}
                      options={monacoEditorOptions}
                      className='mt-4'
                      onChange={(value) => setEsQueryInput(value)}
                    /> */}
                      <div className="mt-6 mb-4 flex items-center justify-end gap-x-6">
                        <button
                          className="text-sm font-semibold leading-6 text-gray-900"
                          onClick={(e) => handleCollectionFormCancel(e)}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  )}
                </div>
                {nodeData.open ? (
                  <ViewModeSwitch
                    viewMode={nodeData.viewMode || 'grid'}
                    onViewModeChange={handleViewModeChange}
                  />
                ) : null}
              </div>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      </div>
      <DragDrop
        allowedTypes={['object/insert']}
        onDrop={(data) => handleDrop(data)}
        className="h-5 absolute w-full left-0 top-0 -translate-y-1/2"
        droppingClassName="bg-blue-500/20"
      >
        {({ isDropping }) =>
          isDropping ? (
            <span className="flex justify-center items-center h-5 pointer-events-none">
              <PlusIcon className="h-4 w-4" />
            </span>
          ) : null
        }
      </DragDrop>
    </div>
  );
};

AttributeNode.displayName = 'AttributeNode';

export default memo(AttributeNode);
