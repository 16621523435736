import { Widget } from '@/components/widget';
import { useListener } from '@/hooks/use-listener';
import { Button } from '@/components/button';
import { useEffect, useState } from 'react';
import { usePreview } from '@/hooks/preview/use-preview';

const WIDGET_ID = 'PREVIEW_WIDGET';

export function PreviewWidget() {
  const [previewWindow, setPreviewWindow] = useState<Window | null>(null);
  const [previewData, setPreviewData] = useState<{
    uuid?: string;
    container?: string;
    previewUuid?: string;
  }>({});
  const { data } = usePreview(previewData);

  useListener({
    id: WIDGET_ID,
    listenerFn: (data) => {
      if (!data?.[0]) {
        return;
      }

      setPreviewData(data[0]);
    },
    type: 'Preview',
  });

  useEffect(() => {
    if (!previewWindow?.window) {
      return;
    }

    previewWindow?.postMessage(
      { data: data?.data || {}, schema: data?.schema || {} },
      '*'
    );
  }, [data, previewWindow]);

  function handleOpenPreviewClick() {
    if (!data?.schema?.previewUrl) {
      return;
    }

    if (!previewWindow?.window) {
      const newPreviewWindow = window.open(data.schema.previewUrl);
      setPreviewWindow(newPreviewWindow);
    }
  }

  return (
    <Widget id={WIDGET_ID} title="Preview">
      <div className="grid place-content-center h-full">
        <Button onClick={handleOpenPreviewClick}>Open preview</Button>
      </div>
    </Widget>
  );
}
