import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ChevronUpDownIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid'; // Add XIcon for clear
import clsx from 'clsx';
import { useState, useEffect } from 'react';

export interface Option {
  label: string;
  value: string;
}

interface SelectMenuProps {
  label: string;
  hideLabel?: boolean;
  name?: string;
  onChange?: (value: Option | null) => void; // Use Option | null for clearing
  selected?: Option | null;
  options: Option[];
  clearable?: boolean; // Add a clearable prop
  wrapText?: boolean;
}

export function SelectMenu({
  label,
  hideLabel,
  name,
  onChange,
  selected,
  options,
  clearable = false,
  wrapText = false,
}: SelectMenuProps) {
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(
    selected
  );

  useEffect(() => {
    setSelectedOption(selected);
  }, [selected]);

  const handleOptionChange = (option: Option | null) => {
    setSelectedOption(option);
    if (onChange) {
      onChange(option);
    }
  };

  const clearSelection = (e) => {
    e.stopPropagation();
    handleOptionChange(null);
  };

  const labelClassName = clsx(
    'block text-sm font-medium text-gray-700 dark:text-gray-100',
    hideLabel && 'sr-only'
  );

  return (
    <Listbox value={selectedOption} onChange={handleOptionChange} name={name}>
      {({ open }) => (
        <>
          <Listbox.Label className={labelClassName}>{label}</Listbox.Label>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
              {({ value }) => (
                <>
                  <span className="block truncate">
                    {value ? value.label : 'Please select'}
                  </span>
                  {clearable && value ? (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 z-1">
                      <XCircleIcon
                        className="h-5 w-5 text-gray-400 cursor-pointer"
                        aria-hidden="true"
                        onClick={(e) => clearSelection(e)}
                      />
                    </span>
                  ) : (
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  )}
                </>
              )}
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className={clsx(
                wrapText ? 'max-w-full' : '',
                'absolute z-10 mt-1 max-h-60 overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 max-w-full z-[10000]'
              )}>
                {options.map((option) => (
                  <Listbox.Option
                    key={option.value}
                    className={({ active }) =>
                      clsx(
                        active
                          ? 'text-white bg-indigo-600 dark:text-gray-700 dark:bg-indigo-50'
                          : 'text-gray-900 dark:text-white',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={clsx(
                            selected ? 'font-semibold' : 'font-normal',
                            'block',
                            wrapText ? 'whitespace-normal' : 'whitespace-nowrap'
                          )}
                        >
                          {option.label}
                        </span>

                        {selected ? (
                          <span
                            className={clsx(
                              active
                                ? 'text-white dark:text-gray-700'
                                : 'text-indigo-600 dark:text-indigo-50',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
