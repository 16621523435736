import { MapContainer, TileLayer, FeatureGroup, LayerGroup, Circle, Marker, Popup, GeoJSON, useMapEvents, useMap } from 'react-leaflet';
import { EditControl } from "react-leaflet-draw";
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { useEffect, useState } from 'react';
import L from 'leaflet';
import locateDrawTool from './locate-draw-tool';
import { geoMercator } from 'd3';

interface MapProps {
  geometries?: any[];
  loading?: boolean;
  totalCount?: number;
}

export function LeafletMap({ geometries = [], loading = true, totalCount = 0 }: MapProps) {
  L.Draw.Locate = locateDrawTool;

  L.DrawToolbar.prototype.options = {
    polyline: {},
    polygon: {},
    rectangle: {},
    circle: {},
    cut: {},
    locate: {},
    manualWkt: {},
    combine: {},
    uploadTrace: {}
  };

  L.DrawToolbar.prototype.getModeHandlers = function (map) {
    return [
      {
          enabled: this.options.marker,
          handler: new L.Draw.Marker(map, this.options.marker),
          title: 'Marker'
      },
      {
        enabled: this.options.locate,
        handler: new L.Draw.Locate(map, this.options.locate),
        title: 'Locate'
      },
    ]
  }

  const MapBoundsHandler = ({ geometries }: MapProps) => {
    const map = useMap();

    useEffect(() => {
      if (Array.isArray(geometries) && geometries.length > 0) {
        const bounds = L.geoJSON(geometries).getBounds();
        map.fitBounds(bounds);
      }
    }, [geometries, map]);

    return null;
  };




  // const _onDrawStart = (e) => {
  //   console.log("_onDrawStart120", e);
  //   e.target.locate({ setView: true, maxZoom: 15 });
  // };
  
  return (
    <MapContainer center={[51.505, -0.09]} zoom={13} style={{ height: "667px" }}>
      <TileLayer
        attribution='&copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
      />
      <FeatureGroup>
        <EditControl
          // onDrawStart={_onDrawStart}
          position='topright'
          draw={{
            rectangle: false,
            locate: true,
            marker: true
          }}
        />
      </FeatureGroup>

      <LayerGroup>
        {geometries && geometries.map((geoJson, index) => (
          <GeoJSON key={index} data={geoJson} style={{'color': 'rgb(99 102 241)'}} />
        ))}
      </LayerGroup>

      {/* <Marker position={[51.505, -0.09]}>
        <Popup>
          A pretty CSS3 popup. <br /> Easily customizable.
        </Popup>
      </Marker> */}
      {geometries && (
        <MapBoundsHandler geometries={geometries} />
      )}

      {loading && (
        <div className="z-[1000] absolute bg-white rounded left-2 bottom-2 flex p-2">
          <svg
            className="animate-spin h-5 w-5 text-indigo-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <span className="ml-2">{geometries.length} / {totalCount}</span>
        </div>
      )}
    </MapContainer>
  );
};