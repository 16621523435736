import { Button } from '@/components/button';
import { ComboBox } from '@/components/ui/combo-box';
import { Controller, useForm } from 'react-hook-form';
import { useGetUserAgents } from '@/features/agents/hooks/use-get-user-agents';
import { useNavigate } from 'react-router-dom';

interface LoadAgentFormInputs {
  agent: { label: string; value: string } | null;
}

export function LoadAgentForm() {
  const { data } = useGetUserAgents();
  const { handleSubmit, control } = useForm<LoadAgentFormInputs>();
  const navigate = useNavigate();

  const onLoadSubmit = (data: LoadAgentFormInputs) => {
    if (!data.agent?.value) {
      return;
    }

    navigate(`/agents/${data.agent.value}/test`);
  };

  return (
    <form onSubmit={handleSubmit(onLoadSubmit)}>
      <div className="flex w-full gap-2">
        <div className="shrink-1 min-w-0 grow">
          <Controller
            control={control}
            name="agent"
            render={({ field: { value, onChange } }) => (
              <ComboBox
                clearable
                label="Agent"
                options={data || []}
                value={value}
                onChange={onChange}
                wrapText={false}
              />
            )}
          />
        </div>
        <div className="self-end shrink-0 gap-2 flex">
          <Button type="submit">Load</Button>
        </div>
      </div>
    </form>
  );
}
