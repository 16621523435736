import {
  Background,
  Controls,
  Edge,
  Node,
  OnEdgesChange,
  OnNodesChange,
  OnSelectionChangeFunc,
  ReactFlow,
  ReactFlowProvider,
  useOnSelectionChange,
} from 'reactflow';
import { AttributeNode } from '@/components/attribute-node';
import { ImageNode } from '@/components/image-node';
import { ObjectNode } from '@/components/object-node';
import { ToolbarNode } from '@/components/toolbar-node';
import 'reactflow/dist/style.css';
import { useAutoLayout } from '@/hooks/use-auto-layout';
import { usePanel } from '@/context/panel-context';
import { RelationEdge } from '@/components/relation-edge';
import { GroupNode } from '@/components/group-node';
import { TableNode } from '@/components/table-node';

const edgeTypes = {
  relation: RelationEdge,
};

const nodeTypes = {
  attribute: AttributeNode,
  object: ObjectNode,
  image: ImageNode,
  toolbar: ToolbarNode,
  group: GroupNode,
  table: TableNode,
};

interface FlowProps {
  edges: Edge[];
  nodes: Node[];
  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;
  onSelectionChange?: OnSelectionChangeFunc;
}

function Flow({
  edges,
  nodes,
  onNodesChange,
  onEdgesChange,
  onSelectionChange,
}: FlowProps) {
  useAutoLayout();

  const containerRef = usePanel();

  useOnSelectionChange({
    onChange: onSelectionChange,
  });

  return (
    <div className="relative h-full" ref={containerRef}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        fitViewOptions={{ padding: 3 }}
        fitView={true}
        zoomOnScroll={false}
        panOnScroll={true}
        proOptions={{ hideAttribution: true }}
      >
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
}

export default function FlowWithProvider(props: FlowProps) {
  return (
    <ReactFlowProvider>
      <Flow {...props} />
    </ReactFlowProvider>
  );
}
