import { Widget } from '@/components/widget';
import { Fragment, useEffect } from 'react';
import moment from 'moment';
import ReactECharts from 'echarts-for-react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import cloneDeep from 'lodash.clonedeep';
import { useJobs } from '@/hooks/jobs/use-jobs';
import { useJobStop } from '@/hooks/jobs/use-job-stop';
import clsx from 'clsx';
import { useJobDownload } from '@/hooks/jobs/use-job-download';
// import { format } from 'date-fns';
import { saveAs } from 'file-saver';

const WIDGET_ID = 'JOBS_WIDGET';

interface Job {
  job_id: string;
  completed: boolean;
  type: string;
  created: number;
  user_uid: string;
  job_steps: {
    step: string;
    label: string;
  }[];
  container_id: string;
  current_job_step: string;
}

interface JobsProps {
  type?: string;
  title?: string;
}

export function JobsWidget({ type = 'import', title = 'Jobs' }: JobsProps) {
  const { data } = useJobs({ type: type });
  const stopJob = useJobStop();
  const downloadBlob = useJobDownload();
  // poll for jobs
  // const { data: userJobs } = useQuery(["user_jobs"],
  //   () => {
  //     return JobsService.getUserJobs({'userId': 0});
  //   }, {
  //     refetchInterval: 5000,
  //   }
  // );

  // const { mutateAsync: postDownloadZip } = useMutation(body => {
  //     return DownloadService.postDownloadZip(body);
  //   }
  // );

  // const { mutateAsync: postStopJob } = useMutation(body => {
  //     return JobsService.postStopJob(body);
  //   }
  // );

  // const handleDownload = async (zipPath, e) => {
  //   const postDownloadZipResponse = await postDownloadZip({
  //     "zipPath": zipPath,
  //   });

  //   saveAs(postDownloadZipResponse, 'download.zip');
  // }

  useEffect(() => {
    // check for downloads
    if (!data) {
      return;
    }

    // console.log(data);
    const downloads = data.jobs.filter(
      (job) =>
        job.type === 'download' &&
        job.zip_downloaded === false &&
        job.hasOwnProperty('zip_download_path')
    );

    downloads.forEach((download) => {
      const downloadAsync = async (download) => {
        const blobResponse = await downloadBlob.mutateAsync({
          jobId: download.job_id,
        });
        saveAs(blobResponse.blob, `${download.job_id}.zip`);
      };

      downloadAsync(download);
    });

    // console.log(downloads);
  }, [data]);

  const handleStopJob = (jobId: string) => {
    stopJob.mutateAsync({
      jobId: jobId,
    });
  };

  const options = {
    color: [
      'rgb(199, 210, 254)',
      'rgb(165, 180, 252)',
      'rgb(129, 140, 248)',
      'rgb(99, 102, 241)',
      'rgb(79, 70, 229)',
      'rgb(67, 56, 202)',
      'rgb(55, 48, 163)',
      'rgb(49, 46, 129)',
    ],
    tooltip: {},
    legend: {
      show: false,
    },
    grid: {
      right: 0,
      top: 0,
      bottom: 0,
      left: 0,
      containLabel: false,
    },
    xAxis: {
      show: false,
      type: 'value',
    },
    yAxis: {
      type: 'category',
      data: ['Progress'],
      show: false,
    },
    series: [
      {
        name: 'Remaining',
        type: 'bar',
        stack: 'total',
        label: {
          show: false,
        },
        emphasis: {
          disabled: true,
        },
        data: [100],
        itemStyle: {
          color: 'rgb(55, 65, 81, 0.1)',
          borderRadius: [0, 4, 4, 0],
        },
        tooltip: { formatter: '{a}\n{c}%', show: false },
      },
    ],
  };

  const getOptions = (job: Job) => {
    const jobOptions = cloneDeep(options);

    const stepCount = job.job_steps.length;
    const total = stepCount * 100;
    let remainingTotal = total;

    job.job_steps.forEach((step, i) => {
      // insert item at index
      jobOptions.series = [
        ...jobOptions.series.slice(0, i),
        {
          data: [0],
          name: step.label,
          type: 'bar',
          stack: 'total',
          tooltip: { formatter: '{a}\n{c}%' },
          itemStyle: { borderRadius: i === 0 ? [4, 0, 0, 4] : '' },
        },
        ...jobOptions.series.slice(i),
      ];

      const status = job.status[step.step];
      let progress: number;
      if (status) {
        progress = (status.processed / status.total) * 100;
      } else {
        progress = 0;
      }

      remainingTotal -= progress;

      jobOptions.series.forEach((series) => {
        if (series.name === step.label) {
          series.data[0] = progress;
        } else if (series.name === 'Remaining') {
          series.data[0] = remainingTotal;
        }
      });

      if (remainingTotal === 0) {
        jobOptions.series[stepCount - 1].itemStyle.borderRadius = [0, 4, 4, 0];
      }
    });

    return jobOptions;
  };

  return (
    <Widget title={title} id={WIDGET_ID}>
      <div className="flex flex-col">
        <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-700">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold bg-gray-50 md:rounded-tl-lg text-gray-900 sm:pl-6 max-w-28 dark:bg-gray-600 dark:text-gray-50"
                    >
                      Job ID
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold bg-gray-50 text-gray-900 max-w-28 dark:bg-gray-600 dark:text-gray-50"
                    >
                      Created
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold bg-gray-50 text-gray-900 dark:bg-gray-600 dark:text-gray-50"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold bg-gray-50 md:rounded-tr-lg text-gray-900 max-w-28 dark:bg-gray-600 dark:text-gray-50"
                    >
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
                  {data?.jobs.map((job) => (
                    <tr key={job.job_id}>
                      <td
                        className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 max-w-28 truncate overflow-hidden"
                        title={job.job_id}
                      >
                        DY-{job.job_id.toUpperCase().split('-')[0]}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 max-w-28 dark:text-gray-700">
                        {moment.unix(job.created).format('DD/MM/YYYY HH:mm')}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 max-w-28">
                        <ReactECharts
                          option={getOptions(job)}
                          style={{ width: '150px', height: '10px' }}
                        />
                        {/* {Object.keys(job._source.status).map((key, i, row) => (
                          <span key={key}>
                            {key} {Math.round((job._source.status[key].processed / job._source.status[key].total) * 100)}%
                            {i < row.length - 1 && (
                              <br/>
                            )}
                          </span>
                        ))} */}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 max-w-28">
                        <Menu
                          as="div"
                          className="relative inline-block text-left"
                        >
                          <div>
                            <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                              Actions
                              <ChevronDownIcon
                                className="-mr-1 ml-2 h-5 w-5"
                                aria-hidden="true"
                              />
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="">
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      href="#"
                                      className={clsx(
                                        active
                                          ? 'bg-gray-100 text-gray-900'
                                          : 'text-gray-700',
                                        'block px-4 py-2 text-sm'
                                      )}
                                    >
                                      Download
                                    </a>
                                  )}
                                </Menu.Item>
                              </div>
                              <div className="">
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      onClick={() => handleStopJob(job.job_id)}
                                      className={clsx(
                                        active
                                          ? 'bg-gray-100 text-gray-900'
                                          : 'text-gray-700',
                                        'block px-4 py-2 text-sm w-full text-left'
                                      )}
                                    >
                                      Stop
                                    </button>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Widget>
  );
}
