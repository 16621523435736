import { Page } from '@/components/page';
import { Widget } from '@/components/widget';
import {
  FormEvent,
  useEffect,
  useRef,
  useState,
  SyntheticEvent,
  useMemo,
} from 'react';
import { ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/outline';
import { FormSchemaEditor } from '@/components/form-schema-editor';
import { useUserDataclasses } from '@/hooks/user/use-user-dataclasses';
import { SelectMenu } from '@/components/select-menu';
import { useDataClass } from '@/hooks/use-data-class';
import { useDataClassRefine } from '@/hooks/use-data-class-refine';
import { useDataClassCreate } from '@/hooks/use-data-class-create';
import { Modal } from '@/components/modal';
import { modalText } from '@/config/modal';
import { Button } from '@/components/button';
import {
  ObjectData,
  useObjectSchemaUpsert,
} from '@/hooks/use-object-schema-upsert';
import Ajv from 'ajv';

const ajv = new Ajv();

export function FormEditor() {
  const [open, setOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const { data: dataClassData } = useUserDataclasses();
  const dataClassLoad = useDataClass();
  const formRef = useRef(null);
  const [schema, setSchema] = useState<object>({});
  const [uiSchema, setUiSchema] = useState<object>({});
  const [initialSchema, setInitialSchema] = useState<object>({});
  const [initialUiSchema, setInitialUiSchema] = useState<object>({});
  const dataClassRefine = useDataClassRefine();
  const dataClassCreate = useDataClassCreate();
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const [loadingDataClass, setLoadingDataClass] = useState<boolean>(false);
  const [createNewDataClassLoading, setCreateNewDataClassLoading] =
    useState<boolean>(false);
  const [refineDataClassLoading, setRefineDataClassLoading] =
    useState<boolean>(false);
  const objectSchemaUpsertMutation = useObjectSchemaUpsert();
  const [error, setError] = useState<string>();
  const [loadedDataClass, setLoadedDataClass] = useState<object>({});

  const handleSchemaChange = (newSchema: object) => {
    setSchema(newSchema);
  };

  const handleUiSchemaChange = (newUiSchema: object) => {
    setUiSchema(newUiSchema);
  };

  useEffect(() => {
    if (open || !containerRef.current) {
      return;
    }

    setOpen(true);
  }, []);

  function handleSearch(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    const target = event.target as HTMLFormElement;
    const formData = new FormData(target);

    // const queryText = (formData.get('queryText') ?? '') as string;
  }

  const handleLoadDataClass = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoadingDataClass(true);

    const target = event.target as HTMLFormElement;
    const formData = new FormData(target);
    const dataClassUuid = formData.get('loadDataclass[value]') as string | null;

    if (!dataClassUuid) {
      return;
    }

    dataClassLoad.mutateAsync(dataClassUuid).then((response) => {
      setLoadedDataClass(response.dataClass);
      setInitialSchema(response.dataClass.schema);
      setInitialUiSchema(response.dataClass.uiSchema);
      setLoadingDataClass(false);
    });
  };

  const handleCreateNew = (e: SyntheticEvent) => {
    e.preventDefault();

    if (!formRef.current) {
      return;
    }

    setCreateNewDataClassLoading(true);

    const formData = new FormData(formRef.current);
    const queryText = (formData.get('queryText') ?? '') as string;

    dataClassCreate
      .mutateAsync({
        input: queryText,
        schema: schema,
        uiSchema: uiSchema,
      })
      .then((created) => {
        setInitialSchema(created.dataClass.schema);
        setInitialUiSchema(created.dataClass.uiSchema);
        setCreateNewDataClassLoading(false);
      });
  };

  const handleRefineExisting = (e: SyntheticEvent) => {
    e.preventDefault();

    if (!formRef.current) {
      return;
    }

    setRefineDataClassLoading(true);

    const formData = new FormData(formRef.current);
    const queryText = (formData.get('queryText') ?? '') as string;

    dataClassRefine
      .mutateAsync({
        input: queryText,
        schema: schema,
        uiSchema: uiSchema,
      })
      .then((refined) => {
        setInitialSchema(refined.dataClass.schema);
        setInitialUiSchema(refined.dataClass.uiSchema);
        setRefineDataClassLoading(false);
      });
  };

  const handleDataClassSaveConfirmation = (e: SyntheticEvent) => {
    e.preventDefault();
    setError(undefined);

    if (!ajv.validateSchema(schema)) {
      setError('Invalid schema. Please correct the schema and try again.');
    }

    const formData = new FormData(formRef.current);
    const dataClassUuid = formData.get('dataclass[value]') as string;

    if (dataClassUuid === '_new') {
      handleDataClassSave();
    } else {
      setShowSaveModal(true);
    }
  };

  const handleDataClassSaveCancel = () => {
    setShowSaveModal(false);
    setError(undefined);
  };

  const handleDataClassSave = () => {
    if (!formRef.current || error) {
      return;
    }

    const formData = new FormData(formRef.current);
    const dataClassUuid = formData.get('dataclass[value]') as string;

    const objectData: ObjectData = {
      uuid: dataClassUuid === '_new' ? undefined : dataClassUuid,
      schema: schema,
      uiSchema: uiSchema,
      title: formData.get('title') as string,
    };

    objectSchemaUpsertMutation.mutate(objectData, {
      onSuccess: () => {
        setShowSaveModal(false);
      },
    });
  };

  const dataClassOptions = useMemo(() => {
    return [
      {
        label: 'Create new',
        value: '_new',
      },
      ...(dataClassData?.dataClasses || []),
    ];
  }, [dataClassData?.dataClasses]);

  return (
    <Page title="Schemas">
      <div className="translate-x-0 translate-y-0 overflow-hidden rounded-lg">
        <Widget id="FORM_EDITOR" title="Form Editor">
          <div ref={containerRef} className="h-[800px] relative">
            <FormSchemaEditor
              initialSchema={initialSchema}
              initialUiSchema={initialUiSchema}
              onSchemaChange={handleSchemaChange}
              onUiSchemaChange={handleUiSchemaChange}
            />
            <div className="bg-white shadow-lg sm:rounded-lg absolute top-4 right-4 bottom-4 max-w-[400px] w-full transform translate-x-full hover:translate-x-0 ease-in-out duration-500 group dark:bg-gray-600">
              <div className="absolute w-10 h-full right-0 translate-x-full"></div>
              <div className="px-4 py-5 sm:p-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100">
                  Create Object
                </h3>
                <form className="mt-5 space-y-5" onSubmit={handleLoadDataClass}>
                  <div className="flex items-end space-x-2">
                    <div className="flex-1">
                      {dataClassData?.dataClasses && (
                        <SelectMenu
                          label=""
                          options={dataClassData?.dataClasses}
                          name="loadDataclass"
                          selected={dataClassData?.dataClasses[0]}
                        />
                      )}
                    </div>
                    <Button type="submit" loading={loadingDataClass}>
                      Load
                    </Button>
                  </div>
                </form>
                <form
                  ref={formRef}
                  className="mt-5 space-y-5"
                  onSubmit={handleSearch}
                >
                  <div>
                    <label htmlFor="queryText" className="sr-only">
                      Message
                    </label>
                    <textarea
                      name="queryText"
                      id="queryText"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:ring-0"
                      placeholder="Paste your data here"
                      rows={10}
                    />
                  </div>
                  <div className="flex items-end space-x-2">
                    <Button
                      type="submit"
                      loading={createNewDataClassLoading}
                      onClick={handleCreateNew}
                    >
                      Create New
                    </Button>
                    <Button
                      type="submit"
                      loading={refineDataClassLoading}
                      onClick={handleRefineExisting}
                    >
                      Refine Existing
                    </Button>
                  </div>
                  <div>
                    {error ? (
                      <div className="mb-2 text-red-600 text-xs">{error}</div>
                    ) : null}
                    <input
                      name="title"
                      id="title"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:ring-0 mb-2"
                      type="text"
                      defaultValue={loadedDataClass?.title}
                    />
                    <div className="flex items-end space-x-2">
                      <div className="flex-1">
                        {dataClassOptions && (
                          <SelectMenu
                            label=""
                            options={dataClassOptions}
                            name="dataclass"
                            selected={dataClassOptions[0]}
                          />
                        )}
                      </div>
                      <Button
                        type="submit"
                        loading={objectSchemaUpsertMutation.isLoading}
                        onClick={handleDataClassSaveConfirmation}
                      >
                        Save As
                      </Button>
                    </div>
                    {showSaveModal && (
                      <Modal
                        onCancel={handleDataClassSaveCancel}
                        onSubmit={handleDataClassSave}
                        loading={objectSchemaUpsertMutation.isLoading}
                        title={modalText.overwriteDataClassTitle}
                        message={modalText.overwriteDataClassMessage}
                        actionName={modalText.overwriteDataClassActionName}
                      />
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Widget>
      </div>
    </Page>
  );
}
